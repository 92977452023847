import { Icon } from '@chakra-ui/icons';

export const CloudIcon = (
) => (
    <Icon
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        marginTop={'4px'}
        marginRight={'20px'}
        viewBox="0 0 24 24"
        fill="none"
    >
        <g clip-path="url(#clip0_8852_222772)">
            <path
                d="M5 12H9V14H5C2.243 14 0 11.757 0 9C0 6.243 2.243 4 5 4C5.533 4 6.059 4.086 6.562 4.254C7.643 1.699 10.142 0 13 0C16.859 0 20 3.141 20 7C20 8.487 19.53 9.865 18.737 11H15.969C17.194 10.088 18 8.642 18 7C18 4.243 15.757 2 13 2C10.664 2 8.662 3.591 8.135 5.867C8.06 6.184 7.838 6.445 7.536 6.568C7.234 6.689 6.892 6.659 6.619 6.482C6.129 6.167 5.568 6 5 6C3.346 6 2 7.346 2 9C2 10.654 3.346 12 5 12Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 24H23C23.553 24 24 23.553 24 23V16C24 15.447 23.553 15 23 15H17.414L15.707 13.293C15.52 13.105 15.266 13 15 13H12C11.447 13 11 13.447 11 14V23C11 23.553 11.447 24 12 24ZM22 22H13V15H14.586L16.293 16.707C16.48 16.895 16.734 17 17 17H22V22Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_8852_222772">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </Icon>
);
export default CloudIcon;