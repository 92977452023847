/* eslint-disable no-console */
import {
    AccountInfo,
    BackButton,
    DesktopView,
    MobileView,
    TextScript,
    TextTranslation,
    getTextScript,
    useAccount,
    useConfig,
} from '@MGPD/myasurion-shared';
import { Box, Center, Grid, GridItem } from '@chakra-ui/layout';
import { Button, Flex, Input, Spinner, Stack, Text, useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { boxShadow } from '../../../App';
import FloatLabelInputField from '../components/FloatLabelInputField';
import MenuSidebar from '../components/MenuSidebar';
import NotificationItem, { NotificationItemApps } from '../components/NotificationItem';
import FooterLayout from '../layout/FooterLayout';
import MainStackLayout from '../layout/MainStackLayout';
import { labels, notificationPreferences } from '../modules/config/constants';
import { defaultToastProps } from '../modules/config/constants';
import { updateProfile } from '../modules/services/apiService';

let newAccount: AccountInfo = { profileId: '', userId: '' };

const NotificationItems = ({
    personalizedNotificationPreferences,
}: {
    personalizedNotificationPreferences: NotificationItemApps;
}) => {
    const { notifLabel } = labels && labels.communication;
    return (
        <Box
            p={{ base: '0 16px', md: 0 }}
            w={'full'}
            bg={'#fff'}
            minH={'290px'}
            boxShadow={{ base: boxShadow, md: 'none' }}
        >
            <Box p={['16px', '16px 0']} w={'full'} bg={'#fff'}>
                {/* <Text fontSize="lg" fontWeight={400} lineHeight={'124%'}>
          <TextTranslation
            text={notifLabel}
            translationKey={'notifLabel'}
            module={'myAsurionProfile'}
            section={'notifications'}
            disabled={false}
          />
        </Text> */}
                <Box fontWeight={400} lineHeight={'124%'}>
                    <Text fontSize="24px" lineHeight="132%" color="#000" fontWeight="400">
                        <TextScript
                            text={notifLabel}
                            translationKey={`CommunicationPreferences/notifLabel`}
                        />
                    </Text>
                </Box>
            </Box>
            <MobileView>
                <Stack spacing={4} direction={'column'}>
                    {notificationPreferences.map((item, id) => {
                        return (
                            <NotificationItem
                                key={`myasurion-notiff-${id}`}
                                id={item.id}
                                title={item.title}
                                description={item.description}
                                apps={
                                    personalizedNotificationPreferences
                                        ? personalizedNotificationPreferences[item.id]
                                        : undefined
                                }
                            />
                        );
                    })}
                </Stack>
            </MobileView>
            <DesktopView>
                <Grid templateColumns={'repeat(2, 1fr)'} gap={'16px'} mb={'16px'}>
                    {notificationPreferences.map((item, id) => {
                        return (
                            <GridItem key={`myasurion-notiff-${id}`}>
                                <NotificationItem
                                    id={item.id}
                                    title={item.title}
                                    description={item.description}
                                    apps={
                                        personalizedNotificationPreferences
                                            ? personalizedNotificationPreferences[item.id]
                                            : undefined
                                    }
                                />
                            </GridItem>
                        );
                    })}
                </Grid>
            </DesktopView>
        </Box>
    );
};

const CommunicationDetails = ({
    preferredName,
    preferredContactNumber,
    preferredEmail,
    preferredAddress,
    setPreferredName,
    setPreferredContactNumber,
    setPreferredEmail,
    setPreferredAddress,
    setPreferredNameError,
    setPreferredContactError,
    setPreferredEmailError,
}: {
    preferredName: string | undefined;
    preferredContactNumber: string | undefined;
    preferredEmail: string | undefined;
    preferredAddress: string | undefined;
    preferredNameError: string | undefined;
    preferredContactError: string | undefined;
    preferredEmailError: string | undefined;
    setPreferredName: (val: string) => void;
    setPreferredContactNumber: (val: string) => void;
    setPreferredEmail: (val: string) => void;
    setPreferredAddress: (val: string) => void;
    setPreferredNameError: React.Dispatch<React.SetStateAction<string | undefined>>;
    setPreferredContactError: React.Dispatch<React.SetStateAction<string | undefined>>;
    setPreferredEmailError: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
    const [fieldsLoading, setFieldsLoading] = useState<boolean>(false);
    const [namePlaceholder, setNamePlaceholder] = useState<string>('Preferred Name');
    const [contactNumberPlaceholder, setContactNumberPlaceholder] = useState<string>(
        'Preferred Contact Number'
    );
    const [emailPlaceholder, setEmailPlaceholder] = useState<string>('Preferred Email');
    const [addressPlaceholder, setAddressPlaceholder] = useState<string>('Preferred Address');
    const { caption1 } = labels && labels.communication;

    const getPlaceholderTexts = async () => {
        const preferredNamePlaceholder: string = await getTextScript(
            `CommunicationPreferences/preferred-name-placeholder`,
            'Preferred Name'
        );
        const preferredContactNumberPlaceholder: string = await getTextScript(
            `CommunicationPreferences/preferred-contact-number-placeholder`,
            'Preferred Contact Number'
        );

        const preferredEmailPlaceholder: string = await getTextScript(
            `CommunicationPreferences/preferred-email-placeholder`,
            'Preferred Email'
        );

        const preferredAddressPlaceholder: string = await getTextScript(
            `CommunicationPreferences/preferred-address-placeholder`,
            'Preferred Address'
        );

        setNamePlaceholder(preferredNamePlaceholder);
        setContactNumberPlaceholder(preferredContactNumberPlaceholder);
        setEmailPlaceholder(preferredEmailPlaceholder);
        setAddressPlaceholder(preferredAddressPlaceholder);
        setFieldsLoading(false);
    };

    useEffect(() => {
        getPlaceholderTexts();
    }, []);

    const preferredNameSchema = yup.object().shape({
        preferredName: yup
            .string()
            .required('As we have not registered any input, no changes will be made')
            .matches(
                /^[a-zA-Z ]*$/,
                'Your input seems to contain characters which are not alphabets. Please amend before clicking on Confirm again.'
            ),
    });

    const preferredContactSchema = yup.object().shape({
        preferredContact: yup
            .string()
            .required('As we have not registered any input, no changes will be made')
            .matches(
                /^\d+$/,
                'Your input seems to contain characters which are not numbers. Please amend before clicking on Confirm again.'
            ),
    });

    const preferredEmailSchema = yup.object().shape({
        preferredEmail: yup
            .string()
            .email('Please provide a valid email address')
            .required('As we have not registered any input, no changes will be made')
            .matches(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                'Please provide a valid email address'
            ),
    });

    return (
        <Box
            p={{ base: '16px', md: 0 }}
            w={'full'}
            bg={'#fff'}
            minH={'290px'}
            boxShadow={boxShadow}
        >
            <Box p={'16px 0'} w={'full'} bg={'#fff'} fontWeight={400} lineHeight={'124%'}>
                <Text fontSize="18px" lineHeight="132%">
                    <TextScript
                        text={caption1}
                        translationKey={`CommunicationPreferences/caption1`}
                    />
                </Text>
            </Box>
            {fieldsLoading ? (
                <Flex justifyContent="center" alignItems="center" direction="column">
                    Fetching translations...
                    <Spinner />
                </Flex>
            ) : (
                <Stack spacing={4} direction={'column'}>
                    {/* <TextInput
            placeholder={namePlaceholder}
            value={preferredName}
            onChange={(i) => setPreferredName(i.value)}
          /> */}
                    <FloatLabelInputField
                        inputPlaceholder={namePlaceholder}
                        defaultValue={preferredName ?? ''}
                        onChange={(i) => setPreferredName(i.target.value)}
                        inputId={namePlaceholder}
                        inputType={'text'}
                        variant="edit"
                        isRequired={true}
                        inputName="preferredName"
                        onError={(errorMessage) => setPreferredNameError(errorMessage)}
                        yupSchema={preferredNameSchema}
                    />
                    {/* <TextInput
            placeholder={contactNumberPlaceholder}
            value={preferredContactNumber}
            onChange={(i) => setPreferredContactNumber(i.value)}
          /> */}
                    <FloatLabelInputField
                        inputPlaceholder={contactNumberPlaceholder}
                        defaultValue={preferredContactNumber ?? ''}
                        onChange={(i) => setPreferredContactNumber(i.target.value)}
                        inputId={contactNumberPlaceholder}
                        inputType={'text'}
                        variant="edit"
                        inputName="preferredContact"
                        onError={(errorMessage) => setPreferredContactError(errorMessage)}
                        yupSchema={preferredContactSchema}
                    />
                    {/* <TextInput
            placeholder={emailPlaceholder}
            value={preferredEmail}
            onChange={(i) => {
              setPreferredEmail(i.value.toLowerCase());
            }}
          /> */}
                    <FloatLabelInputField
                        inputPlaceholder={emailPlaceholder}
                        defaultValue={preferredEmail ?? ''}
                        onChange={(i) => setPreferredEmail(i.target.value.toLowerCase())}
                        inputId={emailPlaceholder}
                        inputType={'email'}
                        variant="edit"
                        inputName="preferredEmail"
                        onError={(errorMessage) => setPreferredEmailError(errorMessage)}
                        yupSchema={preferredEmailSchema}
                    />
                    {/* <Input
            placeholder={emailPlaceholder}
            value={preferredEmail}
            onChange={(i) => setPreferredEmail(i.target.value.toLowerCase())}
          /> */}
                    {/* <TextInput
            placeholder={addressPlaceholder}
            value={preferredAddress}
            onChange={(i) => setPreferredAddress(i.value)}
            isDisabled={true}
          /> */}
                    <Input
                        placeholder={addressPlaceholder}
                        value={preferredAddress}
                        onChange={(i) => setPreferredAddress(i.target.value)}
                        isDisabled={true}
                    />
                </Stack>
            )}
        </Box>
    );
};

const FooterSection = ({
    preferredName,
    preferredContactNumber,
    preferredEmail,
    isUpdating,
    saveProfile,
    account,
    isSubmitDisabled = false,
}: {
    preferredName: string | undefined;
    preferredContactNumber: string | undefined;
    preferredEmail: string | undefined;
    isUpdating: boolean;
    saveProfile: ({ account }: { account: AccountInfo }) => void;
    account: AccountInfo;
    isSubmitDisabled?: boolean;
}) => {
    const { caption2, confirmLabel } = labels && labels.communication;
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    return (
        <FooterLayout textAlign={{ md: 'center' }} position={{ md: 'relative' }}>
            {/* <Text fontWeight={400}>{caption2}</Text> */}
            <Box fontWeight={400}>
                <TextScript text={caption2} translationKey={`CommunicationPreferences/caption2`} />
            </Box>

            <Button
                w={{ base: 'full', md: '340px' }}
                colorScheme="primary"
                mt={'16px'}
                isLoading={isUpdating}
                background={isSubmitDisabled ? 'gray.300' : colors.primary}
                disabled={isSubmitDisabled}
                onClick={(e) => {
                    if (isSubmitDisabled) {
                        e.preventDefault();
                        return;
                    }
                    newAccount = {
                        ...account,
                        communicationPreferences: {
                            name: preferredName || '',
                            address: account.communicationPreferences?.address,
                            contactNumber: preferredContactNumber || '',
                            email: preferredEmail || '',
                        },
                    };
                    saveProfile({ account: newAccount });
                }}
            >
                {/* <TextTranslation
          text={confirmLabel}
          translationKey={'confirmPreferences'}
          module={'myAsurionProfile'}
          section={'notifications'}
          disabled={false}
        /> */}
                <TextScript
                    text={confirmLabel}
                    translationKey={`CommunicationPreferences/confirmLabel`}
                />
            </Button>
        </FooterLayout>
    );
};

const CommunicationPreferencePage = () => {
    const { title } = labels && labels.communication;
    const toast = useToast();
    const [account, setAccount] = useAccount<AccountInfo>({ profileId: '', userId: '' });
    const [preferredName, setPreferredName] = useState<string>(
        account.communicationPreferences?.name ?? ''
    );
    const [preferredContactNumber, setPreferredContactNumber] = useState<string>(
        account.communicationPreferences?.contactNumber ?? ''
    );
    const [preferredEmail, setPreferredEmail] = useState<string>(
        account.communicationPreferences?.email ?? ''
    );
    const [preferredAddress, setPreferredAddress] = useState<string>(
        account.communicationPreferences?.address?.fullAddress ?? ''
    );
    const [personalizedNotificationPreferences, setPersonalizedNotificationPreferences] =
        useState<NotificationItemApps>({});

    const [preferredNameError, setPreferredNameError] = useState<string | undefined>('');
    const [preferredContactError, setPreferredContactError] = useState<string | undefined>('');
    const [preferredEmailError, setPreferredEmailError] = useState<string | undefined>('');

    const { mutate: saveProfile, isLoading: isUpdating } = useMutation({
        mutationFn: updateProfile,
        onSuccess: (data) => {
            if (data && data.statusCode === 200) {
                setAccount(newAccount);
                newAccount = { profileId: '', userId: '' };
                toast({
                    ...defaultToastProps,
                    title: 'Profile Updated!',
                    description: 'Your personalized content type has been updated',
                });
            } else {
                toast({
                    ...defaultToastProps,
                    title: 'Profile Error!',
                    description: 'There was an error while updating your content types',
                });
            }
        },
        onError: () => {
            toast({
                ...defaultToastProps,
                title: 'Profile Error!',
                description: 'There was an error while updating your content types',
            });
        },
    });

    useEffect(() => {
        if (account.notificationPreferences) {
            setPersonalizedNotificationPreferences(account.notificationPreferences);
        }
    }, [account.notificationPreferences]);

    return (
        <Box id={'myasurion-profile-notifications'} paddingTop={'54px'}>
            <MobileView>
                <MainStackLayout h={'auto'}>
                    <BackButton isProfile>
                        {/* <Text>
              <TextTranslation
                text={title}
                translationKey={`notificationsBack-${title}`}
                module={'myAsurionProfile'}
                section={'notifications'}
                disabled={false}
              />
            </Text> */}
                        <TextScript
                            text={title}
                            translationKey={`CommunicationPreferences/title`}
                        />
                    </BackButton>

                    <CommunicationDetails
                        preferredName={preferredName}
                        preferredAddress={preferredAddress}
                        preferredContactNumber={preferredContactNumber}
                        preferredEmail={preferredEmail}
                        preferredNameError={preferredNameError}
                        preferredContactError={preferredContactError}
                        preferredEmailError={preferredEmailError}
                        setPreferredAddress={setPreferredAddress}
                        setPreferredContactNumber={setPreferredContactNumber}
                        setPreferredEmail={setPreferredEmail}
                        setPreferredName={setPreferredName}
                        setPreferredNameError={setPreferredNameError}
                        setPreferredContactError={setPreferredContactError}
                        setPreferredEmailError={setPreferredEmailError}
                    />
                    <NotificationItems
                        personalizedNotificationPreferences={personalizedNotificationPreferences}
                    />
                </MainStackLayout>
                <FooterSection
                    preferredContactNumber={preferredContactNumber}
                    preferredEmail={preferredEmail}
                    preferredName={preferredName}
                    isUpdating={isUpdating}
                    saveProfile={saveProfile}
                    account={account}
                    isSubmitDisabled={
                        !!preferredNameError || !!preferredContactError || !!preferredEmailError
                    }
                />
            </MobileView>

            <DesktopView>
                <Center>
                    <MainStackLayout
                        gap={'20px'}
                        spacing={0}
                        maxW={'1024px'}
                        direction={'row'}
                        mt={'60px'}
                        h={'auto'}
                    >
                        <Box w={'full'} maxWidth={'280px'} p={0} m={0}>
                            <MenuSidebar activePage="communication" />
                        </Box>
                        <Box w={'full'} p={0} m={0}>
                            <Box w={'full'} pl={'16px'}>
                                <Text fontSize={'40px'} fontWeight={400}>
                                    <TextTranslation
                                        text={title}
                                        translationKey={`techcareBack-${title}`}
                                        module={'myAsurionProfile'}
                                        section={'techcare'}
                                        disabled={false}
                                    />
                                </Text>
                                <CommunicationDetails
                                    preferredName={preferredName}
                                    preferredAddress={preferredAddress}
                                    preferredContactNumber={preferredContactNumber}
                                    preferredEmail={preferredEmail}
                                    preferredNameError={preferredNameError}
                                    preferredContactError={preferredContactError}
                                    preferredEmailError={preferredEmailError}
                                    setPreferredAddress={setPreferredAddress}
                                    setPreferredContactNumber={setPreferredContactNumber}
                                    setPreferredEmail={setPreferredEmail}
                                    setPreferredName={setPreferredName}
                                    setPreferredNameError={setPreferredNameError}
                                    setPreferredContactError={setPreferredContactError}
                                    setPreferredEmailError={setPreferredEmailError}
                                />
                                <NotificationItems
                                    personalizedNotificationPreferences={
                                        personalizedNotificationPreferences
                                    }
                                />
                            </Box>
                            <FooterSection
                                preferredContactNumber={preferredContactNumber}
                                preferredEmail={preferredEmail}
                                preferredName={preferredName}
                                isUpdating={isUpdating}
                                saveProfile={saveProfile}
                                account={account}
                                isSubmitDisabled={
                                    !!preferredNameError ||
                                    !!preferredContactError ||
                                    !!preferredEmailError
                                }
                            />
                        </Box>
                    </MainStackLayout>
                </Center>
            </DesktopView>
        </Box>
    );
};

export default CommunicationPreferencePage;
