/* eslint-disable no-console */
import {
    AccountInfo,
    BackButton,
    DesktopView,
    MobileView,
    TextScript,
    TextTranslation,
    useAccount,
} from '@MGPD/myasurion-shared';
import { Button } from '@chakra-ui/button';
import { Box, Center } from '@chakra-ui/layout';
import { Spacer, useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import ContentTypeTag from '../components/ContentTypeTag';
import MainCaption from '../components/MainCaption';
import MenuSidebar from '../components/MenuSidebar';
import FooterLayout from '../layout/FooterLayout';
import MainStackLayout from '../layout/MainStackLayout';
import { contentTypes, labels } from '../modules/config/constants';
import { defaultToastProps } from '../modules/config/constants';
import { updateProfile } from '../modules/services/apiService';

let newAccount: AccountInfo = { profileId: '', userId: '' };

const FooterSection = ({
    isSubmitDisabled,
    isUpdating,
    updateContentTypes,
}: {
    isSubmitDisabled: boolean;
    isUpdating: boolean;
    updateContentTypes: () => void;
}) => {
    const { caption2, confirmLabel } = labels && labels.techcare;
    return (
        <FooterLayout textAlign={{ md: 'center' }}>
            {/* {console.log('I AM caption2', caption2)}
      <Text fontSize="md" fontWeight={400}>
        <TextTranslation
          text={caption2}
          translationKey={'techcareFooterCaption'}
          module={'myAsurionProfile'}
          section={'footer'}
        />
      </Text> */}
            <Box fontSize="md" fontWeight={400}>
                <TextScript text={caption2} translationKey={`TechCarePreferences/caption2`} />
            </Box>
            {isSubmitDisabled ? (
                <Button
                    w={{ base: 'full', md: '340px' }}
                    mt="4"
                    onClick={updateContentTypes}
                    isLoading={isUpdating}
                    isDisabled={true}
                    opacity={1}
                    border={'none'}
                >
                    {/* <TextTranslation
            text={confirmLabel}
            translationKey={'techcareFooterConfirmLabel'}
            module={'myAsurionProfile'}
            section={'techcare'}
          /> */}
                    <TextScript
                        text={confirmLabel}
                        translationKey={`TechCarePreferences/techcareFooterConfirmLabel`}
                    />
                </Button>
            ) : (
                <Button
                    w={{ base: 'full', md: '340px' }}
                    colorScheme="primary"
                    mt="4"
                    onClick={updateContentTypes}
                    isLoading={isUpdating}
                >
                    {/* <TextTranslation
            text={confirmLabel}
            translationKey={'techcareFooterConfirmLabel'}
            module={'myAsurionProfile'}
            section={'techcare'}
          /> */}
                    <TextScript
                        text={confirmLabel}
                        translationKey={`TechCarePreferences/techcareFooterConfirmLabel`}
                    />
                </Button>
            )}
        </FooterLayout>
    );
};

const ContentTypes = ({
    personalizedContentType,
    selectContentHandler,
}: {
    personalizedContentType: string[];
    selectContentHandler: (content: string, isSelected: boolean) => void;
}) => {
    const { subtitle } = labels && labels.techcare;

    return (
        <Box
            id={'content-types-section'}
            p="4"
            w={{ base: 'full', md: '380px' }}
            bg={'#fff'}
            minH={{ base: '290px', md: 'auto' }}
        >
            <Box fontSize="2xl" fontWeight={400}>
                <TextScript text={subtitle} translationKey={`TechCarePreferences/Subtitle`} />
            </Box>

            {personalizedContentType?.map((content: string, i: number) => {
                return (
                    <ContentTypeTag
                        key={`content-type-tag-${content}-${i}`}
                        selected={true}
                        onSelect={() => selectContentHandler(content, false)}
                    >
                        <TextTranslation
                            text={content}
                            translationKey={`${content}-${i}`}
                            module={'myAsurionProfile'}
                            section={'techcare'}
                        />
                    </ContentTypeTag>
                );
            })}
            {contentTypes?.map((content: string, i: number) => {
                const inArray = personalizedContentType.indexOf(content) > -1 ? true : false;

                return (
                    <React.Fragment key={`content-type-tag-${content}-${i}`}>
                        {!inArray && (
                            <ContentTypeTag
                                key={`content-type-tag-${content}-${i}`}
                                selected={false}
                                onSelect={() => selectContentHandler(content, true)}
                            >
                                {/* <TextTranslation
                  text={content}
                  translationKey={`${content}-${i}`}
                  module={'myAsurionProfile'}
                  section={'techcare'}
                /> */}
                                <TextScript
                                    text={content}
                                    translationKey={`TechCarePreferences/Personalized Content Tag-${i}`}
                                />
                            </ContentTypeTag>
                        )}
                    </React.Fragment>
                );
            })}
        </Box>
    );
};

const TechCarePreferencePage = () => {
    const { title, titleDesktop, caption1 } = labels && labels.techcare;
    const [account, setAccount] = useAccount<AccountInfo>({ profileId: '', userId: '' });
    const toast = useToast();
    const [personalizedContentType, setPersonalizedContentType] = useState<string[]>([]);
    const [temporaryContentType, setTemporaryContentType] = useState<{ [key: string]: boolean }>(
        {}
    );
    const [initialDiff, setInitialDiff] = useState<boolean>(true);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

    const { mutate: saveProfile, isLoading: isUpdating } = useMutation({
        mutationFn: updateProfile,
        onSuccess: (data) => {
            if (data && data.statusCode === 200) {
                setAccount(newAccount);
                newAccount = { profileId: '', userId: '' };
                toast({
                    ...defaultToastProps,
                    title: 'Profile Updated!',
                    description: 'Your personalized content type has been updated',
                });
            } else {
                toast({
                    ...defaultToastProps,
                    title: 'Profile Error!',
                    description: 'There was an error while updating your content types',
                });
            }
        },
        onError: () => {
            toast({
                ...defaultToastProps,
                title: 'Profile Error!',
                description: 'There was an error while updating your content types',
            });
        },
    });

    useEffect(() => {
        if (initialDiff && account.contentTypes && account.contentTypes.length > 0) {
            setPersonalizedContentType(account.contentTypes);
        }

        setInitialDiff(false);
    }, [account, initialDiff, personalizedContentType]);

    const selectContentHandler = (content: string, isSelected: boolean) => {
        const temp = temporaryContentType;
        temp[content] = isSelected;
        setTemporaryContentType(temp);

        setIsSubmitDisabled(false);
    };

    const updateContentTypes = () => {
        const personals = personalizedContentType;

        for (const [content, selected] of Object.entries(temporaryContentType)) {
            const index = personals.indexOf(content);

            if (selected && index === -1) {
                personals?.push(content);
            } else if (!selected && index > -1) {
                personals.splice(index, 1);
            }
        }

        setPersonalizedContentType(personals);

        newAccount = {
            ...account,
            contentTypes: personals,
        };

        saveProfile({ account: newAccount });
    };

    return (
        <Box id={'myasurion-profile-techcare'} paddingTop={'54px'}>
            <MobileView>
                <MainStackLayout>
                    <BackButton isProfile>
                        {/* <Text>
              <TextTranslation
                text={title}
                translationKey={`techcareBack-${title}`}
                module={'myAsurionProfile'}
                section={'techcare'}
                disabled={false}
              />
            </Text> */}
                        <TextScript text={title} translationKey={`TechCarePreferences/title`} />
                    </BackButton>
                    <MainCaption opacity={0.48}>
                        {/* <TextTranslation
              text={caption1}
              translationKey={'mainCaption'}
              module={'myAsurionProfile'}
              section={'techcare'}
            /> */}
                        <TextScript
                            text={caption1}
                            translationKey={`TechCarePreferences/mainCaption`}
                        />
                    </MainCaption>
                    <ContentTypes
                        personalizedContentType={personalizedContentType}
                        selectContentHandler={selectContentHandler}
                    />
                    <Spacer />
                </MainStackLayout>
                <FooterSection
                    updateContentTypes={updateContentTypes}
                    isSubmitDisabled={isSubmitDisabled}
                    isUpdating={isUpdating}
                />
            </MobileView>

            <DesktopView>
                <Center>
                    <MainStackLayout
                        gap={'20px'}
                        spacing={0}
                        maxW={'1024px'}
                        direction={'row'}
                        mt={'60px'}
                        h={'auto'}
                    >
                        <Box w={'full'} maxWidth={'280px'} p={0} m={0}>
                            <MenuSidebar activePage="techcare" />
                        </Box>
                        <Box w={'full'} p={0} m={0}>
                            <Box w={'full'} pl={'16px'} fontSize={'40px'} fontWeight={400}>
                                {/* <Text fontSize={'40px'} fontWeight={400}>
                  <TextTranslation
                    text={titleDesktop}
                    translationKey={`techcareBack-${title}`}
                    module={'myAsurionProfile'}
                    section={'techcare'}
                    disabled={false}
                  />
                </Text> */}
                                <TextScript
                                    text={titleDesktop}
                                    translationKey={`TechCarePreferences/title Desktop View`}
                                />
                            </Box>
                            <Box w={'full'} p={0} m={0}>
                                <MainCaption opacity={0.48} w={'full'}>
                                    <Box w={'full'} maxW={'638px'} mb={'32px'}>
                                        {/* <TextTranslation
                      text={caption1}
                      translationKey={'mainCaption'}
                      module={'myAsurionProfile'}
                      section={'techcare'}
                    /> */}
                                        <TextScript
                                            text={caption1}
                                            translationKey={`TechCarePreferences/caption1 Desktop View`}
                                        />
                                    </Box>
                                </MainCaption>

                                <ContentTypes
                                    personalizedContentType={personalizedContentType}
                                    selectContentHandler={selectContentHandler}
                                />
                                <Spacer />
                                <FooterSection
                                    updateContentTypes={updateContentTypes}
                                    isSubmitDisabled={isSubmitDisabled}
                                    isUpdating={isUpdating}
                                />
                            </Box>
                        </Box>
                    </MainStackLayout>
                </Center>
            </DesktopView>
        </Box>
    );
};

export default TechCarePreferencePage;
