/* eslint-disable no-console */
import { getCarrierByDomain, getConfigNameByDomain, getHasRetailerByDomain } from '@MGPD/myasurion-shared';
import { MyAsurionProvider } from '@MGPD/myasurion-shared/modules/config';
import { MyAsurionDevTools } from '@MGPD/myasurion-shared/modules/devtools';
import { Mixpanel } from '@MGPD/myasurion-shared/utils/mixpanel';
import ReactDOM from 'react-dom/client';

import App from './App.tsx';
import ContextProvider from './ClaimComponents/store/appContext.tsx';
import reducer, { initialState } from './ClaimComponents/store/reducers.ts';
import './Swiper.css';
import chtHomeTranslations from './assets/translations/cht_home/portal.json';
import starhubENTranslations from './assets/translations/starhub/portal.json';
import jbhifiTranslations from './assets/translations/jbhifi/portal.json';
import pcHomeTranslations from './assets/translations/twopenmarket_pchome/portal.json';
import yahooTranslations from './assets/translations/twopenmarket_yahoo/portal.json';
import elifeMallTranslations from './assets/translations/twopenmarket_elifemall/portal.json';
import liwenTranslations from './assets/translations/twopenmarket_liwen/portal.json';
import firstTranslations from './assets/translations/twopenmarket_first/portal.json';
import yourLinkTranslations from './assets/translations/twopenmarket_yourlink/portal.json';
import celcomENTranslations from './assets/translations/celcom/en/portal.json';
import celcomMYTranslations from './assets/translations/celcom/my/portal.json';
import ChakraExtension from './modules/theme/ChakraExtension.tsx';
import Fonts from './modules/theme/Fonts.tsx';
import './styles.css';
import { printVersion } from './utils/index.tsx';

printVersion();
Mixpanel.setupMixpanel(
    import.meta.env,
    {
        partner: 'starhub',
        program: null,
    },
    null,
    false
);
const carrier = getHasRetailerByDomain() ? getConfigNameByDomain() : getCarrierByDomain();
const setSrcStrings = () => {
    switch (carrier) {
        case 'starhub':
          let starhubTranslations: any = {};
          starhubTranslations['en-US'] = starhubENTranslations;
          return starhubTranslations;
        case 'cht_home':
          return chtHomeTranslations;
        case 'jbhifi':
          return jbhifiTranslations;
        case 'twopenmarket_pchome':
          return pcHomeTranslations;
        case 'twopenmarket_yahoo':
          return yahooTranslations;
        case 'twopenmarket_elifemall':
          return elifeMallTranslations;
        case 'twopenmarket_first':
          return firstTranslations;
        case 'twopenmarket_liwen':
          return liwenTranslations;
        case 'twopenmarket_yourlink':
          return yourLinkTranslations;
        case 'celcom':
          let celcomTranslations: any = {};
          celcomTranslations['en-US'] = celcomENTranslations;
          celcomTranslations['ms-MY'] = celcomMYTranslations;

          return celcomTranslations;
        default:
            return undefined;
    }
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <ContextProvider {...initialState} {...reducer}>
        <MyAsurionProvider
            env={{
                baseUrl: import.meta.env.BASE_URL,
                apiBaseUrl: import.meta.env.VITE_CONFIG_API_BASE_URL,
                authorization: import.meta.env.VITE_MOCK_AUTH,
                token: import.meta.env.VITE_MOCK_TOKEN,
                apiKey: import.meta.env.VITE_API_KEY,
                hmacKey: import.meta.env.VITE_MYASURION_API_AUTH_HMAC_KEY,
                moduleId: 'myAsurionPortal',
                enableTranslations: import.meta.env.VITE_TRANSLATIONS_ENABLED == 'true',
            }}
            config={{
                env: import.meta.env.VITE_ENV,
                apiKey: import.meta.env.VITE_REMOTE_CONFIG_API_KEY,
                authDomain: import.meta.env.VITE_REMOTE_CONFIG_AUTH_DOMAIN,
                projectId: import.meta.env.VITE_REMOTE_CONFIG_PROJECT_ID,
                storageBucket: import.meta.env.VITE_REMOTE_CONFIG_STORAGE_BUCKET,
                messagingSenderId: import.meta.env.VITE_REMOTE_CONFIG_MESSAGING_SENDER_ID,
                appId: import.meta.env.VITE_REMOTE_CONFIG_APP_ID,
                measurementId: import.meta.env.VITE_REMOTE_CONFIG_MEASUREMENT_ID,
            }}
            onBoardingProps={{
                run: true,
                steps: [],
            }}
            srcStrings={setSrcStrings()}
        >
            <>
                <Fonts />
                <ChakraExtension />
                <App />
                <MyAsurionDevTools enabled={import.meta.env.VITE_DEVTOOLS_ENABLED == 'true'} />
            </>
        </MyAsurionProvider>
    </ContextProvider>
);
