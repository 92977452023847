/* eslint-disable no-console */
import { TextScript, useConfig } from '@MGPD/myasurion-shared';
import { Box, BoxProps, Button, ButtonProps, Center, HStack, Text } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

import CNScripts from '../Scripts/asurion-script-chinese.json';
import Scripts from '../Scripts/asurion-script.json';

export interface QueAns {
    Que: string;
    ans: string;
    expAns: string;
    type: string;
    displayOrder: number;
}

export interface TriageQuestionProps {
  triageQuestionList?: Array<QueAns>;
  onAnswerClick: (triage: QueAns, ans: string) => any;
  getQuestionAnswer?: (args: Array<QueAns>) => void;
  enableButton?: boolean;
  isDesktop?: boolean;
  pageName: string;
  incidentType: string;
  asset:string;
  isDefaultChinese?: boolean;
  showCategoryLabel?: boolean;
  subHeaderContent?: string;
}
export const TriageQuestion: React.FC<
    PropsWithChildren<BoxProps & TriageQuestionProps & ButtonProps>
> = ({
  children,
  triageQuestionList,
  textColor,
  onAnswerClick,
  getQuestionAnswer,
  enableButton,
  isDesktop,
  pageName,
  incidentType,
  asset,
  isDefaultChinese = false,
  showCategoryLabel = false,
  subHeaderContent = '',
  ...props
}) => {
    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { bodyTextColor } = colors;

    const isYesClick = (triageQue: QueAns) => {
        if (triageQue?.ans === 'Yes') return true;
        else if (
            triageQue?.ans?.length > 0 &&
            triageQue?.ans != 'No' &&
            triageQue.type === 'InWarrantyOther'
        )
            return true;
        else return false;
    };

    return (
      <Box {...props}>
        <Box>
          {subHeaderContent && (
            <Text
              fontSize={'20px'} 
              fontWeight="bold" 
              paddingTop={'20px'} 
              px={'5px'} 
              textAlign={{ base: 'start', lg: 'start' }}
            >
              {subHeaderContent}
            </Text>
          )}
          {triageQuestionList?.map((val: any, index) => {
            return (
              <Box key={`device-content-${index}`} id={'myasurion'}>
                {showCategoryLabel && val?.category && (
                  <Text
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize={showCategoryLabel ? '16px': '24px'} 
                    paddingTop={'20px'} 
                    px={'5px'} 
                    textAlign={{ base: 'start', lg: 'start' }}
                  >
                      {val.category}
                  </Text>
                )}
                {val && (
                  <Text 
                    fontSize={showCategoryLabel ? '16px': '24px'} 
                    paddingTop={showCategoryLabel ? '8px': '24px'}  
                    px={'5px'} 
                    textAlign={{ base: 'start', lg: showCategoryLabel ? 'start': 'center'}}
                  >
                    <TextScript
                      text={val.Que}
                      translationKey={`${pageName}/${incidentType}${index}/${asset}`}
                    />
                  </Text>
                )}
                <Box>
                  <Center>
                    <HStack height={'56px'} px={'5px'} width={isDesktop ? '850px' : '355px'}>
                      <Button
                        border={ isYesClick(val) ? '3px solid #000' : '1px solid #A5AAAF'}
                        variant="outline"
                        width="100%"
                        height={'44px'}
                        borderColor={bodyTextColor}
                        onClick={() => onAnswerClick(val, 'Yes')}
                        {...showCategoryLabel ? {fontSize:'16px', fontWeight:"bold"} : {}}
                      >
                        <TextScript
                          text={isDefaultChinese ? CNScripts?.Global?.Yes : Scripts?.Global?.Yes}
                          translationKey={`Global/Yes`}
                        />
                      </Button>
                      <Button
                        border={val?.ans === 'No' ? '3px solid #000' : '1px solid #A5AAAF'}
                        variant="outline"
                        width="100%"
                        height={'44px'}
                        borderColor={bodyTextColor}
                        onClick={() => onAnswerClick(val, 'No')}
                      >
                        <TextScript
                          text={isDefaultChinese ? CNScripts?.Global?.No : Scripts?.Global?.No}
                          translationKey={`Global/No`}
                        />
                      </Button>
                    </HStack>
                  </Center>
                </Box>
              </Box>
            );
          })}
        </Box>
        </Box>
    );
};
