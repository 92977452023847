/* eslint-disable no-console */
import {
    AccountInfo,
    DesktopView,
    LanguageProps,
    LanguageSelector,
    LanguageSelectorProps,
    MenuButton,
    MobileView,
    ServiceRequestButtonProps,
    SignOutLinkProps,
    getCarrierByDomain,
    getHomeLink,
    useChatConfig,
    useConfig,
    useContentfulImage,
    useFeatureIsOn,
    useHeader,
    useNotifications,
    useScrollBlock,
} from '@MGPD/myasurion-shared';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, BoxProps } from '@chakra-ui/layout';
import { Button, HStack, Image, Link, Spinner, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuDisplay } from './MenuDisplay';
import { NotificationsButton } from './NotificationsButton';
import NotificationsDisplay from './NotificationsDisplay';

export interface HeaderProps {
    account: AccountInfo;
    children?: React.ReactNode;
    headerMenu?: React.ReactNode;
    authenticated?: boolean;
    showBack?: boolean;
    showBackText?: string;
    languageSelectorProps?: LanguageSelectorProps;
    signOutProps?: SignOutLinkProps;
    serviceRequestButtonProps?: ServiceRequestButtonProps;
    onClickBack?: () => void;
    onToggleMenu?: (val: boolean) => void;
    onToggleNotifications?: (val: boolean) => void;
    onClickLanding?: () => void;
    skipUrlClick?: boolean;
}

export const Header: React.FC<BoxProps & HeaderProps> = ({
    account,
    children,
    headerMenu,
    authenticated,
    showBack,
    showBackText,
    onToggleMenu,
    onToggleNotifications,
    onClickBack,
    languageSelectorProps,
    signOutProps,
    serviceRequestButtonProps,
    onClickLanding,
    skipUrlClick,
    ...props
}) => {
    // const [openMenu, setOpenMenu] = useState<boolean>();
    const [blockScroll, allowScroll] = useScrollBlock();
    const [logo, setLogo] = useState<string | undefined>(undefined);
    const { data } = useConfig();
    const { theme } = data;
    const { header, favicon } = theme;
    const { updateHeaderIsOpen, headerIsOpen, updateNoficationsIsOpen, notificationsIsOpen } =
        useHeader();
    const isNotificationsFlagOn = useFeatureIsOn('notifications');
    const { notifications, isNotificationsLoading, promptCount } = useNotifications();

    const { chatConfig, firebaseConfig } = useChatConfig();
    const navigate = useNavigate();

    const useContentfulImageConfig = {
        spaceIdRef: chatConfig?.contentful.space,
        accessTokenRef: chatConfig?.contentful.token,
        environmentRef: chatConfig?.contentful.env,
        firebaseConfig: firebaseConfig,
    };

    const { imageUrl: contentfulImageUrl, isLoading: isLogoLoading } = useContentfulImage(
        header.logoSlug,
        useContentfulImageConfig?.spaceIdRef,
        useContentfulImageConfig?.accessTokenRef,
        useContentfulImageConfig?.environmentRef,
        useContentfulImageConfig?.firebaseConfig
    );

    const toggleMenu = useCallback(
        (toggleOpen?: boolean) => {
            // setOpenMenu(open);
            toggleOpen !== undefined
                ? updateHeaderIsOpen(toggleOpen)
                : updateHeaderIsOpen((open) => {
                      onToggleMenu && onToggleMenu(!open);
                      return !open;
                  });
            updateNoficationsIsOpen(false);
            window.scrollTo(0, 0);
        },
        [headerIsOpen]
    );

    const toggleNotifications = useCallback(
        (toggleOpen?: boolean) => {
            // setOpenMenu(open);
            toggleOpen !== undefined
                ? updateNoficationsIsOpen(toggleOpen)
                : updateNoficationsIsOpen((open) => {
                      onToggleNotifications && onToggleNotifications(!open);
                      return !open;
                  });
            updateHeaderIsOpen(false);
            window.scrollTo(0, 0);
        },
        [notificationsIsOpen]
    );

    const goToProfile = useCallback(() => {
        if (!skipUrlClick) {
            updateNoficationsIsOpen(false);
            window.location.href = '/profile';
        } else {
            updateNoficationsIsOpen(false);
            navigate('/profile', { replace: true });
        }
    }, []);

    const selectLanguage = useCallback((selected: LanguageProps) => {
        languageSelectorProps?.onSelect && languageSelectorProps?.onSelect(selected);
    }, []);

    if (favicon) {
        document.getElementById('favicon')?.setAttribute('href', favicon);
    }

    useEffect(() => {
        if (!isLogoLoading) {
            setLogo(contentfulImageUrl ?? undefined);
        }
    }, [isLogoLoading, contentfulImageUrl]);

    useEffect(() => {
        if (headerIsOpen || notificationsIsOpen) {
            blockScroll();
        } else {
            allowScroll();
        }
    }, [headerIsOpen, notificationsIsOpen]);
    return (
        <>
            <Box
                id={'myasurion-header'}
                pos="fixed"
                h={'56px'}
                top={0}
                left={0}
                w="full"
                p={'10px 16px'}
                bg={header.bg || 'white'}
                zIndex={999999}
                boxShadow="0px 4px 8px 0px #00000014, 0px 1px 0px 0px #00000029 inset"
                {...props}
            >
                {showBack ? (
                    <Box alignItems={'end'} w={'full'} p={'8px 16px 4px 16px'}>
                        <Button
                            variant={'link'}
                            colorScheme="black"
                            onClick={() => {
                                onClickBack && onClickBack();
                            }}
                            leftIcon={<ArrowBackIcon />}
                        >
                            <Text>{showBackText || 'Back'}</Text>
                        </Button>
                    </Box>
                ) : (
                    <Box alignItems={'end'} w={'full'}>
                        {children && children}
                        {!children && (
                            <HStack h={'36px'} justifyContent="space-between">
                                <Box>
                                    <Link
                                        href={
                                            getCarrierByDomain()?.toLowerCase() === 'starhub' || getCarrierByDomain()?.toLowerCase() === 'twopenmarket_slw'
                                                ? '#'
                                                : getHomeLink()
                                        }
                                        onClick={() => onClickLanding && onClickLanding()}
                                        _hover={{ textDecoration: 'none', cursor: 'pointer' }}
                                    >
                                        {isLogoLoading ? (
                                            <Spinner />
                                        ) : (
                                            <Image
                                                src={logo ?? header.logo}
                                                alt={'header logo'}
                                                maxH={header.maxHeight ?? '36px'}
                                            />
                                        )}
                                    </Link>
                                </Box>
                                {/* <Spacer /> */}
                                <HStack gap={4}>
                                    <LanguageSelector
                                        boxProps={languageSelectorProps?.boxProps}
                                        linkProps={languageSelectorProps?.linkProps}
                                        onSelect={selectLanguage}
                                    />
                                    {authenticated && headerMenu ? headerMenu : <></>}
                                    {authenticated && isNotificationsFlagOn && (
                                        <NotificationsButton
                                            onToggle={toggleNotifications}
                                            isLoading={isNotificationsLoading}
                                            promptCount={promptCount}
                                        />
                                    )}
                                    {authenticated && !headerMenu ? (
                                        <>
                                            <MobileView>
                                                <MenuButton onToggle={toggleMenu} />
                                            </MobileView>
                                            <DesktopView>
                                                <MenuButton onToggle={goToProfile} />
                                            </DesktopView>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </HStack>
                            </HStack>
                        )}
                    </Box>
                )}
            </Box>
            {headerIsOpen && (
                <MenuDisplay
                    // pt={'50px'}
                    mt={'50px'}
                    firstname={account?.firstName || ''}
                    lastname={account?.lastName || ''}
                    signOutProps={{
                        label: signOutProps?.label && signOutProps.label,
                        onSignOut: () => {
                            toggleMenu(false);
                            signOutProps?.onSignOut && signOutProps?.onSignOut();
                        },
                    }}
                    serviceRequestButtonProps={{
                        enabled:
                            (serviceRequestButtonProps && serviceRequestButtonProps.enabled) ||
                            false,
                        label: serviceRequestButtonProps?.label && serviceRequestButtonProps.label,
                        onClick: () => {
                            toggleMenu(false);
                            serviceRequestButtonProps?.onClick &&
                                serviceRequestButtonProps?.onClick();
                        },
                    }}
                    skipUrlClick={skipUrlClick}
                    toggleMenu={toggleMenu}
                />
            )}
            {isNotificationsFlagOn && notificationsIsOpen && (
                <NotificationsDisplay
                    pt={'50px'}
                    notifications={notifications}
                    isLoading={isNotificationsLoading}
                />
            )}
        </>
    );
};
