import React, { useEffect } from 'react';
import { useForm, Controller, FieldError } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, FormControl, Input, FormErrorMessage, VStack, Text, Center, Flex, Grid, Button } from '@chakra-ui/react';
import JBDefaultPageLayout from './JBDefaultPageLayout';
import _ from '../utils/lodashUtils.ts';
import Scripts from '../Scripts/asurion-script.json';
import translationsKeys from '../Scripts/translationsKeys.json';
import { TextScript, useSessionStorage } from '@MGPD/myasurion-shared';
import { DocumentUploadData, DocumentUploadDataType } from '../modules/config/constants.ts';
import { UploadFieldKeys, UploadIdDocumentCategory, UploadIdDocumentCategoryLabels } from '../utils/constant.tsx';
import { useNavigate } from 'react-router-dom';
import { UploadFieldFieldName, UploadIdField } from '../types/types.ts';
const VerifyUploadedIdDetailsPageKeys = translationsKeys.VerifyUploadedIdDetailsPage;

const uploadIdSchema = yup.object().shape({
  stateTerritory: yup.string().required('State/Territory is required'),
  idType: yup.string().required('ID Type is required'),
  firstName: yup.string().required('First Name is required'),
  middleName: yup.string(), // Not required
  lastName: yup.string().required('Last Name is required'),
  dateOfBirth: yup.string().required('Date of Birth is required').nullable(),
  expiryDate: yup.string().required('Expiry Date is required').nullable(),
  cardNumber: yup.string().required('Card Number is required'),
});

const getUploadIdFields = (documentType: string, defaultValues: any): UploadIdField[] => {
  const UploadIdFields = [
    {
      id: UploadFieldKeys.StateTerritory,
      label: 'State/Territory',
      errorMessage: 'Please enter missing State/Territory',
      defaultValue: defaultValues?.[UploadFieldKeys.StateTerritory] ?? '',
      fieldDataType: 'text',
      isRequired: true,
      isDisabled: false
    },
    {
      id: UploadFieldKeys.IdType,
      label: 'ID Type',
      errorMessage: '',
      defaultValue: defaultValues?.[UploadFieldKeys.IdType] ?? '',
      fieldDataType: 'text',
      isRequired: false,
      isDisabled: true
    },
    {
      id: UploadFieldKeys.FirstName,
      label: 'First Name',
      errorMessage: 'Please enter missing Last Name',
      defaultValue: defaultValues?.[UploadFieldKeys.FirstName] ?? '',
      fieldDataType: 'text',
      isRequired: true,
      isDisabled: false
    },
    {
      id: UploadFieldKeys.MiddleName,
      label: 'Middle Name (if applicable)',
      errorMessage: '',
      defaultValue: defaultValues?.[UploadFieldKeys.MiddleName] ?? '',
      fieldDataType: 'text',
      isRequired: false,
      isDisabled: false
    },
    {
      id: UploadFieldKeys.LastName,
      label: 'Last Name',
      errorMessage: 'Please enter missing Last Name',
      defaultValue: defaultValues?.[UploadFieldKeys.LastName] ?? '',
      fieldDataType: 'text',
      isRequired: true,
      isDisabled: false
    },
    {
      id: UploadFieldKeys.DateOfBirth,
      label: 'Date of Birth',
      errorMessage: 'Please enter missing date of birth',
      defaultValue: defaultValues?.[UploadFieldKeys.DateOfBirth] ?? '',
      fieldDataType: 'text',
      isRequired: true,
      isDisabled: false
    },
    {
      id: UploadFieldKeys.ExpiryDate,
      label: 'Expiry Date',
      errorMessage: 'Kindly Provide the missing expiry date',
      defaultValue: defaultValues?.[UploadFieldKeys.ExpiryDate] ?? '',
      fieldDataType: 'text',
      isRequired: true,
      isDisabled: false
    },
  ];

  switch (documentType) {
    case UploadIdDocumentCategory.AustDriversLicence:
      UploadIdFields.push({
        id: UploadFieldKeys.CardNumber,
        label: 'License Number',
        errorMessage: 'Kindly Provide the missing details',
        defaultValue: defaultValues?.[UploadFieldKeys.CardNumber] ?? '',
        fieldDataType: 'text',
        isRequired: true,
        isDisabled: false
      });
      break;
    case UploadIdDocumentCategory.ProofOfAgeCard:
      UploadIdFields.push({
        id: UploadFieldKeys.CardNumber,
        label: 'Card Number',
        errorMessage: 'Kindly Provide the missing details',
        defaultValue: defaultValues?.[UploadFieldKeys.CardNumber] ?? '',
        fieldDataType: 'text',
        isRequired: true,
        isDisabled: false
      });
      break;
    case UploadIdDocumentCategory.PassportId:
      UploadIdFields.push({
        id: UploadFieldKeys.CardNumber,
        label: 'Passport Number',
        errorMessage: 'Kindly Provide the missing details',
        defaultValue: defaultValues?.[UploadFieldKeys.CardNumber] ?? '',
        fieldDataType: 'text',
        isRequired: true,
        isDisabled: false
      });
      break;
    default:
      break;
  }
  return UploadIdFields;
}

const VerifyUploadedIdDetailsPage: React.FC = () => {

  // Global State
  const navigate = useNavigate();
  const [documentUploadDataType, setDocumentUploadDataType] = useSessionStorage<any>(DocumentUploadDataType, null);
  const [documentUploadData, setDocumentUploadData] = useSessionStorage<any>(DocumentUploadData, null);

  useEffect(() => {
    console.log("Document Upload Data Type", documentUploadDataType);
    console.log("Document Upload Data", documentUploadData);
    return () => {
      setDocumentUploadDataType('');
      setDocumentUploadData({})
    }
  }, []);

  const { control, handleSubmit, /*formState: { errors }*/ } = useForm({
    resolver: yupResolver(uploadIdSchema),
  });

  const onSubmit = (data: any) => {
    console.log("Verfied Uploaded Id Details Form Data", data);
    navigate('/reviewsubmission', { replace: true });
  };

  const uploadIdFields = getUploadIdFields(documentUploadDataType, { [UploadFieldKeys.IdType]: UploadIdDocumentCategoryLabels[documentUploadDataType] });

  return (
    <JBDefaultPageLayout
      showDeviceCard={false}
      showCancelButton={false}
      showNextButton={false}
    >
      <Center>
        <Flex
          w={{ base: '90%', md: '50%' }} m="0"
          direction="column"
          wrap="wrap"
          justifyContent="center"
          alignContent="center"
          id={'myasurion-verify-uploaded-id-details-page-flex-1'}
          h={'auto'}
        >
          <Box
            className={'myasurion-verify-uploaded-id-content-flex-1'}
            w={{ base: 'full', lg: '100%' }}
            margin={{ base: '0', lg: '0' }}
            mb={{ base: '15px', lg: '15px' }}
          >
            <VStack spacing="2" gap="15px">
              <Text
                fontFamily="Apercu Pro"
                fontSize="32px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="130%"
                textAlign="center"
              >
                <TextScript
                  text={Scripts.VerifyUploadedIdDetailsPage.HeaderText}
                  translationKey={`${VerifyUploadedIdDetailsPageKeys.PageName}/${VerifyUploadedIdDetailsPageKeys.HeaderText}`}
                />
              </Text>
              <Text
                fontFamily="Apercu Pro"
                fontSize="24px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="130%"
              >
                <TextScript
                  text={Scripts.VerifyUploadedIdDetailsPage.subHeaderContent}
                  translationKey={`${VerifyUploadedIdDetailsPageKeys.PageName}/${VerifyUploadedIdDetailsPageKeys.SubHeaderContent}`}
                />
              </Text>
            </VStack>
          </Box>
          <Box>
            <Flex direction={{ base: "column", lg: "row" }} gap="15px">
              {uploadIdFields.slice(0, 2).map((field) => (
                <Controller
                  key={`upload-id-fileds-${field.id}-${_.uniqueId()}`}
                  name={field.id as UploadFieldFieldName}
                  control={control}
                  defaultValue={field.defaultValue}
                  render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }: { field: any; fieldState: { error?: FieldError } }) => (
                    <FormControl isRequired={field.isRequired} isInvalid={!!error}>
                      <Input
                        ref={ref}
                        aria-label={field.label}
                        placeholder={field.label}
                        type={field.fieldDataType}
                        isDisabled={field.isDisabled}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      />
                      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
              ))}
            </Flex>
            <Grid templateColumns={{ lg: "repeat(3, 1fr)" }} gap="15px" mt="15px">
              {uploadIdFields.slice(2).map((field) => (
                <Controller
                  key={`upload-id-fileds-${field.id}-${_.uniqueId()}`}
                  name={field.id as UploadFieldFieldName}
                  control={control}
                  defaultValue={field.fieldDataType === 'date' ? new Date().toISOString().split('T')[0] : field.defaultValue}
                  render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }: { field: any; fieldState: { error?: FieldError } }) => (
                    <FormControl isRequired={field.isRequired} isInvalid={!!error}>
                      <Input
                        ref={ref}
                        aria-label={field.label}
                        placeholder={field.label}
                        type={field.fieldDataType}
                        isDisabled={field.isDisabled}
                        {...(field.isDisabled ? {
                          style: { background: '#E6E6EB' },
                          _focus: { border: 'none', boxShadow: 'none' }
                        } : {})}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                        }}
                        value={value}
                      />
                      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
              ))}
            </Grid>
            <Flex justifyContent="center" direction={{ base: "column", lg: "row" }} my="15px">
              <Button
                id={'myasurion-jb-dpl-bt-1'}
                py={3}
                px={5}
                colorScheme="primary"
                color="white"
                w={{ base: 'full', lg: 'full' }}
                onClick={handleSubmit(onSubmit)}
                // isDisabled={Object.keys(errors).length > 0 || _.isEmpty(documentUploadDataType)}
                textTransform={
                  'none'
                }
              >
                <TextScript
                  text={Scripts.VerifyUploadedIdDetailsPage.submitLabel}
                  translationKey={`${VerifyUploadedIdDetailsPageKeys.PageName}/${VerifyUploadedIdDetailsPageKeys.SubmitLabel}`} />
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Center>
    </JBDefaultPageLayout>
  );
};

export default VerifyUploadedIdDetailsPage;