/* eslint-disable no-console */
import { Box, Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useEventListener } from 'usehooks-ts';

// import TextEditIcon from '../icons/TextEditIcon';

export interface TextInputProps {
    id?: string;
    value?: string;
    placeholder?: string;
    containerWidth?: string;
    showIcon?: boolean;
    onChange?: (e: any) => void;
}

export const TextInput = ({
    id,
    value = '',
    showIcon = true,
    placeholder,
    containerWidth = '100%',
    onChange,
    ...props
}: TextInputProps & InputProps) => {
    const [focus, setFocus] = useState<boolean>();
    const [val, setVal] = useState<string>('');
    const [changed, setChanged] = useState<boolean>(false);

    const onFocus = () => setFocus(true);
    const onBlur = () => (val === '' ? setFocus(false) : setFocus(true));

    const inputRef = useRef<HTMLInputElement>(null);

    useEventListener('focus', onFocus, inputRef);
    useEventListener('blur', onBlur, inputRef);

    useEffect(() => {
        if (value) {
            setVal(value);
            setFocus(true);
        } else {
            setVal('');
            setFocus(false);
        }
    }, [value]);

    return (
        <Box position={'relative'} w={containerWidth}>
            {focus && (
                <Box
                    pos={'absolute'}
                    top={'2px'}
                    left={'10px'}
                    fontSize={'sm'}
                    fontWeight={400}
                    color={'gray.500'}
                >
                    {placeholder}
                </Box>
            )}
            <InputGroup>
                <Input
                    px={'10px'}
                    minH={'60px'}
                    borderColor={'gray.300'}
                    placeholder={focus ? '' : placeholder}
                    ref={inputRef}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={val}
                    onChange={(e) => {
                        setVal(e.target.value);
                        if (e.target.value !== value) {
                            setChanged(true);
                        } else {
                            setChanged(false);
                        }
                        onChange && onChange({ id: id, value: e.target.value });
                    }}
                    {...props}
                />
                {changed && showIcon && (
                    <InputRightElement p={'12px'}>{/* <TextEditIcon /> */}</InputRightElement>
                )}
            </InputGroup>
        </Box>
    );
};

export default TextInput;
