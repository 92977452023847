/* eslint-disable no-console */
import { Mixpanel, getCarrierByDomain, getTypeByDomain } from '@MGPD/myasurion-shared';
// import { Header } from '@MGPD/myasurion-shared/components/header';
import { ProtectedLayout } from '@MGPD/myasurion-shared/components/layout';
import { TextScript } from '@MGPD/myasurion-shared/components/localization';
import {
    AccountInfo,
    useAccount,
    useAuthToken,
    useDocumentTitle,
    useFeatureIsOn, // useIsDisabledFeature,
    useSessionStorage,
} from '@MGPD/myasurion-shared/hooks';
import { useConfig } from '@MGPD/myasurion-shared/modules/config';
import { isAuthenticated } from '@MGPD/myasurion-shared/utils/functions';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Link, Text } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { PropsWithChildren, ReactNode, Suspense, lazy, useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import TagManager from 'react-gtm-module';
import { Route, Routes, useNavigate } from 'react-router-dom';

import AddCardComp from './ClaimComponents/pages/AddCard/AddCardComp';
import SuccessAddCard from './ClaimComponents/pages/AddCard/SuccessAddCard';
import AddNewDevice from './ClaimComponents/pages/AddDevice/AddNewDevice';
import ConfirmDeviceRegistration from './ClaimComponents/pages/AddDevice/ConfirmDeviceRegistration';
import DeviceCategoryList from './ClaimComponents/pages/AddDevice/DeviceCategoryList';
import DeviceDetails from './ClaimComponents/pages/AddDevice/DeviceDetails';
import BYODDeviceDetails  from './ClaimComponents/pages/AddDevice/BYODDeviceDetails';
import DeviceDetailsNew from './ClaimComponents/pages/AddDevice/DeviceDetailsNew';
import ErrorPage from './ClaimComponents/pages/AddDevice/ErrorPage';
import ThingsToNote from './ClaimComponents/pages/AddDevice/ThingsToNote';
import UpdateAnchorDevice from './ClaimComponents/pages/AddDevice/UpdateAnchorDevice';
import UploadPurchaseReceipt from './ClaimComponents/pages/AddDevice/UploadPurchaseReceipt';
import ClaimConfirmation from './ClaimComponents/pages/ClaimConfirmation';
import ClaimInEligible from './ClaimComponents/pages/ClaimInEligible';
import CleaningLogistics from './ClaimComponents/pages/CleaningServices/CleaningLogistics';
import CleaningReviewSubmission from './ClaimComponents/pages/CleaningServices/CleaningReviewSubmission';
import CleaningServiceConfirmation from './ClaimComponents/pages/CleaningServices/CleaningServiceConfirmation';
import CleaningTermsAndConditions from './ClaimComponents/pages/CleaningServices/CleaningTermsAndConditions';
import SelectDevice from './ClaimComponents/pages/CleaningServices/SelectDevice';
import SelectDeviceModel from './ClaimComponents/pages/CleaningServices/SelectDeviceModel';
import SelectInvoice from './ClaimComponents/pages/CleaningServices/SelectInvoice';
import SetDeviceDetails from './ClaimComponents/pages/CleaningServices/SetDeviceDetails';
import ConfirmEConfirmation from './ClaimComponents/pages/ConfirmE-confirmation';
import { DataTransferGuide } from './ClaimComponents/pages/DataTransferGuide';
import DeliveryOptionsContainer from './ClaimComponents/pages/DeliveryOptionsContainer';
import DeviceSelection from './ClaimComponents/pages/DeviceSelection';
import EConfirmation from './ClaimComponents/pages/E-Confirmation';
import ErrorScreen from './ClaimComponents/pages/ErrorScreen';
import Fmip from './ClaimComponents/pages/FmipPages/Fmip';
import FmipInstructions from './ClaimComponents/pages/FmipPages/FmipInstructions';
import UnableToProceedSR from './ClaimComponents/pages/FmipPages/NotAbleToProceedService';
import UnableToTurnOffFmip from './ClaimComponents/pages/FmipPages/UnableToTurnoffFmip';
import HowCanWeHelpPage from './ClaimComponents/pages/HowCanWeHelpPage';
import HowCanWeHelpPageInWarranty from './ClaimComponents/pages/HowCanWeHelpPageWarranty';
import InwarrantyNotEligible from './ClaimComponents/pages/InwarrantyNotEligible';
// import LandingPage from './ClaimComponents/pages/LandingPage';
import LogisticsAndFulfilmentContainer from './ClaimComponents/pages/LogisticsAndFulfilmentContainer';
import LostMode from './ClaimComponents/pages/LostMode';
import BillingDetailsComp from './ClaimComponents/pages/MyPayments/BillingDetailsComp';
import MyPaymentsContainer from './ClaimComponents/pages/MyPayments/MyPaymentsContainer';
import OEMWarranty from './ClaimComponents/pages/OEMWaranty';
import CsatFeedback from './ClaimComponents/pages/Rating/CsatFeedback';
import NpsRating from './ClaimComponents/pages/Rating/Nps';
import NpsReasons from './ClaimComponents/pages/Rating/NpsReasons';
import ThanksMessage from './ClaimComponents/pages/Rating/ThanksFeedback';
import RefreshLogisticsAndFulfilmentContainer from './ClaimComponents/pages/RefreshLogisticsAndFulfilmentContainer';
import RenewalComp from './ClaimComponents/pages/Renewal/RenewalComp';
import RenewalConfirmationComp from './ClaimComponents/pages/Renewal/RenewalConfirmationComp';
import RenewalReview from './ClaimComponents/pages/Renewal/RenewalReview';
import Repair from './ClaimComponents/pages/Repair';
import ReplacementContainer from './ClaimComponents/pages/ReplacementContainer';
import RepresentativeForm from './ClaimComponents/pages/RepresentativeForm';
import ReviewHold from './ClaimComponents/pages/ReviewHold';
import Reviewsubmission from './ClaimComponents/pages/Reviewsubmission';
import SubmitDeclarationPage from './ClaimComponents/pages/SubmitDeclaration';
import Submitrequest from './ClaimComponents/pages/Submitrequest';
import TermsAndConditionsNew from './ClaimComponents/pages/TermsAndConditionsNew';
import TriageFailure from './ClaimComponents/pages/TriageFailure';
import ConfirmationUpdateDevice from './ClaimComponents/pages/UpdateDevice/ConfirmationUpdateDevice';
import DeviceCapacityUpdates from './ClaimComponents/pages/UpdateDevice/DeviceCapacityUpdates';
import DeviceColorUpdates from './ClaimComponents/pages/UpdateDevice/DeviceColorUpdates';
import ImeiDetail from './ClaimComponents/pages/UpdateDevice/ImeiDetail';
import SelectMakeModel from './ClaimComponents/pages/UpdateDevice/SelectMakeModel';
import SerialNumberDetail from './ClaimComponents/pages/UpdateDevice/SerialNumberDetail';
import UpdateDeviceDetails from './ClaimComponents/pages/UpdateDevice/UpdateDeviceDetails';
import UpdateDeviceReview from './ClaimComponents/pages/UpdateDevice/UpdateDeviceReview';
import VideoDeclarationPage from './ClaimComponents/pages/VideoDeclaration';
import MyClaims from './ClaimComponents/pages/myclaims/Myclaims';
import QualtricsSurvey from './ClaimComponents/pages/nps/QualtricsSurvey';
import PaymentContainer from './ClaimComponents/pages/payment/PaymentContainer';
import ActionTypes from './ClaimComponents/store/actionTypes';
import { useCentralStore } from './ClaimComponents/store/appContext';
import { getDeviceImages } from './ClaimComponents/utils/get-device-images';
import Loader from './ClaimComponents/utils/loader';
import { areURLsEqual, getRedirectUrl, saveError } from './ClaimComponents/utils/utills';
import { Header } from './HeaderComponents/Header';
import ChatOverlay from './components/ChatOverlay';
import { loadingWaitingMessages } from './modules/config/constants';
import DocumentsPage from './pages/DocumentsPage';
import SSOConsumer from './pages/SSOConsumer';
import SSORedirect from './pages/SSORedirect';
import ChangePasswordPage from './profile/src/pages/ChangePasswordPage';
import CommunicationPreferencePage from './profile/src/pages/CommunicationPreferencePage';
import DashboardPage from './profile/src/pages/DashboardPage';
import FeedbackPage from './profile/src/pages/FeedbackPage';
import DeviceOptionsContainer from '../src/ClaimComponents/pages/DeviceOptionsContainer';
// import ChatOverlay from './components/ChatOverlay';
import TechCarePreferencePage from './profile/src/pages/TechCarePreferencePage';
import { findAgreementByMdn, getOnlineSession } from './services/api';
import LowBalancePage from './ClaimComponents/pages/LowBalancePage';
import VerifyProviderPage from './ClaimComponents/pages/VerifyProviderPage';
import GenerateLabel from './ClaimComponents/pages/generateLabel/generateLabel';
import VerifyUploadedIdDetailsPage from './ClaimComponents/pages/VerifyUploadedIdDetailsPage';
import UploadIdPage from './ClaimComponents/pages/UploadIdPage';
import { checkIsApplLoadDisabled } from './utils/config';
import BGBTPaymentNew from './ClaimComponents/pages/payment/BGBT-PaymentNew';
import DeviceRegistrationInfo from './components/deviceregistration/DeviceRegistrationInfo';
import UploadPurchaseReceiptAlt from './components/deviceregistration/UploadPurchaseReceipt';
import { ConfirmRegistration } from './components/deviceregistration/ConfirmDeviceRegistration';

const LandingPage = lazy(() => import('./pages/LandingPage'));
const PlanDetailsPage = lazy(() => import('./pages/PlanDetailsPage'));
const FaqPage = lazy(() => import('./pages/FaqPage'));

export const boxShadow = `0px -1px 0px 0px #EBEBEB inset;`;

const tagManagerArgs: any = {
    gtmId: import.meta.env.VITE_GTM_ID,
};

interface RoutePageProps {
    account: AccountInfo;
    showBack?: boolean;
    breadcrumb?: ReactNode | undefined;
    bypass?: boolean;
}

const RoutePage: React.FC<RoutePageProps & PropsWithChildren> = ({
    account,
    breadcrumb,
    children,
    bypass = false,
}) => {
    const [authtoken] = useAuthToken({});
    const globalState = useCentralStore();
    const navigate = useNavigate();
    const disableApplLoad = checkIsApplLoadDisabled(getCarrierByDomain())
    // const disableApplLoad = getCarrierByDomain()?.toLowerCase() === 'starhub' || getCarrierByDomain()?.toLowerCase() === 'twopenmarket_slw'; //useFeatureIsOn('disableApplLoad');     
    const [sessionData] = useSessionStorage<any>('data', '');
    const [checkFingagreemntAPICall, setFingagreemntAPICall] = useState<boolean>(false);

    const { mutate: fetchAgreementByMdn } = useMutation({
        mutationFn: findAgreementByMdn,
        retry: 5,
        retryDelay: (failureCount) => {
            if (failureCount === 3) {
                // setLoadingMessage(
                loadingWaitingMessages[Math.floor(Math.random() * loadingWaitingMessages.length)];
                // );
            }
            return Math.min(1000 * 2 ** failureCount, 30000);
        },
        onSuccess: (data) => {
            if (disableApplLoad) {
                // let deviceList: any = [];
                // deviceList = data?.deviceData?.filter((a: any) => a?.type?.toLowerCase() !== 'byod' && a?.assetInstance?.toUpperCase() === 'REPLACED')

                // if (!deviceList || deviceList === undefined || deviceList.length === 0) {
                // deviceList = data?.deviceData?.filter((a: any) => a?.type?.toLowerCase() !== 'byod' && a?.assetInstance?.toUpperCase() === 'ENROLLED')
                // }

                // deviceList = [...new Map(deviceList?.map((item: any) =>
                //   [item['mdn'], item])).values()]

                // let deviceList1 = new Map();
                // data?.deviceData?.forEach((v: any) => {
                //     let prevValue = deviceList1.get(v.mdn);
                //     if (!prevValue || prevValue.type === 'ENROLLED') {
                //         deviceList1.set(v.mdn, v);
                //     }
                // });
                // let deviceList = [...deviceList1.values()];

                // globalState?.dispatch({
                //     type: ActionTypes.SET_DEVICES_LIST,
                //     payload: deviceList || data?.deviceData,
                // });
                globalState?.dispatch({
                    type: ActionTypes.SET_DEVICES_LIST,
                    payload: data?.deviceData?.length > 0 ? data?.deviceData : globalState?.state?.enrolledDevicesList,
                });

                globalState?.dispatch({
                    type: ActionTypes.SET_FINDAGREEMENT_BY_MDN_DATA,
                    payload: data,
                });
                globalState?.dispatch({
                    type: ActionTypes.SET_FINDAGREEMENT_SESSION_DATA,
                    payload: data.data,
                });
            }
            globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
            navigate(getRedirectUrl(getTypeByDomain()));
            setFingagreemntAPICall(false);
        },
        onSettled: () => {
            fetchOnlineSession();
        },
        onError: (error) => {
            setFingagreemntAPICall(false);
            globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
            console.error('Findagreement failed', (error as { message: string })?.message);
            // doLogout('findAgreementByMdn');
        },
    });

    const { refetch: fetchOnlineSession } = useQuery(
        ['sessionData'],
        async () => {
            return await getOnlineSession();
        },
        {
            retry: 5,
            retryDelay: (failureCount, error) => {
                if (failureCount === 3) {
                    // setLoadingMessage(
                    loadingWaitingMessages[
                        Math.floor(Math.random() * loadingWaitingMessages.length)
                    ];
                    // );
                }
                Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Error', {
                    userId: account.userId,
                    error: error,
                });
                return Math.min(1000 * 2 ** failureCount, 30000);
            },
            onSuccess: (data) => {
                setFingagreemntAPICall(false);
                globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
                Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Success', {
                    userId: account.userId,
                });

                globalState?.dispatch({
                    type: ActionTypes.SET_SESSION_RESPONSE,
                    payload: data,
                });
                const isMaxis = data.InitializeResponse?.ClientName?.toUpperCase() === 'MAXIS';
                globalState?.dispatch({
                    type: ActionTypes.SET_IS_MAXIS_FLAG,
                    payload: isMaxis,
                });

                const btMethods = data?.InitializeResponse?.isBraintree?.method;

                globalState?.dispatch({
                    type: ActionTypes.SET_BRAINTREE_METHODS,
                    payload: btMethods,
                });

                const agreementData = data?.FindAgreementsResults?.Agreements?.Agreement?.[0];
                const address1 = agreementData?.Address?.Address1;
                const address2 = agreementData?.Address?.Address2;
                const city = agreementData?.Address?.City;
                const _postalCode = agreementData?.Address?.PostalCode;
                const addressjoin = { address1, address2, city, _postalCode };
                const fulladdress = Object.values(addressjoin).join(', ');

                Geocode.setApiKey(import.meta.env.VITE_GOOGLE_API_KEY);
                Geocode.setLanguage('en');
                Geocode.setRegion('SGP');
                Geocode.setLocationType('ROOFTOP');

                Geocode.fromAddress(fulladdress).then(
                    (response) => {
                        const { lat, lng } = response?.results?.[0]?.geometry?.location;
                        globalState?.dispatch({
                            type: ActionTypes.SET_LOCATION_COORDINATES,
                            payload: { lat, lng },
                        });
                    },
                    (error) => {
                        console.error(error);
                    }
                );
                // setAgreementData(data?.FindAgreementsResults?.Agreements?.Agreement?.[0])
            },
            onError: (error) => {
                setFingagreemntAPICall(false);
                globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
                // console.error(error);
                Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Error', {
                    userId: account.userId,
                    error,
                });
                // setPageLoading(true);
                // setIsFailedApiInit(true);
                // doLogout('getOnlineSession');
            },
            enabled: false,
        }
    );

    const reloadLanding = () => {
        globalState?.dispatch({
            type: ActionTypes.SET_SR_SECTION_DETAILS,
            payload: {
                showServiceRquSection: false,
                srSelectedDevice: undefined,
            }
        });
        let paths = ['/', '/myplansandservicerequests', '/portal/plan', '/profile'];
        if (paths.includes(window.location.pathname)) {
            navigate('/');
        } else {
            setFingagreemntAPICall(true);
            globalState?.dispatch({ type: ActionTypes.LOADING, payload: true });
            fetchAgreementByMdn({
                mdn: globalState?.state?.selectedEnrolledDevice?.mdn || account?.enrolledMdn || '',
                email:
                    globalState?.state?.selectedEnrolledDevice?.emailAddress ??
                    account?.email ??
                    '',
                data: sessionData,
                imei: '',
                idx: 0,
                assetId: '',
                isDeviceData: true,
            });
        }
    };

    return (
        <ProtectedLayout
            headerComponent={
                <>
                    <Header
                        top={0}
                        authenticated={isAuthenticated(account, authtoken)}
                        account={account}
                        signOutProps={{ styleProps: { margin: '0 auto' } }}
                        zIndex={'99999'}
                        onClickLanding={() =>
                            checkFingagreemntAPICall ? undefined : reloadLanding()
                        }
                        skipUrlClick={true}
                    />
                    {breadcrumb && <>{breadcrumb}</>}
                </>
            }
            bypass={bypass}
        >
            {globalState?.state?.Loading && <Loader />}
            {children}
        </ProtectedLayout>
    );
};

function App() {
    const navigate = useNavigate();

    TagManager.initialize(tagManagerArgs);

    const [account] = useAccount({ firstName: '', lastName: '', profileId: '', userId: '' });
    const { data } = useConfig();
    const { title } = data;
    useDocumentTitle(`${title}` || '\u200E');

    const isFaqEnabled = useFeatureIsOn('faq');
    const isDocumentsEnabled = useFeatureIsOn('my-documents');
    const disableApplLoad = checkIsApplLoadDisabled(getCarrierByDomain()) //useFeatureIsOn('disableApplLoad');

    // useDocumentTitle(title ? `${title} [Profile Management]` : '\u200E');
    // const isChangePasswordDisabled = useIsDisabledFeature('profile', 'stnChangePassword');
    // const isCommunicationPreferencesDisabled = useIsDisabledFeature(
    //   'profile',
    //   'stnCommunicationPreferences'
    // );
    // const isTechcarePreferencesDisabled = useIsDisabledFeature('profile', 'stnTechcarePreferences');
    // const isFeedbackDisabled = useIsDisabledFeature('profile', 'stnFeedback');
    
    const portalType = getTypeByDomain();

    // changing favicon
    const { theme } = data;
    const {
        favicon,
        hero: { banner },
    }: Record<string, any> = theme;

    if (favicon) {
        document.getElementById('favicon')?.setAttribute('href', favicon);
    }

    const routes = [
        {
            path: '/',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <LandingPage banner={banner} />
                </RoutePage>
            ),
        },
        {
            //preventing /portal/portal/plan
            path: portalType === 'repair' ? '/plan' : '/portal/plan', // temp: commented during profile merge
            // path: '/plan',
            enabled: true,
            loader: () => import('./pages/PlanDetailsPage').then((module) => module.default),
            element: (
                <RoutePage
                    account={account}
                    breadcrumb={
                        <Box
                            w={'full'}
                            color={'#000'}
                            mt={'60px'}
                            p={'16px 8px'}
                            maxW={{ base: 'initial', lg: '768px', xl: '992px', '2xl': '1280px' }}
                            mx={{ base: '0px', lg: 'auto' }}
                        >
                            <Button
                                variant={'link'}
                                as={Link}
                                colorScheme="black"
                                href={disableApplLoad ? '#' : '/'}
                                onClick={() =>
                                    disableApplLoad
                                        ? (navigate('/'),
                                          globalState?.dispatch({
                                              type: ActionTypes.SCROLL_TO_SR,
                                              payload: false,
                                          }))
                                        : undefined
                                }
                                leftIcon={<ArrowBackIcon />}
                                color={'#000'}
                                // display={{ base: 'block', md: 'none' }}
                            >
                                <Text>
                                    <TextScript
                                        text={'Close'}
                                        translationKey={`Subheader/${portalType}-Device care`}
                                    />
                                </Text>
                            </Button>
                        </Box>
                    }
                >
                    <Suspense>
                        <PlanDetailsPage />
                    </Suspense>
                </RoutePage>
            ),
        },
        {
            path: '/faq',
            enabled: isFaqEnabled && portalType === 'repair',
            loader: () => import('./pages/FaqPage').then((module) => module.default),
            element: (
                <RoutePage
                    account={account}
                    breadcrumb={
                        <Box
                            w={'full'}
                            color={'#000'}
                            mt={'60px'}
                            p={'16px 8px'}
                            maxW={{ base: 'initial', lg: '768px', xl: '992px', '2xl': '1280px' }}
                            mx={{ base: '0px', lg: 'auto' }}
                        >
                            <Button
                                variant={'link'}
                                as={Link}
                                colorScheme="black"
                                href={'/'}
                                leftIcon={<ArrowBackIcon />}
                                color={'#000'}
                                // display={{ base: 'block', md: 'none' }}
                            >
                                <Text>
                                    <TextScript
                                        text={'Close'}
                                        translationKey={`Subheader/${portalType}-Device care`}
                                    />
                                </Text>
                            </Button>
                        </Box>
                    }
                    bypass={true}
                >
                    <Suspense>
                        <FaqPage />
                    </Suspense>
                </RoutePage>
            ),
        },
        {
            //preventing /portal/portal/documents
            path: portalType === 'repair' ? '/documents' : '/portal/documents',
            enabled: isDocumentsEnabled,
            loader: () => import('./pages/DocumentsPage').then((module) => module.default),
            element: (
                <RoutePage account={account}>
                    <DocumentsPage />
                </RoutePage>
            ),
        },
        {
            path: '/ssoredirect',
            enabled: true,
            loader: () => import('./pages/SSORedirect').then((module) => module.default),
            element: (
                // <RoutePage account={account}>
                <Suspense>
                    <SSORedirect />
                </Suspense>
                // </RoutePage>
            ),
        },
        {
            path: '/ssoconsumer',
            enabled: true,
            loader: () => import('./pages/SSOConsumer').then((module) => module.default),
            element: (
                // <RoutePage account={account}>
                <Suspense>
                    <SSOConsumer />
                </Suspense>
                // </RoutePage>
            ),
        },
        {
            path: '/triage',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <HowCanWeHelpPage />
                </RoutePage>
            ),
        },
        //   {
        //     path: '/landingpage',
        //     enabled: true,
        //     element: (
        //       <RoutePage account={account}>
        //         <LandingPage />
        //       </RoutePage>
        //     ),
        //   },
        {
            path: '/oemwarranty',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <OEMWarranty />
                </RoutePage>
            ),
        },
        {
            path: '/fmip',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <Fmip />
                </RoutePage>
            ),
        },
        {
            path: '/howcanwehelpWarranty',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <HowCanWeHelpPageInWarranty />
                </RoutePage>
            ),
        },
        {
            path: '/fmipinstructions',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <FmipInstructions />
                </RoutePage>
            ),
        },
        {
            path: '/unabletoproceedsr',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <UnableToProceedSR />
                </RoutePage>
            ),
        },
        {
            path: '/repair',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <Repair />
                </RoutePage>
            ),
        },
        {
            path: '/unabletoturnofffmip',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <UnableToTurnOffFmip />
                </RoutePage>
            ),
        },
        {
            path: '/deviceselection',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <DeviceSelection />
                </RoutePage>
            ),
        },
        {
            path: '/replacement',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <ReplacementContainer />
                </RoutePage>
            ),
        },
        {
            path: '/terms',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <TermsAndConditionsNew />
                </RoutePage>
            ),
        },
        // {
        //   path: '/techcare',
        //   enabled: true,
        //   element: (
        //     <RoutePage account={account}>
        //       <TechCarePreferencePage />
        //     </RoutePage>
        //   ),
        // }
        {
            path: '/payment',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <PaymentContainer />
                </RoutePage>
            ),
        },
        {
            path: '/reviewsubmission',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <Reviewsubmission />
                </RoutePage>
            ),
        },
        {
            path: '/claimconfirmation',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <ClaimConfirmation />
                </RoutePage>
            ),
        },
        {
            path: '/submit',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <Submitrequest />
                </RoutePage>
            ),
        },
        {
            path: '/deliveryoptions',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <DeliveryOptionsContainer />
                </RoutePage>
            ),
        },
        {
            path: '/logisticsoptions',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <LogisticsAndFulfilmentContainer />
                </RoutePage>
            ),
        },
        {
            path: '/logisticsrefresh',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <RefreshLogisticsAndFulfilmentContainer />
                </RoutePage>
            ),
        },
        {
            path: '/representativeform',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <RepresentativeForm />
                </RoutePage>
            ),
        },
        {
            path: '/lostmode',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <LostMode />
                </RoutePage>
            ),
        },
        {
            path: '/triageFailure',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <TriageFailure />
                </RoutePage>
            ),
        },
        {
            path: '/myplansandservicerequests',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <MyClaims />
                </RoutePage>
            ),
        },
        {
            path: '/submitdeclaration',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <SubmitDeclarationPage />
                </RoutePage>
            ),
        },
        {
            path: '/videodeclaration',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <VideoDeclarationPage />
                </RoutePage>
            ),
        },
        {
            path: '/datatransfer',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <DataTransferGuide />
                </RoutePage>
            ),
        },
        {
            path: '/reviewhold',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <ReviewHold />
                </RoutePage>
            ),
        },
        {
            path: '/econfirmation',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <EConfirmation />
                </RoutePage>
            ),
        },
        {
            path: '/submiteconfirmation',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <ConfirmEConfirmation />
                </RoutePage>
            ),
        },
        {
            path: '/noteligibleinwarranty',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <InwarrantyNotEligible />
                </RoutePage>
            ),
        },
        {
            path: '/npsrating',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <NpsRating />
                </RoutePage>
            ),
        },
        {
            path: '/npsreason',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <NpsReasons />
                </RoutePage>
            ),
        },
        {
            path: '/csatfeedback',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <CsatFeedback />
                </RoutePage>
            ),
        },
        {
            path: '/thanksmessage',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <ThanksMessage />
                </RoutePage>
            ),
        },
        {
            path: '/updateDevice',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <UpdateDeviceDetails />
                </RoutePage>
            ),
        },
        {
            path: '/serialNumberDetails',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <SerialNumberDetail />
                </RoutePage>
            ),
        },
        {
            path: '/imeiDetails',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <ImeiDetail />
                </RoutePage>
            ),
        },
        {
            path: '/updateCapacity',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <DeviceCapacityUpdates />
                </RoutePage>
            ),
        },
        {
            path: '/selectMakeModel',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <SelectMakeModel />
                </RoutePage>
            ),
        },
        {
            path: '/reviewUpdateDevice',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <UpdateDeviceReview />
                </RoutePage>
            ),
        },
        {
            path: '/confirmationUpdateDevice',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <ConfirmationUpdateDevice />
                </RoutePage>
            ),
        },
        {
            path: '/updateColor',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <DeviceColorUpdates />
                </RoutePage>
            ),
        },
        {
            path: '/updateAnchorDevice',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <UpdateAnchorDevice />
                </RoutePage>
            ),
        },
        {
            path: '/BYODDeviceDetails',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <BYODDeviceDetails />
                </RoutePage>
            ),
        },
        {
            path: '/deviceDetails',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <DeviceDetails />
                </RoutePage>
            ),
        },
        {
            path: '/addNewDevice',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <AddNewDevice />
                </RoutePage>
            ),
        },
        {
            path: '/deviceCategoryList',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <DeviceCategoryList />
                </RoutePage>
            ),
        },
        {
            path: '/uploadPurchaseReceipt',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <UploadPurchaseReceipt />
                </RoutePage>
            ),
        },
        {
            path: '/confirmDeviceRegistration',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <ConfirmDeviceRegistration />
                </RoutePage>
            ),
        },
        {
            path: '/myPayments',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <MyPaymentsContainer />
                </RoutePage>
            ),
        },
        {
            path: '/addCard',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <AddCardComp />
                </RoutePage>
            ),
        },
        {
            path: '/billingDetails',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <BillingDetailsComp latestPaymnetHistory={undefined} />
                </RoutePage>
            ),
        },
        {
            path: '/renewal',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <RenewalComp />
                </RoutePage>
            ),
        },
        {
            path: '/renewalConfirmation',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <RenewalConfirmationComp />
                </RoutePage>
            ),
        },
        {
            path: '/renewalReview',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <RenewalReview />
                </RoutePage>
            ),
        },
        {
            path: '/successAddCard',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <SuccessAddCard />
                </RoutePage>
            ),
        },
        {
            path: '/error',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <ErrorScreen />
                </RoutePage>
            ),
        },
        {
            path: '/claimInEligible',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <ClaimInEligible />
                </RoutePage>
            ),
        },
        {
            path: '/thingsToNote',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <ThingsToNote />
                </RoutePage>
            ),
        },
        {
            path: '/deviceDetailsNew',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <DeviceDetailsNew />
                </RoutePage>
            ),
        },
        {
            path: '/errorPage',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <ErrorPage />
                </RoutePage>
            ),
        },
        {
            path: '/cleaningTerms',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <CleaningTermsAndConditions />
                </RoutePage>
            ),
        },
        {
            path: '/cleaningServices',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <CleaningTermsAndConditions />
                </RoutePage>
            ),
        },
        {
            path: '/selectDeviceCateogry',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <SelectDevice />
                </RoutePage>
            ),
        },
        {
            path: '/selectModel',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <SelectDeviceModel />
                </RoutePage>
            ),
        },
        {
            path: '/setDeviceDetails',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <SetDeviceDetails />
                </RoutePage>
            ),
        },
        {
            path: '/cleaningLogistics',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <CleaningLogistics />
                </RoutePage>
            ),
        },
        {
            path: '/cleaningReview',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <CleaningReviewSubmission />
                </RoutePage>
            ),
        },
        {
            path: '/cleaningInvoice',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <SelectInvoice />
                </RoutePage>
            ),
        },
        {
            path: '/cleaningSummary',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <CleaningServiceConfirmation />
                </RoutePage>
            ),
        },
        {
            path: '/survey',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <QualtricsSurvey />
                </RoutePage>
            ),
        },

          //  Profile Routes started from here
          {
            path: '/profile',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <DashboardPage />
                </RoutePage>
            ),
        },
        {
            path: '/techcare',
            enabled: true, //!isTechcarePreferencesDisabled
            element: (
                <RoutePage account={account}>
                    <TechCarePreferencePage />
                </RoutePage>
            ),
        },
        {
            path: '/notifications',
            enabled: true, //!isCommunicationPreferencesDisabled
            element: (
                <RoutePage account={account}>
                    <CommunicationPreferencePage />
                </RoutePage>
            ),
        },
        {
            path: '/feedback',
            enabled: true, //!isFeedbackDisabled
            element: (
                <RoutePage account={account}>
                    <FeedbackPage />
                </RoutePage>
            ),
        },
        {
            path: '/changepass',
            enabled: true, //!isChangePasswordDisabled
            element: (
                <RoutePage account={account}>
                    <ChangePasswordPage />
                </RoutePage>
            ),
        },
        {
            path: '/deviceoptions',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <DeviceOptionsContainer />
                </RoutePage>
            ),
        },          
          {
            path: '/lowbalance',
            enabled: true,
            element: (
              <RoutePage account={account}>
                <LowBalancePage />
              </RoutePage>
            ),
          },
          {
            path: '/verifyprovider',
            enabled: true,
            element: (
              <RoutePage account={account}>
                <VerifyProviderPage />
              </RoutePage>
            ),
          },     
          {
            path: '/generateLabel',
            enabled: true,
            element: (
              <RoutePage account={account}>
                <GenerateLabel />
              </RoutePage>
            ),
          },
          {
            path: '/uploadId',
            enabled: true,
            element: (
              <RoutePage account={account}>
                <UploadIdPage/>
              </RoutePage>
            ),
          },
          {
            path: '/verifyUploadId',
            enabled: true,
            element: (
              <RoutePage account={account}>
                <VerifyUploadedIdDetailsPage/>
              </RoutePage>
            ),
          },
          {
            path: '/paymentbgbt',
            enabled: true,
            element: (
              <RoutePage account={account}>
                <BGBTPaymentNew />
              </RoutePage>
            ),
          },
          {
            path: '/device-register',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <DeviceRegistrationInfo />
                </RoutePage>
            ),
        },
        {
            path: '/upload-receipt',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <UploadPurchaseReceiptAlt />
                </RoutePage>
            ),
        },
        {
            path: '/confirm-registration',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <ConfirmRegistration />
                </RoutePage>
            ),
        },
    ];

    useEffect(() => {
        const navigation = window.performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming;

        if (navigation?.type === 'reload' && !areURLsEqual(navigation?.name, window.location.origin)) {
            const type = getTypeByDomain();
            window.location.href = type === 'repair' ? '/portal' : '/';
        }
    }, []);

    const globalState = useCentralStore();

    const getImagesFromContentful = () => {
        getDeviceImages()
            .then((data: any) => {
                globalState?.dispatch({ type: ActionTypes.GET_DEVICE_IMAGES, payload: data });
            })
            .catch((err: any) => {
                saveError(err, globalState);
            });
    };

    useEffect(() => {
        getImagesFromContentful();
    }, []);

    const [question, setQuestion] = useState<string>('');

    return (
        <>
            <Routes>
                <Route>
                    {routes.map((route, i) => {
                        const { path, enabled, element, loader } = route;

                        if (enabled) {
                            return (
                                <Route
                                    key={`protected-route-${i}`}
                                    path={path}
                                    element={element}
                                    loader={loader}
                                />
                            );
                        }
                    })}
                </Route>
            </Routes>
            <ChatOverlay question={question} onChatClose={() => setQuestion('')} />
        </>
    );
}

export default App;
