import { TextScript, getCarrierByDomain, getTypeByDomain } from '@MGPD/myasurion-shared';
import { Radio } from '@chakra-ui/radio';
import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { useEffect } from 'react';

import PaymentOptionsKeys from '../../Scripts/translationsKeys.json';
import useContentfulImage from '../../hooks/useContentfulImage';
import ApplePaySVG from '../../icons/ApplePay.svg';
import GooglePaySVG from '../../icons/GooglePay.svg';
import PayPalSVG from '../../icons/PayPal.svg';
import VisaIconSVG from '../../icons/Visa.svg';
import { logGTMEvent } from '../../services/api';
import { useCentralStore } from '../../store/appContext';
import { CONTENTFUL_SLUG, GTM_EVENT } from '../../utils/constant';
import { getGTMData } from '../../utils/utills';

export interface PaymentOptionsProps {
    optn: any;
}

export const PaymentOptions: React.FC<PaymentOptionsProps> = ({ optn }) => {
    const globalState = useCentralStore();

    const visa = useContentfulImage(CONTENTFUL_SLUG.VISA);
    const applePay = useContentfulImage(CONTENTFUL_SLUG.APPLE_PAY);
    const payPal = useContentfulImage(CONTENTFUL_SLUG.PAYPAL);
    const googlePay = useContentfulImage(CONTENTFUL_SLUG.GOOGLE_PAY);

    useEffect(() => {
        let GTMData = getGTMData(
            GTM_EVENT.PAYMENT_SCREEN_EVENT,
            globalState?.state?.currentAgreementData,
            getCarrierByDomain(),
            getTypeByDomain(),
            globalState?.state?.currentAgreementData?.CustomerCaseNumber
        );
        logGTMEvent(GTMData);
    }, []);

    return (
        <Box>
            <Stack>
                <Box
                    key={optn}
                    borderWidth="1px"
                    borderRadius="md"
                    borderColor="#A5AAAF"
                    p={4}
                    mb={4}
                >
                    <Flex justifyContent="space-between" alignContent="center">
                        {/* <ChakraProvider theme={theme}> */}
                        <Radio
                            key={optn}
                            value={
                                optn === globalState?.state?.selectedPaymentMethod
                                    ? globalState?.state?.selectedPaymentMethod
                                    : optn
                            }
                        >
                            <Text fontSize={'20px'}>
                                <TextScript
                                    text={optn}
                                    translationKey={`${PaymentOptionsKeys.Payment.PageName}/${optn}`}
                                />
                            </Text>
                        </Radio>
                        {/* </ChakraProvider> */}
                        {optn === 'Visa/Mastercard' && (
                            <Flex alignItems="center" justifyItems={'center'}>
                                <Image
                                    src={visa?.imageUrl || VisaIconSVG}
                                    my={'auto'}
                                    ml={'50px'}
                                    alt="VisaIcon"
                                ></Image>
                            </Flex>
                        )}
                        {optn === 'Google Pay' && (
                            <Image
                                src={googlePay?.imageUrl || GooglePaySVG}
                                ml={'50px'}
                                my={'auto'}
                                alt="GooglePayIcon"
                            ></Image>
                        )}
                        {optn === 'Paypal' && (
                            <Image
                                src={payPal?.imageUrl || PayPalSVG}
                                my={'auto'}
                                ml={'50px'}
                                alt="PaypalIcon"
                            ></Image>
                        )}
                        {optn === 'ApplePay' && (
                            <Image
                                src={applePay?.imageUrl || ApplePaySVG}
                                my={'auto'}
                                ml={'50px'}
                                alt="ApplePayIcon"
                            ></Image>
                        )}
                    </Flex>
                </Box>
            </Stack>
        </Box>
    );
};

export default PaymentOptions;
