/* eslint-disable no-console */
import {
    MyPayments,
    SubHeader,
    TextScript,
    getAuthTokens,
    getCarrierByDomain,
    getCountryByCarrier,
    getTypeByDomain,
    useAccount,
    useSessionStorage,
    useTextScript,
} from '@MGPD/myasurion-shared';
import { CloseIcon } from '@chakra-ui/icons';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogOverlay,
    Box,
    Image,
    Skeleton,
    Text,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import Scripts from '../../Scripts/asurion-script.json';
import VisaIconSVG from '../../icons/Visa.svg';
import MainStackLayout from '../../layout/MainStackLayout';
import '../../services/api';
import { getPCISecurityTokenNew, getPaymentDetails } from '../../services/api';
import ActionTypes from '../../store/actionTypes.ts';
import { useCentralStore } from '../../store/appContext.tsx';
import { AssetData } from '../../types/types.ts';
import { getCurrencySymbol } from '../../utils/localization-helper.tsx';
import { getRedirectUrl, saveError } from '../../utils/utills.tsx';
import CancelSrPopup from '../myclaims/CancelSrPopup.tsx';
// import { getDeviceImageByModel } from '../../utils/get-device-images.tsx';
import BillingDetailsComp from './BillingDetailsComp.tsx';
import PendingPaymentSection from './PendingPaymentSection.tsx';

function MyPaymentsContainer() {
    const myPaymentsScrips = Scripts.MyPayments.MyPayment;
    const globalState = useCentralStore();
    const navigate = useNavigate();
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [assetData, setAssetData] = useState<any>();
    const [_assetData, setAssetData1] = useState<any>();

    const [paymentDetailsResp, setPaymentDetails] = useState<any>();
    const [currentPaymentod, setCurrentPaymentod] = useState<any>();
    const [selectedDevice, setSelectedDevice] = useState<any>();
    const [PCITokenError, setPCITokenError] = useState<any>(false);
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [sessionData] = useSessionStorage<any>('data', '');

    const [latestPaymnetHistory, setLatestPaymnetHistory] = useState<any>();
    const { language, isTranslationsLoading } = useTextScript();

    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;

    const authtoken = getAuthTokens();
    const carrier = getCarrierByDomain();
    const cc = getCountryByCarrier(carrier);
    const myAsurionBaseUrl = import.meta.env.VITE_CONFIG_API_BASE_URL;

    const headers = {
        token: authtoken.token,
        'x-api-key': import.meta.env.VITE_X_API_KEY,
        'x-asurion-client': carrier,
        'x-asurion-cc': cc.code,
        authorization: authtoken.authorization,
        id: authtoken.userId,
    };

    const [currentSessionResp, setCurrentSessionResp] = useState<any>();
    const [account] = useAccount({ email: 'email' });
    const localLang = (sessionStorage.getItem('al') || 'en-US')?.replace(/["']/g, '');
    moment.locale(localLang);

    useEffect(() => {
        const _deviceData = globalState?.state?.enrolledDevicesList?.map((v: any) => {
            return {
                plan: v?.plan,
                mdn: `(${v?.callingCode.split('+')?.[1]}) ${v?.mdn}`,
                model: v?.model,
                imei: v?.imei,
                agreementId: v?.agreementId,
                callingCode: v?.callingCode,
                imageUrl: v?.imageUrl,
            };
        });
        setAssetData(_deviceData);
        setSelectedDevice({
            plan:
                globalState?.state?.myPaymentsSelectedDevice?.plan ||
                globalState?.state?.enrolledDevicesList?.[0]?.plan,
            mdn:
                globalState?.state?.myPaymentsSelectedDevice?.mdn ||
                `(${globalState?.state?.enrolledDevicesList?.[0]?.callingCode.split('+')?.[1]}) ${
                    globalState?.state?.enrolledDevicesList?.[0]?.mdn
                }`,
            model:
                globalState?.state?.myPaymentsSelectedDevice?.model ||
                globalState?.state?.enrolledDevicesList?.[0]?.model,
            imei:
                globalState?.state?.myPaymentsSelectedDevice?.imei ||
                globalState?.state?.enrolledDevicesList?.[0]?.imei,
        });
        callFindAgreement(
            globalState?.state?.myPaymentsSelectedDevice?.mdn ||
                globalState?.state?.enrolledDevicesList?.[0]?.mdn
        );
    }, [globalState?.state?.enrolledDevicesList]);

    const callFindAgreement = async (mdn: any) => {
        try {
            setLoading(true);
            // retryWrapper(axios, {retry_time: 2})
            axios
                .post(
                    `${myAsurionBaseUrl}/online/findagreement`,
                    { mdn: mdn, email: account?.email, data: sessionData },
                    { headers }
                )
                .then(() => {
                    // console.log('---', resp);
                    callSession();
                });
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }
    };

    const callSession = async () => {
        try {
            setLoading(true);
            // retryWrapper(axios, {retry_time: 2})
            let response = await axios.get(`${myAsurionBaseUrl}/online/session`, { headers });
            // response.data.body.InitializeResponse.ApplicationId = "6C6ACEB78E5411E789240A113F1B8C0E"
            globalState?.dispatch({
                type: ActionTypes.MYPAYMENTS_CURRENT_SESSION_RESP,
                payload: response?.data?.body?.FindAgreementsResults?.Agreements?.Agreement?.[0],
            });
            setCurrentSessionResp(
                response?.data?.body?.FindAgreementsResults?.Agreements?.Agreement?.[0]
            );
            callPaymentDetailsApi(
                response?.data?.body?.FindAgreementsResults?.Agreements?.Agreement?.[0]
                    ?.ClientAccount?.ClientAccountId ||
                    response?.data?.body?.FindAgreementsResults?.Agreements?.Agreement?.[0]
                        ?.ClientAccountId
            );
            return response.data.body;
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }
    };

    useEffect(() => {
        // let assetData1: AssetData = currentSessionResp?.Assets?.Asset?.filter(
        //   (a: any) => a.AssetInstance === 'ENROLLED'
        // )[0];

        let assetData1: AssetData = currentSessionResp?.Assets?.Asset?.filter(
            (asset: any) => asset?.AssetInstance === 'ENROLLED'
        )?.[0];
        if (assetData1 === undefined) {
            assetData1 = currentSessionResp?.Assets?.Asset?.filter(
                (asset: any) => asset?.AssetInstance === 'REPLACED'
            )?.[0];
        }

        setAssetData1(assetData1);
    }, [currentSessionResp]);

    const changeDevice = (assetData: any) => {
        callFindAgreement(assetData?.mdn?.split(' ')?.[1]);
        const _device = {
            plan: assetData?.plan,
            mdn: assetData?.mdn,
            model: assetData?.model,
            imei: assetData?.imei,
        };
        setSelectedDevice(_device);
        globalState?.dispatch({ type: ActionTypes.MYPAYMENTS_SELECTED_DEVICE, payload: _device });
    };

    const callPaymentDetailsApi = (ClientAccountId: any) => {
        setLoading(true);
        getPaymentDetails(
            ClientAccountId,
            interactionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((resp) => {
                setLoading(false);
                setPaymentDetails(resp?.GetPaymentDetailsResponse);
                globalState?.dispatch({
                    type: ActionTypes.SET_MY_PAYMENT_DETAILS_RESP,
                    payload: resp?.GetPaymentDetailsResponse,
                });
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const onCancelClick = () => {
        window.location.href = getRedirectUrl(getTypeByDomain());
    };

    function generateUniqueId() {
        return uuid();
    }

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    const callPCIToken = () => {
        setPCITokenError(false);
        let GetSecurityTokenRequest = {
            GetSecurityTokenRequest: {
                UseRecurringBillingProgram: true,
                version: '4.5',
                Culture: 'th-TH',
                CurrencyCode: 'THB',
                FirstName: currentSessionResp?.Customers?.Customer?.[0]?.FirstName,
                LastName: currentSessionResp?.Customers?.Customer?.[0]?.Lastname,
                Phone: currentSessionResp?.ContactPoints?.ContactPoint?.[0]?.PhoneNumber,
                Email: currentSessionResp?.ContactPoints?.ContactPoint?.[0]?.EmailAddress,
                InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
                AgreementRequestId: undefined,
                AgreementId: currentSessionResp?.AgreementId,
                BillingProfileId: generateUniqueId().replace(/-/g, '')?.toUpperCase(),
                FeeType: 'ENRFEE',
            },
        };
        setLoading(true);
        globalState?.dispatch({
            type: ActionTypes.SET_MY_PAYMENTS_REF_ID,
            payload: GetSecurityTokenRequest?.GetSecurityTokenRequest?.BillingProfileId,
        });

        getPCISecurityTokenNew(
            GetSecurityTokenRequest,
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((resp) => {
                navigate('/addCard');
                globalState?.dispatch({
                    type: ActionTypes.SET_MY_PAYMENTS_TOKEN_RESP,
                    payload: resp?.GetSecurityTokenResponse,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                saveError(err, globalState);
                setPCITokenError(true);
            });
    };
    const cancelRef = React.useRef<any>();

    useEffect(() => {
        if (paymentDetailsResp?.PaymentMethods) {
            const _currentCCDetails = paymentDetailsResp?.PaymentMethods?.sort(function (
                a: any,
                b: any
            ) {
                return (
                    new Date(b.BillingStartDate).valueOf() - new Date(a.BillingStartDate).valueOf()
                );
            });
            let currentCCDetails = _currentCCDetails?.filter(
                (v: any) =>
                    v?.BillingProfileStatusCode === 'ACTV' &&
                    v?.BillingPaymentTypeCode === 'CRE' &&
                    v?.IsDefault === 'Y'
            )?.[0];
            setCurrentPaymentod(currentCCDetails);
            globalState?.dispatch({
                type: ActionTypes.SET_CURRENT_CC_DETAILS,
                payload: currentCCDetails,
            });
        }
    }, [paymentDetailsResp]);

    useEffect(() => {
        let sortedBillingHistoryArr = globalState?.state?.myPaymentsResp?.PaymentHistory?.sort(
            function (a: any, b: any) {
                return new Date(b.CreatedDate).valueOf() - new Date(a.CreatedDate).valueOf();
            }
        )?.filter((v: any) => v?.ChargeOrderTypeCode === 'ENRFEE')?.[0];
        setLatestPaymnetHistory(sortedBillingHistoryArr);
    }, [globalState?.state?.myPaymentsResp?.PaymentHistory]);

    useEffect(() => {
        globalState?.dispatch({ type: ActionTypes.SET_PENDING_PAYMENT_FLAG, payload: false });
    }, []);

    useEffect(() => {
        const getCurrency = async () => {
            const currency = await getCurrencySymbol();
            setCurrencySymbol(currency);
        };
        if (!isTranslationsLoading && currencySymbol !== '$') {
            getCurrency();
        }
    }, [isTranslationsLoading, currencySymbol, language]);

    return (
        <Box>
            <Box
                w={{ base: 'full', lg: '80%' }}
                margin="auto"
                pb={'150px'}
                id={'myasurion-profile-dashboard'}
                paddingY={'54px'}
                // mb='20px'
            >
                <MainStackLayout gap={0} spacing={0}>
                    <SubHeader
                        backText={''}
                        allowBackButtonClick={false}
                        showCancelButton={true}
                        showBackButton={false}
                        cancelText={'Close'}
                        cancelTextKey="CloseBtn"
                        onCancelClick={onCancelClick}
                    ></SubHeader>
                    <Box
                        id={'myasurion-profile-avatar'}
                        w={{ base: 'full', lg: '80%' }}
                        margin="0 auto"
                        h={'auto'}
                        // p={'24px 16px'}
                    >
                        {assetData ? (
                            <>
                                <MyPayments
                                    pageTitle={myPaymentsScrips.pageTitle}
                                    subscribedDevices={assetData}
                                    title={myPaymentsScrips.title}
                                    heading={myPaymentsScrips.heading}
                                    cardNumber={
                                        currentPaymentod?.CreditCardNumber
                                            ? `**${currentPaymentod?.CreditCardNumber}`
                                            : '-'
                                    }
                                    // imageEl={
                                    //   <Image
                                    //     height={'80px'}
                                    //     marginTop={'22px'}
                                    //     alignSelf={'center'}
                                    //     src={getDeviceImageByModel(
                                    //       globalState?.state?.deviceImages,
                                    //       `${_assetData?.AssetCatalog.AssetFamily}`,
                                    //       '',
                                    //       80
                                    //     )}
                                    //   ></Image>
                                    // }
                                    cardImage={
                                        currentPaymentod?.CreditCardNumber ? (
                                            <Image
                                                height={'20px'}
                                                marginLeft={'8px'}
                                                alignSelf={'center'}
                                                src={VisaIconSVG}
                                            ></Image>
                                        ) : undefined
                                    }
                                    changeText={
                                        currentSessionResp?.AgreementStatus?.toUpperCase() !==
                                        'PREAPPVE'
                                            ? myPaymentsScrips.changeText
                                            : ''
                                    }
                                    nextBillingDateLBL={myPaymentsScrips.nextBillingDateLBL}
                                    nextBillingDateValue={
                                        latestPaymnetHistory?.NextDueDate
                                            ? moment(latestPaymnetHistory?.NextDueDate).format(
                                                  'DD MMM YYYY'
                                              )
                                            : '-'
                                    }
                                    lastPaymentLBL={myPaymentsScrips.lastPaymentLBL}
                                    lastPaymentDate={
                                        latestPaymnetHistory?.CreatedDate
                                            ? moment(latestPaymnetHistory?.CreatedDate).format(
                                                  'DD MMM YYYY'
                                              ) + ','
                                            : ''
                                    }
                                    lastPaymentFee={
                                        latestPaymnetHistory?.TotalAmount
                                            ? currencySymbol + latestPaymnetHistory?.TotalAmount
                                            : '-'
                                    }
                                    handleSubscribedDevice={(data: any) => changeDevice(data)}
                                    handleChange={() => callPCIToken()}
                                    selectedDevice={selectedDevice}
                                />
                                <hr
                                    style={{
                                        margin: '8px 0px',
                                        border: 'none',
                                        borderTop: '2px solid #adaaaa',
                                    }}
                                />
                                {currentSessionResp?.AgreementStatus?.toUpperCase() ===
                                    'PREAPPVE' && (
                                    <Box>
                                        <PendingPaymentSection
                                            currentSessionResp={currentSessionResp}
                                            assetData={_assetData}
                                            currencySymbol={currencySymbol}
                                            callPCIToken={() => callPCIToken()}
                                        />
                                        <hr
                                            style={{
                                                margin: '8px 0px',
                                                border: 'none',
                                                borderTop: '2px solid #adaaaa',
                                                marginBottom: '100px',
                                            }}
                                        />
                                    </Box>
                                )}
                                {currentSessionResp?.AgreementStatus?.toUpperCase() !==
                                    'PREAPPVE' && (
                                    <BillingDetailsComp
                                        latestPaymnetHistory={latestPaymnetHistory}
                                    />
                                )}
                            </>
                        ) : (
                            <Skeleton
                                minW={'335px'}
                                minH={'150px'}
                                w={'full'}
                                borderRadius={'8px'}
                            />
                        )}
                    </Box>
                </MainStackLayout>
                <CancelSrPopup
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
            {PCITokenError && (
                <AlertDialog
                    motionPreset="slideInBottom"
                    leastDestructiveRef={cancelRef}
                    onClose={() => undefined} //onClose()
                    isOpen={PCITokenError}
                    isCentered
                    autoFocus={false}
                >
                    <AlertDialogOverlay />
                    <AlertDialogContent margin={'12px'} p="3">
                        <Box display={'flex'} justifyContent={'right'} width={'100%'}>
                            <Text
                                variant={'link'}
                                cursor={'pointer'}
                                onClick={() => setPCITokenError(false)}
                            >
                                <TextScript
                                    text={'Close'}
                                    translationKey={`myPayments/CancelButton}`}
                                />
                            </Text>
                            <CloseIcon
                                onClick={() => setPCITokenError(false)}
                                display={'block'}
                                cursor={'pointer'}
                                alignSelf={'center'}
                                marginX={'0.5rem'}
                                padding={'1px'}
                            />
                        </Box>
                        <AlertDialogBody paddingX={'12px'} paddingTop={'12px'}>
                            <Text>
                                <TextScript
                                    text={'Something went wrong, please retry.'}
                                    translationKey={`myPayments/tokenError}`}
                                />
                            </Text>
                        </AlertDialogBody>
                    </AlertDialogContent>
                </AlertDialog>
            )}
        </Box>
    );
}

export default MyPaymentsContainer;
