export const initializeKey = 'initializeApiResponse';
export const interactionKey = 'interactionApiResponse';
export const agreementKey = 'agreementDataResponse';
export const pciTokenKey = 'SecurityTokenResponse';
export const chargeOrderKey = 'ChargeOrderResponse';
export const assetDataKey = 'assetData';
export const serviceRequestKey = 'serviceRequest';
export const processIncidentKey = 'processIncident';
export const currentAgreementKey = 'currentAgreement';
export const interactionLineKey = 'interactionLineId';
export const serviceFeeKey = 'serviceFee';
export const replacementDataKey = 'replacementData';
export const createServiceOrderDataKey = 'createServiceOrderRequest';
export const FulfillmentOptionKey = 'FulfillmentOption';
export const ConfirmDeviceKey = 'ConfirmDevice';
export const submitRequestKey = 'SubmitRequest';
export const fmipEligibilityKey = 'fmipCheckEligibility';
export const btClientTokenKey = 'BTClientTokenResponse';
export const btTokenizeKey = 'BTTokenizeResponse';
export const btCreatePaymentMethodKey = 'BTCreatePaymentMethodResponse';
export const btNewNonceKey = 'BTNewNonceResponse';
export const btChargeOrderKey = 'BTChargeOrderResponse';
export const nightDeliveryFeeKey = 'NightDeliveryFee';
export const btProcessPaymentKey = 'BTProcessPaymentResponse';
export const btSubmitOrderKey = 'BTSubmitOrderResponse';
export const deliveryAddressKey = 'deliveryAddress';
export const returnAddressKey = 'returnAddress';
export const deliveryDateKey = 'deliveryDate';
export const deliveryTimeKey = 'deliveryTime';
export const deliveryTimeCodeKey = 'deliveryTimeCode';
export const shippingMethodResponseKey = 'shippingMethodResponse';
export const deliveryTypeKey = 'deliveryType';
export const getaspstorelistResponseKey = 'getaspstorelistResponse';
export const getscheduledavailabilityResponseKey = 'getscheduledavailabilityResponse';
export const storeAddressKey = 'storeAddress';
export const storeIdKey = 'storeId';
export const storeDateKey = 'storeDate';
export const storeTimeKey = 'storeTime';
export const updateShippingOrderResponseKey = 'updateShippingOrderResponse';
export const ResumeFlowDataKey = 'ResumeFlowData';
export const TriageStatusKey = 'sTriageStatus';
export const LFLStockKey = 'LFLStock';
export const InWarrantyDataKey = "InWarrantyData"
export const claimDetailsResponseKey = "claimDetailsResponse";
export const BGTokenKey = "SecurityTokenResponse";
export const GetCitiesKey = "GetCitiesResponse";
export const createTradeKey = 'createTradeResponse';
export const CityListData = "CityListData";
export const VASCityListData = "VASCityListData";
export const cloudCheryyTokenKey = "cloudCheryyToken";
export const proxyUserDataKey = "proxyUserData";
export const generateLabelKey = 'generateLabeResponse';
export const redemptionOrderKey = 'redemptionOrderResponse';
export const DocumentUploadDataType = 'documentUploadDataType';
export const DocumentUploadData = 'documentUploadData';
export const CancelMySRKey = 'CancelMySRKey';