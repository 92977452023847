/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-console */
import { SubHeader, TextScript, getTextScript, useTextScript } from '@MGPD/myasurion-shared';
import { Box, Button, Card, CardBody, Center, Flex, Select, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import LogisticsKeys from '../../Scripts/translationsKeys.json';
import MainStackLayout from '../../layout/MainStackLayout';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { AddressLinePopupProps } from '../../types/types';
import { CLEANING_SERVICE_EDIT_FIELDS } from '../../utils/constant';
import { getAlternateMobileLength } from '../../utils/featuresUtills';
import { getFullAddress, getMDNFromAgreement } from '../../utils/utills';
import AddressLinePopup from '../myclaims/AddressLinePopup';
import CancelSrPopup from '../myclaims/CancelSrPopup';
import AlternateContactNumber from './AlternateContactNumber';

const CleaningLogistics = () => {
    const navigate = useNavigate();
    const globalState = useCentralStore();

    // const inputRef = useRef<HTMLInputElement>(null);

    const { isTranslationsLoading, language } = useTextScript();

    const [alternateContactNoPlaceholder, setAlternateContactNoPlaceholder] = useState('');
    const [address, setAddress] = useState('');
    const [showAlternateContactField, setShowAlternateContactField] = useState(false);

    const [openAddressPopup, setOpenAddressPopup] = useState<AddressLinePopupProps>();
    // const [date, setDate] = useState<string>('');
    const [timeSlot, setTimeSlot] = useState<string>('');
    const [day, setDay] = useState<string>('');
    // const [prefferedDateLbl, setPreferredDateLbl] = useState<string>('');
    const [prefferedDayLbl, setPreferredDayLbl] = useState<string>('');
    const [prefferedTimeLbl, setPreferredTimeLbl] = useState<string>('');
    const [openCancelPopup, setOpenCancelPopup] = useState(false);

    const agreementData: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

    let validLength = getAlternateMobileLength(agreementData?.ClientOffer?.ClientOfferName);

    const pageName = 'DeliveryDetails';

    // const futureDate = getDateAndDay(
    //   new Date(new Date().setDate(new Date().getDate() + 30)),
    //   'YYYY-MM-DD'
    // );
    // const pastDate = getDateAndDay(new Date().setDate(new Date().getDate() + 1), 'YYYY-MM-DD');

    // const preferredDay =  ['Weekday', 'Weekend', 'PH included'];
    const preferredDay = ['平日', '假日, 不包含國定假日', '假日, 含國定假日'];

    const timeslots =
        globalState?.state?.selectedDeviceCategory?.categoryName === 'Air conditioner'
            ? ['上午9點至中午12點間', '下午1點至下午6點間', '晚上6點至晚上9點間']
            : ['上午9點至中午12點間', '下午1點至下午6點間'];

    useEffect(() => {
        if (!isTranslationsLoading) {
            getAlternateContactNumberPlaceHolder();
            // getPrefferedDateLbl();
            getPrefferedDayLbl();
            getPrefferedTimeLbl();
        }
    }, [language, isTranslationsLoading]);

    useEffect(() => {
        if (globalState?.state?.pickUpAddress)
            setAddress(getFullAddress(globalState?.state?.pickUpAddress));
        else if (agreementData?.Address) setAddress(getFullAddress(agreementData?.Address));
    }, [globalState?.state?.pickUpAddress, agreementData]);

    useEffect(() => {
        const editField = globalState?.state?.cleaningLogisticsEditField;

        if (
            editField === CLEANING_SERVICE_EDIT_FIELDS.ADDRESS ||
            !globalState?.state?.pickUpAddress
        ) {
            // open address popup
            handleChageDeliveryAddressClick();
        } else if (editField === CLEANING_SERVICE_EDIT_FIELDS.ALTERNATE_CONTACT_NUMBER) {
            setShowAlternateContactField(true);
        } else if (editField === CLEANING_SERVICE_EDIT_FIELDS.DATE_TIME) {
            // setDate(globalState?.state?.cleaningLogistics?.date);
            setDay(globalState?.state?.cleaningLogistics?.day);
            setTimeSlot(globalState?.state?.cleaningLogistics?.timeSlot);
        }

        // preselect date time
        if (globalState?.state?.cleaningLogistics) {
            // setDate(globalState?.state?.cleaningLogistics?.date);
            setDay(globalState?.state?.cleaningLogistics?.day);
            setTimeSlot(globalState?.state?.cleaningLogistics?.timeSlot);
        }
    }, [globalState?.state?.cleaningLogisticsEditField]);

    const getDeviceName = () => {
        const selectedModel = globalState?.state?.selectedDeviceModel;

        return selectedModel?.DeviceCategoryCN + ' ' + selectedModel?.DeviceTypeModel;
    };

    const onConfirmOrNextClick = () => {
        const cleaningLogistics = {
            address: globalState?.state?.pickUpAddress || agreementData?.Address,
            mobile: getMDNFromAgreement(agreementData),
            alternateMobile: globalState?.state?.alternateContactNumber,
            // date: date,
            timeSlot: timeSlot,
            day: day,
        };

        // set cleaning logistics in state
        globalState?.dispatch({
            type: ActionTypes.SET_CLEANING_LOGISTICS,
            payload: cleaningLogistics,
        });

        // clear edit field
        globalState?.dispatch({
            type: ActionTypes.SET_EDIT_CLEANING_LOGISTICS_FIELD,
            payload: '',
        });

        navigate('/cleaningReview', { replace: true });
    };

    const onCancelClick = () => {
        setOpenCancelPopup(!openCancelPopup);
    };

    const onBackClick = () => {
        navigate('/cleaningTerms', { replace: true });
    };

    const getAlternateContactNumberPlaceHolder = async () => {
        const returnedTextError = await getTextScript(
            `${LogisticsKeys.Global.PageName}/alternateContactPlaceholder`,
            'Alternate Contact Number',
            language,
            []
        );
        setAlternateContactNoPlaceholder(returnedTextError);
    };

    // const getPrefferedDateLbl = async () => {
    //   const prefferedDateLbl = await getTextScript(
    //     `${LogisticsKeys.CleaningService.CleaningLogistics.PageName}/${LogisticsKeys.CleaningService.CleaningLogistics.PreferredDateLBL}`,
    //     Scripts.CleaningService.CleaningLogistics.PreferredDateLBL,
    //     language,
    //     []
    //   );

    //   setPreferredDateLbl(prefferedDateLbl);
    // };

    const getPrefferedDayLbl = async () => {
        const prefferedDayLbl = await getTextScript(
            `${LogisticsKeys.CleaningService.CleaningLogistics.PageName}/${LogisticsKeys.CleaningService.CleaningLogistics.PreferredDayLBL}`,
            Scripts.CleaningService.CleaningLogistics.PreferredDayLBL,
            language,
            []
        );

        setPreferredDayLbl(prefferedDayLbl);
    };

    const getPrefferedTimeLbl = async () => {
        const prefferedTimeLbl = await getTextScript(
            `${LogisticsKeys.CleaningService.CleaningLogistics.PageName}/${LogisticsKeys.CleaningService.CleaningLogistics.PreferredTimeLBL}`,
            Scripts.CleaningService.CleaningLogistics.PreferredTimeLBL,
            language,
            []
        );

        setPreferredTimeLbl(prefferedTimeLbl);
    };

    const handleChageDeliveryAddressClick = () => {
        setOpenAddressPopup({ open: true, addressFieldName: 'cleaning' });
    };

    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //   // setDate(getDateAndDay(e.target.value, 'MMMM D, YYYY'));

    //   setDate(e.target.value);

    //   // clear time slot if date changes
    //   setTimeSlot('');
    // };

    const handleSelectChange = (event: any, fieldName: string) => {
        const selectedValue = event.target.value;

        if (fieldName === 'timeSlot') setTimeSlot(selectedValue);
        else setDay(selectedValue);
    };

    // const showPicker = () => {
    //   inputRef?.current?.showPicker();
    // };

    const isButtonDisabled = (): boolean => {
        return (
            (!globalState?.state?.pickUpAddress &&
                (!agreementData?.Address || !agreementData?.Address?.Address1)) ||
            showAlternateContactField ||
            // !date?.length ||
            !day?.length ||
            !timeSlot?.length
        );
    };

    return (
        <Box paddingTop={'54px'} w="full">
            <MainStackLayout gap={0} spacing={0}>
                <Flex
                    w={{ base: 'full', lg: '80%' }}
                    margin="0 auto"
                    h={'100%'}
                    direction={'column'}
                >
                    <SubHeader
                        onClick={onBackClick}
                        backText={'Back'}
                        allowBackButtonClick={false}
                        showCancelButton={true}
                        showBackButton={false}
                        cancelText={Scripts.CancelSR.cancelBtnText}
                        onCancelClick={onCancelClick}
                    ></SubHeader>
                    <Box w={{ base: 'full', lg: '60%' }} mx={'auto'} px={4} mt={6}>
                        <Text fontSize={{ base: '2xl', lg: '3xl' }} mb={4}>
                            <TextScript
                                text={Scripts.CleaningService.CleaningLogistics.HeaderText}
                                translationKey={`${pageName}/${LogisticsKeys.CleaningService.CleaningLogistics.HeaderText}`}
                            />
                        </Text>

                        <Card borderWidth="1px" borderColor={'#A5AAAF'} variant={'outline'} pb={4}>
                            <CardBody bgColor={'#F0F0F5'} borderTopRadius={'base'} p={0}>
                                <Flex borderRadius={'full'} p={4}>
                                    <Center>
                                        <Text fontSize="xl">{getDeviceName()}</Text>
                                    </Center>
                                </Flex>
                            </CardBody>

                            <Box px={4}>
                                <Box mb={4}>
                                    <Flex justifyContent={'space-between'}>
                                        <Text my={2} fontWeight={'700'}>
                                            <TextScript
                                                text={'Address'}
                                                translationKey={`${pageName}/${LogisticsKeys.CleaningService.CleaningLogistics.Address}`}
                                            />
                                        </Text>

                                        <Flex>
                                            <Button
                                                colorScheme={'black'}
                                                // color={accent} //Link Color
                                                variant="link"
                                                fontWeight={700}
                                                as={'u'}
                                                w={{ base: 'full', lg: 'auto' }}
                                                cursor={'pointer'}
                                                onClick={handleChageDeliveryAddressClick}
                                            >
                                                <TextScript
                                                    text={'Change Adress'}
                                                    translationKey={`${pageName}/ChangeAdress`}
                                                />
                                            </Button>
                                        </Flex>
                                    </Flex>
                                    <Text>{address || '-'}</Text>
                                </Box>

                                <Box mb={4}>
                                    <Text mb={1} fontWeight={'700'}>
                                        <TextScript
                                            text={
                                                Scripts.CleaningService.CleaningLogistics
                                                    .RegisteredNumber
                                            }
                                            translationKey={`${pageName}/${LogisticsKeys.CleaningService.CleaningLogistics.RegisteredNumber}`}
                                        />
                                    </Text>
                                    <Text>{getMDNFromAgreement(agreementData)}</Text>
                                </Box>
                            </Box>

                            <AlternateContactNumber
                                validLength={validLength}
                                alternateContactNoPlaceholder={alternateContactNoPlaceholder || ''}
                                setShowAlternateContactField={setShowAlternateContactField}
                                showAlternateContactField={showAlternateContactField}
                            ></AlternateContactNumber>

                            {/* <Box px={4} mt={2} mb={4}>
                <Text as={'b'}>{prefferedDateLbl}</Text>
                <Box minHeight={'60px'} onClick={() => showPicker()} position={'relative'}>
                  <Input
                    size="md"
                    borderColor={'#A5AAAF'}
                    max={futureDate}
                    min={pastDate}
                    type="date"
                    ref={inputRef}
                    onChange={handleChange}
                    display={'hidden'}
                    position={'absolute'}
                    cursor={'pointer'}
                    style={{
                      opacity: 0,
                      overflow: 'hidden',
                      zIndex: 999,
                    }}
                  />
                  <InputGroup position={'absolute'}>
                    <InputRightElement
                      pointerEvents="none"
                      bottom={0}
                      right={{ base: '3%', lg: 0 }}
                    >
                      <CalendarIcon />
                    </InputRightElement>
                    <Input
                      size={'lg'}
                      borderColor={'#A5AAAF'}
                      onFocus={(e) => e.target.showPicker()}
                      placeholder={
                        prefferedDateLbl ||
                        Scripts.CleaningService.CleaningLogistics.PreferredDateLBL
                      }
                      type="text"
                      value={date?.length ? getDateAndDay(date, 'MMMM D, YYYY') : ''}
                      // position={'relative'}
                    />
                  </InputGroup>
                </Box>
              </Box> */}

                            <Box px={4} mt={2} mb={4}>
                                <Text as={'b'}>{prefferedDayLbl}</Text>

                                <Select
                                    placeholder={prefferedDayLbl}
                                    size="lg"
                                    h={'60px'}
                                    borderColor={'#A5AAAF'}
                                    value={day}
                                    onChange={(e) => handleSelectChange(e, 'day')}
                                >
                                    {Array.isArray(preferredDay) &&
                                        [...new Set(preferredDay)]?.map((val: any) => {
                                            return (
                                                <option key={val} value={val}>
                                                    {val}
                                                </option>
                                            );
                                        })}
                                </Select>
                            </Box>

                            <Box px={4}>
                                <Text as={'b'}>{prefferedTimeLbl}</Text>

                                <Select
                                    placeholder={prefferedTimeLbl}
                                    size="lg"
                                    h={'60px'}
                                    borderColor={'#A5AAAF'}
                                    value={timeSlot}
                                    onChange={(e) => handleSelectChange(e, 'timeSlot')}
                                >
                                    {Array.isArray(timeslots) &&
                                        [...new Set(timeslots)]?.map((val: any) => {
                                            return (
                                                <option key={val} value={val}>
                                                    {val}
                                                </option>
                                            );
                                        })}
                                </Select>
                            </Box>
                        </Card>
                    </Box>
                    <Box
                        width={{ base: 'full', lg: '30%' }}
                        marginX={'auto'}
                        marginTop={'auto'}
                        height={'100%'}
                        alignContent={'flex-end'}
                        pb={{ base: '25%', lg: '5%' }}
                        pt={'5%'}
                        px={4}
                    >
                        <Button
                            width={'full'}
                            textAlign={'center'}
                            color="white"
                            colorScheme="primary"
                            isDisabled={isButtonDisabled()}
                            onClick={() => {
                                onConfirmOrNextClick();
                                return;
                            }}
                        >
                            <TextScript
                                text={Scripts.Global.Confirm}
                                translationKey={`${LogisticsKeys.Global.PageName}/${LogisticsKeys.Global.Confirm}`}
                            />
                        </Button>
                    </Box>
                </Flex>
            </MainStackLayout>
            <CancelSrPopup
                closeCancelPopup={setOpenCancelPopup}
                openCancelPopup={openCancelPopup}
                isVAS={true}
            />
            {openAddressPopup?.open && (
                <AddressLinePopup
                    setAddressPopup={setOpenAddressPopup}
                    addressPopup={openAddressPopup}
                    srDeliveryType={''}
                    isPickup={true}
                    claimData={undefined}
                    isWalkIn={false}
                    callTCATAddressParseAPI={undefined}
                    setShippingMethodRes={undefined}
                    callshippingmethodapi={undefined}
                    ServiceRequestId={
                        globalState?.state?.cleaningIncidentData?.UpdateCleaningServiceRequest
                            ?.ServiceRequest?.ServiceRequestId
                    }
                    isVAS={true}
                />
            )}
        </Box>
    );
};

export default CleaningLogistics;
