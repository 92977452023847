import React, { useEffect, useState } from 'react';
import _ from '../utils/lodashUtils.ts';
import { Box, VStack, Text, Center, Flex, Button, Radio, Image, Progress, Input } from '@chakra-ui/react';
import JBDefaultPageLayout from './JBDefaultPageLayout';
import Scripts from '../Scripts/asurion-script.json';
import translationsKeys from '../Scripts/translationsKeys.json';
import { TextScript, useSessionStorage } from '@MGPD/myasurion-shared';
const UploadIdPageKeys = translationsKeys.UploadIdPage;
import FileUpload from '../assets/images/jb_fmip_instructions/01-File-Upload.svg';
// import FileUploaded from '../assets/images/jb_fmip_instructions/02-File-Uploaded.svg';

import { uploadToS3BucketBySignedURL } from '../services/api.ts';
import { getDocumentSide, getUploadIdDocumentList, saveError, showErrorPage, validateFileTypeToImageExtension } from '../utils/utills.tsx';
import { useCentralStore } from '../store/appContext.tsx';
import { useNavigate } from 'react-router-dom';
import { AxiosProgressEvent, } from 'axios';
import { dataURItoBlob, getBase64, getSignedRequestForUpload } from '../utils/upload-utils.tsx';
import { getMappedClientNameVal } from '../utils/helper.tsx';
import { DocumentUploadData, DocumentUploadDataType } from '../modules/config/constants.ts';
import { DocumentSideType, UploadIdDocumentType } from '../types/types.ts';

// const FileExtensionRegex: { [key: string]: RegExp } = {
//     FileExtension: /(?:\.([^.]+))?$/,
// };

const DocumentUploadSection = ({ side }: { side: DocumentSideType }) => {
    const navigate = useNavigate();
    const globalState = useCentralStore();
    const [, setFileUploadError] = useState<string>("");
    const [, setFileName] = useState<string>("");
    const [, setFileData] = useState<any>();
    const [progressBarValue, setProgressBarValue] = useState<number>(0);
    const agreementData: any = globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const interactionData = globalState?.state?.sessionResponse?.Interaction;

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files || files.length === 0) return;
        const currentFile = files[0];
        const fileSizeInKB = currentFile.size / 1024;

        if (!validateFileTypeToImageExtension(currentFile.name)) {
            setFileUploadError("Invalid file format.");
        } else if (fileSizeInKB >= 5000) {
            setFileUploadError("Please upload a file of max size 5MB.");
        } else {
            setFileUploadError("");
            // const match = FileExtensionRegex.FileExtension.exec(currentFile.name);
            // const extension = match ? match[1] : 'png';
            // const newFileName = `${side.id.toLowerCase().includes("front") ? "front_id" : "back_id"}.${extension}`;
            // setFileName(newFileName);
            setFileData(currentFile);
            uploadDocumentId(currentFile);
        }
    };

    const saveGeneratedFileName = (url: any, file: any) => {
        const fileName: any = url.split('?')?.[0]?.split('/')?.pop();
        const extension: any = file?.name?.split('.').pop();
        setFileName(`${fileName}.${extension}`);
    };

    const uploadDocumentId = async (fileToUpload: any) => {
        // setLoading(true);
        const signedUrl: any = await getSignedRequestForUpload(
            agreementData?.AgreementId,
            interactionData?.InteractionLine.InteractionLineId,
            'jb-upgrade-documents',
            getMappedClientNameVal(globalState?.state?.sessionResponse?.InitializeResponse?.ClientName?.toLowerCase()),
            globalState?.state?.findAgreementSessionData,
            true,
        ).catch((error) => {
            //   setLoading(false);
            saveError(error, globalState);
            showErrorPage(navigate);
            return;
        });

        const base64Obj = await getBase64(fileToUpload);
        saveGeneratedFileName(signedUrl, fileToUpload);

        const uploadedFile = await uploadToS3BucketBySignedURL(
            signedUrl,
            fileToUpload?.type,
            dataURItoBlob(base64Obj),
            (progressEvent: AxiosProgressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / (progressEvent?.total ?? 0));
                setProgressBarValue(progress);
            }).catch((error) => {
                saveError(error, globalState);
                showErrorPage(navigate);
                return;
            });
        console.log('uploadedFile', uploadedFile);
    }

    const isDeleteBtnDisabled = true;

    const handleDeleteClick = (e: any) => {
        e.preventDefault();
    }

    return (
        <VStack
            key={side.id}
            id={`documentUploadBoxes${side.id}`}
            width={side.custWidth ? side.custWidth : "45%%"}
            height="120px"
            padding="13px 20px"
            gap="10px"
            borderRadius="8px"
            border="1px dashed"
            bg="#FFFFFF"
            alignItems="baseline"
            justifyContent="space-around"
        >
            <Flex id="uploadcontaineraction-fileupload" justifyContent="space-between" alignItems="center" width="100%">
                <label>
                    <Image
                        src={FileUpload}
                        alt={"Upload"}
                        boxSize={{ base: 'auto', md: 'auto' }}
                        objectFit="cover"
                        m={"2px"}
                        cursor="pointer" // Change cursor to indicate clickable
                    />
                    <Input
                        type="file"
                        onChange={(e) => handleFileChange(e)} // Handle file change
                        hidden // Hide the file input
                        accept="image/png, image/heif, image/jpg, image/jpeg" // Accept only image files
                    />
                </label>
                <Text
                    fontFamily="Apercu Pro"
                    fontSize="16px"
                    fontWeight="700"
                    lineHeight="15.6px"
                    textAlign="right"
                    textDecoration="underline"
                    onClick={!isDeleteBtnDisabled ? handleDeleteClick : undefined} // Conditionally handle click based on isDisabled
                    style={{ cursor: isDeleteBtnDisabled ? 'default' : 'pointer' }} // Change cursor based on isDisabled
                >
                    <TextScript
                        text={Scripts.UploadIdPage.deleteBtn}
                        translationKey={`${UploadIdPageKeys.PageName}/${UploadIdPageKeys.DeleteBtn}`}
                    />
                </Text>
            </Flex>
            <Box id="uploadcontainerprogress" width="100%" bg="gray.200">
                <Progress
                    size='sm'
                    value={progressBarValue}
                    sx={{
                        '& > div': {
                            backgroundColor: '#FFEC0F',
                            borderRadius: '8px'
                        },
                    }}
                />
            </Box>
            <Box id="uploadcontainerlabel" textAlign="left">
                <Text
                    fontFamily="Apercu Pro"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="20.8px"
                    textAlign="left"
                >
                    <TextScript
                        text={side.uploadIdText}
                        translationKey={`${UploadIdPageKeys.PageName}/${side.uploadIdTextKey}`}
                    />
                </Text>
            </Box>
        </VStack>
    );
};

interface UploadIdRadioBtnProps {
    radioOption: UploadIdDocumentType;
    selectedDocument: string | null;
    handleUploadIdChange: (value: string) => void;
}

const UploadIdRadioBtn: React.FC<UploadIdRadioBtnProps> = ({
    radioOption,
    selectedDocument,
    handleUploadIdChange,
}) => {
    return (
        <Box
            id={`uploadIdRadioBtn${radioOption.id}`}
            display="flex"
            alignItems="center"
            onClick={() => {
                handleUploadIdChange(radioOption.id);
            }}
            mt='2px'
            w={"60%"}
        >
            <Radio
                value={radioOption.id}
                isChecked={selectedDocument === radioOption.id}
                onChange={(e: any) => {
                    // handleUploadIdChange(id);
                    e.preventDefault();
                    e.stopPropagation();
                }}
                size={'lg'}
                colorScheme="gray"
            />
            <Box>
                <Text ml="4" fontWeight="400">
                    <TextScript
                        text={radioOption.uploadIdText}
                        translationKey={`${UploadIdPageKeys.PageName}/${radioOption.uploadIdTextKey}`}
                    />
                </Text>
            </Box>
        </Box>
    );
};


const UploadIdPage: React.FC = () => {
    // Global State
    const navigate = useNavigate();
    const UploadIdDocumentList = getUploadIdDocumentList(Scripts, UploadIdPageKeys);
    const DocumentSide = getDocumentSide(Scripts, UploadIdPageKeys);
    const defaultDocumentId = UploadIdDocumentList.length > 0 ? UploadIdDocumentList[0].id : null;

    const [documentUploadDataType, setDocumentUploadDataType] = useSessionStorage<any>(DocumentUploadDataType, null);
    const [, setDocumentUploadData] = useSessionStorage<any>(DocumentUploadData, null);

    useEffect(() => {
        console.log("Document Upload Data Type", documentUploadDataType);
        setDocumentUploadDataType(defaultDocumentId);
        setDocumentUploadData({});
    }, []);

    // Handlers
    const handleUploadIdChange = (value: string) => {
        setDocumentUploadDataType(value)
    };

    // PreSign Handling 

    // Memo 
    const filteredDocumentSides = React.useMemo(() => {
        const selectedDoc = UploadIdDocumentList.find(doc => doc.id === documentUploadDataType);
        if (!selectedDoc) return [];
        return DocumentSide.filter(side => selectedDoc.documentSide.includes(side.id));
    }, [documentUploadDataType]);

    return (
        <JBDefaultPageLayout
            showDeviceCard={false}
            showCancelButton={false}
            showNextButton={false}
        >
            <Center>
                <Flex
                    w={{ base: '90%', md: '50%' }} m="0"
                    direction="column"
                    wrap="nowrap"
                    justifyContent="center"
                    alignContent="center"
                    id={'myasurion-uploaded-id-details-page-flex-1'}
                    h={'auto'}
                    gap={"15px"}
                >
                    <Box
                        className={'myasurion-uploaded-id-content-flex-1'}
                        w={{ base: 'full', lg: '100%' }}
                        margin={{ base: '0', lg: '0' }}
                    >
                        <VStack spacing="2" gap="5px">
                            <Text
                                fontFamily="Apercu Pro"
                                fontSize="32px"
                                fontStyle="normal"
                                fontWeight="400"
                                lineHeight="130%"
                                textAlign="center"
                            >
                                <TextScript
                                    text={Scripts.UploadIdPage.HeaderText}
                                    translationKey={`${UploadIdPageKeys.PageName}/${UploadIdPageKeys.HeaderText}`}
                                />
                            </Text>
                            <Text
                                fontFamily="Apercu Pro"
                                fontSize="20px"
                                fontStyle="normal"
                                fontWeight="400"
                            >
                                <TextScript
                                    text={Scripts.UploadIdPage.subHeaderContent}
                                    translationKey={`${UploadIdPageKeys.PageName}/${UploadIdPageKeys.SubHeaderContent}`}
                                />
                            </Text>
                        </VStack>
                    </Box>
                    <Box>
                        <Flex direction={{ base: "column", lg: "column" }} gap="5px" alignItems="center" alignContent="center">
                            {UploadIdDocumentList.map((radioOption: UploadIdDocumentType) => (
                                <UploadIdRadioBtn
                                    key={`radio-option-${radioOption.id}-${_.uniqueId()}`}
                                    radioOption={radioOption}
                                    selectedDocument={documentUploadDataType}
                                    handleUploadIdChange={handleUploadIdChange}
                                />
                            ))}
                        </Flex>
                    </Box>
                    <Box
                        className="uploadDocumentContainer"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="space-between"
                        w="100%"
                        bg="#F0F0F5"
                        p={5}
                        gap={2}
                    >
                        <Box width="100%">
                            <Text
                                fontFamily="Apercu Pro"
                                fontSize="24px"
                                fontStyle="normal"
                                fontWeight="700"
                                textAlign="center"
                            >
                                <TextScript
                                    text={Scripts.UploadIdPage.uploadIdContainerHeader}
                                    translationKey={`${UploadIdPageKeys.PageName}/${UploadIdPageKeys.UploadIdContainerHeader}`}
                                />
                            </Text>
                        </Box>
                        <Flex width="100%" justifyContent="space-evenly" alignItems="center">
                            {filteredDocumentSides.map((side) => (
                                <DocumentUploadSection
                                    key={`document-upload-section-option-${side.id}-${_.uniqueId()}`}
                                    side={side}
                                // handleDocumentUpload={handleDocumentUpload}
                                />
                            ))}
                        </Flex>
                    </Box>
                    <Flex id="uploadidpage-snackbar" justifyContent="center" direction={{ base: "column", lg: "row" }} w="100%" mb="15px">
                        <Button
                            id={'myasurion-jb-dpl-bt-1'}
                            py={3}
                            px={5}
                            colorScheme="primary"
                            color="white"
                            w={{ base: 'full', lg: 'full' }}
                            textTransform={
                                'none'
                            }
                            onClick={() => {
                                navigate('../verifyUploadId', { replace: true });
                            }}
                            isDisabled={_.isEmpty(documentUploadDataType)}
                        // isDisabled={_.isEmpty(documentUploadDataType) && _.isEmpty(documentUploadData)}
                        >
                            <TextScript
                                text={Scripts.UploadIdPage.submitLabel}
                                translationKey={`${UploadIdPageKeys.PageName}/${UploadIdPageKeys.SubmitLabel}`}
                            />
                        </Button>
                    </Flex>
                </Flex>
            </Center>
        </JBDefaultPageLayout>
    );
};

export default UploadIdPage;