import { Box, BoxProps, Flex, Text } from '@chakra-ui/layout';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import React, { ChangeEvent } from 'react';
import Select from 'react-select';

import { DeviceSpecsFieldConfig } from '../../types/types';
import TextInput from '../component/TextInput';
import { useCentralStore } from '../../../ClaimComponents/store/appContext';

export interface DeviceSpecsProps {
    fields: DeviceSpecsFieldConfig[];
    handleInputChange: (fieldName: DeviceSpecsFieldConfig, value: string) => void;
    handleSelectChange?: (e: ChangeEvent<HTMLSelectElement>, field: DeviceSpecsFieldConfig) => void;
    //Others: boolean,
    OtherBrand: boolean;
    OtherModel: boolean;
}
const customStyles = {
    control: (provided: any) => ({
        ...provided,
        height: '56px',
        borderColor: '#A5AAAF',
        marginBottom: '16px',
    }),
};

export const DeviceSpecs: React.FC<DeviceSpecsProps & BoxProps> = ({
    fields,
    handleInputChange,
    handleSelectChange,
    OtherBrand,
    OtherModel,
}) => {
    const globalState = useCentralStore()
    const isBYOD = globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement?.[0]?.isBYODCustomer
    return (
        <>
            <Box mt={4} fontFamily={'Apercu Pro'}>
                <Flex
                    direction={{ base: 'column', lg: 'row' }}
                    wrap="wrap"
                    justifyContent={{ base: 'start', lg: 'space-between' }}
                >
                    {fields?.map((field) => (
                        <>
                            {field?.type === 'Input' && (
                                <>
                                    <FormControl
                                        key={field.name}
                                        isRequired={field.isRequired}
                                        isInvalid={field.isInvalid}
                                        w={{ base: 'full', lg: '48%' }}
                                        mb={4}
                                    >
                                        {field.label && (
                                            <Text fontWeight={'bold'} pb={'4px'}>
                                                {field.label}
                                            </Text>
                                        )}
                                        <TextInput
                                            cursor={field?.defaultDisable ? 'not-allowed' : ''}
                                            type="text"
                                            showIcon={false}
                                            borderColor={'#A5AAAF'}
                                            minH={'56px'}
                                            placeholder={field.placeholder}
                                            value={field.value}
                                            maxLength={40}
                                            onChange={(e: any) => {
                                                handleInputChange(field, e.value);
                                            }}
                                            isDisabled={field?.defaultDisable}
                                        />
                                        {field.isInvalid && (
                                            <FormErrorMessage>
                                                {field.errorMessage}
                                            </FormErrorMessage>
                                        )}
                                    </FormControl>
                                </>
                            )}
                            {field?.type === 'Select' && (
                                <Flex
                                    w={{ base: 'full', lg: '48%' }}
                                    display={'table-column'}
                                    alignContent={field?.Other === 'Others' ? 'end' : 'start'}
                                >
                                    {((field.name === 'Brand' && OtherBrand) ||
                                        (field.name === 'Model' && OtherModel) ||
                                        (field.name === 'Family' && OtherModel) ||
                                        field?.Other !== 'Others' ||
                                        field.showSelect) && (
                                        <>
                                            {field.label && (
                                                <Text fontWeight={'bold'} pb={'4px'}>
                                                    {field.label}
                                                </Text>
                                            )}
                                            <Select
                                                placeholder={field.placeholder}
                                                styles={customStyles}
                                                isSearchable={true}
                                                defaultValue={field.value}
                                                options={field?.data?.map((val: any) => {
                                                    return { label: !isBYOD && (field.name === 'Model' || field.name === 'Family') ? val?.label: val, value: !isBYOD && (field.name === 'Model' || field.name === 'Family') ? val?.value : val };
                                                })}
                                                onChange={(e: any) =>
                                                    handleSelectChange &&
                                                    handleSelectChange(e, field)
                                                }
                                            />
                                        </>
                                    )}
                                    {field?.type === 'Select' && field?.Other === 'Others' && (
                                        <FormControl
                                            key={field.name}
                                            isRequired={field.isRequired}
                                            isInvalid={field.isInvalid}
                                            mb={4}
                                        >
                                            {field.label && (
                                                <Text fontWeight={'bold'} pb={'4px'}>
                                                    {`${field.label}`}
                                                </Text>
                                            )}
                                            <TextInput
                                                cursor={field?.defaultDisable ? 'not-allowed' : ''}
                                                type="text"
                                                //showIcon={false}
                                                borderColor={'#A5AAAF'}
                                                placeholder={field.placeholder}
                                                //ExamplePlaceholder={field.OtherPlaceholder}
                                                //placeholder={!focus && (field?.value === '' || field?.value === undefined) ? field.OtherPlaceholder : field.placeholder}
                                                value={field.value}
                                                maxLength={40}
                                                onChange={(e: any) => {
                                                    handleInputChange(field, e.value);
                                                }}
                                                isDisabled={field?.defaultDisable}
                                            />
                                        </FormControl>
                                    )}
                                </Flex>
                            )}
                        </>
                    ))}
                </Flex>
            </Box>
        </>
    );
};

export default DeviceSpecs;
