import { AccountInfo, useAccount, useSessionStorage } from '@MGPD/myasurion-shared/hooks';
import { useFeature } from '@MGPD/myasurion-shared/modules/features';
import { Box, Flex } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PropsWithChildren, useEffect, useState } from 'react';

import DeviceSelector from '../components/DeviceSelector';
import MyDocumentsSection from '../components/mydocuments/MyDocumentsSection';
import { loadingMessages, loadingWaitingMessages } from '../modules/config/constants';
import { findAgreementByMdn, getDevices } from '../services/api';
import { GetDevicesAPIReturnType, iMDNDevices } from '../types/responses';
import { AgreementClaimStatus, MdnDevice } from '../types/types';

const DocumentsPage: React.FC<PropsWithChildren> = ({ children, ...props }) => {
    const [account] = useAccount<AccountInfo>({ profileId: '', userId: '' });
    const [sessionData] = useSessionStorage<any>('data', '');

    const [devicesList, setDevicesList] = useState<MdnDevice[]>([]);
    const [selectedMdn, setSelectedMdn] = useState<MdnDevice>();
    const [srNumber, setSRNumber] = useState<string>();
    const [agreementClaimStatus, setAgreementClaimStatus] = useState<AgreementClaimStatus>();

    const [pageLoading, setPageLoading] = useState<boolean>(true);

    const [, setLoadingMessage] = useState(
        loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
    );

    const queryClient = useQueryClient();

    // NOTE: Check api versions
    const {
        isFeatureOn: isGetDevicesOn,
        featureVersion: getDevicesVersion,
        isFeatureInitialized,
    } = useFeature('getDevices');

    const { refetch: fetchGetDevicesData } = useQuery(
        ['getDevicesData'],
        async () => {
            return await getDevices(
                getDevicesVersion ? (`v${getDevicesVersion}` as 'v1' | 'v2') : 'v1'
            );
        },
        {
            retry: 5,
            retryDelay: (failureCount) => {
                if (failureCount === 3) {
                    setLoadingMessage(
                        loadingWaitingMessages[
                            Math.floor(Math.random() * loadingWaitingMessages.length)
                        ]
                    );
                }
                return Math.min(1000 * 2 ** failureCount, 30000);
            },
            onSuccess: (data: GetDevicesAPIReturnType<'v1' | 'v2'>) => {
                // Handle Different Versions of GetDevices
                // V1
                if (!isGetDevicesOn || getDevicesVersion === 1) {
                    queryClient.setQueryData(['getDevicesData'], data.body);
                    const devicesData = (data as GetDevicesAPIReturnType<'v1'>)?.body;
                    setDevicesList(devicesData);
                    setSelectedMdn(devicesData[0]);
                }

                // V2
                if (isGetDevicesOn && getDevicesVersion === 2) {
                    const devicesData: iMDNDevices[] = (data as GetDevicesAPIReturnType<'v2'>)
                        ?.body;
                    const mappedDeviceList = [];
                    for (let mdnIndex = 0; mdnIndex < devicesData?.length; mdnIndex++) {
                        const loop_mdn = devicesData[mdnIndex];
                        for (
                            let deviceIndex = 0;
                            deviceIndex < loop_mdn?.devices?.length;
                            deviceIndex++
                        ) {
                            const loop_device = loop_mdn?.devices?.[deviceIndex];
                            mappedDeviceList.push({ mdn: loop_mdn.mdn, ...loop_device });
                        }
                    }
                    queryClient.setQueryData(['getDevicesData'], mappedDeviceList);
                    setDevicesList(mappedDeviceList);
                    setSelectedMdn(mappedDeviceList[0]);
                }
            },
            onError: (error) => {
                console.error(error);
            },
            enabled: false,
        }
    );

    const { isLoading: isFetchingAgreementByMdn, mutate: fetchAgreementByMdn } = useMutation({
        mutationFn: findAgreementByMdn,
        retry: 5,
        retryDelay: (failureCount) => {
            if (failureCount === 3) {
                setLoadingMessage(
                    loadingWaitingMessages[
                        Math.floor(Math.random() * loadingWaitingMessages.length)
                    ]
                );
            }
            return Math.min(1000 * 2 ** failureCount, 30000);
        },
        onSuccess: (data) => {
            setSRNumber(data?.customerCaseNumber);
            if (data?.claimStatus) {
                setAgreementClaimStatus(data.claimStatus?.toLowerCase()?.split(/\s/g)?.join(''));
            }
        },
        onError: (error) => {
            console.error('Findagreement failed', (error as { message: string })?.message);
            setPageLoading(true);
        },
    });

    const handleSelectedDevice = (device: any) => {
        setSelectedMdn(device);
        if (selectedMdn?.mdn !== device?.mdn || selectedMdn?.imei !== device?.imei) {
            fetchAgreementByMdn({
                mdn: device?.mdn || '',
                email: account?.email ?? '',
                data: sessionData,
                imei: device?.imei || '',
                idx: device?.index || 0,
                isDeviceData: true,
            });
        }
    };

    useEffect(() => {
        if (devicesList[0]) {
            setSelectedMdn(devicesList[0]);
            fetchAgreementByMdn({
                mdn: devicesList[0]?.mdn || '',
                email: account?.email ?? '',
                data: sessionData,
                imei: devicesList[0]?.imei || '',
                idx: devicesList[0]?.index || 0,
                isDeviceData: true,
            });
            setPageLoading(false);
        }
    }, [devicesList]);

    useEffect(() => {
        if (isFeatureInitialized) {
            fetchGetDevicesData();
        }
    }, [isFeatureInitialized]);

    return (
        <Box h={'full'} id={'myasurion-portal-documents'} {...props} w={'full'}>
            <Flex
                id="myasurion-tom-documents-container"
                direction="column"
                marginRight="auto"
                marginLeft="auto"
                paddingTop="100px"
                width={{ md: '885px' }}
            >
                <DeviceSelector
                    selectedValue={selectedMdn}
                    setSelectedValue={handleSelectedDevice}
                    deviceList={devicesList}
                    viewAllDeviceText="View all plans"
                    isLoading={pageLoading || isFetchingAgreementByMdn}
                    isLoadingSmartScanToken={false}
                    isAgreementProcessing={
                        (selectedMdn?.type?.toLowerCase() === 'byod' &&
                            selectedMdn?.status !== 'approved') ||
                        (selectedMdn?.type?.toLowerCase() === 'ppe' &&
                            selectedMdn.status !== 'approved')
                    }
                    hasUpgrade={false}
                    activeSRNumber={srNumber}
                    agreementClaimStatus={agreementClaimStatus}
                    isDocuments={true}
                />
                <Flex my={5}>
                    {selectedMdn && <MyDocumentsSection deviceDetails={selectedMdn} />}
                </Flex>
            </Flex>
        </Box>
    );
};

export default DocumentsPage;
