import { TextScript, useConfig } from '@MGPD/myasurion-shared';
import { Box, Button, Center, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import CNScripts from '../../Scripts/asurion-script-chinese.json';
import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import MainStackLayout from '../../layout/MainStackLayout';
import { isChineseDefaultLanguage } from '../../utils/utills';

function ErrorPage() {
    const ErrorPage = translationsKeys.ErrorPage;
    const navigate = useNavigate();
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const isChinese = isChineseDefaultLanguage();

    const onRetryClick = () => {
        navigate('/updateAnchorDevice', { replace: true });
    };

    return (
        <Center>
            <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'}>
                <MainStackLayout gap={0} spacing={0}>
                    <Box
                        id={'myasurion-profile-avatar'}
                        mt={4}
                        w={'full'}
                        h={'auto'}
                        p={'16px 16px 16px 16px'}
                    >
                        <Text fontSize={'32px'}>
                            <TextScript
                                text={
                                    isChinese
                                        ? CNScripts?.ErrorPage.HeaderText
                                        : Scripts.ErrorPage.HeaderText
                                }
                                translationKey={`${ErrorPage.PageName}/${ErrorPage.HeaderText}`}
                            />
                        </Text>

                        <Box display="flex" justifyContent="center" mt={'1.5rem'}>
                            <Button
                                onClick={onRetryClick}
                                mt={'5'}
                                width={'50%'}
                                textAlign={'center'}
                                colorScheme="primary"
                                bg={colors.primary}
                                cursor={'pointer'}
                            >
                                <TextScript
                                    text={isChinese ? CNScripts.Global.Retry : Scripts.Global.Retry}
                                    translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Retry}`}
                                />
                            </Button>
                        </Box>
                    </Box>
                </MainStackLayout>
            </Box>
        </Center>
    );
}

export default ErrorPage;
