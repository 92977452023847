import { SubHeader, TextScript, getTextScript, useSessionStorage } from '@MGPD/myasurion-shared';
import { Box, Button, Checkbox, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import MainStackLayout from '../../layout/MainStackLayout';
import { getInventoryData } from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { getProgrammeName } from '../../utils/localization-helper';
import { saveError, showErrorPage } from '../../utils/utills';
import CancelSrPopup from './../myclaims/CancelSrPopup';

function ThingsToNote() {
    const globalState = useCentralStore();
    const ThingsToNoteKeys = translationsKeys.ThingsToNote;

    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [laptopNotes, setLaptopNotes] = useState('');
    const [tabletNotes, setTabletNotes] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [programmeNameLBL, setProgrammeNameLBL] = useState('');
    const [, setInventoryResponse] = useState<any>();
    const [gadgetCategoryName] = useSessionStorage<string>('gadgetCategoryName', '');
    const agreementData: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const selectedCategory =
        gadgetCategoryName?.toLowerCase() ||
        globalState?.state?.selectedCategory?.selectedDeviceCategory[0]?.categoryName?.toLowerCase();
    const clientOfferId = agreementData?.ClientOffer?.ClientOfferId;
    const interactionData = globalState?.state?.sessionResponse?.Interaction;

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    const navigate = useNavigate();

    const getLaptopNotes = async () => {
        const returnedText = await getTextScript(
            `${ThingsToNoteKeys.PageName}/${ThingsToNoteKeys.LaptopNotes}`,
            Scripts.ThingsToNote.LaptopNotes,
            'en-US',
            [programmeNameLBL]
        );
        setLaptopNotes(returnedText);
    };

    const getTabletNotes = async () => {
        const returnedText = await getTextScript(
            `${ThingsToNoteKeys.PageName}/${ThingsToNoteKeys.TabletNotes}`,
            Scripts.ThingsToNote.TabletNotes,
            'en-US',
            [programmeNameLBL]
        );
        setTabletNotes(returnedText);
    };

    useEffect(() => {
        if (agreementData?.ClientOffer?.ClientOfferName) {
            getProgrammeNameLbl();
        }
    }, [agreementData?.ClientOffer?.ClientOfferName]);

    useEffect(() => {
        if (programmeNameLBL && programmeNameLBL?.length > 0) {
            getLaptopNotes();
            getTabletNotes();
        }
    }, [programmeNameLBL]);

    const getProgrammeNameLbl = async () => {
        const programmeName = await getProgrammeName(agreementData?.ClientOffer?.ClientOfferName);
        setProgrammeNameLBL(programmeName);
    };

    const callInventoryDataApi = () => {
        setLoading(true);
        getInventoryData(clientOfferId, interactionData)
            .then((data: any) => {
                globalState?.dispatch({
                    type: ActionTypes.SET_REGISTER_DEVICE_RESPONSE,
                    payload: data,
                });
                setLoading(false);
                setInventoryResponse(data);
                navigate('/deviceDetailsNew', { replace: true });
            })
            .catch((error: any) => {
                saveError(error, globalState);
                showErrorPage(navigate);
                setLoading(false);
            });
    };

    const onSubmit = () => {
        callInventoryDataApi();
    };

    const onCancelClick = () => {
        setOpenCancelPopup(!openCancelPopup);
    };

    const onBackClick = () => {
        window.history.back();
        // navigate('/deviceCategoryList', { replace: true });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
    };

    return (
        <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'} w="full">
            <MainStackLayout gap={0} spacing={0}>
                <Box
                    w={{ base: 'full', lg: '80%' }}
                    marginX={{ base: '', lg: 'auto' }}
                    mb={{ base: '0', lg: '20px' }}
                >
                    <SubHeader
                        onClick={onBackClick}
                        backText={'Back'}
                        allowBackButtonClick={false}
                        showCancelButton={false}
                        showBackButton={true}
                        cancelText={Scripts.CancelSR.cancelBtnText}
                        onCancelClick={onCancelClick}
                    ></SubHeader>
                </Box>
                {selectedCategory && (
                    <Box
                        id={'myasurion-profile-avatar'}
                        w={'full'}
                        h={'auto'}
                        p={'16px 16px'}
                        maxWidth={{ base: 'full', lg: '60%' }}
                        marginX={{ base: '13px', lg: 'auto' }}
                    >
                        <Box
                            className={''}
                            w={{ base: 'full', lg: '50%' }}
                            margin={{ base: '0', lg: 'auto' }}
                            pb={{ base: '0', lg: '0' }}
                        >
                            <Text mb={'8px'} fontSize={'24px'} fontWeight={'400'}>
                                <TextScript
                                    text={Scripts.ThingsToNote.HeaderText}
                                    translationKey={`${ThingsToNoteKeys.PageName}/${ThingsToNoteKeys.HeaderText}`}
                                />
                            </Text>
                            <Text mb={'4px'} fontSize={'16px'} fontWeight={'400'}>
                                <TextScript
                                    text={Scripts.ThingsToNote.SubText}
                                    translationKey={`${ThingsToNoteKeys.PageName}/${ThingsToNoteKeys.SubText}`}
                                    dynamicValues={[selectedCategory]}
                                />
                            </Text>
                            <Box marginX={'16px'}>
                                {selectedCategory === 'laptop'
                                    ? laptopNotes && (
                                          <div dangerouslySetInnerHTML={{ __html: laptopNotes }} />
                                      )
                                    : tabletNotes && (
                                          <div dangerouslySetInnerHTML={{ __html: tabletNotes }} />
                                      )}
                            </Box>
                        </Box>
                        <Box
                            position={'fixed'}
                            bottom="0"
                            w={'100%'}
                            bg={'#fff'}
                            style={{
                                boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.08)',
                                transform: 'translate(-50%, 0%)',
                            }}
                            left={'50%'}
                        >
                            <Flex
                                pb={{ base: '0', lg: '20px' }}
                                mb={'auto'}
                                w={{ base: 'full', lg: '50%' }}
                                // marginLeft={'auto'}
                                // marginRight={'auto'}
                                marginX={{ base: 'auto', lg: 'auto' }}
                                justifyContent={'center'}
                            >
                                <Checkbox
                                    marginBottom={'16px'}
                                    marginTop={'16px'}
                                    alignItems={'flex-start'}
                                    size="lg"
                                    onChange={handleCheckboxChange}
                                    colorScheme="primary"
                                    border={'#A5AAAF'}
                                ></Checkbox>
                                <Text
                                    marginBottom={'16px'}
                                    marginTop={'16px'}
                                    marginLeft={'8px'}
                                    size={'16px'}
                                >
                                    <TextScript
                                        text={Scripts?.ThingsToNote?.AcceptText}
                                        translationKey={`${ThingsToNoteKeys.PageName}/${ThingsToNoteKeys}`}
                                        dynamicValues={[selectedCategory]}
                                    />
                                </Text>
                            </Flex>
                            <Box
                                display="flex"
                                justifyContent="center"
                                marginX={'16px'}
                                marginBottom={'16px'}
                            >
                                <Button
                                    isDisabled={!isChecked}
                                    color="white"
                                    colorScheme="primary"
                                    onClick={onSubmit}
                                    width={{ base: 'full', lg: '200px' }}
                                >
                                    <TextScript
                                        text={'Next'}
                                        translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Next}`}
                                    />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                )}
            </MainStackLayout>
            <CancelSrPopup
                closeCancelPopup={setOpenCancelPopup}
                openCancelPopup={openCancelPopup}
            />
        </Box>
    );
}

export default ThingsToNote;
