import { getCarrierByDomain } from '@MGPD/myasurion-shared';
import { TextScript, TextString } from '@MGPD/myasurion-shared/components/localization';
import { useSessionStorage } from '@MGPD/myasurion-shared/hooks';
import { useConfig } from '@MGPD/myasurion-shared/modules/config';
import { useFeature } from '@MGPD/myasurion-shared/modules/features';
import { AddIcon } from '@chakra-ui/icons';
import {
    Box,
    BoxProps,
    Button,
    Flex,
    HStack,
    Image,
    Link,
    Skeleton,
    Square,
    Stack,
    Text,
    VStack,
    useBreakpointValue,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { Fragment, ReactNode } from 'react';

import { programsMap, smartScanTokenUrlKey } from '../modules/config/constants';
import { formatMdnString } from '../utils';
import { useNavigate } from 'react-router';
import { checkIsApplLoadDisabled } from '../utils/config';
import { getDeviceImageByModel } from '../ClaimComponents/utils/get-device-images';
import { useCentralStore } from '../ClaimComponents/store/appContext';

type DeviceStatus = {
    id: string;
    text: string;
    background: string;
    color: string;
};

const statusMap: Record<string, DeviceStatus> = {
    pending: {
        id: 'pending',
        text: 'Pending Action',
        background: '#F9D51C',
        color: 'black',
    },
    'under review': {
        id: 'under review',
        text: 'Under Review',
        background: '#D2FA46',
        // background: '#6E767D',
        color: 'black',
        // color: 'white',
    },
    approved: {
        id: 'approved',
        text: 'Approved',
        background: '#37E7A7',
        color: 'black',
    },
    processing: {
        id: 'processing',
        text: 'Processing',
        background: '#D2FA46',
        color: 'black',
    },
    // Claim Status
    draft: {
        id: 'draft',
        text: 'Draft',
        background: '#E6E6EB',
        color: 'black',
    },
    submitted: {
        id: 'submitted',
        text: 'Submitted',
        background: '#E6E6EB',
        color: 'black',
    },
    completed: {
        id: 'completed',
        text: 'Completed',
        background: '#37E7A7',
        color: 'black',
    },
    cancelled: {
        id: 'cancelled',
        text: 'Cancelled',
        background: '#FF6666',
        color: 'black',
    },
    suspended: {
        id: 'suspended',
        text: 'Suspended',
        background: '#FF6666',
        color: 'black',
    },
    backorder: {
        id: 'backorder',
        text: 'Back Order',
        background: '#E6E6EB',
        color: 'black',
    },
};

export interface DeviceCardProps {
    [index: string]: number | ReactNode | undefined | boolean;
    /**
     * MDN Plan
     * @required
     */
    plan: string;
    /**
     * MDN
     * @required
     */

    mdn: string;
    /**
     * Device model
     * @required
     */
    model: string;
    /**
     * IMEI
     * @required
     */
    imei: string;
    assetId: string;
    imageEl?: ReactNode | undefined;
    showMoreDetails?: boolean;
    detailsEl?: ReactNode | undefined;
    isLoading?: boolean;
    status?:
        | 'pending'
        | 'under review'
        | 'approved'
        | 'processing'
        | 'draft'
        | 'completed'
        | 'cancelled'
        | 'OEM_WARRANTY'
        | 'EWS'
        | 'EWS_END'
        | 'backorder'
        | 'submitted'
        | 'suspended'; // Agreement Status

    type: 'ppe' | 'byod' | 'default';
    viewAllDeviceText?: string;
    hasUpgrade?: boolean;
    activeSRNumber?: string;
    use?: 'planOverview' | 'landing';
    // EWS dependencies
    make?: string;
    color?: string;
    serial?: string;
    warranty?: string;
    redirectUrl?: string;
    isDocuments?: boolean;
    isAgreementSuspended?: boolean;
    programName?: string;
    oemStartDate?: string;
    oemEndDate?: string;
    extendedWarrantyStartDate?: string;
    extendedWarrantyEndDate?: string;
    isPremiumUser?: boolean;
    deviceCount: number;
    accountStatus?: 'ACTV' | 'SUSPND' | 'TRMTD' | 'TRMNTD';
    warrantyStatus?: string;
    deviceRegistered?: boolean;
    isSecondDeviceRegistered?: boolean;
    devicecap?: string | number;
    hideAddButton?: boolean;
    agreementData?: any;
    isCleaningService?: boolean;
    deviceType?: string;
    cleaningStatus?: string;
    category?: string;
    schedule?: string;
    cleaningServiceRequest?: any;
}

const BYODCard = ({ url }: { url: string }) => {
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    return (
        <VStack h="140px" alignItems="center" justifyContent="center">
            <Text fontSize="24px" fontWeight="light">
                No device added yet
            </Text>
            <Button
                variant="outline"
                leftIcon={<AddIcon />}
                color={colors.primary}
                borderColor={colors.primary}
                size="md"
                onClick={() => (window.location.href = url)}
            >
                Add a device
            </Button>
        </VStack>
    );
};

export const DeviceCardImageElement = ({ imageUrl }: { imageUrl?: any }) => {
    const globalState = useCentralStore()
    if (!imageUrl) {
        return null;
    }
    
    return (
        <>
            {checkIsApplLoadDisabled(getCarrierByDomain()) ?
                // <img
                //     style={{ width: '80px', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'contain', flexGrow: 1 }}
                //     src={`${import.meta.env.VITE_DEVICE_IMAGE_BASE_URL}${imageUrl}.png`}
                //     onError={(e: any) => e.target.src = "https://images.ctfassets.net/rcqun2fh2hqb/X3kf96P1E3FF53eIGvPXd/721632fa03462af92cc25a70494f240c/no-device-image.png"}
                // />
                <>
                {imageUrl?.assetFamily ?
                <Image
                    height={'80px'}
                    marginTop={'22px'}
                    alignSelf={'center'}
                    src={getDeviceImageByModel(
                    globalState?.state?.deviceImages,
                    `${imageUrl?.assetFamily}`,
                    imageUrl?.color,
                    80
                    )}
                ></Image>
                :
                <Box
                    h="full"
                    minH="80px"
                    minW="80px"
                    bgImage={`url(${imageUrl})`}
                    bgRepeat="no-repeat"
                    bgPosition="center center"
                    bgSize="contain"
                    flexGrow={1}
                />
                }
                </>
                :
                <Box
                    h="full"
                    minH="80px"
                    minW="80px"
                    bgImage={`url(${imageUrl})`}
                    bgRepeat="no-repeat"
                    bgPosition="center center"
                    bgSize="contain"
                    flexGrow={1}
                />
            }
        </>

    );
};

const agreementStatusMap = (agreementStatus?: 'ACTV' | 'SUSPND' | 'TRMNTD' | 'TRMTD') => {
    type StatusTypes = {
        [key: string]: string;
    };

    const statusMap: StatusTypes = {
        None: '--',
        ACTV: 'Active',
        SUSPND: 'Suspended',
        TRMNTD: 'InActive',
        TRMTD: 'InActive',
    };
    return statusMap?.[agreementStatus ?? 'None'] ?? '--';
};

export const cleaningStatusMapping = (
    serviceRequestStatus: 'WORKING' | 'CMPLTD' | 'CANCEL' | 'CNCLCUS',
    repairRequestStatus:
        | 'PENDING SCHEDULE CONFIRMATION'
        | 'SCHEDULE CONFIRMED'
        | 'COMPLETED'
        | 'CANCELLED'
        | null
) => {
    if (repairRequestStatus === null && serviceRequestStatus === 'WORKING') {
        return 'Draft';
    }
    if (
        repairRequestStatus === 'PENDING SCHEDULE CONFIRMATION' &&
        serviceRequestStatus === 'WORKING'
    ) {
        return 'Submitted';
    }

    if (repairRequestStatus === 'SCHEDULE CONFIRMED' && serviceRequestStatus === 'WORKING') {
        return 'Submitted';
    }

    if (repairRequestStatus === 'COMPLETED' && serviceRequestStatus === 'CMPLTD') {
        return 'Completed';
    }

    if (repairRequestStatus === 'CANCELLED' && serviceRequestStatus === 'CANCEL') {
        return 'Cancelled';
    }

    if (repairRequestStatus === null && serviceRequestStatus === 'CNCLCUS') {
        return 'Cancelled';
    }

    return serviceRequestStatus;
};

const isTranslatedCleaningServiceCategory = (category: string) => {
    if (category?.toLowerCase()?.trim() === 'air conditioner') {
        return true;
    }
    if (category?.toLowerCase()?.trim() === 'washing machine') {
        return true;
    }
    return false;
};

/**
 * Shared DeviceCard
 * @param {DeviceCardProps} args:DeviceCardProps
 */
export const DynamicDeviceCard: React.FC<DeviceCardProps & BoxProps> = (args: DeviceCardProps) => {
    // const { data } = useConfig();
    // const { theme } = data;
    // const { colors } = theme;

    const {
        plan: propPlan,
        mdn,
        model,
        imei,
        assetId,
        imageEl,
        showMoreDetails,
        detailsEl,
        isLoading,
        status,
        redirectUrl,
        type = 'default',
        viewAllDeviceText,
        hasUpgrade,
        activeSRNumber,
        use = 'landing',
        isDocuments = false,
        isAgreementSuspended,
        programName,
        clientProductSkuNbr,
        oemStartDate,
        oemEndDate,
        extendedWarrantyStartDate,
        extendedWarrantyEndDate,
        isPremiumUser,
        deviceCount,
        accountStatus,
        warrantyStatus,
        deviceRegistered,
        isSecondDeviceRegistered,
        devicecap,
        color,
        hideAddButton,
        agreementData,
        deviceType,
        isCleaningService,
        cleaningServiceRequest,
        cleaningStatus,
        category,
        schedule,
        ...props
    } = args;

    const plan = programsMap?.[propPlan]?.clientFacingName ?? propPlan;
    
    const carrier = getCarrierByDomain();

    const fields: { field: string; label: string; weight?: number }[] = [
        { field: 'mdn', label: 'Mobile:', weight: 700 },
        { field: 'model', label: 'Model:' },
        { field: 'imei', label: 'IMEI:' },
        { field: 'accountStatus', label: 'Account Status:' },
    ];
    const homeFields: { field: string; label: string; weight?: number }[] = [
        { field: 'brand', label: 'Brand:' },
        { field: 'model', label: 'Model:' },
        { field: 'serial', label: 'Serial:' },
        {
            field: 'oemwarranty',
            label: 'OEM Warranty Period: ',
        },
        {
            field: 'extendedwarranty',
            label: 'Extended Warranty Period:',
        },
        {
            field: 'devicecap',
            label: 'Device Cap:',
        },
        // {
        //     field: warrantyStatus?.toString()?.toLowerCase().split(' ').join('') ?? '',
        //     label: warrantyStatus ?? '',
        // },
        // {
        //     field: isNowWithinWarrantyDate(oemStartDate, oemEndDate)
        //         ? 'oemwarranty'
        //         : isNowWithinWarrantyDate(extendedWarrantyStartDate, extendedWarrantyEndDate)
        //         ? 'extendedwarranty'
        //         : 'oemwarranty',
        //     label: isNowWithinWarrantyDate(oemStartDate, oemEndDate)
        //         ? 'OEM Wasasd:'
        //         : isNowWithinWarrantyDate(extendedWarrantyStartDate, extendedWarrantyEndDate)
        //         ? 'Extended Warranty:'
        //         : 'OEM Warranasdasty:',
        // },
    ];

    const cleaningFields: { field: string; label: string; weight?: number }[] = [
        { field: 'cleaningStatus', label: 'Status:' },
        // { field: 'make', label: 'Make:' },
        { field: 'category', label: 'Category:' },
        { field: 'deviceType', label: 'Type:' },
        { field: 'model', label: 'Model:' },
        { field: 'schedule', label: 'Actual Schedule:' },
    ];

    const [smartScanTokenUrl] = useSessionStorage(smartScanTokenUrlKey, '');
    const queryClient = useQueryClient();
    const getSmartScanToken: string = queryClient.getQueryData(['smartScanToken']) ?? '';
    const { data } = useConfig();
    const { theme } = data;
    const { serviceRequestBtn } = theme?.components || {};
    const disableApplLoad = checkIsApplLoadDisabled(getCarrierByDomain()) //useFeatureIsOn('disableApplLoad');
    const navigate = useNavigate();

    // const { data } = useConfig();
    // const { theme } = data;
    // const { colors } = theme;
    // const [account] = useAccount<AccountInfo>({ profileId: '', userId: '' });

    const { isOn: isDeviceDetailsOn, version: deviceDetailsVersion } = useFeature('device-details');
    const primaryHeadDetail = useBreakpointValue({
        base:
            deviceDetailsVersion === 3 && clientProductSkuNbr ? (
                <TextString
                    text={`${isCleaningService ? 'Cleaning Service' : clientProductSkuNbr}`}
                    translationKey={
                        isCleaningService
                            ? 'Global/CleaningService'
                            : `Global/Plan_SKU_${clientProductSkuNbr}`
                    }
                />
            ) : deviceDetailsVersion === 4 && clientProductSkuNbr ? (
                <TextString
                    text={`${isCleaningService ? 'Cleaning Service' : clientProductSkuNbr}`}
                    translationKey={
                        isCleaningService
                            ? 'Global/CleaningService'
                            : `Global/Plan_SKU_${clientProductSkuNbr}`
                    }
                />
            ) : status ? (
                activeSRNumber ? (
                    <>
                        <TextScript text="Request No" translationKey="Global/RequestNo" />
                        {`: ${activeSRNumber}`}
                    </>
                ) : programName ? (
                    programName
                ) : plan ? (
                    <TextString
                        text={`${isCleaningService ? 'Cleaning Service' : plan}`}
                        translationKey={
                            isCleaningService ? 'Global/CleaningService' : `Global/Plan_${plan}`
                        }
                    />
                ) : (
                    plan
                )
            ) : plan ? (
                <TextString text={plan} translationKey={`Global/Plan_${plan}`} />
            ) : (
                plan
            ),
        lg:
            deviceDetailsVersion === 3 && clientProductSkuNbr ? (
                <TextString
                    text={`${isCleaningService ? 'Cleaning Service' : clientProductSkuNbr}`}
                    translationKey={
                        isCleaningService
                            ? 'Global/CleaningService'
                            : `Global/Plan_SKU_${clientProductSkuNbr}`
                    }
                />
            ) : deviceDetailsVersion === 4 && clientProductSkuNbr ? (
                <TextString
                    text={`${isCleaningService ? 'Cleaning Service' : clientProductSkuNbr}`}
                    translationKey={
                        isCleaningService
                            ? 'Global/CleaningService'
                            : `Global/Plan_SKU_${clientProductSkuNbr}`
                    }
                />
            ) : programName ? (
                programName
            ) : plan ? (
                <TextString text={plan} translationKey={`Global/Plan_${plan}`} />
            ) : (
                plan
            ),
    });

    // if (isLoading) {
    //     return <Skeleton minW={'335px'} minH={'283px'} w={'full'} borderRadius={'8px'} />;
    // }

    // TODO: Apply dependency injection on this card;
    const renderCard = () => {
        // console.log('type', type);
        // console.log('status', status);
        // Render BYOD
        if (type.toLowerCase() === 'byod' && status === 'pending') {
            //Handled if smartscan url has error, redirect to deviceCategoryList
            return <BYODCard url={smartScanTokenUrl?.includes('Error') || getSmartScanToken?.includes('Error') ? '/swap/deviceCategoryList' : smartScanTokenUrl || getSmartScanToken} />;
        }

        // Render PPE
        if (
            (type.toLowerCase() === 'ppe' && status !== 'approved') ||
            (type.toLowerCase() === 'byod' && status !== 'pending' && status !== 'approved')
        ) {
            return (
                <VStack h="full" alignItems="start" gap={0}>
                    {/* PPE Card Header */}
                    <Box m={'0'} w="full" p={'16px'}>
                        <HStack
                            w="full"
                            h="full"
                            pb={0}
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            {/* Plan Name heading */}
                            <Box m={0} p={'4px'} bg={'brand.neutral.bright'} borderRadius={'2px'}>
                                <Text fontSize={'sm'} display="flex">
                                    {primaryHeadDetail}
                                </Text>
                            </Box>
                            {/* Plan Status Tag*/}
                            {status && (
                                <Box
                                    bgColor={statusMap[status].background}
                                    color={statusMap[status].color}
                                    borderRadius="2px"
                                    h={'full'}
                                    p="1"
                                >
                                    <Text fontSize={'sm'} fontWeight="400" display={'flex'}>
                                        <TextScript
                                            text={'Request Status'}
                                            translationKey={`Global/RequestStatus`}
                                        />
                                        <Text whiteSpace={'pre'}>{`: `}</Text>
                                        <TextScript
                                            text={statusMap[status].text}
                                            translationKey={`Global/${statusMap[status].text}`}
                                        />
                                    </Text>
                                </Box>
                            )}
                        </HStack>
                    </Box>
                    {/* PPE Card Body */}
                    <Flex justify={'center'} w={'full'} p={'16px'} pt={0}>
                        <Box w={'full'}>
                            {(isCleaningService
                                ? cleaningFields
                                : deviceDetailsVersion === 3
                                ? homeFields
                                : fields
                            ).map((f, i) => {
                                return (
                                    <Stack
                                        display={'flex'}
                                        direction={'row'}
                                        gap={0}
                                        spacing={0}
                                        w={'full'}
                                        fontSize={'md'}
                                        fontWeight={400}
                                        key={`devicecard-field-${f.field}-${i}`}
                                    >
                                        <Box minWidth={'55px'} marginRight={'4px'}>
                                            {f.label}
                                        </Box>
                                        <Box fontWeight={f.weight || 400}>
                                            {f.field === 'mdn' ? (
                                                formatMdnString(args[`${f.field}`])
                                            ) : f.field === 'accountStatus' ? (
                                                agreementStatusMap(args[f?.field])
                                            ) : f.field === 'extendedwarranty' ? (
                                                `${args?.extendedWarrantyStartDate} to ${args?.extendedWarrantyEndDate}`
                                            ) : f.field === 'oemwarranty' ? (
                                                `${args?.oemStartDate} to ${args?.oemEndDate}`
                                            ) : f.field === 'model' ? (
                                                // ? `${args[`${f.field}`]}${
                                                //       args?.color ? `\n${args.color}` : ''
                                                //   }`
                                                <>
                                                    {args[`${f.field}`]}
                                                    {/* {args?.color ? <br /> : ''}
                                                    {args?.color} */}
                                                </>
                                            ) : isCleaningService &&
                                              f.field === 'cleaningStatus' ? (
                                                <TextScript
                                                    text={cleaningStatusMapping(
                                                        cleaningServiceRequest?.ServiceRequestStatus,
                                                        cleaningServiceRequest?.RepairRequest
                                                            ?.RepairStatus ?? null
                                                    )}
                                                    translationKey={`DynamicDeviceCard/CleaningStatus-${cleaningStatusMapping(
                                                        cleaningServiceRequest?.ServiceRequestStatus,
                                                        cleaningServiceRequest?.RepairRequest
                                                            ?.RepairStatus ?? null
                                                    )}`}
                                                />
                                            ) : isCleaningService &&
                                              f.field === 'category' &&
                                              isTranslatedCleaningServiceCategory(
                                                  args[f.field] ?? ''
                                              ) ? (
                                                <TextScript
                                                    text={args[f.field] ?? '--'}
                                                    translationKey={`DynamicDeviceCard/CleaningCategory-${
                                                        args[f.field] ?? '--'
                                                    }`}
                                                />
                                            ) : (
                                                args[`${f.field}`] ?? '--'
                                            )}
                                        </Box>
                                    </Stack>
                                );
                            })}
                        </Box>
                        <Square>
                            {imageEl ? (
                                imageEl
                            ) : (
                                <Box m={'30% auto'} fontSize={'xs'} color={'gray.300'}>
                                    No image
                                </Box>
                            )}
                        </Square>
                    </Flex>
                    {/* Addtl Details Section */}
                    {!isDocuments && showMoreDetails && detailsEl && (
                        <Box w={'full'}>{detailsEl}</Box>
                    )}
                </VStack>
            );
        }

        // EWS Device Details
        if (isDeviceDetailsOn && deviceDetailsVersion && deviceDetailsVersion === 2) {
            const ewsFields: { field: string; label: string; weight?: number }[] = [
                { field: 'make', label: 'Brand:', weight: 700 },
                { field: 'model', label: 'Model:' },
                { field: 'serial', label: 'Serial:' },
                { field: 'warranty', label: 'OEM Warranty:' },
            ];

            const ewsStatusMap: Record<string, DeviceStatus> = {
                OEM_WARRANTY: {
                    id: 'oem_warranty',
                    text: 'OEM Warranty',
                    background: '#37E7A7',
                    color: 'black',
                },
                EWS: {
                    id: 'ews',
                    text: 'Extended Warranty',
                    background: '#37E7A7',
                    color: 'black',
                },
                EWS_END: {
                    id: 'ews_end',
                    text: 'Extended Warranty Expired',
                    background: '#37E7A7',
                    color: 'black',
                },
            };

            return (
                <VStack h="full" alignItems="start" gap={0}>
                    {/* PPE Card Header */}
                    <Box m={'0'} w="full" p={'16px'}>
                        <HStack
                            w="full"
                            h="full"
                            pb={0}
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            {/* Plan Name heading */}
                            <Box m={0} p={'4px'} bg={'brand.neutral.bright'} borderRadius={'2px'}>
                                <Text fontSize={'sm'}>{primaryHeadDetail}</Text>
                            </Box>
                            {/* Plan Status Tag*/}
                            {status && (
                                <Box
                                    bgColor={ewsStatusMap[status]?.background}
                                    color={ewsStatusMap[status]?.color}
                                    borderRadius="2px"
                                    h={'full'}
                                    p="1"
                                >
                                    {status === 'OEM_WARRANTY' && redirectUrl ? (
                                        <Link
                                            href={redirectUrl}
                                            textDecor="underline"
                                            target="_blank"
                                        >
                                            <Text fontSize={'sm'} fontWeight="400">
                                                <TextString
                                                    text={ewsStatusMap[status].text}
                                                    translationKey={`Global/EWSDeviceInfoStatus_Warranty_${ewsStatusMap[status].text}`}
                                                />
                                            </Text>
                                        </Link>
                                    ) : (
                                        <Text fontSize={'sm'} fontWeight="400">
                                            <TextString
                                                text={ewsStatusMap[status]?.text}
                                                translationKey={`Global/EWSDeviceInfoStatus_Warranty_${ewsStatusMap[status]?.text}`}
                                            />
                                        </Text>
                                    )}
                                </Box>
                            )}
                        </HStack>
                    </Box>
                    {/* PPE Card Body */}
                    <Flex justify={'center'} w={'full'} p={'16px'} pt={0}>
                        <Box w={'full'}>
                            {ewsFields.map((f, i) => {
                                return (
                                    <Stack
                                        display={'flex'}
                                        direction={'row'}
                                        gap={0}
                                        spacing={0}
                                        w={'full'}
                                        fontSize={'md'}
                                        fontWeight={400}
                                        key={`devicecard-field-${f.field}-${i}`}
                                    >
                                        <Box minWidth={'55px'} marginRight={'4px'}>
                                            <TextString
                                                text={f.label}
                                                translationKey={`Global/EWSDeviceInfoLabels_${f.label}`}
                                            />
                                        </Box>
                                        <Box fontWeight={f.weight || 400}>{args[`${f.field}`]}</Box>
                                    </Stack>
                                );
                            })}
                        </Box>
                        <Square>
                            {imageEl ? (
                                imageEl
                            ) : (
                                <Box m={'30% auto'} fontSize={'xs'} color={'gray.300'}>
                                    No image
                                </Box>
                            )}
                        </Square>
                    </Flex>
                    {/* Addtl Details Section */}
                    {!isDocuments && showMoreDetails && detailsEl && (
                        <Box w={'full'}>{detailsEl}</Box>
                    )}
                </VStack>
            );
        }
        // Render Default
        return (
            <>
                <Flex
                    justify={{ base: 'center', lg: hasUpgrade ? 'space-between' : 'space-between' }}
                    h={isLoading ? '283px' : 'auto'}
                >
                    <Box w={{ base: 'full' }}>
                        {isPremiumUser &&
                            deviceCount < 2 &&
                            isSecondDeviceRegistered === false &&
                            deviceRegistered === true &&
                            hideAddButton === false && (
                                <Box
                                    w="full"
                                    py={2.5}
                                    display="flex"
                                    flexDir={'column'}
                                    justifyContent={'center'}
                                    alignItems="center"
                                    borderBottom={'1px solid #A5AAAF'}
                                >
                                    <Box
                                        py={4}
                                        display="flex"
                                        flexDir={'column'}
                                        justifyContent={'center'}
                                        alignItems="center"
                                    >
                                        <Skeleton isLoaded={!isLoading}>
                                            <Text fontWeight={400} fontSize={'24px'}>
                                                <TextScript
                                                    text="Add device"
                                                    translationKey="Global/AddDevice"
                                                />
                                            </Text>
                                        </Skeleton>
                                    </Box>
                                    <Box
                                        px={4}
                                        pb={4}
                                        display="flex"
                                        flexDir={'column'}
                                        justifyContent={'center'}
                                        alignItems="center"
                                    >
                                        <Skeleton isLoaded={!isLoading}>
                                            <Button
                                                {...serviceRequestBtn}
                                                width="100px"
                                                as={Link}
                                                // href="/swap/deviceCategoryList"
                                                href={disableApplLoad ? '#' : '/swap/deviceCategoryList'}
                                                onClick={() => disableApplLoad ? navigate('/deviceCategoryList') : undefined}
                                            >
                                                {`+ `}
                                                <TextScript
                                                    text="Add"
                                                    translationKey="Global/Add"
                                                />
                                            </Button>
                                        </Skeleton>
                                    </Box>
                                </Box>
                            )}
                        <Box id="upper-device-card" p="16px" pb="32px">
                            <Box
                                m={'0'}
                                mb={'15px'}
                                w="full"
                                aria-description="plan details row for device selection"
                                id="boxxy"
                            >
                                <HStack
                                    w="full"
                                    h="full"
                                    pb={0}
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                >
                                    <Skeleton isLoaded={!isLoading} minH="29px">
                                        <Box
                                            m={0}
                                            p={'4px'}
                                            // w={'130px'}
                                            bg={'brand.neutral.bright'}
                                            borderRadius={'2px'}
                                            minH={'29px'}
                                        >
                                            <Text fontSize={'sm'}>{primaryHeadDetail}</Text>
                                        </Box>
                                    </Skeleton>
                                    {status && status !== 'approved' && accountStatus !== 'TRMNTD' && (
                                        <Skeleton
                                            isLoaded={!isLoading}
                                            display={{
                                                base: 'block',
                                                lg: deviceDetailsVersion === 3 ? 'block' : 'none',
                                            }}
                                        >
                                            <Box
                                                bgColor={
                                                    deviceDetailsVersion === 3
                                                        ? 'brand.neutral.bright'
                                                        : statusMap[status]?.background
                                                }
                                                color={statusMap[status]?.color}
                                                borderRadius="2px"
                                                h={'full'}
                                                p="1"
                                                display={{
                                                    base: 'block',
                                                    lg:
                                                        deviceDetailsVersion === 3
                                                            ? 'block'
                                                            : 'none',
                                                }}
                                            >
                                                <Text
                                                    fontSize={'sm'}
                                                    fontWeight="400"
                                                    display="flex"
                                                >
                                                    {/* {deviceDetailsVersion === 3
                                                        ? warrantyStatus
                                                        : `Status: ${statusMap[status].text}`} */}
                                                    {deviceDetailsVersion === 3 ? (
                                                        activeSRNumber ? (
                                                            <>
                                                                <TextScript
                                                                    text="Request No"
                                                                    translationKey="Global/RequestNo"
                                                                />
                                                                {`: ${activeSRNumber}`}
                                                            </>
                                                        ) : null
                                                    ) : (
                                                        <>
                                                            <TextScript
                                                                text={'Request Status'}
                                                                translationKey={`Global/RequestStatus`}
                                                            />
                                                            <Text whiteSpace={'pre'}>{`: `}</Text>
                                                            <TextScript
                                                                text={statusMap[status].text}
                                                                translationKey={`Global/${statusMap[status].text}`}
                                                            />
                                                        </>
                                                    )}
                                                </Text>
                                            </Box>
                                        </Skeleton>
                                    )}
                                </HStack>
                            </Box>
                            {deviceDetailsVersion === 3 &&
                            status === 'processing' ? null : status ? (
                                <Box
                                    w="full"
                                    display={{
                                        base: deviceDetailsVersion === 3 ? 'block' : 'none',
                                        lg: 'block',
                                    }}
                                    id="boxb-status"
                                >
                                    <HStack
                                        w="full"
                                        h="full"
                                        pb={0}
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        py={4}
                                    >
                                        {(activeSRNumber || deviceDetailsVersion === 3) && (
                                            <Skeleton isLoaded={!isLoading}>
                                                <Box
                                                    m={0}
                                                    p={'4px'}
                                                    // w={'130px'}
                                                    bg={'brand.neutral.bright'}
                                                    borderRadius={'2px'}
                                                    display={
                                                        deviceDetailsVersion !== 3
                                                            ? 'block'
                                                            : 'none'
                                                    }
                                                >
                                                    <Text fontSize={'sm'} display="flex">
                                                        {activeSRNumber ? (
                                                            <>
                                                                <TextScript
                                                                    text="Request No"
                                                                    translationKey="Global/RequestNo"
                                                                />
                                                                {`: ${activeSRNumber}`}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TextScript
                                                                    text="Request No"
                                                                    translationKey="Global/RequestNo"
                                                                />
                                                                {`: ---`}
                                                            </>
                                                        )}
                                                    </Text>
                                                </Box>
                                            </Skeleton>
                                        )}
                                        {status &&
                                            deviceDetailsVersion !== 3 &&
                                            status !== 'approved' && accountStatus !== 'TRMNTD' && (
                                                <Skeleton isLoaded={!isLoading}>
                                                    <Box
                                                        bgColor={statusMap[status].background}
                                                        color={statusMap[status].color}
                                                        borderRadius="2px"
                                                        h={'full'}
                                                        p="1"
                                                        display={{ base: 'none', lg: 'block' }}
                                                    >
                                                        <Text
                                                            fontSize={'sm'}
                                                            fontWeight="400"
                                                            display={'flex'}
                                                        >
                                                            <TextScript
                                                                text={'Request Status'}
                                                                translationKey={`Global/RequestStatus`}
                                                            />
                                                            <Text whiteSpace={'pre'}>{`: `}</Text>
                                                            <TextScript
                                                                text={statusMap[status].text}
                                                                translationKey={`Global/${statusMap[status].text}`}
                                                            />
                                                        </Text>
                                                    </Box>
                                                </Skeleton>
                                            )}
                                        {status &&
                                            status !== 'approved' &&
                                            deviceDetailsVersion === 3 && (
                                                <Skeleton
                                                    isLoaded={!isLoading}
                                                    display={{
                                                        base: 'block',
                                                        lg:
                                                            deviceDetailsVersion === 3
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                    alignSelf={'end'}
                                                >
                                                    <Box
                                                        bgColor={
                                                            deviceDetailsVersion === 3
                                                                ? '#37E7A7'
                                                                : statusMap[status]?.background
                                                        }
                                                        color={statusMap[status]?.color}
                                                        borderRadius="2px"
                                                        h={'full'}
                                                        p="1"
                                                        display={{
                                                            base: 'block',
                                                            lg:
                                                                deviceDetailsVersion === 3
                                                                    ? 'block'
                                                                    : 'none',
                                                        }}
                                                    >
                                                        <Text
                                                            fontSize={'sm'}
                                                            fontWeight="400"
                                                            display={'flex'}
                                                        >
                                                            {/* {deviceDetailsVersion === 3
                                                        ? warrantyStatus
                                                        : `Status: ${statusMap[status].text}`} */}
                                                            <TextScript
                                                                text={'Request Status'}
                                                                translationKey={`Global/RequestStatus`}
                                                            />
                                                            <Text whiteSpace={'pre'}>{`: `}</Text>
                                                            <TextScript
                                                                text={statusMap[status].text}
                                                                translationKey={`Global/${statusMap[status].text}`}
                                                            />
                                                        </Text>
                                                    </Box>
                                                </Skeleton>
                                            )}
                                    </HStack>
                                </Box>
                            ) : null}
                            <Flex
                                justify={{
                                    base: isLoading ? 'space-between' : 'center',
                                    xl: use === 'planOverview' ? 'center' : 'space-between',
                                }}
                                w="full"
                                direction={{
                                    base: 'row',
                                    xl: use === 'planOverview' ? 'row' : 'row-reverse',
                                }}
                                id="boxflexx"
                            >
                                {/* This is device details */}
                                <Box
                                    w={
                                        // isLoading
                                        //     ? '60%'
                                        //     : hasUpgrade || use === 'planOverview'
                                        //     ? 'full'
                                        //     : ''
                                        'full'
                                    }
                                    display="flex"
                                    flexFlow={{
                                        base: 'column',
                                        sm: 'column',
                                        '2xl': use === 'planOverview' ? 'column' : 'row',
                                        // lg: hasUpgrade || use === 'planOverview' ? 'column' : 'row',
                                    }}
                                    gap={{
                                        base: use === 'planOverview' || isCleaningService ? 2 : 0,
                                        xl:
                                            use === 'planOverview' || isCleaningService
                                                ? 2
                                                : '16px',
                                    }}
                                    // justifyContent="space-between"
                                >
                                    {(isLoading
                                        ? [
                                              { field: 'Field', label: 'Label' },
                                              { field: 'Field', label: 'Label' },
                                              { field: 'Field', label: 'Label' },
                                          ]
                                        : isCleaningService
                                        ? cleaningFields
                                        : deviceDetailsVersion === 3
                                        ? homeFields
                                        : fields
                                    ).map((f, i) => {
                                        return (
                                            <Fragment key={`${f.label}-${i}`}>
                                                {i === 0 ? (
                                                    <HStack alignItems="start">
                                                        <Skeleton
                                                            isLoaded={!isLoading}
                                                            mr={
                                                                isLoading
                                                                    ? {
                                                                          base: 0,
                                                                          md: 2,
                                                                          //   md: hasUpgrade ? 0 : 2,
                                                                      }
                                                                    : 0
                                                            }
                                                            ml={isLoading ? { base: 2, md: 0 } : 0}
                                                            display={{
                                                                base: 'none',
                                                                '2xl':
                                                                    use === 'planOverview' ||
                                                                    isCleaningService
                                                                        ? 'none'
                                                                        : 'block',
                                                            }}
                                                        >
                                                            <Square
                                                                size={'max-content'}
                                                                marginLeft={'5px'}
                                                            >
                                                                {imageEl ? (
                                                                    imageEl
                                                                ) : (
                                                                    <Skeleton
                                                                        isLoaded={!isLoading}
                                                                        mr={isLoading ? 2 : 0}
                                                                    >
                                                                        <Box
                                                                            m={'30% auto'}
                                                                            fontSize={'xs'}
                                                                            color={'gray.300'}
                                                                        >
                                                                            No image
                                                                        </Box>
                                                                    </Skeleton>
                                                                )}
                                                            </Square>
                                                        </Skeleton>
                                                        <Stack
                                                            direction={{
                                                                base: 'column',
                                                                xsm: 'row',
                                                                lg: 'column',
                                                                // lg:
                                                                //     hasUpgrade ||
                                                                //     use === 'planOverview'
                                                                //         ? 'row'
                                                                //         : 'column',
                                                            }}
                                                            gap={0}
                                                            spacing={0}
                                                            w={'full'}
                                                            fontSize={'md'}
                                                            fontWeight={400}
                                                            key={`devicecard-field-${f.field}-${i}`}
                                                        >
                                                            <Box
                                                                w={{
                                                                    base: 'full',
                                                                    xsm: '33%',
                                                                    md:
                                                                        // hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? '33%'
                                                                            : '33%',
                                                                    lg:
                                                                        // hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? 'full'
                                                                            : 'full',
                                                                }}
                                                                minW={{
                                                                    base: '33%',
                                                                    xl:
                                                                        // hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? '20%'
                                                                            : 'full',
                                                                }}
                                                                float={{
                                                                    base: 'left',
                                                                    xl:
                                                                        // hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? 'none'
                                                                            : 'left',
                                                                }}
                                                            >
                                                                <Skeleton
                                                                    isLoaded={!isLoading}
                                                                    mb={isLoading ? 2 : 0}
                                                                    mr={
                                                                        isLoading
                                                                            ? { base: 2 }
                                                                            : { base: 0 }
                                                                    }
                                                                    height={
                                                                        isLoading ? '20px' : 'auto'
                                                                    }
                                                                    w={{
                                                                        base: 'full',
                                                                        sm: 'full',
                                                                        lg: 'full',
                                                                    }}
                                                                >
                                                                    <Text
                                                                        // fontWeight={{
                                                                        //     lg:
                                                                        //         hasUpgrade ||
                                                                        //         use ===
                                                                        //             'planOverview'
                                                                        //             ? 'normal'
                                                                        //             : 'bold',
                                                                        // }}
                                                                        fontWeight="bold"
                                                                    >
                                                                        <TextScript
                                                                            text={f.label}
                                                                            translationKey={`DynamicDeviceCard/${
                                                                                isCleaningService &&
                                                                                f.field === 'model'
                                                                                    ? 'cleaningModel'
                                                                                    : f.field
                                                                            }`}
                                                                        />
                                                                    </Text>
                                                                </Skeleton>
                                                            </Box>

                                                            <Box
                                                                w={{
                                                                    base: '70%',
                                                                    sm:
                                                                        // hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? '70%'
                                                                            : 'full',
                                                                    xl:
                                                                        // hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? 'full'
                                                                            : 'full',
                                                                }}
                                                                float={{
                                                                    base: 'right',
                                                                    xl:
                                                                        // hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? 'none'
                                                                            : 'right',
                                                                }}
                                                                maxW={{
                                                                    base: 'none',
                                                                    xl:
                                                                        // hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? '200px'
                                                                            : 'none',
                                                                }}
                                                                minW={{
                                                                    base: '0',
                                                                    lg:
                                                                        // hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? '0px'
                                                                            : '200px',
                                                                }}
                                                                fontWeight={f.weight || 400}
                                                                id="First-Item-Container-Header-Value-container"
                                                            >
                                                                <Skeleton
                                                                    isLoaded={!isLoading}
                                                                    height={
                                                                        isLoading ? '20px' : 'auto'
                                                                    }
                                                                    w={'full'}
                                                                >
                                                                    {f.field === 'mdn' ? (
                                                                        formatMdnString(
                                                                            args[`${f.field}`]
                                                                        )
                                                                    ) : f.field ===
                                                                      'accountStatus' ? (
                                                                        agreementStatusMap(
                                                                            args[`${f.field}`]
                                                                        )
                                                                    ) : f.field === 'warranty' ? (
                                                                        '-'
                                                                    ) : isCleaningService &&
                                                                      f.field ===
                                                                          'cleaningStatus' ? (
                                                                        <TextScript
                                                                            text={cleaningStatusMapping(
                                                                                cleaningServiceRequest?.ServiceRequestStatus,
                                                                                cleaningServiceRequest
                                                                                    ?.RepairRequest
                                                                                    ?.RepairStatus ??
                                                                                    null
                                                                            )}
                                                                            translationKey={`DynamicDeviceCard/CleaningStatus-${cleaningStatusMapping(
                                                                                cleaningServiceRequest?.ServiceRequestStatus,
                                                                                cleaningServiceRequest
                                                                                    ?.RepairRequest
                                                                                    ?.RepairStatus ??
                                                                                    null
                                                                            )}`}
                                                                        />
                                                                    ) : (
                                                                        args[
                                                                            `${
                                                                                f.field?.toLowerCase() ===
                                                                                'brand'
                                                                                    ? 'make'
                                                                                    : f.field
                                                                            }`
                                                                        ] ?? '--'
                                                                    )}
                                                                </Skeleton>
                                                            </Box>
                                                        </Stack>
                                                    </HStack>
                                                ) : (
                                                    <Stack
                                                        direction={{
                                                            base: 'column',
                                                            xsm: 'row',
                                                            lg: 'column',
                                                            // lg:
                                                            //     hasUpgrade || use === 'planOverview'
                                                            //         ? 'row'
                                                            //         : 'column',
                                                        }}
                                                        gap={0}
                                                        spacing={0}
                                                        fontSize={'md'}
                                                        fontWeight={400}
                                                        key={`devicecard-field-${f.field}-${i}`}
                                                        // justifyContent="center"
                                                    >
                                                        <Box
                                                            w={{
                                                                base: '33%',
                                                                // xsm: '33%',
                                                                xl:
                                                                    // hasUpgrade ||
                                                                    use === 'planOverview'
                                                                        ? 'full'
                                                                        : 'full',
                                                            }}
                                                            minW={{
                                                                base: '33%',
                                                                xl:
                                                                    // hasUpgrade ||
                                                                    use === 'planOverview'
                                                                        ? '20%'
                                                                        : 'full',
                                                            }}
                                                            // w="auto"
                                                            float={{
                                                                base: 'left',
                                                                xl:
                                                                    // hasUpgrade ||
                                                                    use === 'planOverview'
                                                                        ? 'none'
                                                                        : 'left',
                                                            }}
                                                        >
                                                            <Skeleton
                                                                isLoaded={!isLoading}
                                                                mb={isLoading ? 2 : 0}
                                                                mr={
                                                                    isLoading
                                                                        ? { base: 2 }
                                                                        : { base: 0 }
                                                                }
                                                                height={isLoading ? '20px' : 'auto'}
                                                                minW={'56px'}
                                                            >
                                                                <Text
                                                                    // fontWeight={{
                                                                    //     lg:
                                                                    //         hasUpgrade ||
                                                                    //         use === 'planOverview'
                                                                    //             ? 'normal'
                                                                    //             : 'bold',
                                                                    // }}
                                                                    fontWeight="bold"
                                                                >
                                                                    <TextScript
                                                                        text={f.label}
                                                                        translationKey={`DynamicDeviceCard/${
                                                                            isCleaningService &&
                                                                            f.field === 'model'
                                                                                ? 'cleaningModel'
                                                                                : f.field
                                                                        }`}
                                                                    />
                                                                </Text>
                                                            </Skeleton>
                                                        </Box>

                                                        <Box
                                                            // w={{
                                                            //     base: '70%',
                                                            //     xl:
                                                            //         hasUpgrade ||
                                                            //         use === 'planOverview'
                                                            //             ? '70%'
                                                            //             : 'full',
                                                            // }}
                                                            w="full"
                                                            float={{
                                                                base: 'right',
                                                                xl:
                                                                    // hasUpgrade ||
                                                                    use === 'planOverview'
                                                                        ? 'none'
                                                                        : 'right',
                                                            }}
                                                            maxW={{
                                                                base: 'none',
                                                                xl:
                                                                    // hasUpgrade ||
                                                                    use === 'planOverview'
                                                                        ? '200px'
                                                                        : 'none',
                                                            }}
                                                            minW={{
                                                                base: '0',
                                                                lg:
                                                                    // hasUpgrade ||
                                                                    use === 'planOverview'
                                                                        ? '0px'
                                                                        : '200px',
                                                            }}
                                                            fontWeight={f.weight || 400}
                                                        >
                                                            <Skeleton
                                                                isLoaded={!isLoading}
                                                                height={isLoading ? '20px' : 'auto'}
                                                                // w={'150px'}
                                                                w={'full'}
                                                                display={'flex'}
                                                                flexFlow="column nowrap"
                                                            >
                                                                {f.field === 'mdn' ? (
                                                                    formatMdnString(
                                                                        args[`${f.field}`]
                                                                    )
                                                                ) : f.field === 'accountStatus' ? (
                                                                    agreementStatusMap(
                                                                        args[`${f.field}`]
                                                                    )
                                                                ) : f.field ===
                                                                  'extendedwarranty' ? (
                                                                    `${args?.extendedWarrantyStartDate} to ${args?.extendedWarrantyEndDate}`
                                                                ) : f.field === 'oemwarranty' ? (
                                                                    `${args?.oemStartDate} to ${args?.oemEndDate}`
                                                                ) : f.field === 'model' ? (
                                                                    // ? `${args[`${f.field}`]}${
                                                                    //       args?.color
                                                                    //           ? `\n${args.color}`
                                                                    //           : ''
                                                                    //   }`
                                                                    <>
                                                                        {args[`${f.field}`]}
                                                                        {/* {args?.color ? <br /> : ''}
                                                                        {args?.color} */}
                                                                    </>
                                                                ) : isCleaningService &&
                                                                  f.field === 'category' &&
                                                                  isTranslatedCleaningServiceCategory(
                                                                      args[f.field] ?? ''
                                                                  ) ? (
                                                                    <TextScript
                                                                        text={args[f.field] ?? '--'}
                                                                        translationKey={`DynamicDeviceCard/CleaningCategory-${
                                                                            args[f.field] ?? '--'
                                                                        }`}
                                                                    />
                                                                ) : (
                                                                    args[`${f.field}`] ?? '--'
                                                                )}
                                                                {f.field === 'extendedwarranty' &&
                                                                    carrier === 'cht_home' && (
                                                                        <TextScript
                                                                            text={
                                                                                '(Including extra 15 days friendly warranty)'
                                                                            }
                                                                            translationKey={`DynamicDeviceCard/ExtraFriendlyWarranty`}
                                                                        />
                                                                    )}
                                                            </Skeleton>
                                                        </Box>
                                                    </Stack>
                                                )}
                                            </Fragment>
                                        );
                                    })}
                                </Box>

                                {/* This is mobile image  */}
                                <Skeleton
                                    isLoaded={!isLoading}
                                    mr={isLoading ? { base: 0, md: 2 } : 0}
                                    ml={isLoading ? { base: 2, md: 0 } : 0}
                                >
                                    <Square
                                        size={'max-content'}
                                        marginLeft={'5px'}
                                        display={{
                                            base: 'block',
                                            xl: use === 'planOverview' ? 'block' : 'none',
                                        }}
                                        id="mob-square"
                                    >
                                        {imageEl ? (
                                            imageEl
                                        ) : (
                                            <Skeleton isLoaded={!isLoading} mr={isLoading ? 2 : 0}>
                                                <Box
                                                    m={'30% auto'}
                                                    fontSize={'xs'}
                                                    color={'gray.300'}
                                                >
                                                    No image
                                                </Box>
                                            </Skeleton>
                                        )}
                                    </Square>
                                </Skeleton>
                            </Flex>
                        </Box>

                        {!isDocuments && showMoreDetails && detailsEl && (
                            <Box w={'full'}>{detailsEl}</Box>
                        )}
                    </Box>
                </Flex>
            </>
        );

        // Return PPE

        // Return Default
    };

    return (
        <Box
            id={'myasurion-devicecard'}
            borderRadius={{ base: '8px', lg: 0 }}
            // borderBottomRadius={{ lg: '8px' }}
            minW={'286px'}
            minH={'124px'}
            borderWidth={'1px'}
            borderStyle={'solid'}
            borderColor={'#A5AAAF'}
            background={use === 'planOverview' ? 'initial' : '#F0F0F5'}
            borderBottom={{ base: '1px solid #A5AAAF' }}
            w={{ lg: 'full' }}
            {...props}
        >
            {renderCard()}
        </Box>
    );
};

export default DynamicDeviceCard;