import { useConfig, TextScript } from '@MGPD/myasurion-shared';
import { CloseIcon } from '@chakra-ui/icons';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogOverlay, Image, Text } from '@chakra-ui/react'
import {
  Box,
  Button,
  Flex,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { PropsWithChildren } from 'react';
import TrackingDone from '../../icons/tracking-done.svg'
// import TrackingCurrent from '../../icons/tracking-current.svg'
import TrackingIncomplete from '../../icons/tracking-incomplete.svg'
import { useCentralStore } from '../../store/appContext';
import { isExchange } from '../../utils/featuresUtills';
import ClaimCardKeys from '../../Scripts/translationsKeys.json';
interface popupProps {
  onYesClick?: () => void;
  openTrackMyDelivery: boolean;
  callInquiryForTrackSR: (args: boolean) => void;
  claimData: any;
  openTrackOrderPopup: boolean
  setOpenTrackOrderPopup: any
  agreementdata: any
}

const TrackMyDelivery: React.FC<PropsWithChildren<popupProps>> = ({
claimData,
callInquiryForTrackSR,
openTrackOrderPopup,
setOpenTrackOrderPopup,
agreementdata
}) => {
const isRepairRequest = claimData?.ServiceRequests?.[0]?.RepairRequest
const globalState = useCentralStore()

const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;

let nonRepairTrackSRData = [
    { 
        srLabel: <TextScript 
            text='Service Request Confirmed'
            translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.ServiceRequestConfirmed}`}
        />,  
        srDesc: 'Your Service Request has been confirmed',
        srStatus: 'CMPLTD',
        shippingStatus: ['READY', 'SHIP', 'DLVRD'],
    },
    { 
        srLabel: <TextScript 
            text='Service Request Approved'
            translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.ServiceRequestApproved}`}
        />, 
        srDesc: 'Your Service Request has been accepeted and processing',
        srStatus: 'CMPLTD',
        shippingStatus: ['READY', 'SHIP', 'DLVRD']
    },
    { 
        srLabel: <TextScript 
            text='Service Request Processing'
            translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.ServiceRequestProcessing}`}
        />,
        srDesc: 'Your device is being prepared for quality checks and packing',
        srStatus: 'CMPLTD',
        shippingStatus: ['READY', 'SHIP', 'DLVRD']
    },
    { 
        srLabel: <TextScript 
                text='Quality Checking'
                translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.QualityChecking}`}
            />,
        srDesc: 'Your device is undergoing quality assurance checks',
        srStatus: 'CMPLTD',
        shippingStatus: ['SHIP', 'DLVRD']
    },
    { 
        srLabel: <TextScript 
            text='Device Dispatched'
            translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.DeviceDispatched}`}
        />,  
        srDesc: 'Your device has been dispatched and is on its way to you',
        srStatus: 'CMPLTD',
        shippingStatus: ['SHIP', 'DLVRD']
    },
    { 
        srLabel: <TextScript 
            text='Device Delivered'
            translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.DeviceDelivered}`}
        />, 
        srDesc: 'Your device has been delivered successfully',
        srStatus: 'CMPLTD',
        shippingStatus: ['DLVRD']
    },
]

let repairTrackSRData = [
    { 
        srLabel: <TextScript 
            text='Service Request Confirmed'
            translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.ServiceRequestConfirmed}`}
        />, 
        srDesc: 'Your Service Request has been confirmed',
        repairStatus: ['SCHEDULED', 'CCX STARTED', 'CCX COMPLETED', 'DASP STARTED', 'DASP COMPLETED', 'CHECKIN', 'STARTED', 'DCX STARTED', 'COMPLETED', 'READYFORCOLLECTION', 'CASP STARTED', 'CASP COMPLETED', 'READYFORDELIVERY']
    },
    { 
        srLabel: <TextScript 
            text='Service Request Approved'
            translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.ServiceRequestApproved}`}
        />, 
        srDesc: 'Your Service Request has been accepeted and processing',
        repairStatus: ['SCHEDULED','CCX STARTED', 'CCX COMPLETED', 'DASP STARTED', 'DASP COMPLETED', 'CHECKIN', 'STARTED', 'DCX STARTED', 'COMPLETED', 'READYFORCOLLECTION', 'CASP STARTED', 'CASP COMPLETED', 'READYFORDELIVERY']
    },
    { 
        srLabel: <TextScript 
                text='Service Request Processing'
                translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.ServiceRequestProcessing}`}
            />,
        srDesc: 'Your device is being prepared for quality checks and packing',
        repairStatus: ['CHECKIN', 'STARTED', 'DCX STARTED', 'COMPLETED', 'READYFORCOLLECTION', 'CASP STARTED', 'CASP COMPLETED', 'READYFORDELIVERY']
    },
    { 
        srLabel: <TextScript 
            text='Device Inspection'
            translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.DeviceInspection}`}
        />, 
        srDesc: 'Your device is being inspected for any damages',
        repairStatus: ['STARTED', 'DCX STARTED', 'COMPLETED', 'READYFORCOLLECTION', 'CASP STARTED', 'CASP COMPLETED', 'READYFORDELIVERY']
    },
    { 
        srLabel: <TextScript 
            text='Repair In Progress'
            translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.RepairInProgress}`}
        />, 
        srDesc: 'Your device is being repaired',
        repairStatus: ['STARTED', 'DCX STARTED', 'COMPLETED', 'READYFORCOLLECTION', 'CASP STARTED', 'CASP COMPLETED', 'READYFORDELIVERY']
    },
    { 
        srLabel: <TextScript 
                text='Quality Checking'
                translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.QualityChecking}`}
            />, 
        srDesc: 'Your device is undergoing quality assurance checks',
        repairStatus: ['DCX STARTED', 'COMPLETED', 'READYFORCOLLECTION', 'CASP STARTED', 'CASP COMPLETED', 'READYFORDELIVERY']
    },
    { 
        srLabel: <TextScript 
                text='Device Dispatched'
                translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.DeviceDispatched}`}
            />, 
        srDesc: 'Your device has been dispatched and is on its way to you',
        repairStatus: ['DCX STARTED', 'COMPLETED']
    },
    { 
        srLabel: <TextScript 
                    text='Device Delivered'
                    translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.DeviceDelivered}`}
                />, 
        srDesc: 'Your device has been delivered successfully',
        repairStatus: ['COMPLETED']
    },
]

 const trackingNumber = claimData?.ServiceRequests?.[0]?.ShippingOrder?.TrackingNumber
const callTrackOrder = (type: any) => {
    if(isExchange(agreementdata?.ClientOffer?.ClientOfferName)){
        window.open(`https://www.citylinkexpress.com/tracking-result/?track0=${trackingNumber}`, '_blank')
    }else{
        callInquiryForTrackSR(type)
    }  
}

const TrackDetails = (item: any, index: any) => {
    item = item.item
    
    return(
        <Flex direction={'row'} justifyContent={'space-between'} key={index} w={'full'}>
            <Box w={'10%'}>
                {/* {isRepairRequest ? item?.repairStatus === claimData?.ServiceRequests?.[0]?.RepairRequest?.RepairStatus  ? 1 : 0 :  */}
                {/* {(isRepairRequest && item?.repairStatus.includes(claimData?.ServiceRequests?.[0]?.RepairRequest?.RepairStatus)) || (item?.srStatus === claimData?.ServiceRequests?.[0]?.ServiceRequestStatus 
                && item?.shippingStatus.includes(claimData?.ServiceRequests?.[0]?.ShippingOrder?.ShippingStatus)) ? 1 : 0
                } */}
                <Image marginX={'auto'} mt={'1'} src={(isRepairRequest && item?.repairStatus.includes(claimData?.ServiceRequests?.[0]?.RepairRequest?.RepairStatus)) || (item?.srStatus === claimData?.ServiceRequests?.[0]?.ServiceRequestStatus 
                && item?.shippingStatus.includes(claimData?.ServiceRequests?.[0]?.ShippingOrder?.ShippingStatus)) ? TrackingDone : TrackingIncomplete} />
            </Box>
            <Box ml='2' w={'90%'}>
                <Text fontSize={'14px'} fontWeight={'600'}>
                    {item.srLabel}
                </Text>
                <Text fontSize={'14px'} >
                    {item.srDesc}
                </Text>
                {item.srLabel === 'Device Dispatched' &&
                    ((!isRepairRequest && 
                        item?.shippingStatus.includes(claimData?.ServiceRequests?.[0]?.ShippingOrder?.ShippingStatus) 
                        && (claimData?.ServiceRequests?.[0]?.ShippingOrder?.ShippingStatus !== 'DLVRD' || claimData?.ServiceRequests?.[0]?.ShippingOrder?.ShippingStatus === 'SHIP' ))
                    || (isRepairRequest 
                        && item?.repairStatus.includes(claimData?.ServiceRequests?.[0]?.RepairRequest?.RepairStatus) 
                        && claimData?.ServiceRequests?.[0]?.RepairRequest?.RepairStatus !== 'COMPLETED')) &&
                    <Button
                        colorScheme="primary"
                        variant="outline"
                        width={'40%'}
                        fontSize={'14px'}
                        h={'auto'}
                        p='3'
                        onClick={() => callTrackOrder('trackOrder')}
                    >
                        Track Order
                    </Button>
                }
            </Box>
        </Flex>
    )
}
const cancelRef = React.useRef<any>();

  return (
    <>
    <Box m={3}>
        <Flex direction={'row'} justifyContent={'space-between'}>
            <Text fontWeight={'700'} fontSize={'18px'}>
                <TextScript 
                    text='Service Request ID'
                    translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.ServiceRequestID}`}
                />
              </Text>
              <Text fontWeight={'700'} fontSize={'18px'}>
                  SR{claimData?.RequestNumber}
              </Text>
        </Flex>
        
            <AlertDialog
                      motionPreset="slideInBottom"
                      leastDestructiveRef={cancelRef}
                      onClose={() => setOpenTrackOrderPopup(false)}
                      isOpen={openTrackOrderPopup}
                      isCentered
                      autoFocus={false}
                  >
                      <AlertDialogOverlay />
                      <AlertDialogContent margin={'12px'} height={'70%'}>
                          <Box
                              onClick={() => setOpenTrackOrderPopup(false)}
                              p={3}
                              cursor={'pointer'}
                              position={'absolute'}
                              right={'0'}
                          >
                              <CloseIcon boxSize={4} focusable={true} color={colors.primary} />
                          </Box>
                          <Text align={'center'} mt='5' pt='20px' px={2} fontSize={'20px'} fontWeight={'700'}>Track Order - Device Dispatched</Text>
                          <Text align={'center'} fontSize={'14px'}>Device has been dispatched and is on its way to you</Text>
                          <AlertDialogBody paddingX={'12px'} paddingTop={'12px'} as={'b'} fontSize={'18px'}>
                          {globalState?.state?.trackOrderData?.updatedDelivery?.TrackingUrl ?
                             <iframe height={'100%'} width={'100%'} src={globalState?.state?.trackOrderData?.updatedDelivery?.TrackingUrl} referrerPolicy='no-referrer' />
                             :
                             <Text align={'center'} fontWeight={'normal'}>Tracking details are not avaiable at this moment.</Text>
                          }
                          </AlertDialogBody>
                      </AlertDialogContent>
                  </AlertDialog>
        
        <Box>
            <Stack spacing={4} my={'5'}>
              {!isRepairRequest && nonRepairTrackSRData?.map((item, index) => {
                  return (
                      <TrackDetails item={item} index={index} />
                  )
              })}
              {isRepairRequest && repairTrackSRData?.map((item, index) => {
                  return (
                      <TrackDetails item={item} index={index} />
                  )
              })}
            </Stack>
        </Box>
    </Box>
    </>
  );
};

export default TrackMyDelivery;
