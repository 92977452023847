/**
 * A default page layout component for the JB.
 * This component provides a consistent layout for JB pages, including a header, device card, content area, and buttons.
 * Changes to this component should not be made without the review of the JB component's owner.
 */

import Scripts from '../Scripts/asurion-script.json';
import MainStackLayout from '../layout/MainStackLayout';
import { useCentralStore } from '../store/appContext';
import { getDeviceImageByModel } from '../utils/get-device-images';
import { DeviceCard, SubHeader, TextScript, useConfig } from '@MGPD/myasurion-shared';
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

import translationsKeys from '../Scripts/translationsKeys.json';
import CancelSrPopup from './myclaims/CancelSrPopup';

interface TextScriptProps {
  headerText?: string;
  headerTranslationKey?: string;
  nextBtnText?: string;
  nextBtnTranslationKey?: string;
  cancelBtnText?: string;
  cancelBtnTranslationKey?: string;
}

type DeviceCardProps = {
  assetId: string;
  programmeNameLBL: string;
  clientOfferName: string;
  countryCallingCode: string;
  mobileDeviceNumber: string;
  assetCatalogName: string;
  imei: string;
  assetFamily: string;
};

interface JBDefaultPageLayoutPropsBase {
  onCancelClick?: () => void;
  onNextClick?: () => void;
  cancelText: string;
  showSubHeaderCancelButton?: boolean;
  showCancelButton?: boolean;
  showNextButton?: boolean;
  showSubHeader?: boolean;
  showBackButton?: boolean;
  backText?: string;
  allowBackButtonClick?: boolean;
  children: React.ReactNode;
  showDeviceCard: boolean;
  disableNextButton?: boolean;
}

type JBDefaultPageLayoutProps = JBDefaultPageLayoutPropsBase &
  (JBDefaultPageLayoutPropsBase['showDeviceCard'] extends true
    ? DeviceCardProps
    : Partial<DeviceCardProps>);

const JBDefaultPageLayout: React.FC<
  JBDefaultPageLayoutProps & TextScriptProps & PropsWithChildren<any>
> = ({
  // Text Script Props
  headerText = '',
  headerTranslationKey = '',
  nextBtnText = Scripts.Global.Next,
  cancelBtnText = Scripts.Global.Cancel,
  nextBtnTranslationKey = `${translationsKeys.Global.PageName}/${translationsKeys.Global.Proceed}`,
  cancelText = Scripts.CancelSR.cancelBtnText,
  cancelBtnTranslationKey = `${translationsKeys.Global.PageName}/${translationsKeys.Global.Cancel}`,
  // Button Props
  onCancelClick,
  onNextClick,
  showCancelButton = true,
  showNextButton = true,
  disableNextButton = false,
  // SubHeader Props
  showSubHeader = true,
  showBackButton = false,
  showSubHeaderCancelButton = true,
  backText = '',
  allowBackButtonClick = false,
  // Device Card Required Prop - If 'true' then all Device Card Props are required
  showDeviceCard,
  // Device Card Props
  assetId,
  programmeNameLBL,
  clientOfferName,
  countryCallingCode,
  mobileDeviceNumber,
  assetCatalogName,
  imei,
  assetFamily,
  // Extra Props
  children,
}) => {
  // Theme
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { accent } = colors;

  // Media Query
  const [isDesktop] = useMediaQuery('(min-width: 850px)');

  // SR Close Popup
  const {
    isOpen: isCancelSrPopupOpen,
    onClose: closeCancelSrPopup,
    onOpen: openCancelSrPopup,
  } = useDisclosure();

  // Global State
  const globalState = useCentralStore();

  // Get Asset Image
  const getDeviceImage = (assetFamily: string) => {
    return (
      <Image
        height={'80px'}
        marginTop={'22px'}
        alignSelf={'center'}
        src={getDeviceImageByModel(globalState?.state?.deviceImages, `${assetFamily}`, '', 80)}
      />
    );
  };

  // Get Device Card
  const getDeviceCard = (props: DeviceCardProps) => {
    const {
      assetId,
      programmeNameLBL,
      clientOfferName,
      countryCallingCode,
      mobileDeviceNumber,
      assetCatalogName,
      imei,
      assetFamily,
    } = props;

    return (
      <DeviceCard
        type=""
        status=""
        assetId={assetId}
        plan={programmeNameLBL || clientOfferName}
        mdn={`(${countryCallingCode}) ${mobileDeviceNumber}`}
        model={assetCatalogName}
        imei={imei}
        imageEl={getDeviceImage(assetFamily)}
      />
    );
  };

  return (
    <Stack>
      <Box id={'myasurion-jb-dpl-1'} paddingTop={'54px'}>
        <MainStackLayout
          id={'myasurion-jb-dpl-ms-1'}
          gap={'16px'}
          spacing={0}
          w="full"
          marginX={'auto'}
        >
          {showSubHeader && (
            <SubHeader
              id={'myasurion-jb-dpl-sh-1'}
              backText={backText}
              allowBackButtonClick={allowBackButtonClick}
              showCancelButton={showSubHeaderCancelButton}
              showBackButton={showBackButton}
              cancelText={cancelText}
              onCancelClick={() => {
                openCancelSrPopup();
              }}
            />
          )}
          {headerText ?
          <Box paddingX={'16px'} id={'myasurion-jb-dpl-bx-1'} marginX={isDesktop ? '20%' : '0%'}>
            <Text fontSize={'2rem'}>
              <TextScript text={headerText} translationKey={headerTranslationKey} />
            </Text>
          </Box>
          : null }

          <Center id={'myasurion-jb-dpl-dc-1'}>
            {showDeviceCard &&
              getDeviceCard({
                assetId,
                programmeNameLBL,
                clientOfferName,
                countryCallingCode,
                mobileDeviceNumber,
                assetCatalogName,
                imei,
                assetFamily,
              })}
          </Center>

          {children}

          {showNextButton || showCancelButton ? (
          <Center id={'myasurion-jb-dpl-cen-1'}>
            <Flex
              w="90%"
              m={'16px 0px'}
              direction={{ base: 'column', lg: 'row-reverse' }}
              justifyContent={{ base: 'start', lg: 'space-evenly' }}
            >
              {showNextButton && (
                <Button
                  id={'myasurion-jb-dpl-bt-1'}
                  py={3}
                  px={5}
                  colorScheme="primary"
                  color="white"
                  w={{ base: 'full', lg: '10%' }}
                  onClick={onNextClick}
                  isDisabled={disableNextButton}
                >
                  <TextScript text={nextBtnText} translationKey={nextBtnTranslationKey} />
                </Button>
              )}
              {showCancelButton && (
                <Button
                  id={'myasurion-jb-dpl-bt-2'}
                  colorScheme={'black'}
                  color={accent}
                  variant="link"
                  as={'u'}
                  fontWeight={700}
                  height="48px"
                  w={{ base: 'full', lg: '10%' }}
                  textAlign={'center'}
                  cursor={'pointer'}
                  onClick={onCancelClick}
                >
                  <TextScript text={cancelBtnText} translationKey={cancelBtnTranslationKey} />
                </Button>
              )}
            </Flex>
          </Center>
          ) : null}
        </MainStackLayout>
        <CancelSrPopup
          closeCancelPopup={closeCancelSrPopup}
          openCancelPopup={isCancelSrPopupOpen}
        />
      </Box>
    </Stack>
  );
};

export default JBDefaultPageLayout;
