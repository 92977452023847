import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogOverlay, AlertDialogHeader, AlertDialogCloseButton, Image, Text, VStack, useMediaQuery } from "@chakra-ui/react";
import React, { useRef } from 'react';
import MiniSmartphoneProblemSVG from '../../../icons/MiniSmartphoneProblem.svg';

interface UnableToProceedAlertProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UnableToProceedAlert: React.FC<UnableToProceedAlertProps> = ({ isOpen, onClose }) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  // Media Query
  const [isDesktop] = useMediaQuery('(min-width: 850px)');

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          width={isDesktop ? '50%' : '80%'}
          borderRadius='8px'
          display='inline-flex'
          padding='16px'
          flexDirection='column'
          alignItems='center'
          gap='15px'
        >
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <VStack spacing={4} align="center">
              <Image 
                src={MiniSmartphoneProblemSVG} 
                alt="Text Mobile" 
                boxSize="150px"
                p="5px 18.157px 6.317px 14.34px"
              />
              <Text 
                fontFamily="'Apercu Pro'"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="130%" // 20.8px
              >
                You are not eligible for this upgrade.
              </Text>
            </VStack>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}