/* eslint-disable no-console */
import {
    BackButton,
    DesktopView,
    MobileView,
    TextScript,
    getTextScript,
} from '@MGPD/myasurion-shared';
import { useContentfulEntry } from '@MGPD/myasurion-shared/hooks';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
} from '@chakra-ui/accordion';
import { Button } from '@chakra-ui/button';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Center, Spinner, useBoolean, useDisclosure } from '@chakra-ui/react';
import { Textarea } from '@chakra-ui/textarea';
import { useToast } from '@chakra-ui/toast';
import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { ChatOverlay } from '../components/ChatOverlay';
import MenuSidebar from '../components/MenuSidebar';
import FooterLayout from '../layout/FooterLayout';
import MainStackLayout from '../layout/MainStackLayout';
import { feedbackErrorMessage, feedbackSuccessMessage, labels } from '../modules/config/constants';
import { defaultToastProps } from '../modules/config/constants';
import { submitFeedback } from '../modules/services/apiService';

export interface FeedbackOption {
    fields: {
        carriers: Array<string>;
        header: string;
        id: string;
        slug: string;
        subheader: string;
        subject: string;
        label: string;
        type: string;
    };
}
const getValueFromJson = (keyToQuery: string, jsonData: Array<{ [key: string]: string }>) => {
    for (const item of jsonData) {
        if (keyToQuery in item) {
            return item[keyToQuery];
        }
    }
    return null;
};

const HeadingText = () => {
    const { heading, caption1 } = labels && labels.feedback;
    return (
        <Flex
            flexFlow="column nowrap"
            justifyContent="center"
            alignItems="flex-start"
            gap={{ base: '4px', md: '20px' }}
            p={{ base: 4, md: 0 }}
            mb={{ md: '20px' }}
        >
            <Text fontWeight={{ base: 400, md: 300 }} fontSize={{ base: '24px', md: '40px' }}>
                {/* <TextTranslation
          text={heading}
          translationKey={'feedback_Heading'}
          module={'myAsurionProfile'}
          section={'feedback'}
        /> */}
                <TextScript text={heading} translationKey={`Feedback/feedback_Heading`} />
            </Text>
            <Text fontWeight="400" fontSize={{ base: '16px', md: '18px' }}>
                {/* <TextTranslation
          text={caption1}
          translationKey={'feedback_caption'}
          module={'myAsurionProfile'}
          section={'feedback'}
        /> */}
                <TextScript text={caption1} translationKey={`Feedback/feedback_caption`} />
            </Text>
        </Flex>
    );
};

const FooterSection = ({
    labels,
    selectedOption,
    isSubmitting,
    onSubmit,
}: {
    labels: Array<{ [key: string]: string }>;
    selectedOption: {
        slug: any;
        label: string;
        type: string;
    };
    isSubmitting: boolean;
    onSubmit: () => void;
}) => {
    return (
        <FooterLayout
            border={{ md: 'none' }}
            mt={{ md: '18px' }}
            p={{ md: 0 }}
            textAlign={{ md: 'center' }}
        >
            {selectedOption && selectedOption.label && (
                <Button
                    w={{ base: 'full', md: '340px' }}
                    colorScheme="primary"
                    mt={{ base: '16px', md: 0 }}
                    isLoading={isSubmitting}
                    onClick={onSubmit}
                    id={selectedOption.label}
                >
                    {/* {isFeatureEnabled(FEATURES.TRANSLATOR) ? (
            <TextTranslation
              text={selectedOption.label}
              translationKey={`feedbackButtonSelected-${selectedOption.label}`}
              module={'myAsurionProfile'}
              section={'feedback'}
            />
          ) : (
            <>{selectedOption.label}</>
          )} */}
                    {/* <TextScript
            text={getValueFromJson(selectedOption.slug, labels)}
            translationKey={`Feedback/feedbackButtonSelected-${selectedOption.label}`}
          /> */}
                    <Text>{getValueFromJson(selectedOption.slug, labels)}</Text>
                </Button>
            )}
        </FooterLayout>
    );
};

const FeedbackPage = () => {
    const { title } = labels && labels.feedback;
    const [selectedOption, setSelectedOption] = useState({
        label: '',
        type: '',
        slug: '',
    });
    const [feedbackOptionsContentful, setFeedbackOptionsContentful] = useState<
        Array<FeedbackOption>
    >([]);
    const [resetTextArea, setResetTextArea] = useState(false);

    const [feedback, setFeedback] = useState<{ type: string; details: string }>({
        type: '',
        details: '',
    });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isMinimized, setToMinimize] = useBoolean();
    const [buttonLabels, setButtonLabels] = useState<any>([]);
    const [textAreaPlaceholders, setTextAreaPlaceholders] = useState<{ [key: string]: string }[]>(
        []
    );
    const [showFooter, setShowFooter] = useState<boolean>(true);
    const toast = useToast();

    const { mutate: sendFeedback, isLoading: isSubmitting } = useMutation({
        mutationFn: submitFeedback,
        onSuccess: (data) => {
            if (data && data.statusCode === 200) {
                toast({
                    ...defaultToastProps,
                    title: feedbackSuccessMessage[feedback.type].title,
                    description: feedbackSuccessMessage[feedback.type].description,
                });
                setFeedback({ type: '', details: '' });
                setResetTextArea(true);
            } else {
                toast({
                    ...defaultToastProps,
                    title: feedbackErrorMessage[feedback.type].title,
                    description: feedbackErrorMessage[feedback.type].description,
                });
            }
        },
        onError: () => {
            toast({
                ...defaultToastProps,
                title: feedbackErrorMessage[feedback.type].title,
                description: feedbackErrorMessage[feedback.type].description,
            });
        },
    });

    const onTextAreaChange = useCallback(({ type, value }: { type: string; value: string }) => {
        if (value && type) {
            setFeedback({ type: type, details: value });
        }
    }, []);

    const onSubmit = useCallback(() => {
        if (feedback.type && feedback.details) {
            sendFeedback(feedback);
        }

        if (selectedOption.type.toLowerCase() === 'chat') {
            isMinimized ? setToMinimize.toggle() : onOpen();
        }
    }, [feedback, isMinimized, setToMinimize, selectedOption.type, sendFeedback, onOpen]);

    const spaceIdRef = import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_SPACE_ID_REF;
    const accessTokenRef = import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_ACCESS_TOKEN_REF;
    const environmentRef = import.meta.env.VITE_REMOTE_CONFIG_CONTENTFUL_ENVIRONMENT_REF;
    const firebaseConfig = {
        apiKey: import.meta.env.VITE_REMOTE_CONFIG_API_KEY,
        authDomain: import.meta.env.VITE_REMOTE_CONFIG_AUTH_DOMAIN,
        projectId: import.meta.env.VITE_REMOTE_CONFIG_PROJECT_ID,
        storageBucket: import.meta.env.VITE_REMOTE_CONFIG_STORAGE_BUCKET,
        messagingSenderId: import.meta.env.VITE_REMOTE_CONFIG_MESSAGING_SENDER_ID,
        appId: import.meta.env.VITE_REMOTE_CONFIG_APP_ID,
        measurementId: import.meta.env.VITE_REMOTE_CONFIG_MEASUREMENT_ID,
    };

    const { contentfulEntry } = useContentfulEntry(
        {
            select: 'fields',
            'fields.carriers[all]': 'MyAsurion Base',
            locale: 'en-US',
        },
        'genericHeaderdescriptionadditionalText',
        spaceIdRef,
        accessTokenRef,
        environmentRef,
        firebaseConfig,
        true
    );

    useEffect(() => {
        if (contentfulEntry.length > 0) {
            const sortedArray = contentfulEntry.sort(
                (a: { fields: { type: string } }, b: { fields: { type: string } }) => {
                    if (a.fields.type === 'Chat' && b.fields.type !== 'Chat') {
                        return 1;
                    }
                    if (b.fields.type === 'Chat' && a.fields.type !== 'Chat') {
                        return -1;
                    }
                    return 0;
                }
            );

            setFeedbackOptionsContentful(sortedArray);
            if (sortedArray.length > 0) {
                setSelectedOption({
                    label: sortedArray[0].fields.label,
                    type: sortedArray[0].fields.type,
                    slug: sortedArray[0].fields.slug,
                });
            }
        }
    }, [contentfulEntry]);

    //comment
    const getButtonLabelAndPlaceholders = async () => {
        if (feedbackOptionsContentful.length > 0) {
            const tempPlaceHolders = feedbackOptionsContentful.map((feedback) => {
                return { [feedback.fields.slug]: feedback.fields.subheader };
            });
            const tempButtonLabels = feedbackOptionsContentful.map((feedback) => {
                return { [feedback.fields.slug]: feedback.fields.label };
            });
            setTextAreaPlaceholders(tempPlaceHolders);
            setButtonLabels(tempButtonLabels);
            try {
                const labelArray = await Promise.all(
                    feedbackOptionsContentful.map(async (feedback) => {
                        const tempButtonLabel = await getTextScript(
                            `Feedback/feedbackBack-option-${feedback.fields.slug}`,
                            feedback.fields.label
                        );

                        return { [feedback.fields.slug]: tempButtonLabel };
                    })
                );

                const placeholderArray = await Promise.all(
                    feedbackOptionsContentful.map(async (feedback) => {
                        const tempTextAreaPlaceholder = await getTextScript(
                            `Feedback/feedbackBack-textarea-placeholder-${feedback.fields.slug}`,
                            feedback.fields.subheader
                        );
                        return { [feedback.fields.slug]: tempTextAreaPlaceholder };
                    })
                );

                setButtonLabels(labelArray);
                setTextAreaPlaceholders(placeholderArray);
                setShowFooter(true);
            } catch (error) {
                // Handle any errors that occur during the asynchronous operations
                console.error('Error:', error);
            }
        }
    };

    useEffect(() => {
        getButtonLabelAndPlaceholders();
    }, [feedbackOptionsContentful]);

    return (
        <Box id={'myasurion-profile-feedback'} paddingTop={'54px'} h={'100vh'}>
            <MobileView>
                <MainStackLayout h={'auto'} gap={0}>
                    <BackButton isProfile>
                        {/* <TextTranslation
              text={title}
              translationKey={`feedbackBack-${title}`}
              module={'myAsurionProfile'}
              section={'feedback'}
            /> */}
                        <TextScript
                            text={title}
                            translationKey={`Feedback/feedbackBack-${title}`}
                        />
                    </BackButton>
                    <Box w={'full'} minH={'200px'} m={'0'} minW={'350px'} p={0}>
                        <HeadingText />
                        {showFooter ? (
                            <Accordion
                                width="100%"
                                allowToggle
                                allowMultiple={false}
                                defaultIndex={0}
                            >
                                {feedbackOptionsContentful.map((faq, fi) => {
                                    return (
                                        <AccordionItem key={fi} py="2">
                                            {({ isExpanded }) => {
                                                return (
                                                    <>
                                                        <AccordionButton
                                                            _expanded={{
                                                                fontWeight: '700',
                                                                background: 'inherit',
                                                            }}
                                                            _hover={{ background: 'inherit' }}
                                                            background="inherit"
                                                            onClick={() =>
                                                                setSelectedOption({
                                                                    label: faq.fields.label,
                                                                    type: faq.fields.type,
                                                                    slug: faq.fields.slug,
                                                                })
                                                            }
                                                        >
                                                            <Box
                                                                as="span"
                                                                flex="1"
                                                                textAlign="left"
                                                                fontSize="16px"
                                                                fontWeight={
                                                                    isExpanded ? '700' : '300'
                                                                }
                                                                lineHeight="23.76px"
                                                            >
                                                                {/* <Text>
                                              <TextTranslation
                                                text={faq.fields.header}
                                                translationKey={`${faq.fields.header}-${fi}`}
                                                module={'myAsurionProfile'}
                                                section={'feedback'}
                                              />
                                            </Text> */}
                                                                <TextScript
                                                                    text={title}
                                                                    translationKey={`Feedback/${faq.fields.header}-${fi}`}
                                                                />
                                                            </Box>
                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                        <AccordionPanel>
                                                            {/* {faq.opts.element ? (
                                            <FeedbackTextArea
                                              placeholder={faq.opts.placeholder || ''}
                                              limit={faq.opts.limit}
                                              type={faq.opts.type}
                                              onChange={onTextAreaChange}
                                              isReset={resetTextArea}
                                              onReset={() => {
                                                setResetTextArea(false);
                                              }}
                                            />
                                          ) : (
                                            faq.opts.placeholder
                                          )} */}
                                                            {faq.fields.subject ? (
                                                                <>
                                                                    <FeedbackTextArea
                                                                        placeholder={
                                                                            getValueFromJson(
                                                                                faq.fields.slug,
                                                                                textAreaPlaceholders
                                                                            ) || ''
                                                                        }
                                                                        limit={200}
                                                                        type={faq.fields.subject}
                                                                        onChange={onTextAreaChange}
                                                                        isReset={resetTextArea}
                                                                        onReset={() => {
                                                                            setResetTextArea(false);
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                faq.fields.subheader
                                                            )}
                                                        </AccordionPanel>
                                                    </>
                                                );
                                            }}
                                        </AccordionItem>
                                    );
                                })}
                            </Accordion>
                        ) : (
                            <Flex
                                w={'full'}
                                mb={'20px'}
                                justifyContent="center"
                                direction="column"
                                alignItems="center"
                            >
                                Fetching translations...
                                <Spinner />
                            </Flex>
                        )}
                    </Box>
                </MainStackLayout>
                {showFooter ? (
                    <FooterSection
                        labels={buttonLabels}
                        selectedOption={selectedOption}
                        onSubmit={onSubmit}
                        isSubmitting={isSubmitting}
                    />
                ) : (
                    <></>
                )}
            </MobileView>

            <DesktopView>
                <Center>
                    <MainStackLayout
                        gap={'20px'}
                        spacing={0}
                        maxW={'1024px'}
                        direction={'row'}
                        mt={'60px'}
                        h={'auto'}
                    >
                        <Box w={'full'} maxWidth={'280px'} p={0} m={0}>
                            <MenuSidebar activePage="feedback" />
                        </Box>
                        <Box w={'full'} p={0} m={0}>
                            <HeadingText />

                            {showFooter === false ? (
                                <Flex
                                    w={'full'}
                                    mb={'20px'}
                                    justifyContent="center"
                                    direction="column"
                                    alignItems="center"
                                >
                                    Fetching translations...
                                    <Spinner />
                                </Flex>
                            ) : (
                                <>
                                    <Box w={'full'} mb={'20px'}>
                                        {feedbackOptionsContentful.map((faq, fi) => {
                                            return (
                                                <Button
                                                    variant={
                                                        faq.fields.slug === selectedOption.slug
                                                            ? 'solid'
                                                            : 'outline'
                                                    }
                                                    key={`feedback-option-btn-${fi}`}
                                                    m={'8px 8px 8px 0'}
                                                    colorScheme="primary"
                                                    onClick={() =>
                                                        setSelectedOption({
                                                            label: faq.fields.label,
                                                            type: faq.fields.type,
                                                            slug: faq.fields.slug,
                                                        })
                                                    }
                                                >
                                                    <TextScript
                                                        text={faq.fields.header}
                                                        translationKey={`Feedback/${faq.fields.slug}`}
                                                    />
                                                </Button>
                                            );
                                        })}
                                        {feedbackOptionsContentful.map((faq, fi) => {
                                            return (
                                                <Box
                                                    w={'full'}
                                                    m={0}
                                                    key={`element-feedback-${fi}`}
                                                >
                                                    {faq.fields.type.toLowerCase() !== 'chat' &&
                                                    faq.fields.slug === selectedOption.slug ? (
                                                        <FeedbackTextArea
                                                            placeholder={
                                                                getValueFromJson(
                                                                    faq.fields.slug,
                                                                    textAreaPlaceholders
                                                                ) || ''
                                                            }
                                                            limit={200}
                                                            type={faq.fields.subject}
                                                            onChange={onTextAreaChange}
                                                            isReset={resetTextArea}
                                                            onReset={() => {
                                                                setResetTextArea(false);
                                                            }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {faq.fields.type.toLowerCase() ===
                                                                'chat' &&
                                                            faq.fields.type.toLowerCase() ===
                                                                selectedOption.type.toLowerCase() ? (
                                                                // <Text fontSize={'16px'} fontWeight={400} maxW={'638px'}>
                                                                //   {faq.fields.subheader}
                                                                // </Text>
                                                                <TextScript
                                                                    text={faq.fields.subheader}
                                                                    translationKey={`Feedback/subheader-${faq.fields.slug}`}
                                                                />
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    )}
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                    {showFooter ? (
                                        <FooterSection
                                            labels={buttonLabels}
                                            selectedOption={selectedOption}
                                            onSubmit={onSubmit}
                                            isSubmitting={isSubmitting}
                                        />
                                    ) : (
                                        <Flex
                                            w="full"
                                            justifyContent="center"
                                            direction="column"
                                            alignItems="center"
                                        >
                                            Fetching translations...
                                            <Spinner />
                                        </Flex>
                                    )}
                                </>
                            )}
                        </Box>
                    </MainStackLayout>
                </Center>
            </DesktopView>
            <ChatOverlay
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
                onMinimize={setToMinimize.toggle}
                isMinimized={isMinimized}
            />
        </Box>
    );
};

const FeedbackTextArea = ({
    placeholder,
    type,
    limit,
    isReset,
    isSubmitting,
    onChange,
    onReset,
}: {
    type: string;
    placeholder?: string;
    limit?: number;
    isReset?: boolean;
    isSubmitting?: boolean;
    onChange?: ({ type, value }: { type: string; value: string }) => void;
    onReset?: () => void;
}) => {
    const [value, setValue] = useState('');

    const changeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const i = e.target.value;
        const parsedValue = limit && i.length >= limit ? i.slice(0, limit) : i;
        setValue(parsedValue);
        onChange && onChange({ type, value: parsedValue });
    };

    useEffect(() => {
        if (isReset) {
            setValue('');
            onReset && onReset();
        }
    }, [isReset, onReset]);

    return (
        <Flex flexFlow="column nowrap" justifyContent="center" alignItems="center" gap="4px">
            <Textarea
                value={value}
                onChange={changeHandler}
                placeholder={placeholder}
                size="md"
                lineHeight={'130%'}
                fontWeight={400}
                h="160px"
                background="inherit"
                isDisabled={isSubmitting}
            />
            {limit && (
                <Text alignSelf="flex-end" padding="0 12px" fontSize="14px" color="#6E767D">
                    {limit >= value.length ? value.length + '/' + limit : `${limit}/${limit}`}
                </Text>
            )}
        </Flex>
    );
};

export default FeedbackPage;
