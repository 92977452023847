import { Box, BoxProps, ButtonProps, Heading, Stack, Text } from '@chakra-ui/react';

export interface DeviceServiceButtonProps {
    label: string;
    onClick: () => void;
    disable: boolean;
}
export interface DeviceServiceProps {
    tag?: string;
    title: string;
    status: string;
    desc: any | string;
    feeText: string;
    deviceServiceButtonProps: DeviceServiceButtonProps;
}

export const DeviceService = ({
    title,
    desc,
    feeText,
    deviceServiceButtonProps,
    status,
    ...props
}: DeviceServiceProps & ButtonProps & BoxProps) => {
    return (
        <Box
            id={'myasurion-deviceservice'}
            borderRadius={'1px'}
            borderWidth={'1px'}
            borderStyle={'solid'}
            borderColor={'gray.300'}
            bg={'gray.200'}
            px={'16px'}
            py={4}
            w={'full'}
            {...props}
        >
            <Stack gap={0}>
                {props.tag && (
                    <Box m={'0'} mb={'15px'}>
                        <Box m={0} p={'4px'} maxW={'120px'} bg={'#FFFFFF'} borderRadius={'4px'}>
                            <Text fontSize={'xs'}>{props.tag}</Text>
                        </Box>
                    </Box>
                )}
                {title && (
                    <Heading fontSize={'16px'} fontWeight={'bold'}>
                        {title}
                    </Heading>
                )}
                {desc && (
                    <Text style={status === 'completed' ? { display: 'none' } : {}}>{desc}</Text>
                )}
            </Stack>
        </Box>
    );
};

export default DeviceService;
