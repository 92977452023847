import { useEffect, useRef } from 'react';
import { Box, Button, useToast } from '@chakra-ui/react';
import { GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker';
import { useConfig } from '@MGPD/myasurion-shared';
import { getDocument, PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';

GlobalWorkerOptions.workerSrc = pdfjsWorker;

const LabelViewer = ({ pdfLabelBase64 }: { pdfLabelBase64: string }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const toast = useToast();

  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { primary } = colors;

  useEffect(() => {
    if (pdfLabelBase64) {
      renderPdf(pdfLabelBase64);
    }
  }, [pdfLabelBase64]);

  const renderPdf = (pdfdata: string) => {
    const pdfData = atob(pdfdata);
    const loadingTask = getDocument({ data: pdfData });

    loadingTask.promise.then(
      (pdf: PDFDocumentProxy) => {
        pdf.getPage(1).then((page: PDFPageProxy) => {
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = canvasRef.current;
          const context = canvas?.getContext('2d');
          if (!canvas || !context) {
            console.error('Unable to obtain canvas context');
            toast({
              title: 'Rendering Error',
              description: 'Unable to obtain canvas context for PDF rendering.',
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
            return;
          }
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          page.render(renderContext).promise.then(() => {
            // Rendering completed.
          });
        });
      },
      (reason: any) => {
        console.error('Error rendering PDF:', reason);
        toast({
          title: 'Error rendering PDF',
          description: reason?.message || 'Unknown error occurred',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    );
  };

  return (
    <Box
      p="22px 32px 22px 32px"
      boxShadow="lg"
      rounded="md"
      bg="white"
      mx="auto"
      mt={10}
      borderRadius="8px"
      border="1px solid black"
      borderColor={'gray.300'}
    >
      <>
        <canvas style={{ width: '274.18px', height: '403px' }} // Add the desired height here
          ref={canvasRef} />
        <Button
          as="a"
          textAlign={'center'}
          colorScheme={'primary'}
          p={'12px 20px'}
          width={'full'}
          px={{ base: '20px', lg: '60px' }}
          mt={{ base: '16px', lg: '0px' }}
          href={`data:application/pdf;base64,${pdfLabelBase64}`}
          download="label.pdf"
          style={{
            background: primary,
            color: 'white',
          }}
        >
          Download Postage Label
        </Button>
      </>
    </Box>
  );
};

export default LabelViewer;