/* eslint-disable no-console */
import { SelectCapacity, SubHeader, getTypeByDomain } from '@MGPD/myasurion-shared';
import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import MainStackLayout from '../../layout/MainStackLayout';
import '../../services/api';
import ActionTypes from '../../store/actionTypes.ts';
import { useCentralStore } from '../../store/appContext.tsx';
import { getRedirectUrl } from '../../utils/utills.tsx';
import CancelSrPopup from '../myclaims/CancelSrPopup.tsx';

function DeviceCapacityUpdates() {
    const globalState = useCentralStore();
    const navigate = useNavigate();
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [capacityVal, setCapacityVal] = useState<string>('');

    let filterDevices = globalState?.state?.imeiDeviceDetails;
    // if (simType === 'WIFI') {
    //     filterDevices = deviceDetails?.DeviceDetails?.filter(item => {
    //         return item.AssetMakeName === selectedAgreementDetails.DeviceMake && item.AssetModelNbr === selectedAgreementDetails.Model
    //     })
    // }

    let deviceCapacity = [...new Set(filterDevices.map((item: any) => item.Capacity))] || [];
    console.log('deviceCapacity', deviceCapacity);

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };
    console.log(setLoading);

    const onCancelClick = () => {
        // setOpenCancelPopup(!openCancelPopup);
        window.location.href = getRedirectUrl(getTypeByDomain());
    };

    const handleNext = () => {
        navigate('/updateColor');
    };

    const setCapacity = (e: any) => {
        console.log('setCapacity----', e);
        setCapacityVal(e);
        globalState?.dispatch({ type: ActionTypes.SET_UPDATE_DEVICE_CAPACITY, payload: e });
    };

    return (
        <Box>
            <Box
                w={{ base: 'full', lg: '80%' }}
                margin="auto"
                pb={{ base: 0, lg: '150px' }}
                id={'myasurion-profile-dashboard'}
                paddingTop={'54px'}
            >
                <MainStackLayout gap={0} spacing={0}>
                    <SubHeader
                        backText={''}
                        allowBackButtonClick={false}
                        showCancelButton={true}
                        showBackButton={false}
                        cancelText={Scripts.CancelSR.cancelBtnTextCancel}
                        onCancelClick={onCancelClick}
                    ></SubHeader>
                    <Box
                        id={'myasurion-profile-avatar'}
                        w={{ base: 'full', lg: '80%' }}
                        margin="0 auto"
                        h={'auto'}
                        p={'10px'}
                    >
                        <SelectCapacity
                            storageOptions={deviceCapacity}
                            selectedCapacity={capacityVal}
                            modelName={filterDevices?.[0]?.Family}
                            onClickCallback={(e) => setCapacity(e)}
                            handleNext={() => handleNext()}
                            isDisabled={capacityVal !== '' ? false : true}
                        />
                        {/* <SelectColor 
                colorOptions={['RED', 'BLUE', 'WHITE', 'BLACK']}
                selectedColor='RED'
                modelName='Apple, iPhone 13 Pro Max'
                onClickCallback={(val) => {
                    console.log('Selected Color:', val);
                }}
            /> */}
                    </Box>
                </MainStackLayout>
                <CancelSrPopup
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
        </Box>
    );
}

export default DeviceCapacityUpdates;
