/* eslint-disable no-console */
import { RenewalConfirmation, SubHeader, TextScript, getTextScript } from '@MGPD/myasurion-shared';
import { CloseIcon } from '@chakra-ui/icons';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogOverlay,
    Box,
    Image,
    Text,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import VisaIconSVG from '../../icons/Visa.svg';
import MainStackLayout from '../../layout/MainStackLayout';
import '../../services/api';
import { getPCISecurityTokenNew, getPaymentDetails, saveRenewalDetails } from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { getCurrencySymbol } from '../../utils/localization-helper.tsx';
import { getDateAndDay, saveError, showErrorPage } from '../../utils/utills.tsx';

function RenewalConfirmationComp() {
    const RenewalConfirmationScrips = Scripts.Renewal.RenewalConfirmation;
    const globalState = useCentralStore();
    const navigate = useNavigate();

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;

    const cancelRef = React.useRef<any>();

    const [PCITokenError, setPCITokenError] = useState<any>(false);
    // const [programmeNameLBL, setProgrammeNameLBL] = useState('')
    const [techCare12Months, setTechCare12Months] = useState('TechCare 12 Months');
    const [techCareMonthly, setTechCareMonthly] = useState('TechCare Monthly');
    const [assetData, setAssetData] = useState<any>();
    const [paymentDetailsResp, setPaymentDetails] = useState<any>();
    const [currentPaymentod, setCurrentPaymentod] = useState<any>();
    // const [billingHistory, setBillingHistoryData] = useState<any>();
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [enrollmentFee, setEnrollmentFee] = useState<any>();
    const [discountAmount, setDiscountAmount] = useState<any>();
    const [taxAmount, setTaxAmount] = useState<any>();
    const [totalAmount, setTotalAmount] = useState<any>();
    const [CCNotAddedErr, setCCNotAddedErr] = useState<boolean>(false);
    const [renewalDetailsData, setRenewalDetailsData] = useState<any>();
    const [prevTotalAmount, setPrevTotalAmount] = useState<any>(null);

    const handleSwitchClick = () => {
        setPrevTotalAmount(enrollmentFee);
        globalState?.dispatch({
            type: ActionTypes.SET_RENEWAL_OPTION,
            payload: { name: 'Renew term payment' },
        });
    };

    useEffect(() => {
        const renewalDetails =
            globalState?.state?.renewalSelectedOption?.name === 'Renew term payment'
                ? globalState?.state?.renewalGetQuoteDetails?.['TechCare_SwapOnly_12Month']
                : globalState?.state?.renewalGetQuoteDetails?.['TechCare_SwapOnly_Monthly'];
        setRenewalDetailsData(renewalDetails);
        if (globalState?.state?.renewalSelectedOption?.name === 'Renew term payment') {
            const monthlyDetails =
                globalState?.state?.renewalGetQuoteDetails?.['TechCare_SwapOnly_Monthly'];
            setPrevTotalAmount(monthlyDetails?.enrollmentFee * 1.07);
            let annualFee = renewalDetails?.enrollmentFee * 12 * 1.07;
            let discount = null;
            if (agreementData1?.ClientAccountBillingCycle?.toUpperCase() === 'MONTHLY') {
                discount =
                    agreementData1?.EnrolledMonths < 12
                        ? renewalDetails?.PromotionalOffer?.filter(
                              (v: any) => v?.promotionalOfferCode === 'ANNUAL DISCOUNT'
                          )?.map((v: any) => v?.discountValue)
                        : globalState?.state?.renewalAnnualDiscount;
            } else {
                discount = globalState?.state?.renewalAnnualDiscount;
            }

            let _enrollmentFee = discount?.reduce((total: any, v: any) => {
                total = total - total * (v / 100);
                return total;
            }, annualFee);

            let taxAmount = discount?.reduce((total: any, v: any) => {
                total = total - total * (v / 100);
                return total;
            }, annualFee - renewalDetails?.enrollmentFee * 12);
            setTaxAmount(taxAmount);
            setDiscountAmount(annualFee - _enrollmentFee);
            setEnrollmentFee(annualFee);
            setTotalAmount(_enrollmentFee);
        } else {
            let monthlyFee = renewalDetails?.enrollmentFee * 1.07;
            let _enrollmentFee = 0;
            let discountMonnthly = null;
            if (agreementData1?.ClientAccountBillingCycle?.toUpperCase() === 'MONTHLY') {
                discountMonnthly =
                    agreementData1?.EnrolledMonths < 12
                        ? renewalDetails?.PromotionalOffer?.filter(
                              (v: any) => v?.promotionalOfferCode === 'ANNUAL DISCOUNT'
                          )?.map((v: any) => v?.discountValue)
                        : globalState?.state?.renewalMonthlyDiscount;
            } else {
                discountMonnthly = globalState?.state?.renewalMonthlyDiscount;
            }

            if (discountMonnthly?.length > 0) {
                _enrollmentFee = discountMonnthly?.reduce((total: any, v: any) => {
                    total = total - total * (v / 100);
                    return total;
                }, renewalDetails?.enrollmentFee);
            } else {
                _enrollmentFee = renewalDetails?.enrollmentFee;
            }

            let _taxAmount = discountMonnthly?.reduce((total: any, v: any) => {
                total = total - total * (v / 100);
                return total;
            }, monthlyFee - renewalDetails?.enrollmentFee);

            setTaxAmount(_taxAmount);
            setDiscountAmount(discountMonnthly?.length === 0 ? 0 : monthlyFee - _enrollmentFee);
            setEnrollmentFee(monthlyFee);
            setTotalAmount(_enrollmentFee);
        }
    }, [globalState?.state?.renewalSelectedOption?.name]);

    const getCurrency = async () => {
        const currency = await getCurrencySymbol();
        setCurrencySymbol(currency);
    };

    useEffect(() => {
        const assetData1 = agreementData1?.Assets.Asset.filter(
            (a: any) => a.AssetInstance === 'ENROLLED'
        )[0];
        setAssetData(assetData1);
        callPaymentDetailsApi(agreementData1?.ClientAccount?.ClientAccountId);
    }, [agreementData1]);

    const callPaymentDetailsApi = (ClientAccountId: any) => {
        setLoading(true);
        getPaymentDetails(
            ClientAccountId,
            interactionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((resp) => {
                setLoading(false);
                setPaymentDetails(resp?.GetPaymentDetailsResponse);
                globalState?.dispatch({
                    type: ActionTypes.SET_RENEWAL_PAYMENT_DETAILS_RESP,
                    payload: resp?.GetPaymentDetailsResponse,
                });
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        let setBillingHistoryArr: any = [];
        globalState?.state?.renewalPaymentDetails?.PaymentHistory?.map((v: any) => {
            setBillingHistoryArr.push({
                paymentDate: moment(v?.CreatedDate).format('MMM DD YYYY'),
                nextDueDate: v?.NextDueDate,
            });
        });
        // let sortedBillingHistoryArr = setBillingHistoryArr?.sort(function (a: any, b: any) {
        //     return new Date(b.paymentDate).valueOf() - new Date(a.paymentDate).valueOf();
        // })

        // setBillingHistoryData(sortedBillingHistoryArr)
    }, [globalState?.state?.renewalPaymentDetails?.PaymentHistory]);

    useEffect(() => {
        if (paymentDetailsResp?.PaymentMethods) {
            const _currentCCDetails = paymentDetailsResp?.PaymentMethods?.sort(function (
                a: any,
                b: any
            ) {
                return (
                    new Date(b.BillingStartDate).valueOf() - new Date(a.BillingStartDate).valueOf()
                );
            });
            let currentCCDetails = _currentCCDetails?.filter(
                (v: any) =>
                    v?.BillingProfileStatusCode === 'ACTV' &&
                    v?.BillingPaymentTypeCode === 'CRE' &&
                    v?.IsDefault === 'Y'
            )?.[0];
            setCurrentPaymentod(currentCCDetails);
            //   globalState?.dispatch({ type: ActionTypes.SET_RENEWAL_CURRENT_CC_DETAILS, payload: currentCCDetails });
            globalState?.dispatch({
                type: ActionTypes.SET_CURRENT_CC_DETAILS,
                payload: currentCCDetails,
            });
        }
    }, [paymentDetailsResp]);

    function generateUniqueId() {
        return uuid();
    }

    const callPCIToken = () => {
        setPCITokenError(false);
        let GetSecurityTokenRequest = {
            GetSecurityTokenRequest: {
                UseRecurringBillingProgram: true,
                version: '4.5',
                Culture: 'th-TH',
                CurrencyCode: 'THB',
                FirstName: agreementData1?.Customers?.Customer?.[0]?.FirstName,
                LastName: agreementData1?.Customers?.Customer?.[0]?.Lastname,
                Phone: agreementData1?.ContactPoints?.ContactPoint?.[0]?.PhoneNumber,
                Email: agreementData1?.ContactPoints?.ContactPoint?.[0]?.EmailAddress,
                InteractionLineId: interactionLineId,
                AgreementRequestId: undefined,
                AgreementId: agreementData1?.AgreementId,
                BillingProfileId: generateUniqueId().replace(/-/g, '')?.toUpperCase(),
                FeeType: 'ENRFEE',
            },
        };
        setLoading(true);
        globalState?.dispatch({
            type: ActionTypes.SET_MY_PAYMENTS_REF_ID,
            payload: GetSecurityTokenRequest?.GetSecurityTokenRequest?.BillingProfileId,
        });

        getPCISecurityTokenNew(
            GetSecurityTokenRequest,
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((resp) => {
                navigate('/addCard');
                globalState?.dispatch({
                    type: ActionTypes.SET_MY_PAYMENTS_TOKEN_RESP,
                    payload: resp?.GetSecurityTokenResponse,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                saveError(err, globalState);
                setPCITokenError(true);
            });
    };

    const handleSubmit = () => {
        setPCITokenError(false);
        if (currentPaymentod?.CreditCardNumber === undefined) {
            setCCNotAddedErr(true);
        } else {
            setCCNotAddedErr(false);
            let payload = {
                saveRenewalDetailsRequest: {
                    PaymentTerm:
                        globalState?.state?.renewalSelectedOption?.name === 'Renew term payment'
                            ? 'FIXED'
                            : 'MONTHLY',
                    EnrollmentFee: Number(Math.round(enrollmentFee)),
                    DiscountAmount: Number(Math.round(discountAmount)),
                    TaxAmount: Number(Math.round(taxAmount)),
                    TotalAmount: Number(Math.round(totalAmount)),
                    OriginalFee: Math.round(renewalDetailsData?.enrollmentFee),
                },
            };
            // console.log('saveRenewalDetails', payload);
            setLoading(true);
            saveRenewalDetails(
                payload,
                agreementData1?.AgreementId,
                interactionLineId,
                globalState?.state?.findAgreementSessionData
            )
                .then((resp) => {
                    console.log('saveRenewalDetails', resp);
                    setLoading(false);
                    // window.location.href = '/';
                    navigate('/renewalReview');
                })
                .catch((err) => {
                    setLoading(false);
                    saveError(err, globalState);
                    showErrorPage(navigate);
                });
        }
    };

    useEffect(() => {
        getCurrency();
        getFeeText();
        getPrevFeeText();
        getTechCare12MonthsLbl();
        getTechCareMonthlyLbl();
        globalState?.dispatch({ type: ActionTypes.SET_PENDING_PAYMENT_FLAG, payload: false });
    }, []);

    const getTechCare12MonthsLbl = async () => {
        const val = await getTextScript(
            `RenewalConfirmation/techCare12Months`,
            'TechCare 12 Months',
            'en-US',
            []
        );
        setTechCare12Months(val);
    };

    const getTechCareMonthlyLbl = async () => {
        const val = await getTextScript(
            `RenewalConfirmation/techCareMonthly`,
            'TechCare Monthly',
            'en-US',
            []
        );
        setTechCareMonthly(val);
    };

    const onCancelClick = () => {
        navigate('/renewal');
    };

    const [feeText, setFeeText] = useState<string>('');

    const getFeeText = async () => {
        let id =
            globalState?.state?.renewalSelectedOption?.name === 'Renew term payment'
                ? `RenewalConfirmation/renewalFeeTextYear`
                : (`RenewalConfirmation/renewalFeeTextMonth` as `${string}/${string}`);
        const defaultText =
            globalState?.state?.renewalSelectedOption?.name === 'Renew term payment'
                ? '/year'
                : '/month';
        const val = await getTextScript(id, defaultText, 'en-US', []);
        setFeeText(val);
    };

    const [prevFeeText, setPrevFeeText] = useState<string>('');

    const getPrevFeeText = async () => {
        const val = await getTextScript(
            `RenewalConfirmation/renewalPrevFeeText`,
            '/month',
            'en-US',
            []
        );
        setPrevFeeText(val);
    };

    return (
        <Box>
            <Box
                w={{ base: 'full', lg: '80%' }}
                margin="auto"
                pb={{ base: 0, lg: '150px' }}
                id={'myasurion-profile-dashboard'}
                paddingTop={'54px'}
            >
                <MainStackLayout gap={0} spacing={0}>
                    <Box
                        id={'myasurion-profile-avatar'}
                        w={{ base: 'full', lg: '80%' }}
                        margin="0 auto"
                        h={'auto'}
                        p={'10px'}
                    >
                        <SubHeader
                            backText={'Back'}
                            allowBackButtonClick={true}
                            showCancelButton={false}
                            showBackButton={true}
                            cancelText={Scripts.CancelSR.cancelBtnTextCancel}
                            onCancelClick={onCancelClick}
                            onClick={onCancelClick}
                        ></SubHeader>
                        <RenewalConfirmation
                            heading={RenewalConfirmationScrips.heading}
                            // plan={programmeNameLBL || agreementData1?.ClientOffer?.ClientOfferName}
                            plan={
                                globalState?.state?.renewalSelectedOption?.name ===
                                'Renew term payment'
                                    ? techCare12Months
                                    : techCareMonthly
                            }
                            mdn={`${assetData?.MobileDeviceNumber}`}
                            priceLBL={RenewalConfirmationScrips.priceLBL}
                            // priceValue={agreementData1?.ClientAccountBillingCycle !== "YEARLY" && billingHistory?.[0]?.TotalAmount && billingHistory?.[0]?.ChargeOrderTypeCode === 'ENRFEE' ? '$' + billingHistory?.[0]?.TotalAmount : '-'}
                            // priceValue={currencySymbol + Number(Math.round(totalAmount))?.toString() + (globalState?.state?.renewalSelectedOption?.name === 'Renew term payment' ? '/year' : '/month')}
                            priceValue={
                                currencySymbol +
                                Number(Math.round(enrollmentFee - discountAmount))?.toString() +
                                feeText
                            }
                            prevPriceValue={
                                prevTotalAmount === null
                                    ? ''
                                    : currencySymbol +
                                      Number(Math.round(prevTotalAmount))?.toString() +
                                      prevFeeText
                            }
                            nextBillingDateLBL={RenewalConfirmationScrips.nextBillingDateLBL}
                            // nextBillingDateValue={agreementData1?.ClientAccountBillingCycle !== "YEARLY" && billingHistory?.[0]?.nextDueDate && billingHistory?.[0]?.ChargeOrderTypeCode === 'ENRFEE' ? moment(billingHistory?.[0]?.nextDueDate).format('MMMM DD YYYY') : '-'}
                            // nextBillingDateValue={moment(agreementData1?.EndDate).format('DD MMMM YYYY')}
                            nextBillingDateValue={getDateAndDay(
                                agreementData1?.EndDate,
                                'DD MMMM YYYY'
                            )}
                            switchToSaveText={`Switch to Annual and save over ${globalState?.state?.renewalAnnualDiscount
                                ?.map((v: any) => {
                                    return v + '%';
                                })
                                ?.toString()
                                ?.replace(',', ' + ')}`}
                            // switchToSaveText={RenewalConfirmationScrips.switchToSaveText}
                            switchText={RenewalConfirmationScrips.switchText}
                            paymentMethodLBL={RenewalConfirmationScrips.paymentMethodLBL}
                            cardNumber={
                                currentPaymentod?.CreditCardNumber
                                    ? `**${currentPaymentod?.CreditCardNumber}`
                                    : '-'
                            }
                            cardImage={
                                currentPaymentod?.CreditCardNumber ? (
                                    <Image
                                        height={'20px'}
                                        marginLeft={'8px'}
                                        alignSelf={'center'}
                                        src={VisaIconSVG}
                                    ></Image>
                                ) : undefined
                            }
                            // changeText={currentPaymentod?.CreditCardNumber === undefined ? RenewalConfirmationScrips.addCardText : RenewalConfirmationScrips.changeText}
                            changeText={RenewalConfirmationScrips.changeText}
                            btnConfirmLBL={RenewalConfirmationScrips.btnConfirmLBL}
                            showSwitchSection={
                                globalState?.state?.renewalSelectedOption?.name ===
                                'Renew term payment'
                                    ? false
                                    : true
                            }
                            handleSwitchClick={() => handleSwitchClick()}
                            handleChange={() => callPCIToken()}
                            onClickSubmit={() => handleSubmit()}
                        />
                    </Box>
                    {(PCITokenError || CCNotAddedErr) && (
                        <AlertDialog
                            motionPreset="slideInBottom"
                            leastDestructiveRef={cancelRef}
                            onClose={() => undefined} //onClose()
                            isOpen={PCITokenError || CCNotAddedErr}
                            isCentered
                            autoFocus={false}
                        >
                            <AlertDialogOverlay />
                            <AlertDialogContent margin={'12px'} p="3">
                                <Box display={'flex'} justifyContent={'right'} width={'100%'}>
                                    <Text
                                        variant={'link'}
                                        cursor={'pointer'}
                                        onClick={() => (
                                            setPCITokenError(false), setCCNotAddedErr(false)
                                        )}
                                    >
                                        <TextScript
                                            text={Scripts.Global.Close}
                                            translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Close}`}
                                        />
                                    </Text>
                                    <CloseIcon
                                        onClick={() => (
                                            setPCITokenError(false), setCCNotAddedErr(false)
                                        )}
                                        display={'block'}
                                        cursor={'pointer'}
                                        alignSelf={'center'}
                                        marginX={'0.5rem'}
                                        padding={'1px'}
                                    />
                                </Box>
                                <AlertDialogBody paddingX={'12px'} paddingTop={'12px'}>
                                    {PCITokenError && (
                                        <Text>
                                            <TextScript
                                                text={'Something went wrong, please retry.'}
                                                translationKey={`renewal/tokenError}`}
                                            />
                                        </Text>
                                    )}
                                    {CCNotAddedErr && (
                                        <Text>
                                            <TextScript
                                                text={'Please add credit card details.'}
                                                translationKey={`renewal/ccNotAddedErr}`}
                                            />
                                        </Text>
                                    )}
                                </AlertDialogBody>
                            </AlertDialogContent>
                        </AlertDialog>
                    )}
                </MainStackLayout>
            </Box>
        </Box>
    );
}

export default RenewalConfirmationComp;
