// import { useState } from 'react';
import { SubmitRequest, getTypeByDomain, useSessionStorage } from '@MGPD/myasurion-shared';
import { Box } from '@chakra-ui/react';

import MainStackLayout from '../layout/MainStackLayout';
import { processIncidentKey } from '../modules/config/constants';
import { getRedirectUrl } from '../utils/utills';

function Submitrequest() {
    const [processIncidentResponse] = useSessionStorage<any>(processIncidentKey, null);

    return (
        <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'}>
            <MainStackLayout gap={0} spacing={0}>
                <SubmitRequest
                    title="Your request is submitted."
                    requestIDText={`Your request ID: ${processIncidentResponse?.CustomerCaseNumber}`}
                    confirmationText="You’ll get a confirmation email with shipping instructions at {mary.james@gmail.com} shortly."
                    descHeader1="What happens next?"
                    desc1="You will be notified by SMS and Email once your request is approved. Service fee would be charged to your credit card only upon approval."
                    descHeader2="Important Note"
                    desc2="Please note that we will not accept your device if FMiP is turned on upon point of exchange and we'll charge you Additional Fee which is equivalent to the Replacement fee."
                    linkFmipLBL="How to turn off Find my iPhone"
                    linkClosePageLBL="Close this page"
                    btnRateServiceLBL="Rate our service"
                    onClickFmipLink={() => alert('onClickFmipLink')}
                    onClickRateServiceBtn={() => alert('onClickRateServiceBtn')}
                    onClickClose={() => window.open(getRedirectUrl(getTypeByDomain()), '_self')}
                />
            </MainStackLayout>
        </Box>
    );
}

export default Submitrequest;
