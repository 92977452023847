// JB Hifi Mock Data

// JB Temp Handling for Triage Category until we get from API
export const mockJBTriageQuestionCategory = [
    'General Condition',
    'Battery',
    'Scratches and Damage'
];

// JB Temp Handling for Triage Category until we get from API
export const mockJBTriageSubHeaderContent = 'To assess if your device is suitable for Upgrade, please answer the following questions';
