import {
  Box,
  Input,
  List,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import _ from '../../../utils/lodashUtils.ts';
import React, { useEffect, useRef, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { AutoCompleteOption } from '../../../types/types';
import { DEFAULT_DEBOUNCE_TIMEOUT, DEFAULT_SEARCH_PLACEHOLDER } from '../../../utils/constant';

interface AutoCompleteProps {
  name: string;
  className: string;
  placeholder?: string;
  autoComplete?: 'on' | 'off' | undefined;
  loadOptionLength?: number;
  debounceTimeout?: number;
  styles?: object;
  isDisabled?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  setOptionLabel?: (option: AutoCompleteOption) => string;
  loadOptions: (inputValue: string) => Promise<AutoCompleteOption[]>;
  inputValue: string;
  onInputValueChange: (value: string) => void;
  selectedOption?: AutoCompleteOption;
  onOptionSelectionChange?: (option: AutoCompleteOption) => void;
}

const AutoComplete: React.FC<AutoCompleteProps> = ({
  name='autoComplete',
  className='autoComplete',
  placeholder = DEFAULT_SEARCH_PLACEHOLDER,
  autoComplete = 'off',
  loadOptionLength = 3,
  debounceTimeout = DEFAULT_DEBOUNCE_TIMEOUT,
  styles={},
  isDisabled = false,
  onBlur,
  onFocus,
  setOptionLabel = (option: AutoCompleteOption) => option?.label || '',
  loadOptions,
  inputValue = '',
  onInputValueChange = (value: string) => value,
  selectedOption,
  onOptionSelectionChange = (option: AutoCompleteOption) => option,
}) => {
  const [options, setOptions] = useState<AutoCompleteOption[]>([]);
  const [isLoadingOption, setIsLoadingOption] = useState(false);
  const debouncedInputValue = useDebounce(inputValue, debounceTimeout);
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoadingOption(true);
      if (debouncedInputValue && _.isEmpty(selectedOption) && debouncedInputValue.length >= loadOptionLength) {
        const options:AutoCompleteOption[] = await loadOptions(debouncedInputValue);
        setOptions(options);
      } else {
        setOptions([]);
      }
      setIsLoadingOption(false);
    };

    fetchOptions();
  }, [debouncedInputValue, loadOptions]);

  return (
    <VStack align="stretch" spacing={4} width="100%">
      <Popover
        placement="bottom-start"
        initialFocusRef={inputRef}
        isOpen={(isLoadingOption || _.isEmpty(selectedOption)) && _.isNotEmpty(debouncedInputValue)}
        closeOnBlur={true}
        matchWidth={true}
      >
        <PopoverTrigger>
          <Input
            size={'lg'}
            ref={inputRef}
            name={name}
            value={inputValue}
            onChange={(e) => onInputValueChange(e.target.value)}
            onBlur={onBlur}
            onFocus={onFocus}
            className={className}
            placeholder={placeholder}
            autoComplete={autoComplete}
            isDisabled={isDisabled}
            style={styles}
            _focus={{
              boxShadow: 'none',
            }}
          />
        </PopoverTrigger>
        <PopoverContent
          borderColor="gray.400"
          borderRadius="md"
          paddingBottom={1}
          maxHeight="200px"
          minWidth="95%"
          overflowY="scroll"
          overflowX="hidden"
        >
          <List spacing={1}>
            {isLoadingOption && (
              <>
                <Box
                  as="li"
                  p={2}
                  height="40px"
                  _hover={{ bg: 'gray.100' }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner />
                </Box>
              </>
            )}
            {options.length > 0 &&
              options.map((option: AutoCompleteOption, index) => (
                <Box
                  as="li"
                  key={option?.key || index}
                  onClick={() => onOptionSelectionChange(option)}
                  p={2}
                  height="40px"
                  _hover={{ bg: 'gray.100' }}
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                >
                  {setOptionLabel(option)}
                </Box>
              ))}
            {isLoadingOption === false && _.isNotEmpty(debouncedInputValue) && _.isEmpty(selectedOption) && _.isEmpty(options) && (
              <Box
                as="li"
                p={2}
                height="40px"
                _hover={{ bg: 'gray.100' }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                Nothing found
              </Box>
            )}
          </List>
        </PopoverContent>
      </Popover>
    </VStack>
  );
};

export default React.memo(AutoComplete);
