// import MainStackLayout from '@/layout/MainStackLayout';
import { SubHeader, TextScript, getTextScript, useConfig, useTextScript } from '@MGPD/myasurion-shared';
import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import CancelSrPopup from '../myclaims/CancelSrPopup';
import { isMalfuntionWarrantyEligible } from '../../utils/featuresUtills';
import { useCentralStore } from '../../store/appContext';
import ChatOverlay from '../../../components/ChatOverlay';

function UnableToProceedSR() {
    const navigate = useNavigate();
    const globalState = useCentralStore();
    const { isTranslationsLoading, language } = useTextScript();
    const NotAbletoProceedKeys = translationsKeys.NotAbleToProceedFmip;
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [titleText, setTitleText] = useState('');    
    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const isMalfunctionWarranty = agreementData1
        ? isMalfuntionWarrantyEligible(agreementData1?.ClientOffer?.ClientOfferName)
        : '';

    const onCancelClick = () => {
        setOpenCancelPopup(!openCancelPopup);
    };

    const onBackClick = () => {
        window.history.back();
    };

    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { primary, accent } = colors;

    useEffect(() => {
        if (!isTranslationsLoading){
        getTitleText();        
    }
    },[isTranslationsLoading, language])

    const getTitleText = async () => {
        const title = isMalfunctionWarranty ? Scripts?.NotAbletoProceedSR?.title1 : Scripts?.NotAbletoProceedSR?.title;
        const key = isMalfunctionWarranty ? NotAbletoProceedKeys.HeaderText1 : NotAbletoProceedKeys.HeaderText
        const text = await getTextScript(
            `${NotAbletoProceedKeys.PageName}/${key}`,
            title,
            language,
            []
        );
        setTitleText(text);
    }

    return (
        <>
            <Box>
                <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'} w="full">
                    <Box
                        w={{ base: 'full', lg: '80%' }}
                        marginX={{ base: '', lg: 'auto' }}
                        mb={{ base: '0', lg: '20px' }}
                    >
                        <SubHeader
                            backText={'Back'}
                            onClick={onBackClick}
                            allowBackButtonClick={false}
                            showCancelButton={true}
                            showBackButton={true}
                            cancelText={Scripts.CancelSR.cancelBtnText}
                            onCancelClick={onCancelClick}
                        ></SubHeader>
                    </Box>
                    
                    <Box
                        id={'myasurion-profile-avatar'}
                        w={{ base: 'full', lg: '60%' }}
                        margin={{ base: '0', lg: '0 auto' }}
                        h={'auto'}
                        p={'0px 18px'}
                    >
                        <Text
                            fontSize={'24px'}
                            font-weight={'300'}
                            line-height={'31.68px'}
                            my="10px"
                        >
                            {titleText}
                            {/* <TextScript
                                text={'Sorry, we are unable to process your service request'}
                                translationKey={`${NotAbletoProceedKeys.PageName}/${NotAbletoProceedKeys.HeaderText}`}
                            /> */}
                        </Text>                        
                        {isMalfunctionWarranty ? <Text>
                            <TextScript
                                text={Scripts?.NotAbletoProceedSR?.desc1}
                                translationKey={`${NotAbletoProceedKeys.PageName}/${NotAbletoProceedKeys.Text3}`}
                            />
                        </Text>: <>
                        <Text mb="0px">
                            <TextScript
                                text={' We cannot accept your device if FMiP is still on'}
                                translationKey={`${NotAbletoProceedKeys.PageName}/${NotAbletoProceedKeys.Text1}`}
                            />
                        </Text>
                        <Text mb="24px">
                            <TextScript
                                text={'Would you like any assistance to your off FMiP?'}
                                translationKey={`${NotAbletoProceedKeys.PageName}/${NotAbletoProceedKeys.Text2}`}
                            />
                        </Text>
                        </>}
                        
                        <Stack minWidth="max-content" alignItems="center" gap="2">
                            {isMalfunctionWarranty ? <Button
                                height={'40px'}
                                variant={'outline'}
                                colorScheme={accent}
                                color={'white'}
                                width={'100%'}
                                cursor="pointer"
                                background={primary}
                                onClick={() => window.history.back()}
                                display={{ base: 'block', lg: 'none' }}
                            >
                                <TextScript
                                text={Scripts.NotAbletoProceedSR.btnText2}
                                translationKey={`${NotAbletoProceedKeys.PageName}/${NotAbletoProceedKeys.btnUnderstand}`}
                            />
                            </Button>:<Button
                                height={'40px'}
                                variant={'outline'}
                                colorScheme={accent}
                                color={'white'}
                                width={'100%'}
                                cursor="pointer"
                                background={primary}
                                display={{ base: 'block', lg: 'none' }}
                            >
                                <TextScript
                                    text={'Talk to an expert'}
                                    translationKey={`${NotAbletoProceedKeys.PageName}/${NotAbletoProceedKeys.btnTalktoExpert}`}
                                />
                            </Button>}
                            <Button
                                colorScheme={accent}
                                color={accent}
                                as={'u'}
                                variant="link"
                                height="40px"
                                cursor="pointer"
                                onClick={() => navigate('/fmipinstructions')}
                            >
                                <TextScript
                                    text={'How to turn off Find my iPhone'}
                                    translationKey={`${NotAbletoProceedKeys.PageName}/${NotAbletoProceedKeys.btnHowtoFind}`}
                                />
                            </Button>
                            {/* <Button
                  colorScheme={accent}
                  as={'u'}
                  color={accent}
                  variant="link"
                  height="40px"
                  cursor='pointer'
                  onClick={() => navigate('/fmip')}
                  display={{ base: 'block', lg: 'none' }}
                >
                  Cancel
                </Button> */}
            </Stack>
          </Box>
          {/* </MainStackLayout> */}
        </Box>
        <CancelSrPopup closeCancelPopup={setOpenCancelPopup} openCancelPopup={openCancelPopup} />
      </Box>
      <Box position="fixed" bottom={0} w="full" display={{ base: 'none', lg: 'block' }}>
        <hr
          style={{
            border: 'none',
            borderTop: '2px solid #adaaaa',
          }}
        />
        <Flex
          minWidth="max-content"
          alignItems="center"
          gap="2"
          direction="row"
          py={5}
          justifyContent="space-between"
        >
          <Box></Box>
          {/* <Button
            height={'40px'}
            variant={'outline'}
            // colorScheme={primary}
            // color={primary}
            width={{ base: 'full', lg: '10%' }}
            cursor="pointer"
          >
              <TextScript
                  text={'Talk to an expert'}
                  translationKey={`${NotAbletoProceedKeys.PageName}/${NotAbletoProceedKeys.btnTalktoExpert}`}/>
          </Button> */}
          <Box height={'40px'} display={{ base: 'none', lg: 'block' }}>
          <ChatOverlay question={''} onChatClose={() => undefined} displayExpertText={true} />
          </Box>
          <Box></Box>
        </Flex>
      </Box>
    </>
  );
}

export default UnableToProceedSR;
