import noImageAvailable from '../assets/no-image-available-100.png';
import SEOAPIServies from '../services/SEOAPIServices';

interface DeviceImage {
    title: string;
    horizonId: string;
    brandName: string;
    deviceImagesMyAsurionCollection: {
        items: { title: string; url: string }[];
    };
}

const getDeviceImages = (): Promise<DeviceImage[] | any> => {
    return SEOAPIServies.fetchDeviceImage().then((res) => {
        return res?.data?.seoPageLayoutCollection?.items;
    });
};

const getDeviceImageByModel = (
    deviceImages: DeviceImage[] | undefined,
    model: string,
    color: string | '',
    width: number
): string => {
    let deviceModel = deviceImages?.find((d) => d?.title?.toUpperCase() === model?.toUpperCase());
    if (!deviceModel) {
        deviceModel = deviceImages?.find((d) => d?.horizonId?.includes(model));
    }
    let imagesCollection = deviceModel?.deviceImagesMyAsurionCollection;
    let image;
    if (['GREY', 'GRAY'].includes(color?.toUpperCase())) {
        image = imagesCollection?.items?.find((i) =>
            ['GREY', 'GRAY'].includes(i?.title?.toUpperCase())
        );
    } else {
        image = imagesCollection?.items?.find((i) => i?.title?.toUpperCase()?.includes(color?.toUpperCase()) || color?.toUpperCase().includes(i?.title?.toUpperCase()));
    }

    if (!image) {
        image = imagesCollection?.items?.find((i) =>
            ['BLACK', 'MIDNIGHT', 'GOLD'].includes(i?.title?.toUpperCase())
        );
    }
    if (!image) {
        image = imagesCollection?.items[0];
    }
    return image?.url ? `${image?.url}?w=${width}` : noImageAvailable;
};

export { getDeviceImages, getDeviceImageByModel };
