import { SubHeader } from '@MGPD/myasurion-shared';
import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { useState } from 'react';

import Scripts from '../../Scripts/asurion-script.json';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import CancelSrPopup from '../myclaims/CancelSrPopup';
import IngenicoPayment from './IngenicoPayment';
// import Payment from './Payment';
import BGBTPayment from './BGBT-PaymentNew';
const PaymentContainer: React.FC<PropsWithChildren> = () => {
    const globalState = useCentralStore();
    const isBraintree = globalState?.state?.sessionResponse?.InitializeResponse?.isBGBT;
    const [openCancelPopup, setOpenCancelPopup] = useState(false);

    const onCancelClick = () => {
        setOpenCancelPopup(!openCancelPopup);
    };

    const onBackClick = () => {
        globalState?.dispatch({ type: ActionTypes.SHOW_PAYMENT_OPTIONS, payload: true });
        if (!globalState?.state?.selectedPaymentMethod) {
            window.history.back();
        } else {
            globalState?.dispatch({ type: ActionTypes.SET_PAYMENT_METHOD, payload: '' });
            globalState?.dispatch({ type: ActionTypes.SET_PAYMENT_ERROR, payload: '' });
        }
    };

    return (
        <Box mt={14} w="full">
            <Box
                w={{ base: 'full', lg: '80%' }}
                mx={{ base: '0', lg: 'auto' }}
                mb={{ base: '10px', lg: '20px' }}
            >
                <SubHeader
                    onClick={onBackClick}
                    backText={'Back'}
                    allowBackButtonClick={false}
                    showCancelButton={true}
                    showBackButton={true}
                    cancelText={Scripts.CancelSR.cancelBtnText}
                    onCancelClick={onCancelClick}
                ></SubHeader>
            </Box>
            <Box p={4}>
                {/* {!isBraintree ? <IngenicoPayment /> : <Payment />} */}
                {!isBraintree ? <IngenicoPayment /> : <BGBTPayment />}
                {/* {_paymentMethod !== '' &&
                  <Center position='fixed' bottom='0'>
                      <Link alignItems='center' color={primary} onClick={() => globalState?.dispatch({ type: ActionTypes.SET_PAYMENT_METHOD, payload: '' })}>
                          change payment method(for testing)
                      </Link>
                  </Center>
              } */}
            </Box>
            <CancelSrPopup
                closeCancelPopup={setOpenCancelPopup}
                openCancelPopup={openCancelPopup}
            />
        </Box>
    );
};

export default PaymentContainer;
