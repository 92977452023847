/* eslint-disable no-console */
import {
    AccountInfo,
    getAuthTokens,
    getCarrierByDomain,
    getCountryByCarrier,
} from '@MGPD/myasurion-shared';
import axios from 'axios';

import { Api, Env } from '../config/api';

type ExecuteMethods = 'GET' | 'POST' | 'PUT' | 'DELETE';
const execute = async <Response = any>({
    method,
    url,
    params,
}: {
    method: ExecuteMethods;
    url: string;
    params?: any;
}) => {
    const options: any = {};

    const carrier = getCarrierByDomain();
    const cc = getCountryByCarrier(carrier);
    const authtoken: any = getAuthTokens();

    options.headers = {
        token: authtoken.token,
        authorization: authtoken.authorization,
        'x-api-key': Env.apiKey,
        'x-asurion-client': carrier,
        'x-asurion-cc': cc.code,
        id: authtoken.userId,
    };

    switch (method.toUpperCase()) {
        case 'GET':
            return axios.get<Response>(url, options);
        case 'POST':
            return axios.post<Response>(url, params?.data, options);
        case 'PUT':
            return axios.put<Response>(url, params?.data, options);
        case 'DELETE':
            return axios.delete<Response>(url, options);
        default:
            return axios.get<Response>(url, options);
    }
};

export const updateProfile = async ({ account }: { account: AccountInfo }) => {
    const authtoken = getAuthTokens();
    if (account && authtoken && authtoken.token && authtoken.authorization) {
        const { data } = await execute({
            method: 'PUT',
            url: Api.urls.updateProfile(),
            params: { data: account },
        });
        return data;
    } else {
        console.log('Missing data and parameters while saving profile');
    }
};

export const submitFeedback = async ({ type, details }: { type: string; details: string }) => {
    if (type && details) {
        const { data } = await execute({
            method: 'POST',
            url: Api.urls.sendFeedback(),
            params: { data: { type, details } },
        });
        return data;
    } else {
        console.log('Missing data and parameters while submitting feedback');
    }
};

export const changePassword = async ({ op, np }: { op: string; np: string }) => {
    if (op && np) {
        const { data } = await execute({
            method: 'PUT',
            url: Api.urls.changePassword(),
            params: { data: { op, np } },
        });
        return data;
    } else {
        console.log('Missing data and parameters while changing password');
    }
};

export const fetchAccountProfile = async () => {
    try {
        const { data } = await execute({
            method: 'GET',
            url: `${import.meta.env.VITE_API_BASE_URL}/account/profile`,
        });

        const { statusCode, body } = data;
        if (statusCode === 200) {
            return body;
        } else {
            throw Error(`Error fetching account profile:`, { cause: statusCode });
        }
    } catch (error) {
        console.error('Error fetching account profile:', error);
        throw error;
    }
};
