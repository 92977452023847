/* eslint-disable no-console */
import { TextScript, getTextScript, useSessionStorage } from '@MGPD/myasurion-shared';
import { Box, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import ClaimDetailsScript from '../../Scripts/asurion-script.json';
import Scripts from '../../Scripts/asurion-script.json';
import ClaimDetailsKeys from '../../Scripts/translationsKeys.json';
import { useCentralStore } from '../../store/appContext';
import { getDeliverySlot, getTimeBySlot, hideWarranty, isExchange } from '../../utils/featuresUtills';
import { TIME_SLOT_TYPE } from '../../utils/constant';
import {
    formatTime,
    getAddress,
    getReturnSlotTimeByDeliveryWindow,
    getTime,
    isAddressEmpty,
    timeConvert24to12Hr,
} from '../../utils/utills';
import EditDetails from './EditDetails';
import { currentAgreementKey } from '../../modules/config/constants';

export interface ClaimDetailsProps {
    deliveryAddress: string;
    deliveryDate: string;
    deliveryTime: string;
    collectionAddress: string;
    collectionDate: string;
    collectionTime: string;
    pickupAddress: string;
    pickupDate: string;
    pickupTime: string;
    returnAddress: string;
    returnDate: string;
    returnTime: string;
    RepairCentreLocation: string;
    AppointmentDate: string;
    AppointmentTime: string;
    nightDeliveryFee: string;
    showDeliveryDetails: boolean;
    showCollectionDetails: boolean;
    showPickupDeatils: boolean;
    showReturnDetails: boolean;
    showAppointmentDetails: boolean;
    isNightFeesApplicable: boolean;
    ServiceRequestId?: string;
    ServiceRequestStatus?: string;
    RequestedService?: string;
    RequestType?: string;
    Fees?: number;
    PaymentMethod?: string;
    Notes?: string;
    showEditableFields: boolean;
    claimData: any;
    isWalkin: boolean;
    WarrantyExpiryDate: string;
    IsInWarranty: boolean;
    currencySymbol: string;
    isTOM: boolean;
    isMaxis: boolean;
    AlternativeContactNumber: string;
    isPickupEditable: boolean;
    isReturnEditable: boolean;
    isAlternateNumberEditable: boolean;
}
export const ClaimDetails: React.FC<ClaimDetailsProps> = ({
    deliveryAddress,
    deliveryDate,
    deliveryTime,
    collectionAddress,
    collectionDate,
    collectionTime,
    pickupAddress,
    pickupDate,
    pickupTime,
    returnAddress,
    returnDate,
    returnTime,
    RepairCentreLocation,
    AppointmentDate,
    AppointmentTime,
    nightDeliveryFee,
    showDeliveryDetails,
    showPickupDeatils,
    showReturnDetails,
    showAppointmentDetails,
    showCollectionDetails,
    isNightFeesApplicable,
    ServiceRequestId,
    ServiceRequestStatus,
    RequestedService,
    RequestType,
    Fees = 0,
    PaymentMethod,
    Notes,
    showEditableFields,
    claimData,
    isWalkin,
    WarrantyExpiryDate,
    IsInWarranty,
    currencySymbol,
    isTOM,
    isMaxis,
    AlternativeContactNumber,
    isPickupEditable,
    isReturnEditable,
    isAlternateNumberEditable,
    ...props
}) => {
    const [warrantyValue, setWarrantyValue] = useState('');

    const getWarrantyDate = async (value: string) => {
        let PlaceHolderText;
        if (IsInWarranty) {
            PlaceHolderText = await getTextScript(
                `${ClaimDetailsKeys.ClaimDetails.PageName}/validTill`,
                Scripts.ClaimDetails.ValidTill,
                'en-US',
                [value]
            );
            setWarrantyValue(PlaceHolderText);
        } else {
            PlaceHolderText = await getTextScript(
                `${ClaimDetailsKeys.ClaimDetails.PageName}/expiredOn`,
                Scripts.ClaimDetails.ExpiredOn,
                'en-US',
                [value]
            );
            setWarrantyValue(PlaceHolderText);
        }
    };

    useEffect(() => {
        getWarrantyDate(WarrantyExpiryDate);
    }, []);

    const globalState: any = useCentralStore();
    const [AgreementData] = useSessionStorage<any>(currentAgreementKey, null);
    const AgreementDataMDN = JSON.parse(sessionStorage.getItem('dsMdn') || '')
    const [agreementDataResp] = useSessionStorage<any>('agreementDataResponse', '');
    const Agreement =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0] || agreementDataResp || AgreementData || AgreementDataMDN;

    // let getTimeVal = getTimeBySlot(Agreement?.ClientOffer?.ClientOfferName || Agreement?.plan, features,  pickupTime)
    // console.log('getTimeVal', getTimeVal);
    let hideWarrantyContent;
    if (Agreement?.ClientOffer?.ClientOfferName || Agreement?.plan) {
        hideWarrantyContent = hideWarranty(Agreement?.ClientOffer?.ClientOfferName || Agreement?.plan) ? true : false;
    }

    return (
        <>
            <Box fontFamily={'Apercu Pro'} {...props}>
                {
                    <Box>
                        {ServiceRequestId && (
                            <Box mb={4}>
                                <Text mb={1} fontWeight={'700'}>
                                    <TextScript
                                        text={ClaimDetailsScript.ClaimDetails.SRID}
                                        translationKey={`${ClaimDetailsKeys.ClaimCard.PageName}/${ClaimDetailsKeys.ClaimCard.SRID}`}
                                    />
                                </Text>
                                <Text>{ServiceRequestId}</Text>
                            </Box>
                        )}

                        {ServiceRequestStatus && (
                            <Box mb={4}>
                                <Text mb={1} fontWeight={'700'}>
                                    <TextScript
                                        text={ClaimDetailsScript.ClaimDetails.SRStatus}
                                        translationKey={`${ClaimDetailsKeys.ClaimCard.PageName}/${ClaimDetailsKeys.ClaimCard.SRStatus}`}
                                    />
                                </Text>
                                <Text>
                                    <TextScript
                                        text={ServiceRequestStatus}
                                        translationKey={`${ClaimDetailsKeys.ClaimCard.PageName}/${ServiceRequestStatus}`}
                                    />
                                </Text>
                            </Box>
                        )}

                        {RequestedService && RequestedService?.length > 0 && (
                            <Box mb={4}>
                                <Text mb={1} fontWeight={'700'}>
                                    <TextScript
                                        text={ClaimDetailsScript.Global.RequestedService}
                                        translationKey={`${ClaimDetailsKeys.Global.PageName}/${ClaimDetailsKeys.Global.RequestedService}`}
                                    />
                                </Text>
                                {RequestedService}
                            </Box>
                        )}

                        {RequestType && (
                            <Box mb={4}>
                                <Text mb={1} fontWeight={'700'}>
                                    <TextScript
                                        text={ClaimDetailsScript.Global.RequestType}
                                        translationKey={`${ClaimDetailsKeys.Global.PageName}/${ClaimDetailsKeys.Global.RequestType}`}
                                    />
                                </Text>
                                {RequestType}
                            </Box>
                        )}
                        {AlternativeContactNumber &&
                            (!isAlternateNumberEditable || !showEditableFields) && (
                                <Box mb={4}>
                                    <Text mb={1} fontWeight={'700'}>
                                        <TextScript
                                            text={'Alternate Contact Number'}
                                            translationKey={`${ClaimDetailsKeys.Global.PageName}/AlternativeContactNumber`}
                                        />
                                    </Text>
                                    <Text>{AlternativeContactNumber}</Text>
                                </Box>
                            )}
                    </Box>
                }
                {/* Delivery Address and Date and Time */}
                {showDeliveryDetails && !showEditableFields && (
                    <Box>
                        {deliveryAddress && (
                            <Box mb={4}>
                                <Text mb={1} fontWeight={'700'}>
                                    <TextScript
                                        text={ClaimDetailsScript.ClaimDetails.DeliveryAddress}
                                        translationKey={`${ClaimDetailsKeys.ClaimCard.PageName}/${ClaimDetailsKeys.ClaimCard.DeliveryAddress}`}
                                    />
                                </Text>
                                <Text>{deliveryAddress}</Text>
                            </Box>
                        )}
                        {deliveryDate && deliveryTime && (
                            <Box mb={4}>
                                <Text mb={1} fontWeight={'700'}>
                                    <TextScript
                                        text={ClaimDetailsScript.ClaimDetails.DeliveryDateTime}
                                        translationKey={`${ClaimDetailsKeys.ClaimCard.PageName}/${ClaimDetailsKeys.ClaimCard.DeliveryDateTime}`}
                                    />
                                </Text>
                                <Text>
                                    {deliveryDate + ' ' + ((isTOM) ? getTime(timeConvert24to12Hr(deliveryTime)) : (deliveryTime === 'In 2 Hours' && getDeliverySlot(Agreement?.ClientOffer?.ClientOfferName || Agreement?.plan)) ? 'In 2 Hours' : isExchange(Agreement?.ClientOffer?.ClientOfferName || Agreement?.plan) ? formatTime(deliveryTime): getTime(deliveryTime))}
                                </Text>
                            </Box>
                        )}
                    </Box>
                )}
                {/* Collection Address and Date and Time */}
                {showCollectionDetails && !showEditableFields && (
                    <Box>
                        {collectionAddress && (
                            <Box mb={4}>
                                <Text mb={1} fontWeight={'700'}>
                                    <TextScript
                                        text={ClaimDetailsScript.ClaimDetails.CollectionAddress}
                                        translationKey={`${ClaimDetailsKeys.ClaimCard.PageName}/${ClaimDetailsKeys.ClaimCard.CollectionAddress}`}
                                    />
                                </Text>
                                <Text>{collectionAddress}</Text>
                            </Box>
                        )}
                        {(collectionDate || collectionTime) && (
                            <Box mb={4}>
                                <Text mb={1} fontWeight={'700'}>
                                    <TextScript
                                        text={ClaimDetailsScript.ClaimDetails.CollectionDateTime}
                                        translationKey={`${ClaimDetailsKeys.ClaimCard.PageName}/${ClaimDetailsKeys.ClaimCard.CollectionDateTime}`}
                                    />
                                </Text>
                                <Text>{collectionDate + ' ' + getTime(collectionTime)}</Text>
                            </Box>
                        )}
                    </Box>
                )}
                {/* Repair Centre Location and Date and Time for repair */}
                {showAppointmentDetails && !showEditableFields && (
                    <Box>
                        {RepairCentreLocation && (
                            <Box mb={4}>
                                <Text mb={1} fontWeight={'700'}>
                                    <TextScript
                                        text={ClaimDetailsScript.ClaimDetails.RepairCentreLocation}
                                        translationKey={`${ClaimDetailsKeys.ClaimCard.PageName}/${ClaimDetailsKeys.ClaimCard.RepairCentreLocation}`}
                                    />
                                </Text>
                                <Text>{RepairCentreLocation}</Text>
                            </Box>
                        )}
                        {AppointmentDate && AppointmentTime && (
                            <Box mb={4}>
                                <Text mb={1} fontWeight={'700'}>
                                    <TextScript
                                        text={ClaimDetailsScript.ClaimDetails.AppointmentDateTime}
                                        translationKey={`${ClaimDetailsKeys.ClaimCard.PageName}/${ClaimDetailsKeys.ClaimCard.AppointmentDateTime}`}
                                    />
                                </Text>
                                <Text>{AppointmentDate + ' ' + getTime(AppointmentTime)}</Text>
                            </Box>
                        )}
                    </Box>
                )}
                {/* Pickup Address and Date and Time */}
                {showPickupDeatils && (!isPickupEditable || !showEditableFields) && (
                    <Box>
                        {pickupAddress && Object.keys(pickupAddress).length > 0 && (
                            <Box mb={4}>
                                <Text mb={1} fontWeight={'700'}>
                                    <TextScript
                                        text={ClaimDetailsScript.Global.PickUpAddress}
                                        translationKey={`${ClaimDetailsKeys.Global.PageName}/${ClaimDetailsKeys.Global.PickUpAddress}`}
                                    />
                                </Text>
                                <Text>{getAddress(pickupAddress)}</Text>
                            </Box>
                        )}
                        {pickupDate && pickupTime && (
                            <Box mb={4}>
                                <Text mb={1} fontWeight={'700'}>
                                    <TextScript
                                        text={ClaimDetailsScript.Global.PickUpDateTime}
                                        translationKey={`${ClaimDetailsKeys.Global.PageName}/${ClaimDetailsKeys.Global.PickUpDateTime}`}
                                    />
                                </Text>
                                {/* <Text>{pickupDate + ' ' + ((isTOM) ? getTime(timeConvert24to12Hr(pickupTime)) : getPickSlotTimeByDeliveryWindow(pickupTime))}</Text> */}
                                <Text>
                                    {pickupDate +
                                        ' ' +
                                        (isTOM
                                            ? getTime(timeConvert24to12Hr(pickupTime))
                                            : getTimeBySlot(
                                                  Agreement?.ClientOffer?.ClientOfferName || Agreement?.plan,
                                                  pickupTime,
                                                  TIME_SLOT_TYPE.PICKUP
                                              ))}
                                </Text>
                            </Box>
                        )}
                    </Box>
                )}
                {/* Return Address and Date and Time */}
                {(showReturnDetails || returnDate && returnTime) && !showEditableFields && (
                    <Box>
                        {!isAddressEmpty(returnAddress) && (
                            <Box mb={4}>
                                <Text mb={1} fontWeight={'700'}>
                                    <TextScript
                                        text={ClaimDetailsScript.Global.ReturnAddress}
                                        translationKey={`${ClaimDetailsKeys.Global.PageName}/${ClaimDetailsKeys.Global.ReturnAddress}`}
                                    />
                                </Text>
                                <Text>{getAddress(returnAddress)}</Text>
                            </Box>
                        )}
                        {!isAddressEmpty(returnAddress) && returnDate && returnTime && (
                            <Box mb={4}>
                                <Text mb={1} fontWeight={'700'}>
                                    <TextScript
                                        text={ClaimDetailsScript.Global.ReturnDateTime}
                                        translationKey={`${ClaimDetailsKeys.Global.PageName}/${ClaimDetailsKeys.Global.ReturnDateTime}`}
                                    />
                                </Text>
                                <Text>
                                    {returnDate +
                                        ' ' +
                                        (isTOM
                                            ? getTime(timeConvert24to12Hr(returnTime))
                                            : getTimeBySlot(
                                                  Agreement?.ClientOffer?.ClientOfferName || Agreement?.plan,
                                                  returnTime,
                                                  TIME_SLOT_TYPE.RETURN
                                              ))}
                                </Text>
                                {/* getReturnSlotTimeByDeliveryWindow(returnTime, isTOM, isMaxis))}</Text> */}
                            </Box>
                        )}
                    </Box>
                )}

                {/* Edit Address, Date and Time */}
                {showEditableFields && (
                    <EditDetails
                        pickupAddress={pickupAddress}
                        pickupDate={pickupDate}
                        pickupTime={
                            isTOM
                                ? pickupTime
                                : getTimeBySlot(
                                      Agreement?.ClientOffer?.ClientOfferName || Agreement?.plan,
                                      pickupTime,
                                      TIME_SLOT_TYPE.PICKUP
                                  )
                        } //getPickSlotTimeByDeliveryWindow(pickupTime)}
                        deliveryAddress={
                            deliveryAddress || RepairCentreLocation || collectionAddress
                        }
                        deliveryDate={deliveryDate || AppointmentDate || collectionDate}
                        deliveryTime={deliveryTime || AppointmentTime || collectionTime}
                        returnAddress={returnAddress}
                        returnDate={returnDate}
                        returnTime={
                            isTOM || isMaxis
                                ? getReturnSlotTimeByDeliveryWindow(returnTime, isTOM, isMaxis)
                                : getTimeBySlot(
                                      Agreement?.ClientOffer?.ClientOfferName || Agreement?.plan,
                                      returnTime,
                                      TIME_SLOT_TYPE.RETURN
                                  ) || ''
                        }
                        showDeliveryDeatils={showDeliveryDetails}
                        showCollectionDetails={showCollectionDetails}
                        showAppointmentDetails={showAppointmentDetails}
                        showPickupDeatils={showPickupDeatils}
                        showReturnDetails={showReturnDetails}
                        isWalking={isWalkin}
                        claimData={claimData}
                        isPickupEditable={isPickupEditable}
                        isReturnEditable={isReturnEditable}
                        alternateNumber={AlternativeContactNumber}
                        isAlternateNumberEditable={isAlternateNumberEditable}
                    />
                )}
                {nightDeliveryFee && currencySymbol && currencySymbol?.length > 0 && (
                    <Box mb={4}>
                        <Text mb={1} fontWeight={'700'}>
                            <TextScript
                                text={ClaimDetailsScript.ClaimDetails.NightDeliveryFees}
                                translationKey={`${ClaimDetailsKeys.ClaimCard.PageName}/${ClaimDetailsKeys.ClaimCard.NightDeliveryFees}`}
                            />
                        </Text>
                        <Text>{`${currencySymbol}${nightDeliveryFee}`}</Text>
                    </Box>
                )}
                {Fees > 0 && currencySymbol && currencySymbol?.length > 0 && (
                    <Box mb={4}>
                        <Text mb={1} fontWeight={'700'}>
                            <TextScript
                                text={ClaimDetailsScript.ClaimDetails.FeePaid}
                                translationKey={`${ClaimDetailsKeys.ClaimCard.PageName}/${ClaimDetailsKeys.ClaimCard.FeePaid}`}
                            />
                        </Text>
                        <Text>{`${currencySymbol}${Fees?.toFixed(2)}`}</Text>
                    </Box>
                )}

                {Fees > 0 && PaymentMethod && (
                    <Box mb={4}>
                        <Text mb={1} fontWeight={'700'}>
                            <TextScript
                                text={ClaimDetailsScript.ClaimDetails.PaymentMethod}
                                translationKey={`${ClaimDetailsKeys.ClaimCard.PageName}/${ClaimDetailsKeys.ClaimCard.PaymentMethod}`}
                            />
                        </Text>
                        <Text>
                            <TextScript
                                text={PaymentMethod}
                                translationKey={`${ClaimDetailsKeys.Global.PageName}/${PaymentMethod}`}
                            />
                        </Text>
                    </Box>
                )}

                {WarrantyExpiryDate && !hideWarrantyContent && (
                    <Box mb={4}>
                        <Text mb={1} fontWeight={'700'}>
                            <TextScript
                                text={'Warranty'}
                                translationKey={`${ClaimDetailsKeys.ClaimCard.PageName}/${ClaimDetailsKeys.ClaimCard.Warranty}`}
                            />
                        </Text>
                        <Text>{warrantyValue}</Text>
                    </Box>
                )}

                {Notes && (
                    <Box mb={4}>
                        <Text mb={1} fontWeight={'700'}>
                            <TextScript
                                text={ClaimDetailsScript.ClaimDetails.Notes}
                                translationKey={`${ClaimDetailsKeys.ClaimCard.PageName}/${ClaimDetailsKeys.ClaimCard.Notes}`}
                            />
                        </Text>
                        <Text>{Notes}</Text>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default ClaimDetails;
