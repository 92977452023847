import { TextScript, getTypeByDomain, useConfig, useTextScript } from '@MGPD/myasurion-shared';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import MainStackLayout from '../../layout/MainStackLayout';
import { useCentralStore } from '../../store/appContext';
import { CLEANING_SERVICE_EDIT_FIELDS } from '../../utils/constant';
import { getCurrencySymbol, getPerilText } from '../../utils/localization-helper';
import { clearSessionData, getFullAddress, getRedirectUrl } from '../../utils/utills';
import ServiceField from './ServiceField';

function CleaningServiceConfirmation() {
    const globalState = useCentralStore();

    const { isTranslationsLoading, language } = useTextScript();

    const navigate = useNavigate();

    // const agreementData1: any =
    //   globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

    const commonScripts = Scripts?.ClaimConfirmation;
    // const swapScripts = Scripts?.ClaimConfirmation?.swap;

    // const [paymentBreakdownLbl, setPaymentBreakdownlbl] = useState<string>();

    const [PerilType, setPerilType] = useState('');
    const [currencySymbol, setCurrencySymbol] = useState('$');
    // const [dateFormat, setDateFormat] = useState<string>('');

    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { accent } = colors;

    useEffect(() => {
        if (!isTranslationsLoading) {
            // getDateFormat();
            getCurrency();
            getPerilLbl();
        }
    }, [language, isTranslationsLoading]);

    // const getDateFormat = async () => {
    //   const format = await getGlobalDateFormat();
    //   setDateFormat(format);
    // };

    const getCurrency = async () => {
        const currency = await getCurrencySymbol();
        setCurrencySymbol(currency);
    };

    const getPerilLbl = async () => {
        const cleaningIncidentData =
            globalState?.state?.cleaningIncidentData?.UpdateCleaningServiceRequest;

        const peril = await getPerilText(
            cleaningIncidentData?.ServiceRequest?.IncidentType,
            cleaningIncidentData?.ServiceRequest?.IncidentType
        );
        setPerilType(peril);
    };

    const onSurveyClick = () => {
        navigate('/npsrating', { replace: true });
    };

    const onClosePageClick = () => {
        clearSessionData(true, false);
        window.location.href = getRedirectUrl(getTypeByDomain());
    };

    return (
        <Box paddingTop={'64px'} w="full">
            <MainStackLayout gap={0} spacing={0}>
                <Box mx={{ base: '0', lg: 'auto' }} mt={4} w={'full'} h={'auto'} p={'0px 16px'}>
                    <Box
                        p={'16px , 16px'}
                        w={{ base: 'full', lg: '60%' }}
                        m={{ base: '0', lg: 'auto' }}
                        pb={{ base: '0', lg: '100px' }}
                    >
                        <Text fontSize="3xl" mb={'8px'}>
                            <TextScript
                                text={commonScripts?.YourRequestisApproved}
                                translationKey={`${translationsKeys?.ConfirmationAfterSubmission.PageName}/${translationsKeys?.ConfirmationAfterSubmission.title}`}
                            />
                        </Text>
                        <Flex>
                            <Text fontSize="lg" mr={1}>
                                <TextScript
                                    text={commonScripts?.YourRequestID}
                                    translationKey={`${translationsKeys?.ConfirmationAfterSubmission.PageName}/${translationsKeys?.ConfirmationAfterSubmission.heading}`}
                                />
                            </Text>
                            <Text as={'b'} fontSize="lg">
                                {
                                    globalState?.state?.cleaningIncidentData
                                        ?.UpdateCleaningServiceRequest?.CustomerCase
                                        ?.CustomerCaseNumber
                                }
                            </Text>
                        </Flex>

                        <Box mb={'34px'} pt={'8px'}>
                            <Flex
                                direction={{ base: 'column', lg: 'row' }}
                                justifyContent={{ base: 'start', lg: 'space-between' }}
                            >
                                <Box
                                    w={{ base: 'full', lg: '50%' }}
                                    border={{ base: 'none', lg: '1px' }}
                                    padding={{ base: '0', lg: '10px 16px' }}
                                    borderRadius={{ base: 'none', lg: '16px' }}
                                    borderColor={{ base: 'none', lg: '#adaaaa' }}
                                >
                                    <Text fontSize="2xl" fontWeight={'light'}>
                                        <TextScript
                                            text={commonScripts.serviceRequestdetails}
                                            translationKey={`${translationsKeys.ConfirmationAfterSubmission.PageName}/${translationsKeys.ConfirmationAfterSubmission.serviceRequestdetails}`}
                                        />
                                    </Text>

                                    <Box my={2}>
                                        <ServiceField
                                            name={CLEANING_SERVICE_EDIT_FIELDS.REQUESTED_SERVICE}
                                            value={
                                                PerilType ||
                                                globalState?.state?.cleaningIncidentData
                                                    ?.ServiceRequest?.IncidentType
                                            }
                                            text={Scripts.Global.RequestedService}
                                            pageName={translationsKeys.Global.PageName}
                                            translationKey={
                                                translationsKeys.Global.RequestedService
                                            }
                                            showEditButton={false}
                                        ></ServiceField>
                                    </Box>
                                    <Box mb={2}>
                                        <ServiceField
                                            name={CLEANING_SERVICE_EDIT_FIELDS.BRAND}
                                            value={
                                                globalState?.state?.selectedCleaningDeviceDetails
                                                    ?.brand
                                            }
                                            text={Scripts.AddDevice.AddNewDevice.brand}
                                            pageName={
                                                translationsKeys.AddDevice.AddNewDevice
                                                    .PageNameAnchorDevice
                                            }
                                            translationKey={
                                                translationsKeys.AddDevice.AddNewDevice.brand
                                            }
                                            showEditButton={false}
                                        ></ServiceField>
                                    </Box>
                                    <Box mb={2}>
                                        <ServiceField
                                            name={CLEANING_SERVICE_EDIT_FIELDS.MODEL}
                                            value={
                                                globalState?.state?.selectedCleaningDeviceDetails
                                                    ?.model
                                            }
                                            text={Scripts.AddDevice.AddNewDevice.model}
                                            pageName={
                                                translationsKeys.AddDevice.AddNewDevice
                                                    .PageNameAnchorDevice
                                            }
                                            translationKey={
                                                translationsKeys.AddDevice.AddNewDevice.model
                                            }
                                            showEditButton={false}
                                        ></ServiceField>
                                    </Box>

                                    <Box mb={2}>
                                        <ServiceField
                                            name={CLEANING_SERVICE_EDIT_FIELDS.ADDRESS}
                                            value={getFullAddress(
                                                globalState?.state?.cleaningLogistics?.address
                                            )}
                                            text="Address"
                                            pageName="DeliveryDetails"
                                            translationKey="lblAddress"
                                            showEditButton={false}
                                        ></ServiceField>
                                    </Box>

                                    <Box mb={2}>
                                        <ServiceField
                                            name={CLEANING_SERVICE_EDIT_FIELDS.REGISTERED_NUMBER}
                                            value={globalState?.state?.cleaningLogistics?.mobile}
                                            text={
                                                Scripts.CleaningService.CleaningLogistics
                                                    .RegisteredNumber
                                            }
                                            pageName="DeliveryDetails"
                                            translationKey="lblRegisteredNumber"
                                            showEditButton={false}
                                        ></ServiceField>
                                    </Box>

                                    {globalState?.state?.cleaningLogistics?.alternateMobile && (
                                        <Box mb={2}>
                                            <ServiceField
                                                name={
                                                    CLEANING_SERVICE_EDIT_FIELDS.ALTERNATE_CONTACT_NUMBER
                                                }
                                                value={
                                                    globalState?.state?.cleaningLogistics
                                                        ?.alternateMobile || ''
                                                }
                                                text={Scripts.Global.AlternateContactNumber}
                                                pageName={translationsKeys.Global.PageName}
                                                translationKey={
                                                    translationsKeys.Global.AlternateContactNumber
                                                }
                                                showEditButton={false}
                                            ></ServiceField>
                                        </Box>
                                    )}
                                    {/* {dateFormat && (
                    <Box mb={2}>
                      <ServiceField
                        name={CLEANING_SERVICE_EDIT_FIELDS.DATE_TIME}
                        value={
                          getDateAndDay(globalState?.state?.cleaningLogistics?.date, dateFormat) ||
                          ''
                        }
                        text={Scripts.CleaningService.CleaningLogistics.PreferredDateLBL}
                        pageName={translationsKeys.CleaningService.CleaningLogistics.PageName}
                        translationKey={
                          translationsKeys.CleaningService.CleaningLogistics.PreferredDateLBL
                        }
                        showEditButton={false}
                      ></ServiceField>
                    </Box>
                  )} */}

                                    <Box mb={2}>
                                        <ServiceField
                                            name={CLEANING_SERVICE_EDIT_FIELDS.DATE_TIME}
                                            value={globalState?.state?.cleaningLogistics?.day}
                                            text={
                                                Scripts.CleaningService.CleaningLogistics
                                                    .PreferredDayLBL
                                            }
                                            pageName={
                                                translationsKeys.CleaningService.CleaningLogistics
                                                    .PageName
                                            }
                                            translationKey={
                                                translationsKeys.CleaningService.CleaningLogistics
                                                    .PreferredDayLBL
                                            }
                                            showEditButton={false}
                                        ></ServiceField>
                                    </Box>

                                    <Box mb={2}>
                                        <ServiceField
                                            name={CLEANING_SERVICE_EDIT_FIELDS.DATE_TIME}
                                            value={
                                                globalState?.state?.cleaningLogistics?.timeSlot ||
                                                ''
                                            }
                                            text={
                                                Scripts.CleaningService.CleaningLogistics
                                                    .PreferredTimeLBL
                                            }
                                            pageName={
                                                translationsKeys.CleaningService.CleaningLogistics
                                                    .PageName
                                            }
                                            translationKey={
                                                translationsKeys.CleaningService.CleaningLogistics
                                                    .PreferredTimeLBL
                                            }
                                            showEditButton={false}
                                        ></ServiceField>
                                    </Box>
                                </Box>

                                <Box display={{ base: 'none', lg: 'block' }}>
                                    <hr style={{ marginTop: '8px' }} />
                                </Box>
                                <Box mt={'16px'} mb={'16px'}>
                                    <hr
                                        style={{
                                            border: 'none',
                                            borderTop: '2px solid #adaaaa',
                                        }}
                                    />
                                </Box>
                                <Box w={{ base: 'full', lg: '45%' }}>
                                    <Text my={3} fontWeight={700}>
                                        <TextScript
                                            text={commonScripts?.paymentBreakdown}
                                            translationKey={`${translationsKeys?.ConfirmationAfterSubmission.PageName}/${translationsKeys?.ConfirmationAfterSubmission.lblPaymentBreakdown}`}
                                        />
                                    </Text>

                                    <Flex mb={3} direction="row" justifyContent="space-between">
                                        <Text>
                                            <TextScript
                                                text={Scripts.CleaningService.Terms.SRF}
                                                translationKey={`${translationsKeys.CleaningService.Terms.PageName}/${translationsKeys.CleaningService.Terms.SRF}`}
                                            ></TextScript>
                                        </Text>
                                        {currencySymbol && (
                                            <Text>
                                                {`${currencySymbol}${globalState?.state?.selectedDeviceModel?.DeviceTypeModelPrice?.toString()}`}
                                            </Text>
                                        )}
                                    </Flex>

                                    <hr
                                        style={{
                                            border: 'none',
                                            borderTop: '2px dotted #adaaaa',
                                        }}
                                    />

                                    <Flex my={2} direction="row" justifyContent="space-between">
                                        <Text>
                                            <TextScript
                                                text={Scripts.Global.Subtotal}
                                                translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Subtotal}`}
                                            />
                                        </Text>

                                        {currencySymbol && (
                                            <Text>
                                                {`${currencySymbol}${globalState?.state?.selectedDeviceModel?.DeviceTypeModelPrice?.toString()}`}
                                            </Text>
                                        )}
                                    </Flex>
                                    <Flex mb={2} direction="row" justifyContent="space-between">
                                        <Text>
                                            <TextScript
                                                text={Scripts.Global.Discount}
                                                translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Discount}`}
                                            />
                                        </Text>
                                        {currencySymbol && (
                                            <Text>
                                                {`${currencySymbol}${globalState?.state?.selectedDeviceModel?.DiscountPrice?.toString()}`}
                                            </Text>
                                        )}
                                    </Flex>
                                    <Flex mb={2} direction="row" justifyContent="space-between">
                                        <Text fontWeight={700}>
                                            <TextScript
                                                text={Scripts.Global.Total}
                                                translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Total}`}
                                            />
                                        </Text>
                                        {currencySymbol && (
                                            <Text fontWeight={700}>
                                                {`${currencySymbol}${globalState?.state?.selectedDeviceModel?.PricewithDiscount?.toString()}`}
                                            </Text>
                                        )}
                                    </Flex>
                                </Box>
                                <Box mt={'16px'} mb={'16px'}>
                                    <hr
                                        style={{
                                            border: 'none',
                                            borderTop: '2px solid #adaaaa',
                                        }}
                                    />
                                </Box>
                            </Flex>
                        </Box>
                    </Box>

                    <Box
                        position={{ base: 'relative', lg: 'fixed' }}
                        bottom="0"
                        w="full"
                        mt={'36px'}
                        pb="10px"
                        bgColor={{ base: '', lg: '#faf9f5' }}
                        display={true ? 'block' : 'none'}
                    >
                        <Box display={{ base: 'none', lg: 'block' }}>
                            <hr
                                style={{
                                    border: 'none',
                                    borderTop: '2px solid #adaaaa',
                                }}
                            />
                        </Box>
                        <Flex
                            direction={{ base: 'column', lg: 'row-reverse' }}
                            align={'center'}
                            py="10px"
                        >
                            <Button
                                paddingX={'16px'}
                                paddingY={'12px'}
                                colorScheme={'primary'}
                                // minWidth={'343px'}
                                w={{ base: 'full', lg: '13%' }}
                                margin={'auto'}
                                onClick={() => onSurveyClick()}
                            >
                                <TextScript
                                    text={commonScripts?.Rate}
                                    translationKey={`${translationsKeys?.ConfirmationAfterSubmission.PageName}/${translationsKeys?.ConfirmationAfterSubmission.lblRate}`}
                                />
                            </Button>
                            <Button
                                paddingX={'16px'}
                                paddingY={'12px'}
                                color={accent}
                                // minWidth={'343px'}
                                w={{ base: 'full', lg: '5%' }}
                                margin={'auto'}
                                onClick={() => onClosePageClick()}
                                cursor="pointer"
                                variant="link"
                                as={'u'}
                            >
                                <TextScript
                                    text={Scripts.Global.CloseThisPage}
                                    translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.CloseThisPage}`}
                                />
                            </Button>
                        </Flex>
                    </Box>
                </Box>
            </MainStackLayout>
        </Box>
    );
}

export default CleaningServiceConfirmation;
