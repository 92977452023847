import { Chat } from '@MGPD/myasurion-shared';
import { Box, UseDisclosureProps } from '@chakra-ui/react';

export const ChatOverlay = ({
    isOpen,
    isMinimized,
    onClose,
    onMinimize,
}: UseDisclosureProps & { onMinimize(): void; isMinimized: boolean }) => {
    // const [accountInfo] = useAccount<AccountInfo>({ profileId: '', userId: '' });
    // const chatConfig = getChatConfig(accountInfo);
    //this is a test
    return (
        <>
            {isOpen && (
                <Box
                    position="absolute"
                    w={{ base: 'full', lg: '350px' }}
                    h={{ base: 'full', xs: '800px' }}
                    mt={{ base: 'unset', lg: '30px' }}
                    top="56px"
                    bottom={{ base: 0, lg: 8 }}
                    right={{ base: 0, lg: 8 }}
                    zIndex="999"
                    borderRadius="lg"
                    boxShadow="lg"
                    visibility={isMinimized ? 'hidden' : 'visible'}
                    bg="white"
                >
                    <Chat
                        onMinimize={onMinimize}
                        onClose={() => {
                            onClose?.();
                        }}
                        isLoggedIn={true}
                    />
                </Box>
            )}
        </>
    );
};
