const baseApi = import.meta.env.VITE_API_BASE_URL;

export const Env = {
    isDev: import.meta.env.VITE_ENV,
    apiKey: import.meta.env.VITE_API_KEY,
};

export const Api = {
    urls: {
        updateProfile: () => `${baseApi}/account/profile`,
        sendFeedback: () => `${baseApi}/feedback/submit`,
        changePassword: () => `${baseApi}/account/password`,
    },
};
