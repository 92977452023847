import { TextScript, useConfig } from '@MGPD/myasurion-shared';
import { Box, Flex, Stack } from '@chakra-ui/layout';

import { notificationApps } from '../modules/config/constants';
import NotificationItemCheckbox from './NotificationItemCheckbox';

export interface NotificationItemApps {
    [key: string]: boolean;
}

export interface NotificationItem {
    id: string;
    title: string;
    description: string;
    apps?: any;
    onToggleCheck?: () => void;
}

export const NotificationItem = ({
    id,
    title,
    description,
    apps,
    onToggleCheck,
}: NotificationItem) => {
    const { data } = useConfig();
    const { theme } = data;
    const { components, notification: image } = theme;
    const notificationItem = components?.notificationItem || {
        display: 'flex',
        flexFlow: 'row nowrap',
        background: '#FFF',
        border: '1px solid #EBEBEB',
        boxShadow: '0px 1px 0px 0px #EBEBEB inset, 0px -1px 0px 0px #EBEBEB inset',
    };

    return (
        <Stack direction={'column'} id={`myasurion-notificationitem-${id}`}>
            <Box {...notificationItem}>
                <Box
                    w="100px"
                    h="100px"
                    minW="100px"
                    display="flex"
                    flexFlow="column nowrap"
                    justifyContent="center"
                    alignItems="center"
                    m={0}
                    background="#F0F0F5"
                >
                    {<Box as="svg" backgroundImage={image[id]} w="60px" h="60px" />}
                </Box>
                <Flex
                    flexFlow="column nowrap"
                    justifyContent="center"
                    alignItems="flex-start"
                    p="0 12px"
                    gap="4px"
                >
                    {/* <Text fontWeight="bold" lineHeight="19.84px">
            <TextTranslation
              text={title || ''}
              translationKey={`notificationsPreference-${title || ''}`}
              module={'myAsurionShared'}
              section={'notifications'}
              disabled={false}
            />
          </Text> */}
                    <Box fontWeight="bold" lineHeight="19.84px">
                        <TextScript
                            text={title}
                            translationKey={`CommunicationPreferences/notificationsPreference-${
                                title || ''
                            }`}
                        />
                    </Box>
                    {/* <Text fontWeight="400" lineHeight="19.84px">
            <TextTranslation
              text={description || ''}
              translationKey={`notificationsPreference-${description || ''}`}
              module={'myAsurionShared'}
              section={'notifications'}
            />
          </Text> */}
                    <Box fontWeight="400" lineHeight="19.84px">
                        <TextScript
                            text={description}
                            translationKey={`CommunicationPreferences/notificationsPreference-${
                                description || ''
                            }`}
                        />
                    </Box>
                </Flex>
            </Box>
            <Flex flexFlow="column nowrap" padding={['0 16px', '0']}>
                {notificationApps.map((notif, i) => {
                    return (
                        <NotificationItemCheckbox
                            id={notif.id}
                            notifType={id}
                            key={`myasurion-notificationapps-${i}`}
                            title={notif.label}
                            value={apps && apps[notif.id] ? true : false}
                            onToggleCheck={onToggleCheck}
                        />
                    );
                })}
            </Flex>
        </Stack>
    );
};

export default NotificationItem;
