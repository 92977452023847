// lodashUtils.ts
import * as lodash from 'lodash';

const isNotEmpty = (value: any): boolean => {
  return !lodash.isEmpty(value);
};

const isNullOrUndefined = (value: any): boolean => {
  return lodash.isNull(value) || lodash.isUndefined(value);
};

const isNullOrUndefinedOrEmpty = (value: any): boolean => {
  return lodash.isNull(value) || lodash.isUndefined(value) || lodash.isEmpty(value);
};

const hasKey = (obj: object, key: string): boolean => {
  return lodash.has(obj, key);
};

const isNumberAndPositive = (value: any): boolean => {
  return lodash.isNumber(value) && value > 0;
};

const isArrayAndNotEmpty = (value: any): boolean => {
  return lodash.isArray(value) && !lodash.isEmpty(value);
};

const isObjectAndNotEmpty = (value: any): boolean => {
  return lodash.isObject(value) && !lodash.isEmpty(value);
};

const lodashUtils = {
  ...lodash, // Default Lodash Functions
  isNotEmpty,
  isNullOrUndefined,
  isNullOrUndefinedOrEmpty,
  hasKey,
  isNumberAndPositive,
  isArrayAndNotEmpty,
  isObjectAndNotEmpty,
};

export default lodashUtils;