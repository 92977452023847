import { Button, DesktopView, MobileView, TextScript } from '@MGPD/myasurion-shared';
import { Box, HStack, Text } from '@chakra-ui/react';

import translationsKeys from '../Scripts/translationsKeys.json';

interface FeedbackProps {
    title: string;
    description: string;
    buttonText?: string;
    buttonLink?: string;
    feedbackGradient?: string;
    feedbackColor?: string;
    clientOfferName: string;
}

const Feedback = ({
    title,
    description,
    buttonText = '',
    feedbackGradient = '#1ED760',
    feedbackColor = 'white',
    clientOfferName,
}: FeedbackProps) => {
    const DoorStepDataTransferKeys = translationsKeys.DoorStepDataTransfer;

    return (
        <Box
            id={'myasurion-portal-chatstart'}
            h={{ base: '180px', md: '104px' }}
            w={'full'}
            mt={'1rem'}
            px={4}
            py="24px"
            bg={feedbackGradient}
            display="flex"
            justifyContent="center"
        >
            <HStack
                h="full"
                w="full"
                maxWidth={{ base: '800px', md: '1024px' }}
                justifyContent="center"
                gap={'20px'}
            >
                <DesktopView>
                    <Box h="full" display={'flex'} alignItems={'center'}>
                        <FeedbackIconSvg color={feedbackColor} />
                    </Box>
                </DesktopView>
                <Box h="full" color={feedbackColor}>
                    <Box mb={2}>
                        <Text fontSize="24px">
                            <TextScript
                                text={title}
                                translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.feedback.KeyTitle}`}
                            />
                        </Text>
                        <Text>
                            <TextScript
                                text={description}
                                translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.feedback.KeyDescription}`}
                                dynamicValues={[clientOfferName]}
                            />
                        </Text>
                    </Box>
                    <MobileView>
                        <Button display={'none'} variant="outline" colorScheme={feedbackColor}>
                            <TextScript text={buttonText} translationKey="Feedback/buttonText" />
                        </Button>
                    </MobileView>
                </Box>
                <DesktopView>
                    <Box h="full" display={'flex'} alignItems={'center'}>
                        <Button display={'none'} variant="outline" colorScheme={feedbackColor}>
                            <TextScript text={buttonText} translationKey="Feedback/buttonText" />
                        </Button>
                    </Box>
                </DesktopView>
                <MobileView>
                    <Box h="full" justifyContent="start" alignItems="start">
                        <FeedbackIconSvg color={feedbackColor} />
                    </Box>
                </MobileView>
            </HStack>
        </Box>
    );
};

const FeedbackIconSvg = ({ color }: { color: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
        >
            <path
                d="M23.9958 28C19.8958 28 16.3838 25.528 14.8398 22H33.1518C31.6078 25.528 28.0958 28 23.9958 28Z"
                fill={color}
            />
            <path
                d="M17.9961 17C19.3768 17 20.4961 15.8807 20.4961 14.5C20.4961 13.1193 19.3768 12 17.9961 12C16.6154 12 15.4961 13.1193 15.4961 14.5C15.4961 15.8807 16.6154 17 17.9961 17Z"
                fill={color}
            />
            <path
                d="M32.4961 14.5C32.4961 15.8807 31.3768 17 29.9961 17C28.6154 17 27.4961 15.8807 27.4961 14.5C27.4961 13.1193 28.6154 12 29.9961 12C31.3768 12 32.4961 13.1193 32.4961 14.5Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9961 36V44L22.6641 36H39.9961C42.2041 36 43.9961 34.206 43.9961 32V8C43.9961 5.792 42.2041 4 39.9961 4H7.99609C5.78809 4 3.99609 5.792 3.99609 8V32C3.99609 34.206 5.78809 36 7.99609 36H11.9961ZM7.99609 32V8H39.9961V32H21.3281L15.9961 36V32H7.99609Z"
                fill={color}
            />
        </svg>
    );
};

export default Feedback;
