/* eslint-disable no-console */
import {
    SubHeader,
    TextScript,
    getTextScript,
    getTypeByDomain,
    useConfig,
    useSessionStorage,
    useTextScript,
} from '@MGPD/myasurion-shared';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Text,
    UnorderedList,
    ListItem
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import useContentfulImage from '../../hooks/useContentfulImage';
//Local Images
import phoneImg from '../../icons/BYODPhone.png';
import CalendarIcon from '../../icons/CalendarIcon';
import earphonesimg from '../../icons/Earphones.png';
import headphonesimg from '../../icons/Headphones.png';
import laptopimg from '../../icons/Laptop.png';
import smartwatchimg from '../../icons/Smartwatches.png';
import tabletimg from '../../icons/Tablet.png';
// import gameconsolesimg from '../../icons/GameConsoles.png';
import gameconsolesimg from '../../icons/game-consoles.png';
import MainStackLayout from '../../layout/MainStackLayout';
import {
    createInquiry,
    deviceRegistration,
    getMakeModel,
} from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { DeviceSpecsFieldConfig } from '../../types/types';
import { CONTENTFUL_SLUG } from '../../utils/constant';
import {
    getAddDeviceFields,
    showDeviceCategoryFromCode,
} from '../../utils/featuresUtills';
import {
    formatDate,
    getDateAndDay,
    getRedirectUrl,
    isEarPhones,
    isGameConsoles,
    isHeadPhones,
    isLaptop,
    isPhone,
    isSmartWatches,
    isTablet,
    saveError,
    showErrorPage,
} from '../../utils/utills.tsx';
import DeviceSpecs from './DeviceSpecs';
import FindIMEINoModal from './FindIMEINoModal';

function BYODDeviceDetails() {
    const navigate = useNavigate();
    const globalState = useCentralStore();
    const { isTranslationsLoading, language } = useTextScript();

    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const [gadgetCategoryName] = useSessionStorage<string>('gadgetCategoryName', '');

    const laptop = useContentfulImage(CONTENTFUL_SLUG.LAPTOP);
    const tablet = useContentfulImage(CONTENTFUL_SLUG.TABLET);
    const gameConsoles = useContentfulImage(CONTENTFUL_SLUG.GAMECONSOLE);
    const healthexercise = useContentfulImage(CONTENTFUL_SLUG.HEALTHEXERCISE);
    const headphones = useContentfulImage(CONTENTFUL_SLUG.HEADPHONES);
    const earphonesImg = useContentfulImage(CONTENTFUL_SLUG.EARPHONEPHONE);

    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const selectedCategory = gadgetCategoryName?.toUpperCase();
    const EmailAddress = agreementData1?.ContactPoints?.ContactPoint?.filter(
        (a: any) => a.EmailAddress
    );

    const MobileDeviceNumber = agreementData1?.ContactPoints?.ContactPoint?.filter((a: any) => {
        return a.PhoneNumber;
    });
    const intializeResponse = globalState?.state?.sessionResponse?.InitializeResponse;

    const enrolledAssetData =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0]?.Assets?.Asset?.filter(
            (a: any) => {
                return (
                    a?.AssetInstance?.toUpperCase() === 'ENROLLED' ||
                    a?.AssetInstance?.toUpperCase() === 'CUSVERIFY'
                );
            }
        );
    const isBYOD = agreementData1?.isBYODCustomer;

    const [openCancelPopup, setOpenCancelPopup] = useState(false);

    const [date, setDate] = useState<string>('');
    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
    const [purchaseDateLbl, setPurchaseDateLbl] = useState<string>('');
    const [fields, setFields] = useState<any>([]);
    const [otherSelected, setOther] = useState<any>(false);
    const [otherBrand, setBrandOther] = useState<any>(false);
    const [otherModel, setModelOther] = useState<any>(false);
    const [AssetCatalogId, setAssetCatalogId] = useState<any>('');
    const [customerEmail, setCustomerEmail] = useState(EmailAddress[0]?.EmailAddress);
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [isEnrollmentEmail, setIsEnrollmentEmail] = useState(true);

    const AddNewDeviceScripts = Scripts.AddDevice.AddNewDevice;
    const AddNewDeviceKeys = translationsKeys.AddDevice.AddNewDevice;
    const ThingsToNoteBYODKeys = translationsKeys.ThingsToNoteBYOD;

    const AssetPurchaseDate = date && getDateAndDay(date, 'MMMM D, YYYY');
    const pageName = AddNewDeviceKeys.PageName;
    const futureDate = getDateAndDay(new Date(), 'YYYY-MM-DD');
    const [pageNo, setPageNo] = useState(0);
    const [conditionMeets, setConditionMeets] = useState(false);

    const getFormValues = () => {
        const purchaseDate = formatDate(date, 'YYYY-MM-DD', 'en-US', 'MMMM D, YYYY');
        //const categoryDetails = AssetCategoryDetails?.filter((a: any) => a.CategoryName?.toUpperCase() === selectedCategory)
        // const categoryListResponse = globalState?.state?.categoryListResponse || SelectedAddGadgetData;
        // const categoryDetails = categoryListResponse?.filter((a: any) => a.CategoryName?.toUpperCase() === selectedCategory)
        // const AssetCategoryId = categoryDetails?.map((a: any) => { return a.AssetCategoryId })
        let obj = {
            //   AssetCategoryId: AssetCategoryId?.[0],
            Category: selectedCategory,
            AssetCatalogId: AssetCatalogId,
            Brand: getFieldByFieldName('Brand')?.value,
            Family: getFieldByFieldName('Family')?.value,
            Model: getFieldByFieldName('Model')?.value,
            Connectivity: getFieldByFieldName('Connectivity')?.value,
            Capacity: getFieldByFieldName('Capacity')?.value,
            Storage: getFieldByFieldName('Storage')?.value,
            StorageType: getFieldByFieldName('StorageType')?.value,
            RRP: getFieldByFieldName('RRP')?.value,
            RAM: getFieldByFieldName('RAM')?.value,
            SerialNumber: getFieldByFieldName('SerialNumber')?.value,
            IMEI: getFieldByFieldName('IMEI')?.value,
            ModelNumber: getFieldByFieldName('ModelNumber')?.value,
            FaceSize: getFieldByFieldName('CaseSize')?.value,
            DateOfPurchase: purchaseDate,
            Processor: getFieldByFieldName('Processor')?.value,
        };
        return obj;
    };

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    useEffect(() => {
        if (!isTranslationsLoading) {
            getPurchaseDateLbl();
        }
    }, [language, isTranslationsLoading]);

    useEffect(() => {
        if (agreementData1) {
            const addDeviceFields = getAddDeviceFields(
                agreementData1?.ClientOffer?.ClientOfferName,
                selectedCategory
            );
            setFields(addDeviceFields);
        }
    }, [selectedCategory, agreementData1]);

    useEffect(() => {
        if (globalState?.state?.modelDataUpdateDevice) {
            const BrandField = getFieldByFieldName('Brand');
            if (
                globalState?.state?.modelDataUpdateDevice?.length &&
                fields?.length > 0 &&
                BrandField?.data?.length === 0
            ) {
                const BrandNames = globalState?.state?.modelDataUpdateDevice?.map((val: any) => {
                    return val.Make;
                });
                BrandNames.sort();
                BrandNames.push('Others');
                const filterBrands = Array.from(new Set(BrandNames));
                updateFields(BrandField, filterBrands, false);
            }
        }
    }, [globalState?.state?.modelDataUpdateDevice, fields]);

    const getPurchaseDateLbl = async () => {
        const purchaseDateLbl = await getTextScript(
            `${AddNewDeviceKeys.PageNameAnchorDevice}/${AddNewDeviceKeys.purchaseDate}`,
            AddNewDeviceScripts.purchaseDate,
            language,
            []
        );

        setPurchaseDateLbl(purchaseDateLbl);
    };
    useEffect(() => {
        // setAssetModels(undefined);
        // setFields((prevFormFields: any) => ({
        //     ...prevFormFields,
        //     ['model']: '',
        // }));
        let _assetModels = [...new Set(globalState?.state?.modelDataUpdateDevice)]
            ?.filter((val: any) => {
                return val?.Family === fields[1]?.value;
            })
            ?.map((v: any) => {
                return v?.AssetCatalogName;
            });
        // setFields(_assetModels);
        // setFields((prevFormFields: any) => ({
        //     ...prevFormFields,
        //     ['model']: _assetModels,
        // }));
        setFields((prevFields: any) =>
            prevFields.map((item: any) =>
                item.name === 'Model'
                    ? {
                          ...item,
                          data: _assetModels,
                      }
                    : item
            )
        );
    }, [fields[1]?.value]);
    useEffect(() => {
        if (fields[0] !== undefined) {
            // setFormFields((prevFormFields) => ({
            //     ...prevFormFields,
            //     ['family']: '',
            // }));
            globalState?.dispatch({
                type: ActionTypes.UPDATEDEVICE_ASSET_MODELS_DATA,
                payload: undefined,
            });
            setLoading(true);
            getMakeModel(
                fields[0]?.value || '',
                globalState?.state?.sessionResponse?.InitializeResponse?.ClientId,
                interactionLineId,
                globalState?.state?.findAgreementSessionData
            )
                .then((resp) => {
                    setLoading(false);
                    globalState?.dispatch({
                        type: ActionTypes.UPDATEDEVICE_ASSET_MODELS_DATA,
                        payload: resp?.AssetDetials,
                    });
                    let deviceFamilyData = resp?.AssetDetials?.map((v: any) => {
                        return {
                            label: v?.Family,
                            value: v?.Family,
                        };
                    });

                    var resArr: any = [];
                    deviceFamilyData?.filter(function (item: any) {
                        var i = resArr.findIndex((x: any) => x.label == item.label);
                        if (i <= -1) {
                            resArr.push(item);
                        }
                        return null;
                    });
                    const familData = resArr?.filter((v: any) => {
                        return (
                            v?.label !== '' &&
                            v?.label !== undefined &&
                            v?.label !== null &&
                            v.label
                        );
                    });
                    const familData1 = familData.map((data: any) => data.label);
                    setFields((prevFields: any) =>
                        prevFields.map((item: any) =>
                            item.name === 'Family'
                                ? {
                                      ...item,
                                      data: familData1,
                                      value: ''
                                  }
                                : item
                        )
                    );
                })
                .catch((err) => {
                    setLoading(false);
                    console.log('validate imei:', err);
                    saveError(err, globalState);
                    showErrorPage(navigate);
                });
        }
    }, [fields[0]?.value]); //formFields.make

    const onClickPopup = () => {
        setOpenCancelPopup(!openCancelPopup);
    };

    const getImagesFromContentFul = (CategoryName: any) => {
        switch (CategoryName?.toUpperCase()) {
            case 'LAPTOP':
                return laptop.imageUrl;

            case 'HEADPHONES':
                return headphones.imageUrl;

            case 'TABLET':
                return tablet.imageUrl;

            case 'GAME CONSOLES':
                return gameConsoles.imageUrl;

            case 'EARPHONES':
                return earphonesImg.imageUrl;

            case 'SMARTWATCHES':
                return healthexercise.imageUrl;
        }
    };

    const getImagesFromLocal = (CategoryName: any) => {
        switch (CategoryName?.toUpperCase()) {
            case 'PHONE':
                return phoneImg;

            case 'LAPTOP':
                return laptopimg;

            case 'HEADPHONES':
                return headphonesimg;

            case 'TABLET':
                return tabletimg;

            case 'GAME CONSOLES':
                return gameconsolesimg;

            case 'EARPHONES':
                return earphonesimg;

            case 'SMARTWATCHES':
                return smartwatchimg;
        }
    };

    const onCancelClick = () => {
        window.location.href = getRedirectUrl(getTypeByDomain());
    };

    const handleSelectChange = (event: any, field: DeviceSpecsFieldConfig) => {
        let isInvalid = false;
        const selectedValue = event.value;
        if (field.name === 'Brand') {
            if (selectedValue !== '') {
                clearFieldsForBrand();
                if (selectedValue === 'Others') {
                    setOther(true);
                    setAssetCatalogId('');
                    setBrandOther(true);
                    setModelOther(false);
                    setOtherValues();
                } else {
                    setOther(false);
                    setBrandOther(false);
                    const BrandField = getFieldByFieldName('Brand');
                    updateFields(BrandField, selectedValue, isInvalid);

                    // const ModelField = getFieldByFieldName('Model');
                    // updateFields(ModelField, '', isInvalid);

                    // const FamilyField = getFieldByFieldName('Family');
                    // updateFields(FamilyField, '', isInvalid);
                }
            }
        } else if (field.name === 'Model') {
            if (selectedValue !== '') {
                clearFieldsForModel(selectedValue);
                if (selectedValue === 'Others') {
                    setOther(true);
                    setModelOther(true);
                    OtherModelSelected();
                    setAssetCatalogId('');
                } else {
                    setOther(false);
                    setModelOther(false);
                    const AssetCatalogId = globalState?.state?.modelDataUpdateDevice?.find(
                        ({ AssetCatalogName }: any) => AssetCatalogName === selectedValue
                    )?.CatalogId;
                    setAssetCatalogId(AssetCatalogId);
                    const ModelField = getFieldByFieldName('Model');
                    updateFields(ModelField, selectedValue, isInvalid);
                }
            }
        } else if (field.name === 'Family') {
            setOther(false);
            setBrandOther(false);
            const FamilyField = getFieldByFieldName('Family');
            updateFields(FamilyField, selectedValue, isInvalid);
        } else {
            if (selectedValue === 'Others') {
                setOther(true);
                SetOtherAttributes(field);
                setAssetCatalogId('');
            } else {
                setOther(false);
                clearOtherAtr(field);
                updateFields(field, selectedValue, isInvalid);
            }
        }
    };

    const handleInputChange = (field: DeviceSpecsFieldConfig, value: string) => {
        let isInvalid = false;
        if (field.name === 'IMEI') {
            const regex = /^[0-9]{15}(,[0-9]{15})*$/;
            if (regex.test(value)) {
                isInvalid = false;
            } else {
                isInvalid = true;
            }
        }
        updateFields(field, value, isInvalid);
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDate(e.target.value);
    };

    const handleSubmit = () => {
        if (showDeviceCategoryFromCode(agreementData1?.ClientOffer?.ClientOfferName)) {
            navigate('/uploadPurchaseReceipt', { replace: true });
        } else {
            if (
                isLaptop(selectedCategory) ||
                isTablet(selectedCategory) ||
                isPhone(selectedCategory) ||
                otherSelected
            ) {
                callInquiryRegisterDeviceApi();
            } else {
                callDeviceRegistrationApi();
            }
        }
    };

    const inputRef = useRef<HTMLInputElement>(null);

    const showPicker = () => {
        inputRef?.current?.showPicker();
    };

    const onBackClick = () => {
        if (isLaptop(selectedCategory) || isTablet(selectedCategory)) {
            navigate('/thingsToNote', { replace: true });
        } else {
            if (pageNo > 0) {
                setPageNo(pageNo => pageNo - 1);
            } else {
                window.history.back();
            }
            // navigate('/deviceCategoryList', { replace: true });
        }
    };

    const getFieldByFieldName = (fieldName: string) => {
        return fields?.find((item: any) => {
            return item.name === fieldName;
        });
    };

    const setOtherValues = () => {
        setFields((prevFields: any) =>
            prevFields.map((item: any) => {
                return {
                    ...item,
                    value: '',
                    isInvalid: false,
                    Other: 'Others',
                    showSelect: false,
                };
            })
        );
    };

    const OtherModelSelected = () => {
        setFields((prevFields: any) =>
            prevFields.map((item: any) =>
                item.name === 'Brand'
                    ? {
                          ...item,
                          isInvalid: false,
                          Other: '',
                          showSelect: false,
                      }
                    : {
                          ...item,
                          Other: 'Others',
                          showSelect: false,
                          value: '',
                      }
            )
        );
    };

    const SetOtherAttributes = (field: any) => {
        setFields((prevFields: any) =>
            prevFields.map((item: any) =>
                item?.showSelect
                    ? {
                          ...item,
                          isInvalid: false,
                          Other: 'Others',
                          showSelect: true,
                      }
                    : item.name === field?.name
                    ? {
                          ...item,
                          value: '',
                          isInvalid: false,
                          Other: 'Others',
                          showSelect: true,
                      }
                    : {
                          ...item,
                          showSelect: false,
                          Other: '',
                      }
            )
        );
    };

    const clearOtherAtr = (field: any) => {
        setFields((prevFields: any) =>
            prevFields.map((item: any) =>
                item.name === field?.name
                    ? {
                          ...item,
                          //data: [],
                          isInvalid: false,
                          Other: '',
                          showSelect: false,
                      }
                    : {
                          ...item,
                      }
            )
        );
    };

    const clearFieldsForBrand = () => {
        setFields((prevFields: any) =>
            prevFields.map((item: any) =>
                item.name !== 'Brand'
                    ? {
                          ...item,
                          data: [],
                          value: '',
                          isInvalid: false,
                          Other: '',
                          showSelect: false,
                      }
                    : {
                          ...item,
                          value: '',
                          Other: '',
                          showSelect: false,
                      }
            )
        );
    };

    const clearFieldsForModel = (value: string) => {
        setFields((prevFields: any) =>
            prevFields.map((item: any) =>
                item.name !== 'Model' && item.name !== 'Brand' && item.name !== 'Family'
                    ? {
                          ...item,
                          data: [],
                          value: '',
                          isInvalid: false,
                          Other: '',
                          showSelect: false,
                      }
                    : item.name === 'Model'
                    ? {
                          ...item,
                          value: value,
                          Other: '',
                          showSelect: false,
                      }
                    : item
            )
        );
    };

    const updateFields = (field: any, value: any, isInvalid: boolean) => {
        if (typeof value === 'string') {
            if (value === 'Others') {
                setFields((prevFields: any) =>
                    prevFields.map((item: any) =>
                        item.name === field.name
                            ? {
                                  ...item,
                                  Other: value,
                                  isInvalid: isInvalid,
                                  value: '',
                              }
                            : item
                    )
                );
            } else {
                setFields((prevFields: any) =>
                    prevFields.map((item: any) =>
                        item.name === field.name
                            ? {
                                  ...item,
                                  value: value,
                                  isInvalid: isInvalid,
                              }
                            : item
                    )
                );
            }
        } else {
            setFields((prevFields: any) =>
                prevFields.map((item: any) =>
                    item.name === field?.name
                        ? {
                              ...item,
                              data: value,
                              value: '',
                              isInvalid: isInvalid,
                          }
                        : item
                )
            );
        }
    };

    const isAnyRequiredFieldInvalid = (fields: any) => {
        for (const field of fields) {
            if (field?.isRequired && field?.isInvalid) {
                return true;
            }
            if (field?.isRequired && (field?.value === null || field?.value === '')) {
                return true;
            }
            if (date === '') {
                return true;
            }
        }
        return false;
    };

    const getInquiryDescription = (separator: any) => {
        //do else if
        const AddedDeviceDetails = getFormValues();
        let inquiryDescription: any;
        if (isLaptop(selectedCategory)) {
            inquiryDescription = `${
                'Processor=' +
                AddedDeviceDetails?.Processor +
                separator +
                'RAM=' +
                AddedDeviceDetails?.RAM +
                separator +
                'Storage_Size=' +
                (AddedDeviceDetails?.Storage || AddedDeviceDetails.Capacity) +
                separator +
                'Storage_Type=' +
                AddedDeviceDetails?.StorageType +
                separator +
                'Brand=' +
                AddedDeviceDetails?.Brand +
                separator +
                'Model=' +
                AddedDeviceDetails?.Model +
                separator +
                'SerialNumber=' +
                AddedDeviceDetails?.SerialNumber +
                separator +
                'AssetCatalogId=' +
                AssetCatalogId +
                separator
            } `;
        }
        if (isTablet(selectedCategory)) {
            inquiryDescription = `${
                'RAM=' +
                AddedDeviceDetails?.RAM +
                separator +
                'Capacity=' +
                AddedDeviceDetails?.Capacity +
                separator +
                'Connectivity=' +
                AddedDeviceDetails?.Connectivity +
                separator +
                'AssetId=' +
                enrolledAssetData?.[0]?.AssetId +
                separator +
                'AssetCatalogId=' +
                AssetCatalogId +
                separator +
                'Brand=' +
                AddedDeviceDetails?.Brand +
                separator +
                'Model=' +
                AddedDeviceDetails?.Model +
                separator +
                'SerialNumber=' +
                AddedDeviceDetails?.SerialNumber +
                separator
            } `;
        }
        if (isSmartWatches(selectedCategory)) {
            inquiryDescription = `${
                'Connectivity=' +
                AddedDeviceDetails?.Connectivity +
                separator +
                'Face_Size=' +
                AddedDeviceDetails?.FaceSize +
                separator +
                'Brand=' +
                AddedDeviceDetails?.Brand +
                separator +
                'Model=' +
                AddedDeviceDetails?.Model +
                separator +
                'SerialNumber=' +
                AddedDeviceDetails?.SerialNumber +
                separator +
                'AssetCatalogId=' +
                AssetCatalogId +
                separator
            } `;
        }
        if (isHeadPhones(selectedCategory) || isEarPhones(selectedCategory)) {
            inquiryDescription = `${
                'Model=' +
                AddedDeviceDetails?.Model +
                separator +
                'Brand=' +
                AddedDeviceDetails?.Brand +
                separator +
                'AssetCatalogId=' +
                AssetCatalogId +
                separator
            } `;
        }
        if (isPhone(selectedCategory)) {
            inquiryDescription = `${
                'Model=' +
                AddedDeviceDetails?.Model +
                separator +
                'AssetId=' +
                enrolledAssetData?.[0]?.AssetId +
                separator +
                'AssetCatalogId=' +
                AssetCatalogId +
                separator +
                'Brand=' +
                AddedDeviceDetails?.Brand +
                separator +
                'SerialNumber=' +
                AddedDeviceDetails?.IMEI +
                separator
            }`;
        }
        if (isGameConsoles(selectedCategory)) {
            inquiryDescription = `${
                'Storage_Size=' +
                AddedDeviceDetails?.Storage +
                separator +
                'Model=' +
                AddedDeviceDetails?.Model +
                separator +
                'Brand=' +
                AddedDeviceDetails?.Brand +
                separator +
                'SerialNumber=' +
                AddedDeviceDetails?.SerialNumber +
                separator +
                'AssetCatalogId=' +
                AssetCatalogId +
                separator
            }`;
        }
        return inquiryDescription?.charAt(inquiryDescription.length - 1) === separator
            ? inquiryDescription.substring(0, inquiryDescription.length - 1)
            : inquiryDescription;
    };

    // Inquiry Api
    const callInquiryRegisterDeviceApi = () => {
        setLoading(true);
        const AddedDeviceDetails = getFormValues();
        const CreateInquiryParams = {
            CreateInquiryParams: {
                MobileDeviceNumber: MobileDeviceNumber?.[0]?.PhoneNumber || '',
                FirstName: agreementData1?.Customers?.Customer[0]?.FirstName || '',
                LastName: agreementData1?.Customers?.Customer[0]?.LastName || '',
                ContactNumber: MobileDeviceNumber?.[0]?.PhoneNumber || '',
                ClientName: intializeResponse?.ClientName,
                InquiryChannel: 'ONLINE',
                InquiryType: isBYOD || otherSelected ? 'Spex Check' : 'Device Registration', //isPhone(selectedCategory) && isBYOD ? 'Device Registration' :
                InquirySubType: isBYOD
                    ? 'Spex Check'
                    : otherSelected
                    ? 'Spex Check Other'
                    : 'Device Registration',
                InquiryDescription: getInquiryDescription('|'),
                CallDriverCategory: 'Master Data Management',
                CallDriverSubCategory: AddedDeviceDetails?.Category,
                ClientId: intializeResponse?.ClientId,
                Email: customerEmail,
                DepartmentType:
                    (isLaptop(AddedDeviceDetails?.Category) ||
                        isTablet(AddedDeviceDetails?.Category) ||
                        isPhone(selectedCategory)) &&
                    !otherSelected
                        ? 'Risk'
                        : 'Supply Chain',
                Priority: 'High',
                ProgramName: agreementData1?.ClientOffer?.ClientOfferName,
                AgreementId: agreementData1?.AgreementId,
                isPremiumPlan: true,
                ClientAccountId: agreementData1?.ClientAccount?.ClientAccountId,
                ClientChannelId: agreementData1?.ClientAccount?.ClientChannelId,
                CustomerId: agreementData1?.Customers?.Customer[0]?.CustomerId,
                AssetId: enrolledAssetData?.[0]?.AssetId,
                DeviceDetails: {
                    Category: AddedDeviceDetails?.Category || AddedDeviceDetails?.Storage,
                    AssetMake: AddedDeviceDetails?.Brand,
                    AssetModel: AddedDeviceDetails?.Model,
                    AssetAttributes: getInquiryDescription('|'),
                    PurchaseDate: AddedDeviceDetails?.DateOfPurchase,
                    SerialNumber: AddedDeviceDetails?.SerialNumber,
                    Face_Size: AddedDeviceDetails?.FaceSize,
                    Connectivity: AddedDeviceDetails?.Connectivity,
                    Storage_Size: AddedDeviceDetails.Storage,
                },
                UpdateDeviceDetails: {
                    AssetCatalogId: AddedDeviceDetails?.AssetCatalogId,
                    IMEI: AddedDeviceDetails?.SerialNumber || '',
                    ClientAssetSkuNumber: AddedDeviceDetails?.Model,
                },
            },
        };
        createInquiry(
            CreateInquiryParams,
            interactionData?.InteractionLine.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((resp: any) => {
                setLoading(false);
                globalState?.dispatch({ type: ActionTypes.SET_INQUIRY_RESPONSE, payload: resp });
                navigate('/confirmDeviceRegistration', { replace: true });
            })
            .catch((error: any) => {
                setLoading(false);
                saveError(error, globalState);
                showErrorPage(navigate);
            });
    };

    const callDeviceRegistrationApi = () => {
        setLoading(true);
        let AddedDeviceDetails = getFormValues();
        // console.log('AddedDeviceDetails', AddedDeviceDetails);

        const DeviceRegistrationParameters = {
            DeviceRegistrationParameters: {
                // AssetCategoryId: AddedDeviceDetails.AssetCategoryId,
                AgreementId: agreementData1?.AgreementId,
                User: `${
                    agreementData1?.Customers?.Customer[0]?.FirstName ||
                    '' + agreementData1?.Customers?.Customer[0]?.LastName ||
                    ''
                }`,
                DeviceDetails: {
                    Category: selectedCategory,
                    Make: AddedDeviceDetails?.Brand,
                    Capacity: AddedDeviceDetails?.Capacity || '',
                    RAM: AddedDeviceDetails?.RAM || '',
                    Connectivity: AddedDeviceDetails.Connectivity || '',
                    SerialNumber: AddedDeviceDetails?.SerialNumber,
                    Face_Size: AddedDeviceDetails?.FaceSize,
                    Storage_Size: AddedDeviceDetails.Storage,
                },
                SerialNumber: AddedDeviceDetails?.SerialNumber
                    ? AddedDeviceDetails?.SerialNumber
                    : AddedDeviceDetails?.IMEI,
                ClientId: intializeResponse?.ClientId,
                ClientAccountId: agreementData1?.ClientAccount?.ClientAccountId,
                ClientChannelId: agreementData1?.ClientAccount?.ClientChannelId,
                ModelNo: AddedDeviceDetails?.ModelNumber,
                PurchaseDate: AddedDeviceDetails?.DateOfPurchase || '',
                PurchasePrice: AddedDeviceDetails?.RRP || '',
            },
        };

        deviceRegistration(
            DeviceRegistrationParameters,
            interactionData?.InteractionLine.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((resp: any) => {
                setLoading(false);
                globalState?.dispatch({
                    type: ActionTypes.SET_DEVICE_REGISTRATION_RESPONSE,
                    payload: resp,
                });
                navigate('/confirmDeviceRegistration', { replace: true });
            })
            .catch((error: any) => {
                setLoading(false);
                saveError(error, globalState);
                showErrorPage(navigate);
            });
    };

    const handleEmailChange = (e: any) => {
        const emailValidater = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
            if (!emailValidater.test(e.target.value)) {
                setIsInvalidEmail(true);
            } else {
                setIsInvalidEmail(false);
            }
        setCustomerEmail(e.target.value);
    };
    const handleCheckboxChange = () => {
        if (!isEnrollmentEmail) {
            setIsInvalidEmail(false);
            setCustomerEmail(EmailAddress[0]?.EmailAddress);
        }
        setIsEnrollmentEmail(!isEnrollmentEmail);
    };
    return (
        <Box>
            <Box w={{ base: 'full', lg: '80%' }} margin="auto" pt={'50px'}>
                <MainStackLayout gap={0} spacing={0}>
                    <SubHeader
                        onClick={onBackClick}
                        backText={'Back'}
                        allowBackButtonClick={false}
                        showCancelButton={false}
                        showBackButton={true}
                        cancelText={Scripts.CancelSR.cancelBtnTextCancel}
                        onCancelClick={onCancelClick}
                    ></SubHeader>
                    {pageNo === 0 ?  <Box
                            id={'myasurion-profile-avatar'}
                            w={{ base: 'full', lg: '80%' }}
                            margin="0 auto"
                            h={'auto'}
                            p={'1rem'}
                        >
                            <Box paddingTop={'16px'}>
                            <Text fontWeight={'400'} fontSize={'24px'} lineHeight={'27px'}>
                                <TextScript
                                    text={Scripts.ThingsToNoteBYOD.HeaderText}
                                    translationKey={`${ThingsToNoteBYODKeys.PageName}/${ThingsToNoteBYODKeys.HeaderText}`}
                                />
                            </Text>
                            <Text paddingTop={'10px'}>
                                <TextScript
                                    text={Scripts.ThingsToNoteBYOD.SubText}
                                    translationKey={`${ThingsToNoteBYODKeys.PageName}/${ThingsToNoteBYODKeys.SubText}`}
                                />
                            </Text>
                            <Box fontWeight={'400'} fontSize={'16px'} lineHeight={'20px'} marginLeft={'2rem'}>
                                <UnorderedList>
                                    <ListItem>
                                        <TextScript
                                            text={Scripts.ThingsToNoteBYOD.FirstLine}
                                            translationKey={`${ThingsToNoteBYODKeys.PageName}/${ThingsToNoteBYODKeys.FirstLine}`}
                                        />
                                    </ListItem>
                                    <ListItem>
                                    <TextScript
                                            text={Scripts.ThingsToNoteBYOD.SecondLine}
                                            translationKey={`${ThingsToNoteBYODKeys.PageName}/${ThingsToNoteBYODKeys.SecondLine}`}
                                        />
                                    </ListItem>
                                    <ListItem>
                                    <TextScript
                                            text={Scripts.ThingsToNoteBYOD.ThirdLine}
                                            translationKey={`${ThingsToNoteBYODKeys.PageName}/${ThingsToNoteBYODKeys.ThirdLine}`}
                                        />
                                    </ListItem>
                                    <ListItem>
                                    <TextScript
                                            text={Scripts.ThingsToNoteBYOD.FourthLine}
                                            translationKey={`${ThingsToNoteBYODKeys.PageName}/${ThingsToNoteBYODKeys.FourthLine}`}
                                        />
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                        </Box>
                            <Box mt={'20'} width={{ base: 'full', lg: '50%' }} marginX={'auto'}>
                            <Flex
                                    pb={{ base: '0', lg: '10px' }}
                                    mb={'auto'}
                                    w={'full'}
                                    justifyContent={'center'}
                                >
                                    <Checkbox
                                        isChecked={conditionMeets}
                                        marginY={'12px'}
                                        alignItems={'flex-start'}
                                        size="lg"
                                        onChange={() => setConditionMeets(!conditionMeets)}
                                        colorScheme="primary"
                                    ></Checkbox>
                                    <Text marginY={'12px'} marginLeft={'8px'} size={'16px'}>
                                    <TextScript
                                        text={Scripts.ThingsToNoteBYOD.AcceptText}
                                        translationKey={`${ThingsToNoteBYODKeys.PageName}/${ThingsToNoteBYODKeys.AcceptText}`}
                                    />
                                    </Text>
                            </Flex>
                                <Button
                                    mt={'5'}
                                    width={'full'}
                                    textAlign={'center'}
                                    colorScheme="primary"
                                    bg={
                                        colors.primary
                                    }
                                    cursor={
                                             'pointer'
                                    }
                                    isDisabled={!conditionMeets}
                                    onClick={() => setPageNo(1)}
                                >
                                    <TextScript
                                        text={Scripts.Global.Next}
                                        translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Next}`}
                                    />
                                </Button>
                            </Box>
                        </Box>: null}
                    {pageNo === 1 && fields?.length > 0 && (
                        <Box
                            id={'myasurion-profile-avatar'}
                            w={{ base: 'full', lg: '80%' }}
                            margin="0 auto"
                            h={'auto'}
                            p={'1rem'}
                        >
                            <Image
                                my={'2'}
                                marginX={'auto'}
                                w={'120px'}
                                h={'120px'}
                                src={
                                    getImagesFromContentFul(selectedCategory) ||
                                    getImagesFromLocal(selectedCategory)
                                }
                                //src={selectedCategoryDetails?.image || selectedCategoryDetails?.localimage}
                                alt="your model"
                            />

                            <Text mt={'5'} fontSize={{ base: '3xl', lg: '4xl' }}>
                                <TextScript
                                    text={AddNewDeviceScripts.header}
                                    translationKey={`${pageName}/${AddNewDeviceKeys.header}`}
                                />
                            </Text>
                            <DeviceSpecs
                                fields={fields}
                                handleInputChange={handleInputChange}
                                handleSelectChange={handleSelectChange}
                                OtherBrand={otherBrand}
                                OtherModel={otherModel}
                            ></DeviceSpecs>
                            <Flex
                                flexDir={'row'}
                                justifyContent={'center'}
                                marginBottom={'2rem'}
                                onClick={() => {
                                    onClickPopup();
                                }}
                            >
                                <Link
                                    cursor={'pointer'}
                                    ml={'2'}
                                    textDecoration={'underline'}
                                    onClick={() => {
                                        onClickPopup();
                                    }}
                                >
                                    <TextScript
                                        text={AddNewDeviceScripts.findIMEINumber}
                                        translationKey={`${pageName}/${AddNewDeviceKeys.findSerialNumber}`}
                                    />
                                </Link>
                            </Flex>
                            {
                                // (((selectedCategory === 'PHONE' || selectedCategory === 'TABLET') && isBYOD) || showDeviceCategoryFromCode(agreementData1?.ClientOffer?.ClientOfferName)) &&
                                <Box onClick={() => showPicker()} position={'relative'} mb={'10px'}>
                                    <Input
                                        size="md"
                                        borderColor={'#A5AAAF'}
                                        max={futureDate}
                                        // min={pastDateFormat}
                                        type="date"
                                        ref={inputRef}
                                        onChange={handleDateChange}
                                        display={'hidden'}
                                        position={'absolute'}
                                        cursor={'pointer'}
                                        style={{
                                            opacity: 0,
                                            overflow: 'hidden',
                                            zIndex: 999,
                                        }}
                                    />
                                    <InputGroup position={'absolute'}>
                                        <InputRightElement
                                            pointerEvents="none"
                                            marginTop={{ lg: '0px', base: '20px' }}
                                            marginRight={{ lg: '0px', base: '7px' }}
                                        >
                                            <CalendarIcon />
                                        </InputRightElement>
                                        <Input
                                            size={'lg'}
                                            borderColor={'#A5AAAF'}
                                            onFocus={(e) => e.target.showPicker()}
                                            placeholder={
                                                purchaseDateLbl || AddNewDeviceScripts.purchaseDate
                                            }
                                            type="text"
                                            position={'relative'}
                                            value={AssetPurchaseDate}
                                        />
                                    </InputGroup>
                                </Box>
                            }
                            {/* <Box mt={showDeviceCategoryFromCode(agreementData1?.ClientOffer?.ClientOfferName) || isBYOD ? '20' : '0'} width={{ base: 'full', lg: '50%' }} marginX={'auto'}> */}
                            <Box mt={'20'} width={{ base: 'full', lg: '50%' }} marginX={'auto'}>
                                <Button
                                    mt={'5'}
                                    width={'full'}
                                    textAlign={'center'}
                                    colorScheme="primary"
                                    bg={
                                        isAnyRequiredFieldInvalid(fields)
                                            ? 'gray.200'
                                            : colors.primary
                                    }
                                    cursor={
                                        isAnyRequiredFieldInvalid(fields)
                                            ? 'not-allowed'
                                            : 'pointer'
                                    }
                                    isDisabled={isAnyRequiredFieldInvalid(fields)}
                                    onClick={() => setPageNo(2)}
                                >
                                    <TextScript
                                        text={Scripts.Global.Next}
                                        translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Next}`}
                                    />
                                </Button>
                            </Box>
                        </Box>
                    )}
                    {pageNo === 2 ? (
                        <Box
                            id={'myasurion-profile-avatar'}
                            w={{ base: 'full', lg: '80%' }}
                            margin="0 auto"
                            h={'auto'}
                            p={'1rem'}
                        >
                            <Text mt={'5'} fontSize={{ base: '3xl', lg: '4xl' }}>
                                <TextScript
                                    text={'Please provide your email address'}
                                    translationKey={`${pageName}/Please provide your email address`}
                                />
                            </Text>
                            <Box pb={4} pt={2}>
                                <Box paddingTop={'16px'}>
                                    <Text>
                                        <TextScript
                                            text={
                                                'We will send you an email to this address regarding the status of your device registration within 3 working days'
                                            }
                                            translationKey={`/`}
                                        />
                                    </Text>
                                </Box>
                                <Box borderWidth="1px" borderRadius="lg" borderColor="#6E767D">
                                    <Box
                                        px={3}
                                        py={1}
                                        mb={'-10px'}
                                        fontSize={'sm'}
                                        fontWeight={400}
                                        color={'gray.500'}
                                    >
                                        {'Email Address'}
                                    </Box>
                                    <Input
                                        disabled={isEnrollmentEmail}
                                        value={customerEmail}
                                        maxLength={50}
                                        onChange={handleEmailChange}
                                        py="0.5rem"
                                        border={'none'}
                                        _focus={{ border: 'none', boxShadow: 'none' }}
                                    />
                                </Box>
                                {isInvalidEmail ? <Text fontSize={'14px'} color={'red'}>{`* Please enter valid email address`}</Text>: ''}
                                <Flex
                                    pb={{ base: '0', lg: '10px' }}
                                    mb={'auto'}
                                    w={'full'}
                                    justifyContent={'start'}
                                >
                                    <Checkbox
                                        isChecked={isEnrollmentEmail}
                                        marginY={'12px'}
                                        alignItems={'flex-start'}
                                        size="lg"
                                        onChange={handleCheckboxChange}
                                        colorScheme="primary"
                                    ></Checkbox>
                                    <Text marginY={'12px'} marginLeft={'8px'} size={'16px'}>
                                        <TextScript
                                            text={`Use enrolled email address`}
                                            translationKey={`$''}/${''}`}
                                        ></TextScript>
                                    </Text>
                                </Flex>
                            </Box>
                            <Box mt={'20'} width={{ base: 'full', lg: '50%' }} marginX={'auto'}>
                                <Button
                                    mt={'5'}
                                    width={'full'}
                                    textAlign={'center'}
                                    colorScheme="primary"
                                    bg={
                                        isAnyRequiredFieldInvalid(fields) || isInvalidEmail
                                            ? 'gray.200'
                                            : colors.primary
                                    }
                                    cursor={
                                        isAnyRequiredFieldInvalid(fields)
                                            ? 'not-allowed'
                                            : 'pointer'
                                    }
                                    isDisabled={isAnyRequiredFieldInvalid(fields) || isInvalidEmail}
                                    onClick={() => handleSubmit()}
                                >
                                    <TextScript
                                        text={'Submit'}
                                        translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Submit}`}
                                    />
                                </Button>
                            </Box>
                        </Box>
                    ) : null}
                </MainStackLayout>
                <FindIMEINoModal
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
        </Box>
    );
}

export default BYODDeviceDetails;
