/* eslint-disable no-console */

import { Box, Button, Flex } from "@chakra-ui/react";
import { useCentralStore } from "../store/appContext";
import { isMalfuntionWarrantyEligible } from "../utils/featuresUtills";
import DeviceModel from "./DeviceModel";
import { useNavigate } from 'react-router-dom';
import { SubHeader, TextScript, useSessionStorage } from "@MGPD/myasurion-shared";
import { ResumeFlowDataKey, assetDataKey, currentAgreementKey } from "../modules/config/constants";
import MainStackLayout from "../layout/MainStackLayout";
import Scripts from '../Scripts/asurion-script.json';
import { useEffect, useState } from "react";
import CancelSrPopup from "./myclaims/CancelSrPopup";
import { CardContent } from "../types/types";
import Translationkeys from '../Scripts/translationsKeys.json';
import ActionTypes from "../store/actionTypes";
import { getReplacementMatrix } from "../services/api";
import { getCurrencySymbol } from "../utils/localization-helper";
import { getDeviceImageByModel } from "../utils/get-device-images";
import { camelize } from "../utils/helper";
import SmartStockContainer from "./SmartStockContainer";
import Cards from "./Cards";

const DeviceOptionsConatainer: React.FC = () => {
    const navigate = useNavigate();
    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [deviceData, setDeviceData] = useState<{
        img: string;
        familyName: string;
        srf: string;
    }>({
        img: '',
        familyName: '',
        srf: '',
    })
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [cardContent, setCardContent] = useState<any>([]);
    const [replacementResponse, setReplacementResponse] = useState<any>([]);    
    // const [filterData, setFilterData] = useState<Section[]>([]);
    const [, setClickedCard] = useState<CardContent | null>(null);
    const [, setTotalFee] = useState<string>('');
    // const [sliderDefaultValue] = useState<[number, number]>([0, 15000]);
    // const [selectedSliderValues, setSelectedSliderValues] = useState<[number, number]>([0, 15000]);
    // const [showPriceRange, setShowPriceRange] = useState(false);
    // const [selectedValues, setSelectedValues] = useState<{
    //     [sectionId: string]: { [value: string]: boolean };
    // }>({});
    const [, setLoaded] = useState(false);
    // const [sortedOptions, setSortedOptions] = useState('');    
    const globalState = useCentralStore();
    const ReplacementKeys = Translationkeys.Replacement;
    // const sortOptions = [
    //     // { name: 'Best selling', value: 'Best selling' },
    //     { name: 'Price (Low to High)', value: 'Price (Low to High)' },
    //     { name: 'Price (High to Low)', value: 'Price (High to Low)' },
    //     // { name: 'Latest release', value: 'Latest release' },
    // ];
    const [caseDetails] = useSessionStorage<any>(ResumeFlowDataKey, null);
    const [currentAgreementData] = useSessionStorage<any>(currentAgreementKey, null);
    const [,setWarrantyRepairFee] = useSessionStorage<any>('warrantyRepairFee', null)
    const agreementData: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const [assetData] = useSessionStorage<any>(assetDataKey, null);
    
    useEffect(() => {
        callReplacement();
        getCurrency();        
    }, [])

    useEffect(() => {
        const EnrolledDevice = caseDetails?.ServiceRequests[0]?.EnrolledDevice;
        const currentModel = {
            img: getDeviceImageByModel(
                globalState?.state?.deviceImages,
                EnrolledDevice?.Model?.Family,
                '',
                80
            ),
            familyName:
                (EnrolledDevice?.Model?.Family ? camelize(
                    EnrolledDevice?.Model?.Family?.toLowerCase()
                )?.replace(/iphone/gi, 'iPhone'): assetData?.Model?.Name) +
                ' ' +
                (EnrolledDevice?.AssetCatalog?.Capacity ? getAssetCapacity(EnrolledDevice?.AssetCatalog?.Capacity) : '') +
                ' ' +
                (EnrolledDevice?.AssetCatalog?.Color ? EnrolledDevice?.AssetCatalog?.Color : ''),
            srf: ``,
        };
        setDeviceData(currentModel);
    }, [caseDetails])

    // useEffect(() => {
    //     if(replacementResponse.length){
    //     const extractedData = replacementResponse?.map((item: any) => {
    //         return {
    //             id: item.AssetCatalogId,
    //             name: item.AssetCatalogName,
    //             make: item.Make,
    //             color: item.Color,
    //             capacity: item.Capacity,
    //             phonePrice: item.PhonePrice,
    //             srf: item.PhonePrice,
    //         };
    //     });

    //     let makeOptions = [
    //         ...new Set(
    //             extractedData?.map((val: any) => {
    //                 return val?.make;
    //             })
    //         ),
    //     ]?.sort();

    //     let colorOptions = [
    //         ...new Set(
    //             extractedData?.map((val: any) => {
    //                 return val?.color;
    //             })
    //         ),
    //     ]?.sort();

    //     let storageOptions = [
    //         ...new Set(
    //             extractedData?.map((val: any) => {
    //                 return val?.capacity;
    //             })
    //         ),
    //     ]?.sort((a: any, b:any) => a - b);

    //     let filterData: any = [
    //         {
    //             id: 'category',
    //             name: 'Brand',
    //             options: makeOptions?.map((val) => {
    //                 return { value: val, label: val, name: 'Brand', checked: false };
    //             }),
    //         },
    //         {
    //             id: 'color',
    //             name: 'Color',
    //             options: colorOptions?.map((val) => {
    //                 return { value: val, label: val, name: 'Color', checked: false };
    //             }),
    //         },
    //         {
    //             id: 'storage',
    //             name: 'Storage',
    //             options: storageOptions?.map((val) => {
    //                 return {
    //                     value: val == 1 ? `${val}TB` : `${val}GB`,
    //                     label: val == 1 ? `${val}TB` : `${val}GB`,
    //                     name: 'Storage',
    //                     checked: false,
    //                 };
    //             }),
    //         },
    //     ];
    //     setFilterData(filterData);
    // }
    // }, [replacementResponse]);

    useEffect(() => {
        // let cardDataWithoutLFL: any = replacementResponse
        
        // const filterValues = {
        //     makes: extractTrueValues(selectedValues).category || [],
        //     assetCapacity:
        //         extractTrueValues(selectedValues).storage?.map((val) =>
        //             val === '1TB' ? val.replace(/TB/g, '') : val.replace(/GB/g, '')
        //         ) || [],
        //     colors: extractTrueValues(selectedValues).color || [],
        // };
        // if (filterValues?.makes?.length > 0) {
        //     cardDataWithoutLFL = cardDataWithoutLFL?.filter((item: any) =>
        //         filterValues.makes.includes(item.Make)
        //     );
        // }

        // if (filterValues.assetCapacity?.length > 0) {
        //     cardDataWithoutLFL = cardDataWithoutLFL?.filter((item: any) =>
        //         filterValues.assetCapacity.includes(item.Capacity)
        //     );
        // }

        // if (filterValues?.colors?.length > 0) {
        //     cardDataWithoutLFL = cardDataWithoutLFL?.filter((item: any) =>
        //         filterValues.colors.includes(item.Color)
        //     );
        // }

        // cardDataWithoutLFL = cardDataWithoutLFL?.filter((item: any) => {
        //     const srf = parseInt(item?.PhonePrice);
        //     return srf >= selectedSliderValues[0] && srf <= selectedSliderValues[1];
        // });

        // if (sortedOptions && sortedOptions == 'Best selling') {
        //     cardDataWithoutLFL.sort((a, b) => {
        //         const srfA = Number(a.PhysicalInvent);
        //         const srfB = Number(b.PhysicalInvent);
        //         return srfB - srfA;
        //     });
        // }
        // if (sortedOptions && sortedOptions == 'Latest release') {
        //     cardDataWithoutLFL.sort(
        //         (a, b) =>
        //             new Date(b.HorizonItemInformation.AssetCatalogStartDate).getTime() -
        //             new Date(a.HorizonItemInformation.AssetCatalogStartDate).getTime()
        //     );
        // }

        // let AssetFamily = [
        //     ...new Set(
        //         cardDataWithoutLFL?.map((item: any) => item?.AssetCatalogName)
        //     ),
        // ];
        // let cardData: any =
        //     AssetFamily &&
        //     AssetFamily?.map((val: any) => {
        //         return {
        //             img: getDeviceImageByModel(globalState?.state?.deviceImages, val, '', 80),
        //             familyName: val,
        //             srf: replacementResponse
        //                 .filter((item: any) => {
        //                     return item?.AssetCatalogName === val;
        //                 })
        //                 .sort((a: any, b: any) => {
        //                     const srfA = Number(a.PhonePrice);
        //                     const srfB = Number(b.PhonePrice);
        //                     return srfA - srfB;
        //                 })[0].PhonePrice,
        //         };
        //     });
        // if (sortedOptions && sortedOptions == 'Price (Low to High)') {
        //     cardData.sort((a: any, b: any) => {
        //         const srfA = Number(a.srf);
        //         const srfB = Number(b.srf);
        //         return srfA - srfB;
        //     });
        // }
        // if (sortedOptions && sortedOptions == 'Price (High to Low)') {
        //     cardData.sort((a: any, b: any) => {
        //         const srfA = Number(a.srf);
        //         const srfB = Number(b.srf);
        //         return srfB - srfA;
        //     });
        // }

        // let cardData = replacementResponse.map((val: any) => {
        //     return {
        //         img: getDeviceImageByModel(
        //             globalState?.state?.deviceImages,
        //             val.familyName,
        //             '',
        //             80
        //         ),
        //         familyName: val.familyName,
        //         srf:
        //             val.PhonePrice === '0' || val.PhonePrice === '0.00'
        //                 ? 'SRF waived'
        //                 : `${currencySymbol}${val.srf}`,
        //     };
        // });

        // setCardContent(cardData);
    }, [replacementResponse]);

    // function extractTrueValues(obj: { [key: string]: { [key: string]: boolean } }): {
    //     [key: string]: string[];
    // } {
    //     const result: { [key: string]: string[] } = {};

    //     for (const prop in obj) {
    //         const innerObj = obj[prop];
    //         const trueValues: string[] = [];

    //         for (const key in innerObj) {
    //             if (innerObj[key] === true) {
    //                 trueValues.push(key);
    //             }
    //         }

    //         if (trueValues.length > 0) {
    //             result[prop] = trueValues;
    //         }
    //     }

    //     return result;
    // }

    const getAssetCapacity = (capacity: string) => {
        return capacity ? (capacity == '1' ? `${capacity}TB` : `${capacity}GB`) : '';
    }
    const getCurrency = async () => {
        const currency = await getCurrencySymbol();
        setCurrencySymbol(currency);
    };
    const onCancelClick = () => {
            setOpenCancelPopup(!openCancelPopup);
        };    

    const handleCardClick = (card: any) => {
        setClickedCard(card);       
        // globalState?.dispatch({ type: ActionTypes.SRF, payload: card.srf });
        if(isMalfuntionWarrantyEligible(agreementData?.ClientOffer?.ClientOfferName)){
            let deliveryFee: any = 0;
            let fee: any = [];
        let handlingFee: any = 0;
        if(!currentAgreementData?.RemainingDeliveryCap){
            deliveryFee = parseInt(caseDetails?.HandlingFee); 
            fee = [
                ...fee,
                {feeType: 'Delivery Fee',
                fee : `${currencySymbol}${deliveryFee}`    // delivery fee
            }]
        }
        if(!currentAgreementData?.RemainingWarrantyCap){
            handlingFee = parseInt(globalState?.state?.isWarrantySwapEligible ? card.srf.replace('$','') : 
                caseDetails?.RepairRequestQuatationAmount);        // repair fee
                fee = [
                    ...fee,
                    {
                    feeType: 'Repair Fee',
                    fee: `${currencySymbol}${handlingFee}` 
                }]
        }
        const totalFee: any = deliveryFee + handlingFee;
        fee = [
            ...fee,
            {
                feeType: 'Total',
                fee: `${currencySymbol}${totalFee}`
            }
        ]
        setWarrantyRepairFee(fee);
        setTotalFee(`${totalFee}`)
        totalFee ? navigate('/cleaningInvoice') : navigate('/claimconfirmation')        
        }
        else navigate('/cleaningInvoice');        
    };

    // const handleSliderChange = (values: [number, number]) => {
    //     setShowPriceRange(true);
    //     setSelectedSliderValues(values);
    // };

    // const handleSliderCancel = () => {
    //     setShowPriceRange(false);
    //     setSelectedSliderValues(sliderDefaultValue);
    // };

    const callReplacement = async () => {
        setLoading(true);
        const response: any = await getReplacementMatrix({
            "ReplacementMatrixParameter":{
            "CustomerCaseNo": '420096921'//`${caseDetails?.CustomerCaseNumber}`
    }
        });
        if (response && response.length) {
            const data = response;
            setReplacementResponse(data);            
            setCardContent(
                data.map((item: any) => {
                    return {
                        img: getDeviceImageByModel(globalState?.state?.deviceImages, item?.AssetCatalogName, '', 80),
                        familyName: item?.AssetCatalogName,                        
                        srf: `${currencySymbol}${item?.PhonePrice}`,
                    };
                })
            );
            setLoaded(true);
        }
        setLoading(false);
    };

    // const getSelectedSortOption = (val: string) => {
    //     setSortedOptions(val);
    //     console.log('setSortedOptions:', val);
    // };

    return <>
    <Box id={'myasurion-triage'} paddingTop={'54px'} marginX={'auto'} w="full">
    <MainStackLayout gap={'4px'} spacing={0}>
    <Box
                    w={{ base: 'full', lg: '80%' }}
                    marginX={{ base: '', lg: 'auto' }}
                    mb={{ base: '0', lg: '20px' }}
                >
                    <SubHeader
                        backText={''}
                        allowBackButtonClick={false}
                        showCancelButton={true}
                        showBackButton={false}
                        cancelText={Scripts.CancelSR.cancelBtnText}
                        onCancelClick={onCancelClick}
                    ></SubHeader>
                </Box>
        <DeviceModel
            DeviceDetails={deviceData ||{ img: '', familyName: '', srf: '' }}
            inStock={false}
            incidentType={'Malfunction'}
            onClickCallback={() => { console.log('set srf') }}
            isWarrantyRepairEligible={isMalfuntionWarrantyEligible(agreementData?.ClientOffer?.ClientOfferName)}
        />
            
            (
                            <Box>
                                <Box marginTop={'1rem'} display={{ base: 'block', lg: 'none' }}>
                                    {/* <SmartStock
                                        sidebarBody={
                                            <>
                                                <DualRangeSlider
                                                    selectedSliderValues={selectedSliderValues}
                                                    handleSliderChange={handleSliderChange}
                                                    handleSliderCancel={handleSliderCancel}
                                                    showPriceRange={showPriceRange}
                                                    defaultSliderValues={sliderDefaultValue}
                                                />

                                                <FilterOptionsAccordion
                                                    selectedValues={selectedValues}
                                                    setSelectedValues={setSelectedValues}
                                                    filterArray={filterData}
                                                    pageName={ReplacementKeys.PageName}
                                                />
                                            </>
                                        }
                                        SortOptions={sortOptions}
                                        getSelectedOption={getSelectedSortOption}
                                    /> */}
                                    <Box marginTop={'24px'} paddingX={'16px'}>
                                        <Cards
                                            cardContent={cardContent}                                            
                                            onClickCallback={handleCardClick}
                                        />
                                    </Box>
                                </Box>
                                {/* desktop view */}
                                <Flex
                                    direction="row"
                                    marginTop={'1rem'}
                                    justifyContent={'center'}
                                    display={{ base: 'none', lg: 'flex' }}
                                >
                                    <Box w="30%" mr="30px">
                                        <Box>
                                            <Button
                                                fontSize={'24px'}
                                                variant={'link'}
                                                colorScheme="black"
                                                fontWeight="bold"
                                                // leftIcon={<ArrowBackIcon />}
                                            >
                                                <TextScript
                                                    text={'Filter'}
                                                    translationKey={`${ReplacementKeys.PageName}/${ReplacementKeys.keyFilterBtn}`}
                                                />
                                            </Button>
                                        </Box>
                                        {/* <Box border="1px" borderColor={'#A5AAAF'} p="20px">
                                            <Box>
                                                <DualRangeSlider
                                                    selectedSliderValues={selectedSliderValues}
                                                    handleSliderChange={handleSliderChange}
                                                    handleSliderCancel={handleSliderCancel}
                                                    showPriceRange={showPriceRange}
                                                    defaultSliderValues={sliderDefaultValue}
                                                />
                                                <FilterOptionsAccordion
                                                    selectedValues={selectedValues}
                                                    setSelectedValues={setSelectedValues}
                                                    filterArray={filterData}
                                                    pageName={ReplacementKeys.PageName}
                                                />
                                            </Box>
                                        </Box> */}
                                    </Box>
                                    <Box>
                                        <SmartStockContainer
                                            sidebarBody={<></>}
                                            SortOptions={[]}
                                            getSelectedOption={() => {}}
                                        />
                                        <Box marginTop={'24px'} paddingX={'16px'}>
                                            <Cards
                                                cardContent={cardContent}
                                                actualModel={deviceData}
                                                onClickCallback={handleCardClick}
                                            />
                                        </Box>
                                    </Box>
                                </Flex>
                            </Box>
                        )
        </MainStackLayout>
    <CancelSrPopup
                closeCancelPopup={setOpenCancelPopup}
                openCancelPopup={openCancelPopup}
            />
        </Box>
    </>
}

export default DeviceOptionsConatainer;