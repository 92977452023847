import { TextScript } from '@MGPD/myasurion-shared';

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogOverlay,
    Box,
    Flex,
    useDisclosure,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    CloseButton
} from '@chakra-ui/react';
import React from 'react';
import { PropsWithChildren } from 'react';

import Scripts from '../../Scripts/asurion-script.json';
// import translationsKeys from '../../Scripts/translationsKeys.json';

interface popupProps {
    openCancelPopup: boolean;
    closeCancelPopup: (args: boolean) => void;
}

const FindIMEINoModal: React.FC<PropsWithChildren<popupProps>> = ({
    openCancelPopup,
    closeCancelPopup,
}) => {
    const cancelRef = React.useRef<any>();
    let { isOpen } = useDisclosure();
    isOpen = openCancelPopup;
    // const SerialNoPopupKeys = translationsKeys.SerialNoPopup;
    // const GlobalTranslations = translationsKeys.Global;
    const ImeiDetailsScrips = Scripts.UpdateDeviceDetails.ImeiDetails;

    const onClose = () => {
        closeCancelPopup(false);
    };

    // const onOk = () => {
    //     onClose();
    // };
    return (
        <>
            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={() => onClose()}
                isOpen={isOpen}
                isCentered
                autoFocus={false}
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogBody>
                        <Box>
                        <Flex justifyContent={'flex-end'}>
                                    <CloseButton onClick={() => onClose()} />
                        </Flex>
                        <Flex justifyContent={'center'}>
                            <Text
                                fontSize={'24px'}
                                fontFamily={'Apercu Pro'}
                                fontWeight="300"
                                lineHeight="32px"
                                paddingTop={'12px'}
                            >
                                <TextScript
                                    text={'How to find my IMEI number?'}
                                    translationKey={`${'SerialNoPopupKeys.PageName1112'}/How to find my IMEI number?`}
                                />
                            </Text>
                        </Flex>
                            <Box
                                p={2}
                                alignItems="center"
                                w={{ base: 'full', lg: '50%' }}
                                margin={{ base: '0', lg: 'auto' }}
                                pb={{ base: '0', lg: '140px' }}
                            >

                                <Accordion allowToggle width="100%">
                                    <AccordionItem key={`accordion-item-faq`} py="6" border={'none'}>
                                        {({ isExpanded }) => (
                                            <>
                                                <AccordionButton
                                                    background={'inherit'}
                                                    _hover={{ background: 'inherit', border: 'none' }}
                                                    p={0}
                                                    alignItems={'baseline'}
                                                >
                                                    <Box as="span" flex="1" textAlign="left">
                                                        <Text
                                                            as={'b'}
                                                            fontSize={'18px'}
                                                            fontWeight={isExpanded ? 700 : 'normal'}
                                                        >
                                                            <TextScript
                                                                text={ImeiDetailsScrips.iosTitle}
                                                                translationKey={`${'sdf'}/iosTitle`}
                                                            />
                                                        </Text>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                <AccordionPanel px={0}>
                                                    <Text
                                                        as={'b'}
                                                        fontSize={'18px'}
                                                        fontWeight={isExpanded ? 700 : 'normal'}
                                                    >
                                                        <TextScript
                                                            text={ImeiDetailsScrips.iosHeading}
                                                            translationKey={`${'pahjgeName'}/iosHeading`}
                                                        />
                                                    </Text>
                                                    <ol style={{ listStyle: 'none' }}>
                                                        {ImeiDetailsScrips.iosInstruciton?.map((v: any, i: any) => {
                                                            return (
                                                                <li style={{ paddingTop: '8px' }}>
                                                                    <Flex direction={'row'}>
                                                                        <Box display="inline-block" width="1em">
                                                                            {i + 1}.
                                                                        </Box>
                                                                        <Text fontSize={'14px'}>
                                                                            <TextScript
                                                                                text={v}
                                                                                translationKey={`${'pageName12'}/iosInstruciton${
                                                                                    i + 1
                                                                                }`}
                                                                            />
                                                                        </Text>
                                                                    </Flex>
                                                                </li>
                                                            );
                                                        })}
                                                    </ol>
                                                </AccordionPanel>
                                            </>
                                        )}
                                    </AccordionItem>
                                    <AccordionItem key={`accordion-item-faq`} py="6">
                                        {({ isExpanded }) => (
                                            <>
                                                <AccordionButton
                                                    background={'inherit'}
                                                    _hover={{ background: 'inherit', border: 'none' }}
                                                    p={0}
                                                    alignItems={'baseline'}
                                                >
                                                    <Box as="span" flex="1" textAlign="left">
                                                        <Text
                                                            as={'b'}
                                                            fontSize={'18px'}
                                                            fontWeight={isExpanded ? 700 : 'normal'}
                                                        >
                                                            <TextScript
                                                                text={ImeiDetailsScrips.androidTitle}
                                                                translationKey={`${'wepageName'}/androidTitle`}
                                                            />
                                                        </Text>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                <AccordionPanel px={0}>
                                                    <Text
                                                        as={'b'}
                                                        fontSize={'18px'}
                                                        fontWeight={isExpanded ? 700 : 'normal'}
                                                    >
                                                        <TextScript
                                                            text={ImeiDetailsScrips.androidHeading}
                                                            translationKey={`${'we'}/androidHeading`}
                                                        />
                                                    </Text>
                                                    <ol style={{ listStyle: 'none' }}>
                                                        {ImeiDetailsScrips?.androidInstruciton?.map((v: any, i: any) => {
                                                            return (
                                                                <li style={{ paddingTop: '8px' }}>
                                                                    <Flex direction={'row'}>
                                                                        <Box display="inline-block" width="1em">
                                                                            {i + 1}.
                                                                        </Box>
                                                                        <Text fontSize={'14px'}>
                                                                            <TextScript
                                                                                text={v}
                                                                                translationKey={`${'gh'}/androidInstruciton${
                                                                                    i + 1
                                                                                }`}
                                                                            />
                                                                        </Text>
                                                                    </Flex>
                                                                </li>
                                                            );
                                                        })}
                                                    </ol>
                                                </AccordionPanel>
                                            </>
                                        )}
                                    </AccordionItem>
                                </Accordion>
                            </Box>
                        </Box>
                    </AlertDialogBody>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};

export default FindIMEINoModal;
