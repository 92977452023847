/* eslint-disable no-console */
import { useConfig } from '@MGPD/myasurion-shared';
import { Tag } from '@chakra-ui/react';
import { PropsWithChildren, useState } from 'react';

export interface ContentTypeTagProps {
    selected?: boolean;
    onSelect?: () => void;
}

const ContentTypeTag: React.FC<PropsWithChildren & ContentTypeTagProps> = ({
    selected,
    onSelect,
    children,
}) => {
    const [active, setActive] = useState(selected);
    const { data } = useConfig();
    const { theme } = data;
    const { components } = theme;
    const { contentTypeTag } = components;
    const size: string = (contentTypeTag?.size as string) || 'lg';
    const fontWeight: number = parseInt(contentTypeTag?.fontWeight as string) || 700;
    const color: string = active ? '#FFF' : (contentTypeTag?.textColor as string) || '#000';

    return (
        <Tag
            size={size}
            fontWeight={fontWeight}
            color={color}
            borderRadius={contentTypeTag?.borderRadius || '8px'}
            m={'12px 12px 0 0'}
            variant={active ? 'solid' : 'outline'}
            colorScheme="primary"
            onClick={() => {
                setActive(!active);
                onSelect && onSelect();
            }}
            _hover={{ cursor: 'pointer' }}
        >
            {children}
        </Tag>
    );
};
export default ContentTypeTag;
