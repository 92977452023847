/* eslint-disable no-console */
import { SubHeader, TextScript, useConfig } from '@MGPD/myasurion-shared';
import { Box, Button, Card, CardBody, Flex, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import Translationkeys from '../../Scripts/translationsKeys.json';
import airconImg from '../../icons/aircon.png';
import washingMachineImg from '../../icons/washing_machine.jpg';
import MainStackLayout from '../../layout/MainStackLayout';
import { getCleaningServicesDevices } from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { DeviceCategory } from '../../types/types';
import { saveError, showErrorPage } from '../../utils/utills';

function SelectDevice() {
    const globalState = useCentralStore();
    const navigate = useNavigate();

    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;

    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [deviceCategoryList, setDeviceCategoryList] = useState<Array<DeviceCategory>>();

    const SelectDeviceScripts = Scripts.CleaningService.SelectDevice;
    const SelectDeviceTranslationkeys = Translationkeys.CleaningService.SelectDevice;
    const pageName = SelectDeviceTranslationkeys.PageName;

    const agreementData: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const interactionData = globalState?.state?.sessionResponse?.Interaction;

    useEffect(() => {
        if (agreementData) {
            if (!globalState?.state?.deviceCategories) {
                setLoading(true);
                const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;

                getCleaningServicesDevices(
                    interactionLineId,
                    agreementData?.ClientOffer?.ClientOfferId,
                    agreementData?.AgreementId,
                    globalState?.state?.findAgreementSessionData
                )
                    .then((data) => {
                        setLoading(false);

                        globalState?.dispatch({
                            type: ActionTypes.SET_CLEANING_DEVICE_CATEGORIES,
                            payload: data,
                        });

                        // get device category list from response
                        const categories = Object.keys(
                            data?.GetCleaningServiceDeviceDetails
                        ).filter(
                            (key) =>
                                typeof data?.GetCleaningServiceDeviceDetails[key] === 'object' &&
                                data?.GetCleaningServiceDeviceDetails[key] !== null
                        );

                        loadDeviceCategory(categories);
                    })
                    .catch((error) => {
                        setLoading(false);
                        saveError(error, globalState);
                        showErrorPage(navigate);
                    });
            } else {
                const GetCleaningServiceDeviceDetails =
                    globalState?.state?.deviceCategories?.GetCleaningServiceDeviceDetails;

                // get device category list from response
                const categories = Object.keys(GetCleaningServiceDeviceDetails).filter(
                    (key) =>
                        typeof GetCleaningServiceDeviceDetails[key] === 'object' &&
                        GetCleaningServiceDeviceDetails[key] !== null
                );

                loadDeviceCategory(categories);
            }
        }
    }, [agreementData]);

    useEffect(() => {
        if (deviceCategoryList?.length) setDeviceCategory(deviceCategoryList);
    }, [deviceCategoryList]);

    const loadDeviceCategory = (categories: any) => {
        const categoryList: Array<DeviceCategory> = [];

        for (const category in categories) {
            const isAircon = categories[category].includes('Air');

            const deviceCategory = {
                categoryNameDisplay: categories[category],
                categoryName: categories[category],
                image: '',
                localimage: isAircon ? airconImg : washingMachineImg,
                selected: isCategorySelected(categories[category]),
                key: categories[category],
            };
            categoryList.push(deviceCategory);
        }

        setDeviceCategoryList(categoryList);
    };

    const isCategorySelected = (categoryName: string) => {
        // pre select category if it is there in state
        if (categoryName === globalState?.state?.selectedDeviceCategory?.categoryName) return true;

        return false;
    };

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    const onSubmit = () => {
        navigate('/selectModel', { replace: true });
    };

    const onBackClick = () => {
        navigate('/cleaningServices', { replace: true });
    };

    const onDeviceCategoryClick = (category: any) => {
        const selectedOptions = deviceCategoryList?.map((item: DeviceCategory) => {
            if (category === item.categoryName) {
                item.selected = !item.selected;
            } else {
                item.selected = false;
            }
            return item;
        });

        setDeviceCategoryList(selectedOptions);
    };

    const setDeviceCategory = (selectedOptions?: Array<DeviceCategory>) => {
        // get selected cateogry
        const selectedCategory = selectedOptions?.find((a) => {
            return a.selected == true;
        });

        // set selected category state
        globalState?.dispatch({
            type: ActionTypes.SET_CLEANING_DEVICE_CATEGORY,
            payload: selectedCategory,
        });

        // set button disable state
        setIsDisabled(selectedCategory ? false : true);
    };

    return (
        <Box paddingTop={'54px'} w="full">
            <MainStackLayout gap={0} spacing={0}>
                <Flex
                    w={{ base: 'full', lg: '80%' }}
                    margin="0 auto"
                    h={'100%'}
                    direction={'column'}
                >
                    <SubHeader
                        backText={'Back'}
                        allowBackButtonClick={false}
                        showCancelButton={false}
                        showBackButton={true}
                        onClick={onBackClick}
                        cancelText={Scripts.CancelSR.cancelBtnText}
                    ></SubHeader>

                    <Box px={4} mx={'auto'} w={{ base: 'full', lg: '60%' }}>
                        <Text mt={'5'} fontSize={{ base: '2xl', lg: '3xl' }}>
                            <TextScript
                                text={SelectDeviceScripts.HeaderText}
                                translationKey={`${pageName}/${SelectDeviceTranslationkeys.HeaderText}`}
                            />
                        </Text>

                        <SimpleGrid
                            my={'4'}
                            spacing={{ base: 2, lg: 5 }}
                            columns={{ base: 2, lg: 3 }}
                        >
                            {deviceCategoryList?.map((val, index) => {
                                return (
                                    <center key={index}>
                                        <Card
                                            size={'sm'}
                                            borderWidth={val.selected ? '2px' : '1px'}
                                            borderColor={
                                                val.selected ? colors.bodyTextColor : '#A5AAAF'
                                            }
                                            borderRadius={'8px'}
                                            onClick={() => {
                                                onDeviceCategoryClick(val.categoryName);
                                            }}
                                            cursor={'pointer'}
                                            _hover={{ bg: '#A5AAAF' }}
                                        >
                                            <CardBody py={'4'}>
                                                <Image
                                                    my={'2'}
                                                    marginX={'auto'}
                                                    height={'100px'}
                                                    src={val?.image || val?.localimage}
                                                    alt="your model"
                                                />
                                                <Text>
                                                    <TextScript
                                                        text={val?.categoryNameDisplay}
                                                        translationKey={`${pageName}/${SelectDeviceTranslationkeys.Category}-${val?.key}`}
                                                    />
                                                </Text>
                                            </CardBody>
                                        </Card>
                                    </center>
                                );
                            })}
                        </SimpleGrid>
                    </Box>
                    <Box
                        width={{ base: 'full', lg: '30%' }}
                        marginX={'auto'}
                        marginTop={'auto'}
                        height={'100%'}
                        alignContent={'flex-end'}
                        mb={{ base: '25%', lg: '2rem' }}
                        px={4}
                    >
                        <Button
                            isDisabled={isDisabled}
                            width={'full'}
                            textAlign={'center'}
                            color="white"
                            colorScheme="primary"
                            disabled={isDisabled}
                            onClick={() => onSubmit()}
                        >
                            <TextScript
                                text={Scripts.Global.Continue}
                                translationKey={`${Translationkeys.Global.PageName}/${Translationkeys.Global.Continue}`}
                            />
                        </Button>
                    </Box>
                </Flex>
            </MainStackLayout>
        </Box>
    );
}

export default SelectDevice;
