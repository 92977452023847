/* eslint-disable no-console */
import {
    AccountInfo,
    ImeiConfirmation,
    Mixpanel,
    SubHeader,
    getCarrierByDomain,
    getTypeByDomain,
    useAccount,
    useSessionStorage,
} from '@MGPD/myasurion-shared';
import { Box } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { useNavigate } from 'react-router-dom';
import { checkIsApplLoadDisabled } from '../../../utils/config.ts';

import { loadingWaitingMessages } from '../../../modules/config/constants.ts';
import { findAgreementByMdn } from '../../../services/api.ts';
// import { useNavigate } from 'react-router-dom';
import Scripts from '../../Scripts/asurion-script.json';
import MainStackLayout from '../../layout/MainStackLayout';
import '../../services/api';
import '../../services/api';
import { getOnlineSession, logGTMEvent } from '../../services/api';
import ActionTypes from '../../store/actionTypes.ts';
import { useCentralStore } from '../../store/appContext.tsx';
import { GTM_EVENT } from '../../utils/constant.tsx';
import { getGTMData, getRedirectUrl } from '../../utils/utills.tsx';
import CancelSrPopup from '../myclaims/CancelSrPopup.tsx';

function ConfirmationUpdateDevice() {
  const [account, ] = useAccount<AccountInfo>({ profileId: '', userId: '' });
  const [sessionData, ] = useSessionStorage<any>('data', '');
  const disableApplLoad = checkIsApplLoadDisabled(getCarrierByDomain()) //useFeatureIsOn('disableApplLoad');
  const navigate = useNavigate();

    useEffect(() => {
        let GTMData = getGTMData(
            GTM_EVENT.UPDATE_DEVICE_CONFIRMATION_SCREEN_EVENT,
            globalState?.state?.currentAgreementData,
            getCarrierByDomain(),
            getTypeByDomain(),
            globalState?.state?.currentAgreementData?.CustomerCaseNumber
        );
        logGTMEvent(GTMData);
    }, []);

    const ImeiConfirmationScrips = Scripts.UpdateDeviceDetails.ImeiConfirmation;
    const globalState = useCentralStore();
    // const navigate = useNavigate();
    const [openCancelPopup, setOpenCancelPopup] = useState(false);

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };
    console.log(setLoading);

    const onCancelClick = () => {
        // setOpenCancelPopup(!openCancelPopup);
        // window.location.href = getRedirectUrl(getTypeByDomain());
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: true });
        fetchAgreementByMdn({
            mdn: account?.enrolledMdn || '',
            email: account?.email ?? '',
            data: sessionData,
            imei: '',
            idx: 0,
            assetId: '',
            isDeviceData: true
        });
    };

    // const handleSubmit = () => {
    //   window.location.href = getRedirectUrl(getTypeByDomain());
    // }

    const handleSubmit = () => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: true });
        fetchAgreementByMdn({
            mdn: account?.enrolledMdn || '',
            email: account?.email ?? '',
            data: sessionData,
            imei: '',
            idx: 0,
            assetId: '',
            isDeviceData: true
        });
    };

    const { mutate: fetchAgreementByMdn } = useMutation({
        mutationFn: findAgreementByMdn,
        retry: 5,
        retryDelay: (failureCount) => {
            if (failureCount === 3) {
                // setLoadingMessage(
                loadingWaitingMessages[Math.floor(Math.random() * loadingWaitingMessages.length)];
                // );
            }
            return Math.min(1000 * 2 ** failureCount, 30000);
        },
        onSuccess: (data) => {
            if (disableApplLoad) {
                let deviceList: any = [];
                deviceList = data?.deviceData?.filter(
                    (a: any) =>
                        a?.type?.toLowerCase() !== 'byod' &&
                        a?.assetInstance?.toUpperCase() === 'REPLACED'
                );

                if (!deviceList || deviceList === undefined || deviceList.length === 0) {
                    deviceList = data?.deviceData?.filter(
                        (a: any) =>
                            a?.type?.toLowerCase() !== 'byod' &&
                            a?.assetInstance?.toUpperCase() === 'ENROLLED'
                    );
                }

                // deviceList = [
                //     ...new Map(deviceList?.map((item: any) => [item['mdn'], item])).values(),
                // ];

                // globalState?.dispatch({
                //     type: ActionTypes.SET_DEVICES_LIST,
                //     payload: deviceList || data?.deviceData,
                // });

                globalState?.dispatch({
                    type: ActionTypes.SET_DEVICES_LIST,
                    payload: data?.deviceData?.length > 0 ? data?.deviceData : globalState?.state?.enrolledDevicesList,
                });

                globalState?.dispatch({
                    type: ActionTypes.SET_FINDAGREEMENT_BY_MDN_DATA,
                    payload: data,
                });
            }
            globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
            navigate(getRedirectUrl(getTypeByDomain()));
        },
        onSettled: () => {
            fetchOnlineSession();
        },
        onError: (error) => {
            globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
            console.error('Findagreement failed', (error as { message: string })?.message);
            // doLogout('findAgreementByMdn');
        },
    });

    const { refetch: fetchOnlineSession } = useQuery(
        ['sessionData'],
        async () => {
            return await getOnlineSession();
        },
        {
            retry: 5,
            retryDelay: (failureCount, error) => {
                if (failureCount === 3) {
                    // setLoadingMessage(
                    loadingWaitingMessages[
                        Math.floor(Math.random() * loadingWaitingMessages.length)
                    ];
                    // );
                }
                Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Error', {
                    userId: account.userId,
                    error: error,
                });
                return Math.min(1000 * 2 ** failureCount, 30000);
            },
            onSuccess: (data) => {
                globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
                Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Success', {
                    userId: account.userId,
                });

                globalState?.dispatch({
                    type: ActionTypes.SET_SESSION_RESPONSE,
                    payload: data,
                });
                const isMaxis = data.InitializeResponse?.ClientName?.toUpperCase() === 'MAXIS';
                globalState?.dispatch({
                    type: ActionTypes.SET_IS_MAXIS_FLAG,
                    payload: isMaxis,
                });

                const btMethods = data?.InitializeResponse?.isBraintree?.method;

                globalState?.dispatch({
                    type: ActionTypes.SET_BRAINTREE_METHODS,
                    payload: btMethods,
                });

                const agreementData = data?.FindAgreementsResults?.Agreements?.Agreement?.[0];
                const address1 = agreementData?.Address?.Address1;
                const address2 = agreementData?.Address?.Address2;
                const city = agreementData?.Address?.City;
                const _postalCode = agreementData?.Address?.PostalCode;
                const addressjoin = { address1, address2, city, _postalCode };
                const fulladdress = Object.values(addressjoin).join(', ');

                Geocode.setApiKey(import.meta.env.VITE_GOOGLE_API_KEY);
                Geocode.setLanguage('en');
                Geocode.setRegion('SGP');
                Geocode.setLocationType('ROOFTOP');

                Geocode.fromAddress(fulladdress).then(
                    (response) => {
                        const { lat, lng } = response?.results?.[0]?.geometry?.location;
                        globalState?.dispatch({
                            type: ActionTypes.SET_LOCATION_COORDINATES,
                            payload: { lat, lng },
                        });
                    },
                    (error) => {
                        console.error(error);
                    }
                );
                // setAgreementData(data?.FindAgreementsResults?.Agreements?.Agreement?.[0])
            },
            onError: (error) => {
                globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
                // console.error(error);
                Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Error', {
                    userId: account.userId,
                    error,
                });
                // setPageLoading(true);
                // setIsFailedApiInit(true);
                // doLogout('getOnlineSession');
            },
            enabled: false,
        }
    );

    return (
        <Box>
            <Box
                w={{ base: 'full', lg: '80%' }}
                margin="auto"
                pb={{ base: 0, lg: '150px' }}
                id={'myasurion-profile-dashboard'}
                paddingTop={'54px'}
            >
                <MainStackLayout gap={0} spacing={0}>
                    <SubHeader
                        backText={''}
                        allowBackButtonClick={false}
                        showCancelButton={false}
                        showBackButton={false}
                        cancelText={Scripts.CancelSR.cancelBtnTextCancel}
                        onCancelClick={onCancelClick}
                    ></SubHeader>
                    <Box
                        id={'myasurion-profile-avatar'}
                        w={{ base: 'full', lg: '80%' }}
                        margin="0 auto"
                        h={'auto'}
                        p={'10px'}
                    >
                        <ImeiConfirmation
                            title={ImeiConfirmationScrips.title}
                            descText={ImeiConfirmationScrips.descText}
                            btnSubmitLBL={ImeiConfirmationScrips.btnSubmitLBL}
                            onClickSubmit={() => handleSubmit()}
                        />
                    </Box>
                </MainStackLayout>
                <CancelSrPopup
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
        </Box>
    );
}

export default ConfirmationUpdateDevice;
