/* eslint-disable no-console */
import { TextScript, useConfig } from '@MGPD/myasurion-shared';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import VisaIconSVG from '../../icons/Visa.svg';
import MainStackLayout from '../../layout/MainStackLayout.tsx';
import { getPaymentDetails } from '../../services/api.ts';
import ActionTypes from '../../store/actionTypes.ts';
import { useCentralStore } from '../../store/appContext.tsx';

function SuccessAddCard() {
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;

    const SuccessCardScrips = Scripts.MyPayments.SuccessCard;
    const globalState = useCentralStore();
    const navigate = useNavigate();

    const [paymentDetailsResp, setPaymentDetails] = useState<any>();
    const [currentPaymentod, setCurrentPaymentod] = useState<any>();

    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    useEffect(() => {
        callPaymentDetailsApi(agreementData1?.ClientAccount?.ClientAccountId);
    }, [agreementData1]);

    const callPaymentDetailsApi = (ClientAccountId: any) => {
        setLoading(true);
        getPaymentDetails(
            ClientAccountId,
            interactionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((resp) => {
                setLoading(false);
                setPaymentDetails(resp?.GetPaymentDetailsResponse);
                globalState?.dispatch({
                    type: ActionTypes.SET_RENEWAL_PAYMENT_DETAILS_RESP,
                    payload: resp?.GetPaymentDetailsResponse,
                });
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        if (paymentDetailsResp?.PaymentMethods) {
            const _currentCCDetails = paymentDetailsResp?.PaymentMethods?.sort(function (
                a: any,
                b: any
            ) {
                return (
                    new Date(b.BillingStartDate).valueOf() - new Date(a.BillingStartDate).valueOf()
                );
            });
            let currentCCDetails = _currentCCDetails?.filter(
                (v: any) =>
                    v?.BillingProfileStatusCode === 'ACTV' &&
                    v?.BillingPaymentTypeCode === 'CRE' &&
                    v?.IsDefault === 'Y'
            )?.[0];
            setCurrentPaymentod(currentCCDetails);
            //   globalState?.dispatch({ type: ActionTypes.SET_RENEWAL_CURRENT_CC_DETAILS, payload: currentCCDetails });
            globalState?.dispatch({
                type: ActionTypes.SET_CURRENT_CC_DETAILS,
                payload: currentCCDetails,
            });
        }
    }, [paymentDetailsResp]);

    return (
        <Box
            w={{ base: 'full', lg: '80%' }}
            margin="20px auto"
            p="4"
            // pb={{ base: 0, lg: '150px' }}
            id={'myasurion-profile-dashboard'}
            // mb='20px'
        >
            <MainStackLayout gap={0} spacing={0}>
                <Box
                    id={'myasurion-profile-avatar'}
                    w={{ base: 'full', lg: '80%' }}
                    margin="0 auto"
                    h={'auto'}
                    // p={'24px 16px'}
                >
                    <Text mt="8" mb="3" fontSize={'32px'} fontWeight={300}>
                        <TextScript
                            text={SuccessCardScrips.cardSaved}
                            translationKey={`successCard/cardSaved`}
                        />
                    </Text>
                    <hr
                        style={{
                            border: 'none',
                            borderTop: '2px solid #adaaaa',
                        }}
                    />
                    <Text my="3" fontSize={'18px'} fontWeight={700}>
                        <TextScript
                            text={SuccessCardScrips.modeOfPayment}
                            translationKey={`successCard/modeOfPayment`}
                        />
                    </Text>
                    <Flex dir="row" justify={'space-between'}>
                        <Flex dir="row" alignContent={'center'} align={'center'}>
                            <Image
                                height={'20px'}
                                marginLeft={'8px'}
                                alignSelf={'center'}
                                src={VisaIconSVG}
                            ></Image>
                            <Text>
                                <TextScript
                                    text={SuccessCardScrips.CCLBL}
                                    translationKey={`successCard/CCLBL`}
                                />
                            </Text>
                        </Flex>
                        <Text mb="3" fontSize={'16px'}>
                            {currentPaymentod?.CreditCardNumber}
                        </Text>
                    </Flex>
                    <hr
                        style={{
                            border: 'none',
                            borderTop: '2px solid #adaaaa',
                            margin: '10px 0px',
                        }}
                    />
                    <Text mb="3" fontSize={'24px'}>
                        <TextScript
                            text={SuccessCardScrips.whatHappensScript}
                            translationKey={`successCard/whatHappensScript`}
                        />
                    </Text>
                    <Text mb="3" fontSize={'16px'}>
                        <TextScript
                            text={SuccessCardScrips.desc}
                            translationKey={`successCard/descScript`}
                        />
                    </Text>
                    <Button
                        w="full"
                        // marginLeft={{ base: '0', lg: '20px' }}
                        color="white"
                        colorScheme="primary"
                        bg={colors.primary}
                        cursor={'pointer'}
                        onClick={() =>
                            navigate(
                                globalState?.state?.renewalSelectedOption === undefined
                                    ? '/myPayments'
                                    : '/renewalConfirmation'
                            )
                        }
                    >
                        <TextScript
                            text={Scripts.Global.Close}
                            translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Close}`}
                        />
                    </Button>
                </Box>
            </MainStackLayout>
        </Box>
    );
}

export default SuccessAddCard;
