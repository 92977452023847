/* eslint-disable no-console */
import {
    SubHeader,
    TextScript,
    getCarrierByDomain,
    getTypeByDomain,
    useAccount,
} from '@MGPD/myasurion-shared';
import { Box, Button, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import Scripts from '../../Scripts/asurion-script.json';
import Translationkeys from '../../Scripts/translationsKeys.json';
import MainStackLayout from '../../layout/MainStackLayout';
import '../../services/api';
import { createInquiry, getMakeModel, logGTMEvent } from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { AssetData } from '../../types/types.ts';
import { GTM_EVENT } from '../../utils/constant.tsx';
import { hasMultiDeviceSupport } from '../../utils/featuresUtills.tsx';
import { getGTMData, getRedirectUrl, saveError, showErrorPage } from '../../utils/utills.tsx';
import CancelSrPopup from './../myclaims/CancelSrPopup.tsx';

function SelectMakeModel() {
    const [account] = useAccount({ firstName: 'firstName', lastName: 'lastName', email: 'email' });

    const SelectMakeModelScrips = Scripts.UpdateDeviceDetails.SelectMakeModel;
    const globalState = useCentralStore();
    const navigate = useNavigate();

    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    // const assetDetails = globalState?.state?.assetDetailsUpdateDevice
    const InitializeResponse = globalState?.state?.sessionResponse?.InitializeResponse;

    const [formFields, setFormFields] = useState({
        make: '',
        family: '',
        model: '',
    });
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [assetData, setAssetData] = useState<any>();
    const [selectedModel, setSelectedModel] = useState<any>();
    const [assetDetails, setAssetDetails] = useState<any>();
    const [familyDetails, setAssetFamilyDetails] = useState<any>();
    const [assetModels, setAssetModels] = useState<any>();

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    const onCancelClick = () => {
        // setOpenCancelPopup(!openCancelPopup);
        window.location.href = getRedirectUrl(getTypeByDomain());
    };

    useEffect(() => {
        let GTMData = getGTMData(
            GTM_EVENT.SELECT_MAKE_MODEL_SCREEN_EVENT,
            globalState?.state?.currentAgreementData,
            getCarrierByDomain(),
            getTypeByDomain(),
            globalState?.state?.currentAgreementData?.CustomerCaseNumber
        );
        logGTMEvent(GTMData);
    }, []);

    useEffect(() => {
        if (agreementData1) {
            // let assetData1: AssetData = agreementData1?.Assets.Asset.filter(
            //     (a: any) => a.AssetInstance === 'ENROLLED'
            // )[0];
            // setAssetData(assetData1);

            let assetData1: AssetData;

            // for multi device support get selected device from session
            if (
                hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName) &&
                sessionStorage.getItem('dsMdn')
            ) {
                // get selected asset details stored in session
                const selectedAsset: any = JSON.parse(sessionStorage.getItem('dsMdn') || '');
                assetData1 = agreementData1?.Assets.Asset.filter(
                    (a: any) => a.AssetId === selectedAsset?.assetId
                )[0];

                setAssetData(assetData1);
            } else {
                assetData1 = agreementData1?.Assets.Asset.filter(
                    (a: any) => a.AssetInstance === 'REPLACED'
                )[0];

                if (!assetData1 || assetData1 === undefined) {
                    assetData1 = agreementData1?.Assets.Asset.filter(
                        (a: any) => a.AssetInstance === 'ENROLLED'
                    )[0];
                }

                setAssetData(assetData1);
            }
        }
    }, [agreementData1 || globalState?.state?.sessionResponse]);

    useEffect(() => {
        let _assetDetails = globalState?.state?.assetDetailsUpdateDevice;
        _assetDetails = [...new Set(_assetDetails)]
            ?.sort((a: any, b: any) => {
                if (a.Make < b.Make) return -1;
                if (a.Make > b.Make) return 1;
                return 0;
            })
            .map((val: any) => {
                return { value: val?.Make, label: val?.Make };
            });
        setAssetDetails(_assetDetails);
    }, [globalState?.state?.assetDetailsUpdateDevice]);

    useEffect(() => {
        // let _assetModels = [...new Set(globalState?.state?.modelDataUpdateDevice)].map((val: any) => {
        //     return ({ value: val?.CatalogId, label: val?.AssetCatalogName });
        // })
        setAssetModels(undefined);
        setFormFields((prevFormFields) => ({
            ...prevFormFields,
            ['model']: '',
        }));
        let _assetModels = [...new Set(globalState?.state?.modelDataUpdateDevice)]
            ?.filter((val: any) => {
                return val?.Family === formFields?.family;
            })
            ?.map((v: any) => {
                return { value: v?.CatalogId, label: v?.AssetCatalogName };
            });
        setAssetModels(_assetModels);
    }, [formFields?.family]);
    // }, [globalState?.state?.modelDataUpdateDevice])

    const handleSubmit = () => {
        // navigate('/reviewUpdateDevice')
        const payload = {
            CreateInquiryParams: {
                MobileDeviceNumber: assetData?.MobileDeviceNumber,
                ContactNumber: assetData?.MobileDeviceNumber,
                AgreementId: agreementData1?.AgreementId,
                FirstName: account?.firstName,
                LastName: account?.lastName,
                Email: account?.email,
                CustomerId: agreementData1?.Customers?.Customer?.[0]?.CustomerId,
                ClientName: InitializeResponse?.ClientName,
                InquiryChannel: 'ONLINE',
                InquiryType: 'Update Device Details',
                InquirySubType: 'Update Device Details',
                InquiryDescription:
                    'SerialNumber=' +
                    globalState?.state?.updateDeviceImei +
                    '|Make=' +
                    selectedModel?.[0]?.Make +
                    '|Model=' +
                    selectedModel?.[0]?.PopularName +
                    '|AssetId=' +
                    assetData?.AssetId +
                    '|AssetCatalogId=' +
                    selectedModel?.[0]?.CatalogId +
                    '|InquiryDescription=' +
                    globalState?.state?.updateDeviceInqDesc,
                Note: globalState?.state?.updateDeviceInqDesc,
                ResolveStatus: false,
                UnnecessaryInquiry: false,
                CallDriverCategory: 'Device Mismatch',
                CallDriverSubCategory: 'Device Mismatch',
                Priority: 'HIGH',
                ClientId: InitializeResponse?.ClientId,
                ClientChannelId: interactionData?.ClientChannelId,
                AssetId: assetData?.AssetId,
                SelectedInquiryType: 'Online Channel',
                Documents: globalState?.state?.updateDeviceFile,
                DepartmentType: 'Care',
                // Files: [
                //     {
                //         fileId: "upload-UpdateDevice-Id-doc",
                //         name: globalState?.state?.updateDeviceFile?.name,
                //         fileType: globalState?.state?.updateDeviceFile?.type,
                //         fileSize: globalState?.state?.updateDeviceFile?.size,
                //         data: globalState?.state?.updateDeviceBaseURL
                //     }
                // ],
                UpdateDeviceDetails: {
                    AssetCatalogId: formFields.model,
                    IMEI: globalState?.state?.updateDeviceImei,
                    ClientAssetSkuNumber: selectedModel?.[0]?.AssetCatalogName,
                    MobileDeviceNumber: assetData?.MobileDeviceNumber,
                    Email: account?.email,
                    SubscriptionNumber: agreementData1?.SubscriptionNumber,
                },
            },
        };
        setLoading(true);
        createInquiry(payload, interactionLineId, globalState?.state?.findAgreementSessionData)
            .then((resp) => {
                setLoading(false);
                if (resp) {
                    navigate('/confirmationUpdateDevice');
                }
            })
            .catch((err) => {
                console.log('', err);
                setLoading(false);
                saveError(err, globalState);
                showErrorPage(navigate);
            });
    };

    const handleSelectChange = (event: any, fieldName: any) => {
        const selectedValue = event.value;
        setFormFields((prevFormFields) => ({
            ...prevFormFields,
            [fieldName]: selectedValue,
        }));
    };

    useEffect(() => {
        if (formFields.model) {
            setSelectedModel(
                globalState?.state?.modelDataUpdateDevice?.filter(
                    (v: any) => v?.CatalogId === formFields.model
                )
            );
        }
    }, [formFields.model]);

    useEffect(() => {
        if (formFields.make) {
            setFormFields((prevFormFields) => ({
                ...prevFormFields,
                ['family']: '',
            }));
            globalState?.dispatch({
                type: ActionTypes.UPDATEDEVICE_ASSET_MODELS_DATA,
                payload: undefined,
            });
            setLoading(true);
            getMakeModel(
                formFields?.make?.toLowerCase(),
                globalState?.state?.sessionResponse?.InitializeResponse?.ClientId,
                interactionLineId,
                globalState?.state?.findAgreementSessionData
            )
                .then((resp) => {
                    setLoading(false);
                    globalState?.dispatch({
                        type: ActionTypes.UPDATEDEVICE_ASSET_MODELS_DATA,
                        payload: resp?.AssetDetials,
                    });
                    let deviceFamilyData = resp?.AssetDetials?.map((v: any) => {
                        return {
                            label: v?.Family,
                            value: v?.Family,
                        };
                    });

                    var resArr: any = [];
                    deviceFamilyData?.filter(function (item: any) {
                        var i = resArr.findIndex((x: any) => x.label == item.label);
                        if (i <= -1) {
                            resArr.push(item);
                        }
                        return null;
                    });
                    setAssetFamilyDetails(
                        resArr?.filter((v: any) => {
                            return v?.label !== '' && v?.label !== undefined && v?.label !== null;
                        })
                    );
                })
                .catch((err) => {
                    setLoading(false);
                    console.log('validate imei:', err);
                    saveError(err, globalState);
                    showErrorPage(navigate);
                });
        }
    }, [formFields.make]);

    const isDisabled =
        formFields.make === '' || formFields.family === '' || formFields.model === ''
            ? false
            : true;

    const onBackClick = () => {
        navigate('/imeiDetails');
    };

    return (
        <Box>
            <Box
                w={{ base: 'full', lg: '50%' }}
                margin="auto"
                pb={{ base: 0, lg: '150px' }}
                id={'myasurion-profile-dashboard'}
                paddingTop={'54px'}
            >
                <MainStackLayout gap={0} spacing={0}>
                    <SubHeader
                        backText={'Back'}
                        onClick={onBackClick}
                        allowBackButtonClick={false}
                        showCancelButton={true}
                        showBackButton={true}
                        cancelText={Scripts.CancelSR.closeBtnTextClose}
                        cancelTextKey="CloseBtn1"
                        disableGlobalPageName={true}
                        onCancelClick={onCancelClick}
                    ></SubHeader>
                    <Box
                        id={'myasurion-profile-avatar'}
                        w={{ base: 'full', lg: '80%' }}
                        margin="0 auto"
                        h={'auto'}
                        p={'10px'}
                    >
                        <Box>
                            <Text fontSize="18px" fontWeight={700}>
                                <TextScript
                                    text={SelectMakeModelScrips.DeviceMakeLbl}
                                    translationKey={`${SelectMakeModelScrips.PageName}/${SelectMakeModelScrips.DeviceMake}`}
                                />
                            </Text>
                            {/* <Select
                                placeholder={SelectMakeModelScrips.SelectMakeLbl}
                                size="md"
                                h={'50px'}
                                color={'gray.500'}
                                borderColor={'gray.300'}
                                value={formFields.make}
                                onChange={(e) => handleSelectChange(e, 'make')}
                            > */}
                            <Select
                                defaultValue={formFields?.make}
                                onChange={(e) => handleSelectChange(e, 'make')}
                                options={assetDetails}
                                isSearchable={true}
                            />
                            {/* {Array.isArray(assetDetails) &&
                                    [...new Set(assetDetails)]?.sort((a, b) => {
                                        if (a.Make < b.Make)
                                            return -1;
                                        if (a.Make > b.Make)
                                            return 1;
                                        return 0;
                                    }).map((val: any) => {
                                        return (
                                            <option key={val?.MakeId} value={val?.Make}>
                                                {val?.Make}
                                            </option>
                                        );
                                    })}
                            </Select> */}
                        </Box>
                        <Box my={5}>
                            <Text fontSize="18px" fontWeight={700}>
                                <TextScript
                                    text={SelectMakeModelScrips.DeviceFamilyLbl}
                                    translationKey={`${SelectMakeModelScrips.PageName}/${SelectMakeModelScrips.DeviceFamily}`}
                                />
                            </Text>
                            <Select
                                defaultValue={formFields?.family !== '' ? formFields?.family : ''}
                                onChange={(e) => handleSelectChange(e, 'family')}
                                options={familyDetails}
                                isSearchable={true}
                            />
                        </Box>
                        <Box my={5}>
                            <Text fontSize="18px" fontWeight={700}>
                                <TextScript
                                    text={SelectMakeModelScrips.DeviceModelLbl}
                                    translationKey={`${SelectMakeModelScrips.PageName}/${SelectMakeModelScrips.DeviceModel}`}
                                />
                            </Text>
                            <Select
                                defaultValue={formFields?.model !== '' ? formFields?.model : ''}
                                onChange={(e) => handleSelectChange(e, 'model')}
                                options={assetModels}
                                isSearchable={true}
                            />
                            {/* <Select
                                placeholder={SelectMakeModelScrips.SelectModelLbl}
                                size="md"
                                h={'50px'}
                                color={'gray.500'}
                                borderColor={'gray.300'}
                                value={formFields.model}
                                onChange={(e) => handleSelectChange(e, 'model')}
                            >
                                {Array.isArray(globalState?.state?.modelDataUpdateDevice) &&
                                    [...new Set(globalState?.state?.modelDataUpdateDevice)].map((val: any) => {
                                        return (
                                            <option key={val?.AssetCatalogName} value={val?.CatalogId}>
                                                {val?.AssetCatalogName}
                                            </option>
                                        );
                                    })}
                            </Select> */}
                        </Box>
                        <Box w={{ base: 'full', lg: '50%' }} m="20px auto">
                            <Button
                                isDisabled={!isDisabled}
                                color="white"
                                colorScheme="primary"
                                width="full"
                                m="auto"
                                mb={'16px'}
                                onClick={handleSubmit}
                            >
                                <TextScript
                                    text={'Next'}
                                    translationKey={`${Translationkeys.Global.PageName}/${Translationkeys.Global.Next}`}
                                />
                            </Button>
                        </Box>
                    </Box>
                </MainStackLayout>
                <CancelSrPopup
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
        </Box>
    );
}

export default SelectMakeModel;
