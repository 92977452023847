import moment from 'moment-timezone';
import 'moment/dist/locale/th';
import 'moment/dist/locale/zh-tw';
import 'moment/dist/locale/ms-my';
import { DocumentSideType, UploadIdDocumentType } from '../types/types';

import Scripts from '../Scripts/asurion-script.json';
import {
    LFLStockKey,
    ResumeFlowDataKey,
    claimDetailsResponseKey,
    createServiceOrderDataKey,
    deliveryAddressKey,
    deliveryTypeKey,
    processIncidentKey,
    proxyUserDataKey,
    replacementDataKey,
    returnAddressKey,
    serviceRequestKey,
} from '../modules/config/constants';
import { loggerApiCall } from '../services/api';
import ActionTypes from '../store/actionTypes';
import {
    CATEGORY,
    DEVICE_MAKE_CATEGORY,
    DOMAIN,
    DocumentType,
    FULLFILLMENT_TYPES,
    INCIDENT_TYPES,
    LOGISTICS_TYPES,
    POST_RETRY_APIS,
    TCAT_JOB_MODE,
    UploadIdDocumentCategory,
    UploadIdDocumentCategoryLabels,
    WARRANTY_TYPE,
} from './constant';
import { getCarrierByDomain } from '@MGPD/myasurion-shared';

export const isBatteryReplacement = (PerilType: string) => {
    return PerilType?.toUpperCase() === INCIDENT_TYPES.BATTERY_REPLACEMENT?.toUpperCase();
};

export const isScreenRepair = (PerilType: string) => {
    return PerilType?.toUpperCase() === INCIDENT_TYPES.SCREEN_REPAIR?.toUpperCase();
};

export const isScreenRepairWarranty = (PerilType: string) => {
    return PerilType?.toUpperCase() === INCIDENT_TYPES.SCREEN_REPAIR_WARRANTY?.toUpperCase();
};

export const isScreenRepairBatReplacement = (PerilType: string) => {
    return (
        PerilType?.toUpperCase() === INCIDENT_TYPES.SCREENREPAIR_BATTERYREPLACEMENT?.toUpperCase()
    );
};

export const isDeviceRepair = (PerilType: string) => {
    return PerilType?.toUpperCase() === INCIDENT_TYPES.FAULT_REPAIR?.toUpperCase();
};

export const isSwap = (PerilType: string) => {
    return PerilType?.toUpperCase() === INCIDENT_TYPES.SWAP?.toUpperCase();
};

export const isReplacement = (PerilType: string) => {
    return PerilType?.toUpperCase() === INCIDENT_TYPES.REPLACEMENT?.toUpperCase();
};

export const isWalkIn = (srDeliveryType: string) => {
    return (
        srDeliveryType?.toUpperCase() === LOGISTICS_TYPES.WALKIN?.toUpperCase() ||
        srDeliveryType?.toUpperCase() === FULLFILLMENT_TYPES.WALKIN?.toUpperCase()
    );
};

export const isPUR = (srDeliveryType: string, globalState?: any) => {
    if(globalState?.state?.isWarrantySwapEligible || globalState?.state?.isWarrantyRepairEligible) {
     return false;       
    }
    else return (        
        srDeliveryType?.toUpperCase() === LOGISTICS_TYPES.PUR?.toUpperCase() ||
        srDeliveryType?.toUpperCase() === FULLFILLMENT_TYPES.PUR?.toUpperCase()
    );
};

export const isDelivery = (srDeliveryType: string) => {
    return srDeliveryType?.toUpperCase() === LOGISTICS_TYPES.DELIVERY?.toUpperCase();
};

export const isMalfunction = (PerilType: string) => {
    return PerilType?.toUpperCase() === INCIDENT_TYPES.MALFUNCTION?.toUpperCase();
};

export const isDeviceRefresh = (PerilType: string) => {
    return PerilType?.toUpperCase() === INCIDENT_TYPES.DEVICE_REFRESH?.toUpperCase();
};

export const isDeviceRefreshFree = (find_agreement: any, IncidentType: string) => {
    return find_agreement?.firstDeviceRefreshEligible && isDeviceRefresh(IncidentType);
};

export const isCleaningService = (PerilType: string) => {
    return PerilType?.toUpperCase() === INCIDENT_TYPES.CLEANING_SERVICE?.toUpperCase();
};

export const showErrorPage = (navigate: any) => {
    navigate('../error', { replace: true });
};

export const isUpgrade = (PerilType: string) => {
  return PerilType?.toUpperCase() === INCIDENT_TYPES.UPGRADE?.toUpperCase();
};

export const saveError = (error: any, globalState: any) => {
    if (error?.response?.data?.error?.includes('jwt expired')) {
        globalState?.dispatch({
            type: ActionTypes.SET_JWT_EXPIRED,
            payload: true,
        });
    }
    globalState?.dispatch({
        type: ActionTypes.SET_ERROR_DATA,
        payload: error,
    });
};

export const isClaimEligible = (incidentResponse: any) => {
    return incidentResponse?.Eligibility?.[0]?.EligibilityOutcome?.toUpperCase() === 'APPROVED';
};

export const callLoggerApi = (errorData: any, globalState: any) => {
    const errorMessage = JSON.stringify(errorData?.response?.data || errorData?.message);

    let payload = {
        LoggerRequest: {
            message: JSON.stringify(errorData?.stack || errorData),
            level: 'error',
            category: 'API Log',
            agreementRequestId:
                globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements
                    ?.Agreement?.[0]?.AgreementId,
            errorAPI: errorData?.config?.url,
            errorId: '',
            status: 'API Log',
            errorCode: errorData?.response?.status,
            errorMessage,
            customer:
                globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements
                    ?.Agreement?.[0]?.Customers?.Customer?.[0]?.FullName,
            clientId: globalState?.state?.sessionResponse?.InitializeResponse?.ClientId,
            clientName: globalState?.state?.sessionResponse?.InitializeResponse?.ClientName,
        },
    };
    loggerApiCall(
        payload,
        globalState?.state?.sessionResponse?.Interaction?.InteractionLine?.InteractionLineId,
        globalState?.state?.findAgreementSessionData
    );
};

export const isRetryEligibile = (url: string) => {
    return POST_RETRY_APIS.some((str) => url.includes(str));
};

export const convertTimeRange24To12 = (timeRange: any) => {
    const parts = timeRange && timeRange?.match(/(\d{2})-(\d{2})([A-Z]{2})/);
    if (parts && parts.length === 4) {
        const startHour = parseInt(parts[1]);
        // const endHour = parseInt(parts[2]);
        const formattedStartTime = moment({ hour: startHour }).format('hh:00A');
        // const formattedEndTime = moment({ hour: endHour }).format('hh:00A');
        return `${formattedStartTime}`;
    } else {
        return timeRange;
    }
};

export const clearSessionData = (
    clearServiceRequest: boolean = true,
    clearClaimDetails: boolean = true
) => {
    sessionStorage.removeItem(ResumeFlowDataKey);
    sessionStorage.removeItem(createServiceOrderDataKey);
    sessionStorage.removeItem(replacementDataKey);
    sessionStorage.removeItem(processIncidentKey);
    sessionStorage.removeItem(LFLStockKey);
    sessionStorage.removeItem(deliveryTypeKey);
    sessionStorage.removeItem(deliveryAddressKey);
    sessionStorage.removeItem(returnAddressKey);
    sessionStorage.removeItem(proxyUserDataKey);

    if (clearServiceRequest) sessionStorage.removeItem(serviceRequestKey);
    if (clearClaimDetails) sessionStorage.removeItem(claimDetailsResponseKey);
};

export const convertDateToUTC = (date: string, format: string, timezone: string) => {
    return moment.tz(date, format, timezone).utc();
};

export const getSRFee = (ServiceFeeData: any, IncidentType: string) => {
    let _srFee =  IncidentType.toUpperCase() === INCIDENT_TYPES?.FAULT_REPAIR?.toUpperCase() ?
    (ServiceFeeData?.filter(
        (item: any) => item?.IncidentType?.toUpperCase() === IncidentType.toUpperCase()
    )?.[0]?.TotalAmountWithDiscount || 
    ServiceFeeData?.filter(
        (item: any) => item?.IncidentType === IncidentType.toUpperCase()
    )?.[0]?.Fees?.TotalAmount)
    : ServiceFeeData?.filter(
        (item: any) => item?.IncidentType === IncidentType.toUpperCase()
    )?.[0]?.Fees?.TotalAmount;

    if (
        IncidentType?.toUpperCase() ===
        INCIDENT_TYPES.SCREENREPAIR_BATTERYREPLACEMENT?.toUpperCase()
    ) {
        let scrFee =
            ServiceFeeData &&
            ServiceFeeData?.filter(
                (item: any) => item?.IncidentType === INCIDENT_TYPES.SCREEN_REPAIR?.toUpperCase()
            )?.[0]?.Fees?.TotalAmount;
        let batteryRplcmntFee =
            ServiceFeeData &&
            ServiceFeeData?.filter(
                (item: any) =>
                    item?.IncidentType === INCIDENT_TYPES.BATTERY_REPLACEMENT?.toUpperCase()
            )?.[0]?.Fees?.TotalAmount;
        _srFee = (Number(scrFee) + Number(batteryRplcmntFee)).toFixed(2);
    }

    const srFee =
        IncidentType.toUpperCase() === INCIDENT_TYPES.FAULT_REPAIR.toUpperCase() && isNaN(_srFee)
            ? ServiceFeeData?.filter(
                  (item: any) => item?.IncidentType === INCIDENT_TYPES.SCREEN_REPAIR.toUpperCase()
              )?.[0]?.Fees?.TotalAmount
            : _srFee;

    return srFee;
};

export const isAfter4PMSlot = (timeSlot: string) => {
    const time = moment(timeSlot, 'h:mma');
    const fourPMSlot = moment('4:00pm', 'h:mma');

    return time.isAfter(fourPMSlot);
};

export const timeConvert24to12Hr = (hours: any) => {
    if (hours?.toString()?.toLowerCase()?.includes('by')) {
        return hours;
    } else {
        if (hours?.toString()?.toUpperCase()?.endsWith('H')) {
            hours = hours?.toString()?.slice(0, -1);
        }
        let suffix = hours >= 12 ? 'pm' : 'am';
        let hrs = hours > 12 ? hours - 12 : hours;
        hrs = hrs == '00' ? 12 : hrs;
        let prefix = 'By';
        return `${prefix} ${hrs}${suffix}`;
    }
};

// export const getDeliveryDay = (date: any) => {
//   const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
//   const d = new Date(date);
//   const dateselected = d.getDate();
//   const month = [
//     'January',
//     'February',
//     'March',
//     'April',
//     'May',
//     'June',
//     'July',
//     'August',
//     'September',
//     'October',
//     'November',
//     'December',
//   ];
//   let monthEng = month[d.getMonth()];
//   const year = d.getFullYear();
//   const day = dateselected + ' ' + monthEng + ' ' + year + ', ' + weekday[d.getDay()];
//   return day;
// };

export const formatDate = (
    inputDate: string,
    currentFormat: string,
    language: string,
    parsedFormat: string = 'YYYY-MM-DD'
) => {
    const parsedDate = moment(inputDate, currentFormat, language, true);

    if (parsedDate?.isValid()) {
        return parsedDate?.format(parsedFormat);
    }

    return inputDate;
};

export const getDateAndDay = (date: any, format: string = Scripts.Global.DateFormat) => {
    const language = getLanguage();
    moment.locale(language);    
    return date ? moment(date)?.format(format) : '';
};

const getLanguage = (): string => {
    const language = sessionStorage.getItem('al') || 'en-US';
    switch (
        language.replace(/"/g, '') // Remove quotes from the string
    ) {
        case 'en-US':
            return 'en-US';
        case 'ms-MY':
            return 'ms-MY';
        case 'th-TH':
            return 'th-TH';
        case 'zh-Hant-TW':
            return 'zh-TW';
        default:
            return language;
    }
};

export const getTime = (inputTime: string, timeFormat: string = 'h:mm A') => {
    // const timeFormatRegex = /^(\d{1,2}:\d{2} (AM|PM)) - (\d{1,2}:\d{2} (AM|PM))$/;
    if (/[至-]/.test(inputTime))
        // returning original value if time contains - and 至
        return inputTime;

    let formatTo24;

    if (inputTime) {
        const matchResult = inputTime?.match(/\d+/);
        if (matchResult && matchResult[0]) {
            let numericHour = parseInt(matchResult[0], 10);
            if (inputTime?.toLowerCase()?.includes('pm') && numericHour !== 12) {
                numericHour += 12;
            }
            formatTo24 = inputTime.toLowerCase().includes('by')
                ? 'by ' + numericHour
                : numericHour.toString();
        }
    }

    // const language: string = sessionStorage.getItem('al') || 'en-US';
    // moment.locale( (language === '"en-US"' || language === '"ms-MY"') ? 'en-US' : language === '"th-TH"' ? 'th-TH' : language)

    const language = getLanguage();
    moment.locale(language);

    if (formatTo24) {
        let translatedInput;
        if (formatTo24?.toLowerCase()?.includes('by')) {
            translatedInput = language === '"th-TH"' ? 'ภายใน' : 'by';
        } else {
            translatedInput = '';
        }
        const match = formatTo24?.match(
            /\b(\d{1,2}:\d{2}\s?[APMapm]{0,2}|\d{1,2}\s?[APMapm]{0,2})/i
        );
        const extractedTime = match ? match[0] : '';
        if (!extractedTime) {
            return formatTo24;
        }
        const parsedDate = moment(extractedTime, 'h:mm A');
        const formattedTime = parsedDate?.format(timeFormat);
        const result = `${translatedInput} ${formattedTime}`;
        return result;
    } else {
        return inputTime;
    }
};

export const formatTime = (time: string) => {
    if (time === '18H') {
        return '1:00PM - 6:00PM';
    } else {
        return '9:00AM - 1:00PM';
    }
};

export const formatReturnTime = (time: string) => {
    if (time === '12:00AM - 8:00PM') {
        return '20';
    } else if (time === '12:00AM - 6:00PM' || time === '2:00PM - 6:00PM') {
        return '18';
    }
};

export const getPickUpDeliveryWindow = (pickupTime: string) => {
    if (pickupTime === '9:00AM - 12:00PM') {
        return '12H';
    } else if (pickupTime === '12:00PM - 4:00PM') {
        return '16H';
    }
};

export const getPickSlotTimeByDeliveryWindow = (deliveryWindow: string) => {
    if (deliveryWindow === '12H') {
        return '9:00AM - 12:00PM';
    } else if (deliveryWindow === '16H') {
        return '12:00PM - 4:00PM';
    }
    return deliveryWindow;
};

export const getReturnDeliveryWindow = (time: string) => {
    if (time === '12:00AM - 8:00PM' || time === '20') {
        return '20H';
    } else if (time === '12:00AM - 6:00PM' || time === '2:00PM - 6:00PM') {
        return '18H';
    } else {
        return time;
    }
};

export const getReturnSlotTimeByDeliveryWindow = (
    deliveryWindow: string,
    isTOM: Boolean,
    isMaxis: Boolean
) => {
    if (deliveryWindow === '20H') {
        if (isTOM) {
            return '20';
        } else {
            return '12:00AM - 8:00PM';
        }
    } else if (deliveryWindow === '18H') {
        if (isMaxis) {
            return '2:00PM - 6:00PM';
        }
        return '12:00AM - 6:00PM';
    } else {
        return deliveryWindow;
    }
};

export const getRedirectUrl = (domain: string) => {
    if (domain === DOMAIN.REPAIR) {
        return '/portal';
    }

    return '/';
};

export const isInWarranty = (processIncidentData: any): Boolean => {
    const isWarranty = processIncidentData?.Eligibility?.filter(
        (a: any) =>
            a?.WarrantyType?.toUpperCase() === WARRANTY_TYPE.IW ||
            a?.WarrantyType?.toUpperCase() === WARRANTY_TYPE.IW_OEM
    );

    return isWarranty?.length > 0;
};

export const isInWarrantyOEM = (processIncidentData: any): Boolean => {
    const isWarranty = processIncidentData?.Eligibility?.filter(
        (a: any) => a?.WarrantyType?.toUpperCase() === WARRANTY_TYPE.IW_OEM
    );

    return isWarranty?.length > 0;
};

export const isEWSClaim = (processIncidentData: any, incidentType: string): Boolean => {
    const isWarranty = processIncidentData?.Eligibility?.filter(
        (a: any) => a?.WarrantyType?.toUpperCase() === WARRANTY_TYPE.HI
    );

    // if incident type is Malfunction and Warranty Type is Malfunction then it is a EWS Claim
    return isMalfunction(incidentType) && isWarranty?.length > 0;
};

export const isDPClaim = (incidentType: string, isInWarrantyOEM: Boolean): Boolean => {
    return (
        isSwap(incidentType) ||
        isReplacement(incidentType) ||
        (isMalfunction(incidentType) && !isInWarrantyOEM)
    );
};

export const getServiceRequestFee = (ServiceFeeData: any, IncidentType: string) => {
    let srFee: any;
    const carrier = getCarrierByDomain()
    if (
        IncidentType?.toUpperCase() ===
        INCIDENT_TYPES.SCREENREPAIR_BATTERYREPLACEMENT?.toUpperCase()
    ) {
        let scrFee =
            ServiceFeeData &&
            ServiceFeeData?.filter(
                (item: any) => item?.IncidentType === INCIDENT_TYPES.SCREEN_REPAIR?.toUpperCase()
            )?.[0]?.Fees?.TotalAmount;
        let batteryRplcmntFee =
            ServiceFeeData &&
            ServiceFeeData?.filter(
                (item: any) =>
                    item?.IncidentType === INCIDENT_TYPES.BATTERY_REPLACEMENT?.toUpperCase()
            )?.[0]?.Fees?.TotalAmount;
        srFee = (Number(scrFee) + Number(batteryRplcmntFee)).toFixed(2);
    } else {
        if(carrier === 'celcom' && INCIDENT_TYPES.FAULT_REPAIR.toUpperCase() === IncidentType.toUpperCase()){
            srFee =
            ServiceFeeData &&
            ServiceFeeData?.filter(
                (item: any) => item?.IncidentType === IncidentType?.toUpperCase()
            )?.[0]?.TotalAmountWithDiscount;
        }else{
            srFee =
            ServiceFeeData &&
            ServiceFeeData?.filter(
                (item: any) => item?.IncidentType === IncidentType?.toUpperCase()
            )?.[0]?.Fees?.TotalAmount;
        } 
    }

    return srFee;
};

export const getAddress = (Address: any) => {
    const addressString =
        Address &&
        (Address?.Address1 ? Address?.Address1 : '') +
            ' ' +
            (Address?.Address2 ? Address?.Address2 : '') +
            ' ' +
            (Address?.Address3 ? Address?.Address3 : '') +
            ' ' +
            (Address?.StateProvinceCode || '') +
            ' ' +
            (Address?.PostalCode || '');

    return addressString?.trim();
};

export const isAddressEmpty = (Address: any): Boolean => {
    if (
        Address &&
        Object.keys(Address)?.length > 0 &&
        Address?.PostalCode &&
        Address?.PostalCode?.length > 0 &&
        Address?.Address1 &&
        Address?.Address1?.length > 0
    ) {
        return false;
    }
    return true;
};

export const getMDNFromAgreement = (agreementData: any) => {
    return agreementData?.ContactPoints?.ContactPoint?.filter(
        (val: any) => val?.ContactPointType === 'MOBILE'
    )?.[0]?.PhoneNumber;
};

export const getShippingNodeByJobMode = (mode?: string) => {
    if (mode === TCAT_JOB_MODE.PICKUP) return 'ShippingOrderOutbound';
    else if (mode === TCAT_JOB_MODE.DELIVERY || mode === TCAT_JOB_MODE.RETURN)
        return 'ShippingOrderInbound';
    else return 'ShippingOrder';
};

export const isChineseDefaultLanguage = () => {
    return sessionStorage.getItem('al') === '"zh-Hant-TW"';
};

export const isTablet = (Category: string) => {
    return Category?.toUpperCase() === CATEGORY.TABLET?.toUpperCase();
};

export const isLaptop = (Category: string) => {
    return Category?.toUpperCase() === CATEGORY.LAPTOP?.toUpperCase();
};

export const isSmartWatches = (Category: string) => {
    return Category?.toUpperCase() === CATEGORY.SMARTWATCHES?.toUpperCase();
};

export const isGameConsoles = (Category: string) => {
    return Category?.toUpperCase() === CATEGORY.GAMECONSOLES?.toUpperCase();
};

export const isPhone = (Category: string) => {
    return Category?.toUpperCase() === CATEGORY.PHONE?.toUpperCase();
};

export const isHeadPhones = (Category: string) => {
    return Category?.toUpperCase() === CATEGORY.HEADPHONES?.toUpperCase();
};

export const isEarPhones = (Category: string) => {
    return Category?.toUpperCase() === CATEGORY.EARPHONES?.toUpperCase();
};

export const getFullAddress = (address: any) => {
    const address1 = address?.Address1;
    const address2 = address?.Address2;

    const city = address?.City;
    const state = address?.StateProvinceCode;
    const _postalCode = address?.PostalCode;
    const addressjoin = { address1, address2, city, state, _postalCode };
    const fulladdress = Object.values(addressjoin).filter(Boolean).join(', ');

    return fulladdress;
};

export const getCustomerNameFromAgreement = (agreementData: any) => {
    let customerData = agreementData?.Customers?.Customer?.[0];
    const name =
        customerData?.FullName.trim() || `${customerData?.FirstName} ${customerData?.FirstName}`;
    return name;
};

export type Nullable<T> = T | null;

export const getGTMData: any = (event: string, agreementData: any, carrier: string, domainType: any, caseNumber?: Number) => {
  return {
    event: event,
    CaseNo: caseNumber,
    Partner: carrier,
    Program: agreementData?.ClientProductSkuNbr,
    MainSubscriberMDN: agreementData?.ContactPoints?.ContactPoint?.filter(
      (val: any) => val?.PhoneNumber
    )?.[0]?.PhoneNumber,
    EmailAddress: agreementData?.ContactPoints?.ContactPoint?.filter(
      (val: any) => val?.EmailAddress
    )?.[0]?.EmailAddress,
    Skill: domainType
  };
}

export const getTimeSlot = (timeSlot: string) => {
    const DeliverySlots = [
        '9am - 11am:09-11',
        '10am - 12pm:10-12',
        '11am - 1pm:11-13',
        '12pm - 2pm:12-14',
        '1pm - 3pm:13-15',
        '2pm - 4pm:14-16',
        '3pm - 5pm:15-17',
        '4pm - 6pm:16-18',
        '5pm - 7pm:17-19',
        '6pm - 8pm:17-20',
        '7pm - 10pm:19-22',
    ];
    const formatedTimeSlot = DeliverySlots.filter((str: string) =>
        str.includes(timeSlot)
    )?.[0]?.split(':')[0];
    return formatedTimeSlot || timeSlot;
};

export const getDeviceMakeCategory = (makeName: string = '', checkAdditionalCategory: boolean): string => {
  const changeMakeName = makeName?.toUpperCase();
  switch (changeMakeName) {
    case DEVICE_MAKE_CATEGORY.APPLE:
      return DEVICE_MAKE_CATEGORY.APPLE;
    case DEVICE_MAKE_CATEGORY.MICROSOFT:
      return checkAdditionalCategory ? DEVICE_MAKE_CATEGORY.MICROSOFT : DEVICE_MAKE_CATEGORY.OTHER;
    default:
      return DEVICE_MAKE_CATEGORY.OTHER;
  }
};

export const getUploadIdDocumentList = (Scripts: any, UploadIdPageKeys: any): UploadIdDocumentType[] => {
  const DocumentSide = getDocumentSide(Scripts, UploadIdPageKeys);
  return [
    {
      id: UploadIdDocumentCategory.AustDriversLicence,
      label: UploadIdDocumentCategoryLabels[UploadIdDocumentCategory.AustDriversLicence],
      documentSide: [DocumentSide[0].id, DocumentSide[1].id],
      uploadIdText: Scripts.UploadIdPage.documentDrivingLicense,
      uploadIdTextKey: UploadIdPageKeys.DocumentDrivingLicense,
    },
    {
      id: UploadIdDocumentCategory.ProofOfAgeCard,
      label: UploadIdDocumentCategoryLabels[UploadIdDocumentCategory.ProofOfAgeCard],
      documentSide: [DocumentSide[0].id, DocumentSide[1].id],
      uploadIdText: Scripts.UploadIdPage.documentAgeCard,
      uploadIdTextKey: UploadIdPageKeys.DocumentAgeCard,
    },
    {
      id: UploadIdDocumentCategory.PassportId,
      label: UploadIdDocumentCategoryLabels[UploadIdDocumentCategory.PassportId],
      documentSide: [DocumentSide[2].id],
      uploadIdText: Scripts.UploadIdPage.documentPassport,
      uploadIdTextKey: UploadIdPageKeys.DocumentPassport,
    }
  ];
}

export const getDocumentSide = (Scripts: any, UploadIdPageKeys: any): DocumentSideType[] => [
  {
    id: DocumentType.Front,
    uploadIdText: Scripts.UploadIdPage.uploadIdFront,
    uploadIdTextKey: UploadIdPageKeys.UploadIdFront,
    custWidth: "45%"
  },
  {
    id: DocumentType.Back,
    uploadIdText: Scripts.UploadIdPage.uploadIdBack,
    uploadIdTextKey: UploadIdPageKeys.UploadIdBack,
    custWidth: "45%"
  },
  {
    id: DocumentType.Passport,
    uploadIdText: Scripts.UploadIdPage.uploadIdPassport,
    uploadIdTextKey: UploadIdPageKeys.UploadIdPassport,
    custWidth: "80%"
  },
];

export const validateFileTypeToImageExtension = (file: any) => {
  let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.heif)$/i;
  return allowedExtensions.exec(file);
};

export const areURLsEqual = (url1: string, url2: string): boolean => {
    // Function to standardize URLs for comparison
    function standardizeUrl(url: string): string {
      try {
        // Use the URL API available in most modern browsers and Node.js
        const normalizedUrl = new URL(url);
  
        // Ensure that the pathname ends with a slash if it's not empty
        normalizedUrl.pathname = normalizedUrl.pathname.endsWith('/') ? normalizedUrl.pathname : `\${normalizedUrl.pathname}/`;
  
        // Return the full standardized URL string
        return normalizedUrl.href;
      } catch (error) {
        console.error('Invalid URL provided:', error);
        return ''; // Return an empty string to indicate an error
      }
    }
  
    // Compare the standardized versions of both URLs
    return standardizeUrl(url1) === standardizeUrl(url2);
  }
