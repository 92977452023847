import { getTextScript } from '@MGPD/myasurion-shared';

import Scripts from '../Scripts/asurion-script.json';
import translationsKeys from '../Scripts/translationsKeys.json';
import { getPgmDisplayName } from './featuresUtills';

export const getPerilText = async (key: string, defaultValue: string) => {
    let perilKey = key?.toUpperCase(); // common key in upper case
    const perilText = await getTextScript(
        `${translationsKeys.Global.PageName}/${perilKey}`,
        defaultValue
    );

    return perilText;
};

export const getSwapLBL = async () => {
    const swapText = await getPerilText(translationsKeys.Global.Swap, Scripts.Global.Swap);
    return swapText;
};

export const getReplacementLBL = async () => {
    const replaceText = await getPerilText(
        translationsKeys.Global.Replacement,
        Scripts.Global.Replacement
    );
    return replaceText;
};

export const getMalfunctionLBL = async () => {
    const malfunctionText = await getPerilText(
        translationsKeys.Global.Malfunction,
        Scripts.Global.Malfunction
    );
    return malfunctionText;
};

export const getDeviceRepairLBL = async () => {
    const repairText = await getPerilText(
        translationsKeys.Global.DeviceRepair,
        Scripts.Global.DeviceRepair
    );
    return repairText;
};

export const getScreenRepairLBL = async () => {
    const repairText = await getPerilText(
        translationsKeys.Global.ScreenRepair,
        Scripts.Global.ScreenRepair
    );
    return repairText;
};

export const getDeviceRefreshLBL = async () => {
    const refreshText = await getPerilText(
        translationsKeys.Global.DeviceRefresh,
        Scripts.Global.DeviceRefresh
    );
    return refreshText;
};

export const getBatReplacementLBL = async () => {
    const batterReplText = await getPerilText(
        translationsKeys.Global.BatteryReplacement,
        Scripts.Global.BatteryReplacement
    );
    return batterReplText;
};

export const getCurrencySymbol = async () => {
    const currency = await getPerilText(translationsKeys.Global.Currency, 'MYR ' || Scripts.Global.Currency); //quick fix remove this hardcode
    return currency;
};

export const getProgrammeName = async (defaultValue: string) => {
    const _pgmName = getPgmDisplayName(defaultValue);
    const programmeName = await getPerilText(
        `${translationsKeys.Global.ProgrammeName}-${_pgmName}`,
        _pgmName
    );
    return programmeName;
};

export const getGlobalDateFormat = async () => {
    const programmeName = await getPerilText(
        translationsKeys.Global.DateFromat,
        Scripts.Global.DateFormat
    );
    return programmeName;
};
