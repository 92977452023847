import { fetchStandardizedAddress } from '../../../services/api';
import { useCentralStore } from '../../../store/appContext';
import { saveError } from '../../../utils/utills';
import { Box, BoxProps, Flex, FormControl, Text } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import AutoComplete from '../AutoComplete/AutoComplete';
import { AutoCompleteOption } from '../../../types/types';
import { EMPTY_ADDRESS_OPTION } from '../../../utils/constant';

interface PredictiveAddressProps {
  isDisabled?: boolean;
  inputStyles?: object;
  showTitle?: boolean;
  newAddressQuestion?: string;
  setAddressErrorState: React.Dispatch<React.SetStateAction<boolean>>;
  inputValue: string;
  onInputValueChange: React.Dispatch<React.SetStateAction<string>>;
  selectedOption: AutoCompleteOption;
  onOptionSelectionChange: React.Dispatch<React.SetStateAction<AutoCompleteOption>>;
}

const PredictiveAddress: React.FC<PredictiveAddressProps & BoxProps> = ({
  isDisabled = false,
  inputStyles = {},
  showTitle = true,
  newAddressQuestion='',
  setAddressErrorState,
  inputValue,
  onInputValueChange,
  selectedOption,
  onOptionSelectionChange,
}) => {
  const globalState = useCentralStore();
  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
  const clientID = globalState?.state?.sessionResponse?.InitializeResponse?.ClientId;

  const { mutateAsync: mutationStandardizedAddress } = useMutation(
    (inputValue: string) =>
      fetchStandardizedAddress(inputValue, '', '', interactionLineId, clientID),
  );

  const loadOptions = useCallback(
    async (inputValue: string) => {
      try {
        const data = await mutationStandardizedAddress(inputValue);
        const pickListAddress: AutoCompleteOption[] =
          data?.StandardizeAddressResponse?.PickListAddress;

        if (!Array.isArray(pickListAddress)) {
          return [];
        }

        const newAddresses = pickListAddress.map((address) => {
          return {...address, label: address.Picklist};
        });

        return newAddresses;
      } catch (error) {
        console.error('Error occurred while fetching options:', error);
        saveError(error, globalState);
        setAddressErrorState(true);
        return [];
      }
    },
    [mutationStandardizedAddress]
  );

  const handleSelectedOptionChange = (option: AutoCompleteOption) => {
    onOptionSelectionChange(option);
    onInputValueChange(option.Picklist);
    setAddressErrorState(false);
  };

  const handleInputValueChange = (value: string) => {
    onOptionSelectionChange(EMPTY_ADDRESS_OPTION);
    onInputValueChange(value);
  };

  return (
    <Box mb={1} fontFamily={'Apercu Pro'} width={"100%"}>
      {showTitle && <Text fontSize="md" fontWeight={700} mb={1}>
        {newAddressQuestion}
      </Text>}
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        wrap="wrap"
        justifyContent={{ base: 'start', lg: 'space-between' }}
      >
        <FormControl
          key={'predictiveAddress'}
          isRequired={true}
          isInvalid={false}
          mb={1}
        >
          <AutoComplete
            isDisabled={isDisabled}
            styles={inputStyles}
            name= "predictiveAddress"
            className= "predictive-address"
            placeholder="Search address"
            loadOptions = {loadOptions}
            inputValue= {inputValue}
            selectedOption={selectedOption} 
            onOptionSelectionChange={(option: AutoCompleteOption) => handleSelectedOptionChange(option)}
            onInputValueChange={(value: string) => handleInputValueChange(value)}
          />
        </FormControl>
      </Flex>
    </Box>
  );
};

export default PredictiveAddress;
