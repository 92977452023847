/* eslint-disable no-console */
import {
    AddCard,
    SubHeader,
    TextScript,
    getTextScript,
    getTypeByDomain,
} from '@MGPD/myasurion-shared';
import { Env, PaymentClient } from '@backoffice/fast-payments-client-js-sdk';
import { Box, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import MainStackLayout from '../../layout/MainStackLayout';
import { addPaymentMethod, collectPayment, deletePaymentMethod } from '../../services/api';
import ActionTypes from '../../store/actionTypes.ts';
import { useCentralStore } from '../../store/appContext.tsx';
import { getRedirectUrl, saveError, showErrorPage } from '../../utils/utills.tsx';
import CancelSrPopup from '../myclaims/CancelSrPopup.tsx';

// import { getRedirectUrl, saveError, showErrorPage } from '../../utils/utils';

function AddCardComp() {
    const addCardScrips = Scripts.MyPayments.AddCardScript;
    const globalState = useCentralStore();
    const navigate = useNavigate();
    const [openCancelPopup, setOpenCancelPopup] = useState(false);

    const agreementData1: any =
        globalState?.state?.myPaymentsCurrentSession ||
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

    const onCancelClick = () => {
        navigate(
            globalState?.state?.renewalSelectedOption === undefined
                ? '/myPayments'
                : '/renewalConfirmation'
        );
    };

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    const [ccNumber, setCC] = useState('');
    const [cardName, setCCName] = useState('');
    const [expiryDateVal, setCCExpiryData] = useState('');
    const [cvvVal, setCVV] = useState('');

    const [invalidField, setInvalidField] = useState({
        isInvalidInputCCNumberValue: false,
        isInvalidInputExpiryValue: false,
        isInvalidInputCVVValue: false,
        isInvalidInputCCNameValue: false,
    });

    const [addCardError, setAddCardError] = useState<any>(null);

    // const validateCreditCardLength = (CCNumberval: string) => {
    //   const cardNumber = CCNumberval.replace(/\s/g, '');
    //   if (cardNumber.length < 15 || cardNumber.length > 16) {
    //     setInvalidField({ ...invalidField, isInvalidInputCCNumberValue: true });
    //     return false;
    //   } else {
    //     setInvalidField({ ...invalidField, isInvalidInputCCNumberValue: false });
    //     return true;
    //   }
    // };

    // const validateCreditCardNumber = (CCNumberval: string) => {
    //   const cardNumber = CCNumberval.replace(/\D/g, '');
    //   if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cardNumber)) {
    //     setInvalidField({ ...invalidField, isInvalidInputCCNumberValue: false });
    //     return true;
    //   } else {
    //     setInvalidField({ ...invalidField, isInvalidInputCCNumberValue: true });
    //     return false;
    //   }
    // };

    const validateCreditCardLength = (CCNumberval: string) => {
        const cardNumber = CCNumberval.replace(/\s/g, '');
        if (cardNumber.length < 15 || cardNumber.length > 16) {
            setInvalidField({ ...invalidField, isInvalidInputCCNumberValue: true });
            return false;
        } else {
            // setCCFields({ ...CCFields, cardNumber: CCNumberval });
            setInvalidField({ ...invalidField, isInvalidInputCCNumberValue: false });
            return true;
        }
    };

    // const CARD_IMG: CCImage = {
    //     VISA: '../assets/img-visa2.png',
    //     MASTER: '../assets/img-master-2.png',
    //     MASTERCARD: '../assets/img-master-2.png',
    // };

    const CARD_TYPES: any = {
        VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
        MASTER: /^5[1-5][0-9]{14}$/,
        MASTERCARD: /^5[1-5][0-9]{14}$/,
    };

    const validateCreditCardNumber = (cardNumber: string) => {
        const cleanCardNumber = cardNumber && cardNumber.split(' ').join('');
        let cardType: any = Object.keys(CARD_TYPES).find((cardType) => {
            if (CARD_TYPES[cardType].test(cleanCardNumber)) {
                // setCCFields({ ...CCFields, cardNumber: cleanCardNumber });
                setInvalidField({ ...invalidField, isInvalidInputCCNumberValue: false });
                return true;
            } else {
                setInvalidField({ ...invalidField, isInvalidInputCCNumberValue: true });
                return false;
            }
        });

        return {
            CardType: cardType,
            // CardImg: CARD_IMG[cardType],
        };
    };

    const validateExpiryDate = (expDate: any) => {
        if (expDate === '') {
            setInvalidField({ ...invalidField, isInvalidInputExpiryValue: true });
            return false;
        }
        if (expDate) {
            setCCExpiryData(expDate);

            const parts = expDate.split('/');
            if (parts.length !== 2) {
                setInvalidField({ ...invalidField, isInvalidInputExpiryValue: true });
                return false;
            }
            const expMonth = parseInt(parts[0], 10);
            const expYear = parseInt(parts[1], 10);
            const _currentYear = new Date().getFullYear();
            const currentYear = Number(
                _currentYear?.toString()?.split('')?.[2] + _currentYear?.toString()?.split('')?.[3]
            );
            const currentMonth = new Date().getMonth() + 1;
            if (isNaN(expMonth) || isNaN(expYear)) {
                setInvalidField({ ...invalidField, isInvalidInputExpiryValue: true });
                return false;
            }
            if (expYear > currentYear + 10) {
                setInvalidField({ ...invalidField, isInvalidInputExpiryValue: true });
                return false;
            } else if (expYear === currentYear && expMonth >= currentMonth) {
                setInvalidField({ ...invalidField, isInvalidInputExpiryValue: false });
                return true;
            } else if (
                expYear > currentYear ||
                (expYear === currentYear && expMonth > currentMonth)
            ) {
                setInvalidField({ ...invalidField, isInvalidInputExpiryValue: false });
                return true;
            } else {
                setInvalidField({ ...invalidField, isInvalidInputExpiryValue: true });
                return false;
            }
        } else {
            setInvalidField({ ...invalidField, isInvalidInputExpiryValue: false });
            return false;
        }
    };

    const validateCVV = (cvv: any) => {
        if (!isNaN(cvv)) {
            setCVV(cvv);
            setInvalidField({ ...invalidField, isInvalidInputCVVValue: false });
        } else {
            setInvalidField({ ...invalidField, isInvalidInputCVVValue: true });
        }
        if (cvv === '') {
            setInvalidField({ ...invalidField, isInvalidInputCVVValue: true });
        }
    };

    const validateCCName = (name: any) => {
        if (/^[a-zA-Z -]*$/.test(name)) {
            setCCName(name);
            setInvalidField({ ...invalidField, isInvalidInputCCNameValue: false });
        } else {
            setInvalidField({ ...invalidField, isInvalidInputCCNameValue: true });
        }
        if (name === '') {
            setInvalidField({ ...invalidField, isInvalidInputCCNameValue: true });
        }
    };

    const handleInputCCNumber = (e: any) => {
        setCC(e);
    };
    // const handleInputCardName = (e: any) => {
    //     setCCName(e)
    // }
    // const handleInputexpiryDate = (e: any) => {
    //     setCCExpiryData(e.target.value)
    // }
    // const handleInputCVV = (e: any) => {
    //     setCVV(e.target.value)
    // }

    const isDisabled = ccNumber === '' || cardName === '' || expiryDateVal === '' || cvvVal === '';
    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    // function generateUniqueId() {
    //   return uuid();
    // }
    // useEffect(() => {
    //   let GetSecurityTokenRequest = {
    //     GetSecurityTokenRequest: {
    //       "UseRecurringBillingProgram": true,
    //       "version": "4.5",
    //       "Culture": "th-TH",
    //       "CurrencyCode": "THB",
    //       "FirstName": agreementData1?.Customers?.Customer?.[0]?.FirstName,
    //       "LastName": agreementData1?.Customers?.Customer?.[0]?.Lastname,
    //       "Phone": agreementData1?.ContactPoints?.ContactPoint?.[0]?.PhoneNumber,
    //       "Email": agreementData1?.ContactPoints?.ContactPoint?.[0]?.EmailAddress,
    //       "InteractionLineId": interactionData?.InteractionLine?.InteractionLineId,
    //       "AgreementRequestId": undefined, //agreementData1?.AgreementId,
    //       "AgreementId": agreementData1?.AgreementId,
    //       "BillingProfileId": generateUniqueId().replace(/-/g, "")?.toUpperCase(),
    //       "FeeType": "ENRFEE"
    //     }
    //   }
    //   setLoading(true)
    //   globalState?.dispatch({ type: ActionTypes.SET_MY_PAYMENTS_REF_ID, payload: GetSecurityTokenRequest?.GetSecurityTokenRequest?.BillingProfileId });

    //   getPCISecurityTokenNew(GetSecurityTokenRequest, interactionData?.InteractionLine?.InteractionLineId)
    //     .then(resp => {
    //       console.log('getPCISecurityTokenNew resp', resp);
    //       globalState?.dispatch({ type: ActionTypes.SET_MY_PAYMENTS_TOKEN_RESP, payload: resp?.GetSecurityTokenResponse });
    //       setLoading(false)
    //     })
    //     .catch(err => {
    //       setLoading(false)
    //       saveError(err, globalState);
    //       // showErrorPage(navigate);
    //     })
    // }, [agreementData1])

    const checkCardType = (cardNumber: any) => {
        const cleanCardNumber = cardNumber && cardNumber.split(' ').join('');
        const CARD_TYPES: any = {
            VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
            MASTER: /^5[1-5][0-9]{14}$/,
            MASTERCARD: /^5[1-5][0-9]{14}$/,
        };
        let cardType = Object.keys(CARD_TYPES).find((cardType) =>
            CARD_TYPES[cardType].test(cleanCardNumber)
        );
        return {
            CardType: cardType,
            // CardImg: CARD_IMG[cardType],
        };
    };

    let creditCardName = cardName ? cardName?.split(' ') : [];
    let firstName = creditCardName[0];
    let lastName = creditCardName[1];

    // const saveCard = async () => {
    //   const billingContact = {
    //     name: {
    //       first: firstName ? firstName : ' ',
    //       last: lastName ? lastName : agreementData1?.Customers?.Customer?.[0]?.Lastname,
    //     },
    //     address: {
    //       address1: undefined,
    //       address2: undefined,
    //       city: undefined,
    //       state: undefined,
    //       country: 'TH',
    //       zip: agreementData1?.Address?.PostalCode,
    //     },
    //     locale: {
    //       countryCodeISO3166: 'TH',
    //       languageCodeISO639: 'en',
    //     },
    //     contactInfo: {
    //       phone: agreementData1?.ContactPoints?.ContactPoint?.[0]?.PhoneNumber,
    //       //email: selectedPendingPayAgr ? selectedPendingPayAgr.EmailAddress : login?.agreementData?.EmailAddress,
    //     },
    //   }
    //   const month = expiryDateVal?.split('/') && expiryDateVal.split('/')[0];
    //   const year = expiryDateVal?.split('/') && expiryDateVal.split('/')[1];
    //   const creditCardInfo = {
    //     number: ccNumber,
    //     securityCode: cvvVal,
    //     expiration: { month, year },
    //   }

    //   // validations pending

    //   const AddPaymentMethodRequest = {
    //     "AddPaymentMethodParams": {
    //       "BillingProfileId": globalState?.state?.myPaymentsToken?.referenceID,
    //       "AgreementId": agreementData1?.AgreementId,
    //       "BillingPaymentTypeCode": "CRE",
    //       "CardType": checkCardType(ccNumber).CardType,
    //       "ClientAccountId": agreementData1?.ClientAccount?.ClientAccountId
    //     }
    //   }
    //   const encryptionKeyValue = globalState?.state?.myPaymentsToken?.myPaymentsToken?.EncryptionKey
    //   const paymentClient = new PaymentClient({ env: (import.meta.env.VITE_ENV === 'PROD' ? Env["prod-apac"] : Env["qa-apac"]) });
    //   setLoading(true)
    //   try {
    //     await Promise.all([
    //       paymentClient.addSession(globalState?.state?.myPaymentsToken?.myPaymentsToken?.token, {
    //         encryptionKey: encryptionKeyValue,
    //         appName: 'ENRPORTAL',
    //         currency: 'THB',
    //       }),
    //       paymentClient.addBillingContactInfo(billingContact),
    //       paymentClient.addCreditCardInfo(creditCardInfo),
    //     ]);
    //     await paymentClient.processPayment();
    //     // if (!selectedPendingPayAgr) {
    //     addPaymentMethod(AddPaymentMethodRequest, interactionData?.InteractionLine?.InteractionLineId)
    //       .then(resp => {
    //         if (resp) {
    //           console.log('addPaymentMethod resp', resp);
    //           setLoading(false);
    //           navigate('/myPayments');
    //         }
    //       })
    //       .catch(err => {
    //         console.log(err);
    //         setLoading(false);

    //       });
    //   } catch (err_1) {
    //     console.log(err_1);
    //     setLoading(false);
    //   }
    // }

    const inActivePaymentMethod = (myPaymentsCurrentCCDetails: any) => {
        let deletePaymentMethodRequest = {
            DeletePaymentMethodParams: {
                ClientAccountId: agreementData1?.ClientAccount?.ClientAccountId,
                AgreementId: myPaymentsCurrentCCDetails?.AgreementId,
                BillingProfileId: myPaymentsCurrentCCDetails?.BillingProfileId,
            },
        };
        deletePaymentMethod(
            deletePaymentMethodRequest,
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((resp) => {
                if (resp) {
                    console.log('deletePaymentMethod resp', resp);
                    setLoading(false);
                    navigate('/successAddCard');
                    // navigate(globalState?.state?.renewalSelectedOption === undefined ? '/myPayments' : '/renewalConfirmation')
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setAddCardError('Something went wrong, please retry.');
            });
    };

    const saveCard = () => {
        setAddCardError(null);
        const billingContact = {
            name: {
                first: firstName ? firstName : ' ',
                last:
                    (lastName ? lastName : agreementData1?.Customers?.Customer?.[0]?.Lastname) ||
                    firstName,
            },
            address: {
                address1: undefined,
                address2: undefined,
                city: undefined,
                state: undefined,
                country: 'TH',
                zip: agreementData1?.Address?.PostalCode,
            },
            locale: {
                countryCodeISO3166: 'TH',
                languageCodeISO639: 'en',
            },
            contactInfo: {
                phone: agreementData1?.ContactPoints?.ContactPoint?.[0]?.PhoneNumber,
                //email: selectedPendingPayAgr ? selectedPendingPayAgr.EmailAddress : login?.agreementData?.EmailAddress,
            },
        };
        const month = expiryDateVal?.split('/') && expiryDateVal.split('/')[0];
        const year = expiryDateVal?.split('/') && expiryDateVal.split('/')[1];
        const creditCardInfo = {
            number: ccNumber,
            securityCode: cvvVal,
            expiration: { month, year },
        };

        const AddPaymentMethodRequest = {
            AddPaymentMethodParams: {
                BillingProfileId: globalState?.state?.myPaymentsToken?.referenceID,
                AgreementId: agreementData1?.AgreementId,
                BillingPaymentTypeCode: 'CRE',
                CardType: checkCardType(ccNumber).CardType,
                ClientAccountId: agreementData1?.ClientAccount?.ClientAccountId,
            },
        };

        let CollectPaymentRequest = {
            CollectPaymentRequest: {
                AgreementRequestId: agreementData1?.AgreementRequestId,
                AgreementId: agreementData1?.AgreementId,
                ClientAccountId: agreementData1?.ClientAccount?.ClientAccountId,
                Token: globalState?.state?.myPaymentsToken?.myPaymentsToken?.token,
            },
        };

        const encryptionKeyValue =
            globalState?.state?.myPaymentsToken?.myPaymentsToken?.EncryptionKey;

        const paymentClient = new PaymentClient({
            env: import.meta.env.VITE_ENV === 'prod' ? Env['prod-apac'] : Env['qa-apac'],
        });
        setLoading(true);
        return Promise.all([
            paymentClient.addSession(globalState?.state?.myPaymentsToken?.myPaymentsToken?.token, {
                encryptionKey: encryptionKeyValue,
                appName: 'ENRPORTAL',
                currency: 'THB',
            }),
            paymentClient.addBillingContactInfo(billingContact),
            paymentClient.addCreditCardInfo(creditCardInfo),
        ])
            .then(() => {
                return paymentClient.processPayment();
            })
            .then(() => {
                // if (!selectedPendingPayAgr) {
                if (globalState?.state?.isPendingAgreement) {
                    callCollectPayment(CollectPaymentRequest);
                } else {
                    addPaymentMethod(
                        AddPaymentMethodRequest,
                        interactionData?.InteractionLine?.InteractionLineId,
                        globalState?.state?.findAgreementSessionData
                    )
                        .then((resp) => {
                            if (resp) {
                                console.log('addPaymentMethod resp', resp);
                                setLoading(false);
                                if (globalState?.state?.myPaymentsCurrentCCDetails !== undefined) {
                                    inActivePaymentMethod(
                                        globalState?.state?.myPaymentsCurrentCCDetails
                                    );
                                } else {
                                    // navigate(globalState?.state?.renewalSelectedOption === undefined ? '/myPayments' : '/renewalConfirmation')
                                    navigate('/successAddCard');
                                }
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            setLoading(false);
                            setAddCardError('Something went wrong, please retry.');
                        });
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setAddCardError('Something went wrong, please retry.');
            });
    };

    const callCollectPayment = (callCollectPaymentPayload: any) => {
        setLoading(true);
        collectPayment(
            callCollectPaymentPayload,
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((resp) => {
                setLoading(false);
                console.log(resp);
                window.location.href = getRedirectUrl(getTypeByDomain());
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                saveError(err, globalState);
                showErrorPage(navigate);
            });
    };

    useEffect(() => {
        loadPlaceholderScripts();
    }, []);

    const [placeholders, setPlaceholders] = useState({
        cc: '',
        cardName: '',
        expiryDate: '',
        cvv: '',
    });

    const getDefaultPlaceholder = (fieldName: string) => {
        switch (fieldName) {
            case 'cc':
                return addCardScrips.ccPlaceholder;
            case 'cardName':
                return addCardScrips.cardNamePlaceholder;
            case 'expiryDate':
                return addCardScrips.expiryDatePlaceholder;
            case 'cvv':
                return addCardScrips.cvvPlaceholder;
            default:
                return `${fieldName}`;
        }
    };

    const loadPlaceholderScripts = async () => {
        const placeholdersData: any = {};
        const fieldNames = ['cc', 'cardName', 'expiryDate', 'cvv'];

        for (const fieldName of fieldNames) {
            const defaultPlaceholder = getDefaultPlaceholder(fieldName);

            const returnedText = await getTextScript(`AddCard/${fieldName}`, defaultPlaceholder);

            placeholdersData[fieldName] = returnedText;
        }

        setPlaceholders(placeholdersData);
    };

    console.log('setPlaceholders placeholders', placeholders);

    return (
        <Box>
            <Box
                w={{ base: 'full', lg: '80%' }}
                margin="auto"
                pb={{ base: 0, lg: '150px' }}
                id={'myasurion-profile-dashboard'}
                paddingY={'54px'}
                // mb='20px'
            >
                <MainStackLayout gap={0} spacing={0}>
                    <SubHeader
                        backText={'Back'}
                        allowBackButtonClick={true}
                        showCancelButton={false}
                        showBackButton={true}
                        cancelText={Scripts.CancelSR.cancelBtnTextCancel}
                        onCancelClick={onCancelClick}
                        onClick={onCancelClick}
                    ></SubHeader>
                    <Box
                        id={'myasurion-profile-avatar'}
                        w={{ base: 'full', lg: '80%' }}
                        margin="0 auto"
                        h={'auto'}
                        // p={'24px 16px'}
                    >
                        <AddCard
                            pageTitle={addCardScrips.pageTitle}
                            ccCardLBL={addCardScrips.ccCardLBL}
                            validCVVLbl={addCardScrips.validCVVLbl}
                            isInvalidCCNumber={invalidField.isInvalidInputCCNumberValue}
                            ccPlaceholder={placeholders.cc}
                            ccNumber={ccNumber}
                            isInvalidCardName={invalidField.isInvalidInputCCNameValue}
                            cardNamePlaceholder={placeholders.cardName}
                            cardName={cardName}
                            isInvalidexpiryDate={invalidField.isInvalidInputExpiryValue}
                            expiryDatePlaceholder={placeholders.expiryDate}
                            expiryDate={expiryDateVal}
                            isInvalidCVV={invalidField.isInvalidInputCVVValue}
                            cvvPlaceholder={placeholders.cvv}
                            CVV={cvvVal}
                            saveCardBtnLBL={addCardScrips.saveCardBtnLBL}
                            handleChange={''}
                            onClickSubmit={() => saveCard()}
                            // handleInputCCNumber={(e: any) => handleInputCCNumber(e)}
                            handleInputCCNumber={(e: any) => {
                                handleInputCCNumber(e);
                                validateCreditCardLength(e);
                                validateCreditCardNumber(e);
                            }}
                            handleInputCardName={(e: any) => validateCCName(e)}
                            handleInputexpiryDate={(e: any) => validateExpiryDate(e.target.value)}
                            handleInputCVV={(e: any) => validateCVV(e.target.value)}
                            isDisabled={isDisabled}
                        />
                        {addCardError !== null && (
                            <Text
                                align={'center'}
                                pb={'16px'}
                                w={{ base: 'full', lg: '80%' }}
                                margin={'auto'}
                            >
                                <TextScript
                                    text={addCardError}
                                    translationKey={`addCard/addCardError`}
                                />
                            </Text>
                        )}
                    </Box>
                </MainStackLayout>
                <CancelSrPopup
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
        </Box>
    );
}

export default AddCardComp;
