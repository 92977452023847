import { SubHeader, TextScript, useConfig, useSessionStorage } from '@MGPD/myasurion-shared';
import { getTextScript } from '@MGPD/myasurion-shared/components/localization';
import { Box, Button, Checkbox, Flex, Input, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import Scripts from '../Scripts/asurion-script.json';
import translationsKeys from '../Scripts/translationsKeys.json';
import { ResumeFlowDataKey, processIncidentKey } from '../modules/config/constants';
import { genratePDFDocument, updateHold, uploadHoldDoc } from '../services/api';
import ActionTypes from '../store/actionTypes';
import { useCentralStore } from '../store/appContext';
import {
    getDateAndDay,
    getMDNFromAgreement,
    isBatteryReplacement,
    isDeviceRefresh,
    isDeviceRepair,
    isMalfunction,
    isReplacement,
    isScreenRepair,
    isScreenRepairBatReplacement,
    isScreenRepairWarranty,
    isSwap,
    saveError,
    showErrorPage,
} from '../utils/utills';
import CancelSrPopup from './myclaims/CancelSrPopup';

// interface pdfResObject {
//   Base64Data: string;
//   Extension: string;
//   MIMEType: string;
//   Size: number;
// }

const ConfirmEConfirmation = () => {
    const navigate = useNavigate();
    const globalState = useCentralStore();
    const [name, setName] = useState('');
    const [NRIC, setNRIC] = useState('');
    const [error, setError] = useState('');
    const [nameError, setNameError] = useState('');
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [pdfData, setPdfData] = useState<any>();
    const [SubHeaderText, setSubHeaderText] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);
    const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
    const [assetData] = useSessionStorage<any>('assetData', null);

    const agreementData: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

    // const assetData1 = agreementData?.Assets?.Asset?.filter(
    //   (a: any) => a?.AssetInstance === 'ENROLLED'
    // )?.[0];

    let assetData1 = agreementData?.Assets?.Asset?.filter(
        (asset: any) => asset?.AssetInstance === 'ENROLLED'
    )?.[0];
    if (assetData1 === undefined) {
        assetData1 = agreementData?.Assets?.Asset?.filter(
            (asset: any) => asset?.AssetInstance === 'REPLACED'
        )?.[0];
    }

    const EConfirmationPage2Keys = translationsKeys.EConfirmationPage2;
    const R_ServiceRequests = ResumeFlowData?.ServiceRequests?.[0];
    const serviceRequestId =
        serviceRequestData?.ServiceRequest?.ServiceRequestId || R_ServiceRequests?.ServiceRequestId;
    const R_IMEI = R_ServiceRequests?.EnrolledDevice?.IMEI;

    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const InteractionLineId = interactionData?.InteractionLine.InteractionLineId;

    const InitializeResponse = globalState?.state?.sessionResponse?.InitializeResponse;

    const IncidentType =
        serviceRequestData?.ServiceRequest?.IncidentType || R_ServiceRequests?.IncidentType;

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    useEffect(() => {
        if (agreementData) {
            setNRIC(getNRIC());
            setName(getFullName());
        }
    }, [agreementData]);

    let payload = {
        GetClaimDetailsRequest: {
            ClientName: InitializeResponse?.ClientName,
            TemplateName: 'SR_Confirmation_Form',
            AgreementId: agreementData?.AgreementId,
            DynamicData: {
                CustomerContact: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
                Make: assetData?.Make?.Name || assetData1?.Make?.Name,
                Model:
                    assetData?.AssetCatalog?.AssetFamily || assetData1?.AssetCatalog?.AssetFamily,
                IMEI: assetData?.IMEI || R_IMEI,
                CaseNumber:
                    serviceRequestData?.CustomerCaseNumber?.toString() ||
                    ResumeFlowData?.CustomerCaseNumber?.toString(),
                CustomerName: name,
                CurrentDate: getDateAndDay(new Date(), 'DD MMMM YYYY'),
            },
            Options: {
                format: 'A3',
                orientation: 'portrait',
                border: '10mm',
                timeout: '180000',
            },
        },
    };

    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { primary } = colors;

    const { refetch: callCreatePDFDocument } = useQuery(
        ['pdfDocument'],
        async () => {
            setLoading(true);
            return await genratePDFDocument(
                payload,
                InteractionLineId,
                globalState?.state?.findAgreementSessionData
            );
        },
        {
            enabled: false,
            onSuccess: (data) => {
                setLoading(false);
                setPdfData(data);
            },
            onError: (error) => {
                setLoading(false);
                console.error('Error creating pdf document:', error);
                saveError(error, globalState);
                showErrorPage(navigate);
            },
        }
    );

    const {} = useQuery(
        ['UploadDocument'],
        async () => {
            setLoading(true);
            const uploadDocParams = {
                UpdateHoldsInput: {
                    Files: [
                        {
                            data: `data:${pdfData?.MIMEType};filename=generated.pdf;base64,${pdfData?.Base64Data}`, // base64ToURL(pdfData?.Base64Data, pdfData?.MIMEType),
                            fileId: 'file-0',
                            name: `Confirmation_Form_Check_${
                                serviceRequestData?.CustomerCaseNumber?.toString() ||
                                ResumeFlowData?.CustomerCaseNumber?.toString()
                            }.pdf`,
                            fileType: pdfData?.MIMEType,
                            fileSize: pdfData?.Size,
                        },
                    ],
                    ClientName: InitializeResponse?.ClientName,
                    InteractionLineId: InteractionLineId,
                    CorrelationId: uuid()?.replace(/-/g, '')?.toUpperCase(),
                    CustomerCaseNumber: getCaseNumber(),
                    ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
                    ServiceRequestId: serviceRequestId,
                    CustomerCaseId: getCustomerCaseId(),
                    ServiceRequestNumber: getServiceRequestNumber(),
                    MobileDeviceNumber: getMDNFromAgreement(agreementData),
                    AgreementId: agreementData?.AgreementId,
                    CustomerName: name,
                    ClientChannelId: agreementData?.ClientAccount?.ClientChannelId,
                },
            };
            return await uploadHoldDoc(
                uploadDocParams,
                InteractionLineId,
                globalState?.state?.findAgreementSessionData
            );
        },
        {
            enabled: pdfData ? true : false,
            onSuccess: (data) => {
                setLoading(false);
                console.log('UploadDocResponse:', data);

                // call auto resolve hold API tp allow user to file claim
                callUpdateHoldAPI(data?.WorkQueueId);
                // navigate('../reviewhold', { replace: true });
            },
            onError: (error) => {
                setLoading(false);
                console.error('Error uploading pdf document:', error);
                saveError(error, globalState);
                showErrorPage(navigate);
            },
        }
    );

    const getServiceRequestNumber = () => {
        return (
            serviceRequestData?.ServiceRequest?.ServiceRequestNumber?.toString() ||
            R_ServiceRequests?.ServiceRequestNumber?.toString()
        );
    };

    const getCustomerCaseId = () => {
        return serviceRequestData?.CustomerCaseId || R_ServiceRequests?.CustomerCaseId;
    };

    const getFullName = () => {
        return agreementData?.Customers?.Customer[0]?.FullName;
    };

    const getNRIC = () => {
        return agreementData?.IdentificationNumber.substring(
            agreementData?.IdentificationNumber?.length - 4
        );
    };

    const getCaseNumber = () => {
        return (
            serviceRequestData?.CustomerCaseNumber?.toString() ||
            ResumeFlowData?.CustomerCaseNumber?.toString()
        );
    };

    const getDocumentHold = () => {
        return serviceRequestData?.Holds.find((val: any) => {
            return val.HoldType === 'DOCUMENT' && val.HoldStatus === 'OPEN';
        });
    };

    const allHolds = () => {
        const holds = serviceRequestData?.Holds.filter((val: any) => {
            return val.HoldStatus === 'OPEN';
        }).map((hold: any) => {
            return {
                HoldId: hold?.HoldId,
                HoldType: hold?.HoldType,
            };
        });
        return holds;
    };

    const callUpdateHoldAPI = (WorkQueueId: string) => {
        setLoading(true);

        const documentHold = getDocumentHold();

        const updateHoldParams = {
            UpdateHoldParams: {
                ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
                ClientChannelId: agreementData?.ClientAccount?.ClientChannelId,
                ClientName: InitializeResponse?.ClientName,
                MobileDeviceNumber: getMDNFromAgreement(agreementData),
                AgreementId: agreementData?.AgreementId,
                HoldWorkItemAction: documentHold && allHolds()?.length === 1 ? 'APPRVD' : 'UPDATE',
                ResolutionReason: '',
                ResolutionSubReason: '',
                UpdatedBy: name,
                Notes: '',
                ServiceRequestNumber: getServiceRequestNumber(),
                ServiceRequestId: serviceRequestId,
                InteractionLineId: InteractionLineId,
                CustomerCaseId: getCustomerCaseId(),
                CustomerCaseNumber: getCaseNumber(),
                ReferenceId: documentHold?.HoldId,
                Holds: allHolds(),
                Files: [],
                IsRepairReimbursementClaim: false,
                IsRepairReceiptRecieved: false,
                Documents: [],
            },
        };

        updateHold(
            updateHoldParams,
            WorkQueueId,
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((data: any) => {
                setLoading(false);
                if (data?.UpdateHoldsResponse?.Holds[0]?.HoldResolution === 'APPROVE')
                    // hold is removed successfully now allow user to move ahead
                    handleNavigation();
                else {
                    // to do show error
                    navigate('../reviewhold', { replace: true });
                }
            })
            .catch((error: any) => {
                setLoading(false);
                saveError(error, globalState);
                showErrorPage(navigate);
            });
    };

    const handleNavigation = () => {
        if (isReplacement(IncidentType)) {
            // redirect to LostMode for Apple Device OR redirect to replacement Page for Non-Apple devices
            if (assetData?.Make?.Name.toLowerCase() === 'apple') {
                navigate('../lostmode', { replace: true });
            } else {
                navigate('../replacement', { replace: true });
            }
        } else if (
            isDeviceRefresh(IncidentType) ||
            isDeviceRepair(IncidentType) ||
            isScreenRepair(IncidentType) ||
            isBatteryReplacement(IncidentType) ||
            isScreenRepairBatReplacement(IncidentType) ||
            isScreenRepairWarranty(IncidentType)
        ) {
            navigate('../repair', { replace: true });
        } else if (isSwap(IncidentType)) {
            navigate('../oemwarranty', { replace: true });
        } else if (isMalfunction(IncidentType)) {
            navigate('../replacement', { replace: true });
        }
    };

    const handleConfirm = () => {
        callCreatePDFDocument();
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputName: any = event.target.value;
        setName(inputName);
        if (/^[a-z ,.'-]+$/i.test(inputName)) {
            setNameError('');
        } else {
            setNameError('Please enter valid name.');
        }
    };

    const handleNRICChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputNRIC: any = event.target.value;
        if (inputNRIC.length == 4 && /^[a-zA-Z0-9]*$/.test(inputNRIC)) {
            setError('');
            setNRIC(inputNRIC);
        } else {
            setError('Please enter valid 4 digit NRIC.');
            setNRIC(inputNRIC);
        }
    };

    // const disableCnfBtn = () => {
    //   return error || !name || !NRIC ? true : false;
    // };

    const onCancelClick = () => {
        setOpenCancelPopup(!openCancelPopup);
    };

    const onBackClick = () => {
        window.history.back();
    };

    useEffect(() => {
        const getSubHeaderText = async () => {
            const SubHeaderText = await getTextScript(
                `${EConfirmationPage2Keys.PageName}/${EConfirmationPage2Keys.SubHeaderText}`,
                `I understand that:
     <br />
     <br /> a. any unauthorised sale, transfer or use of the Previous Registered Device may be
     seen as dealing in stolen property and may be subject to criminal charges; and b. if the
     Previous Registered Device comes back into my possession I must arrange return of that
     device to [Starhub Provider] as the owner of that device.
     <br />
     <br />
     Confirmed by`
            );
            setSubHeaderText(SubHeaderText);
        };
        getSubHeaderText();
    }, []);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
    };

    return (
        <Box w={{ base: 'full', lg: '80%' }} margin="auto">
            <Box pt={12}>
                <SubHeader
                    backText={'Back'}
                    allowBackButtonClick={false}
                    showCancelButton={true}
                    showBackButton={true}
                    cancelText={Scripts.CancelSR.cancelBtnText}
                    onCancelClick={onCancelClick}
                    onClick={onBackClick}
                ></SubHeader>
            </Box>
            <Box px={4} pt={6} w={{ base: 'full', lg: '80%' }} margin="0 auto">
                <Text pb={'0.5rem'} fontSize={'2rem'} fontWeight={300}>
                    <TextScript
                        text={`Reminder`}
                        translationKey={`${EConfirmationPage2Keys.PageName}/${EConfirmationPage2Keys.HeaderText}`}
                    />
                </Text>
                <Box fontSize={'18px'}>
                    {SubHeaderText && <div dangerouslySetInnerHTML={{ __html: SubHeaderText }} />}
                </Box>
                <Box pb={4} pt={2}>
                    <Box borderWidth="1px" borderRadius="lg" borderColor="#6E767D">
                        <Box
                            px={3}
                            py={1}
                            mb={'-10px'}
                            fontSize={'sm'}
                            fontWeight={400}
                            color={'gray.500'}
                        >
                            {'Name'}
                        </Box>
                        <Input
                            value={name}
                            readOnly={true}
                            onChange={handleNameChange}
                            py="0.5rem"
                            border={'none'}
                            _focus={{ border: 'none', boxShadow: 'none' }}
                        />
                    </Box>
                    {nameError && <Text fontSize={'14px'} color={'red'}>{`*${nameError}`}</Text>}
                </Box>
                <Box pb={4}>
                    <Box borderWidth="1px" borderRadius="lg" borderColor="#6E767D">
                        <Box
                            px={3}
                            py={1}
                            mb={'-10px'}
                            fontSize={'sm'}
                            fontWeight={400}
                            color={'gray.500'}
                        >
                            {'Last 4 NRIC digits'}
                        </Box>
                        <Input
                            value={NRIC}
                            type="text"
                            readOnly={true}
                            maxLength={4}
                            onChange={handleNRICChange}
                            py="0.5rem"
                            border={'none'}
                            _focus={{ border: 'none', boxShadow: 'none' }}
                        />
                    </Box>
                    {error && <Text fontSize={'14px'} color={'red'}>{`*${error}`}</Text>}
                </Box>
                <Box pb={4}>
                    <Box borderWidth="1px" borderRadius="lg" borderColor="#6E767D">
                        <Box
                            px={3}
                            py={1}
                            mb={'-10px'}
                            fontSize={'sm'}
                            fontWeight={400}
                            color={'gray.500'}
                        >
                            {'Date'}
                        </Box>
                        <Input
                            value={getDateAndDay(new Date(), 'DD MMMM YYYY')}
                            readOnly={true}
                            py="0.5rem"
                            border={'none'}
                            _focus={{ border: 'none', boxShadow: 'none' }}
                        />
                    </Box>
                </Box>
                <Flex
                    pb={{ base: '0', lg: '10px' }}
                    mb={'auto'}
                    w={'full'}
                    justifyContent={'start'}
                >
                    <Checkbox
                        marginY={'12px'}
                        alignItems={'flex-start'}
                        size="lg"
                        onChange={handleCheckboxChange}
                        colorScheme="primary"
                    ></Checkbox>
                    <Text marginY={'12px'} marginLeft={'8px'} size={'16px'}>
                        <TextScript
                            text={`I here by confirm that above information's are correct`}
                            translationKey={`${EConfirmationPage2Keys.PageName}/${EConfirmationPage2Keys.CheckBoxText}`}
                        ></TextScript>
                    </Text>
                </Flex>
                <Box w={{ base: 'full', lg: '30%' }} margin={{ base: 0, lg: 'auto' }}>
                    <Button
                        my={4}
                        py={3}
                        px={5}
                        bgColor={primary}
                        color="white"
                        w="full"
                        onClick={() => {
                            handleConfirm();
                        }}
                        isDisabled={!isChecked}
                    >
                        <TextScript
                            text={Scripts.Global.Confirm}
                            translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Confirm}`}
                        />
                    </Button>
                </Box>
                <CancelSrPopup
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
        </Box>
    );
};

export default ConfirmEConfirmation;
