/* eslint-disable no-console */
import {
    SubHeader,
    TextScript,
    getTextScript,
    getTypeByDomain,
    useConfig,
    useTextScript,
} from '@MGPD/myasurion-shared';
import {
    Box,
    Button,
    Flex,
    FormControl,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import Headphones from '../../icons/Headphones.png'
// import GameConsolesimg from '../../icons/GameConsoles.png';
import CNScripts from '../../Scripts/asurion-script-chinese.json';
import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import useContentfulImage from '../../hooks/useContentfulImage';
import beautyCareimg from '../../icons/BeautyCare.png';
import CalendarIcon from '../../icons/CalendarIcon';
import dehumidifierHeaterimg from '../../icons/Dehumidifier.png';
import massagechairimg from '../../icons/HealthExercise.png';
import help from '../../icons/Help.svg';
import kitchenAppliancesimg from '../../icons/KitchenAppliances.png';
import laptopimg from '../../icons/Laptop.png';
import laundryclosetimg from '../../icons/LaundryCloset.png';
import smartwatchimg from '../../icons/Smartwatches.png';
import tabletimg from '../../icons/Tablet.png';
import tvimg from '../../icons/Television.png';
import vaccumCleanerimg from '../../icons/VaccumCleaner.png';
//Local Images for Category
import laptop from '../../icons/laptop_big.svg';
import MainStackLayout from '../../layout/MainStackLayout';
import { createInquiry } from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { DeviceCategory } from '../../types/types';
import { CONTENTFUL_SLUG } from '../../utils/constant';
import {
    hasMultiDeviceSupport,
    serialNbrScreenWithDeviceDetails,
} from '../../utils/featuresUtills';
import {
    formatDate,
    getDateAndDay,
    getRedirectUrl,
    isChineseDefaultLanguage,
    saveError,
    showErrorPage,
} from '../../utils/utills';
import TextInput from '../component/TextInput';
import FindSerialNoModal from './FindSerialNoModal';
import Select from './Select';

export interface AssetData {
    AssetCategory: {
        AssetCategoryId: string;
        AssetCategoryName: string;
    };
    MobileDeviceNumber: string;
    CountryCallingCode: string;
    AssetPurchaseDate: string;
    AssetId: string;
    AssetCatalog: {
        AssetCatalogId: string;
        AssetCatalogName: string;
        AssetFamily: string;
    };
    IMEI: string;
    Model: {
        ModelId: string;
        Name: string;
    };
    Make: {
        MakeId: string;
        Name: string;
    };
}

function AddNewDevice() {
    const navigate = useNavigate();
    const globalState = useCentralStore();

    const { isTranslationsLoading, language } = useTextScript();

    const laptops = useContentfulImage(CONTENTFUL_SLUG.LAPTOP);
    const laundryCloset = useContentfulImage(CONTENTFUL_SLUG.LAUNDRYCLOSET);
    const massageEquipment = useContentfulImage(CONTENTFUL_SLUG.MASSAGEEQUIPMENT);
    const kitchenAppliances = useContentfulImage(CONTENTFUL_SLUG.KITCHENAPPLIANCES);
    const beautyCare = useContentfulImage(CONTENTFUL_SLUG.BEAUTYCARE);
    const dehumidifierHeater = useContentfulImage(CONTENTFUL_SLUG.DEHUMIDIFIERHEATER);
    const purifierVaccum = useContentfulImage(CONTENTFUL_SLUG.PURIFIERVACCUM);
    const mobile = useContentfulImage(CONTENTFUL_SLUG.MOBILE);
    const tablet = useContentfulImage(CONTENTFUL_SLUG.TABLET);
    const tv = useContentfulImage(CONTENTFUL_SLUG.TV);
    // const gameConsole = useContentfulImage(CONTENTFUL_SLUG.GAMECONSOLE);
    const healthexercise = useContentfulImage(CONTENTFUL_SLUG.HEALTHEXERCISE);

    const CategoryList: any = [
        {
            categoryNameDisplay: '衣物整潔', //Laundry and closet
            categoryName: '衣物整潔',
            image: laundryCloset.imageUrl,
            localimage: laundryclosetimg,
        },
        {
            categoryNameDisplay: '按摩舒壓', // 'Massage Equipment',
            categoryName: '按摩舒壓',
            image: massageEquipment.imageUrl,
            localimage: massagechairimg,
        },
        {
            categoryNameDisplay: '廚房家電', //'Kitchen Appliances',
            categoryName: '廚房家電',
            image: kitchenAppliances.imageUrl,
            localimage: kitchenAppliancesimg,
        },
        {
            categoryNameDisplay: '美容美髮', //'Beauty care',
            categoryName: '美容美髮',
            image: beautyCare.imageUrl,
            localimage: beautyCareimg,
        },
        {
            categoryNameDisplay: '除濕保暖', //'除溼保暖', //'Dehumidifier & Heater',
            categoryName: '除濕保暖',
            image: dehumidifierHeater.imageUrl,
            localimage: dehumidifierHeaterimg,
        },
        {
            categoryNameDisplay: '清淨除塵', //'Purifier & Vacuum cleaner',
            categoryName: '清淨除塵',
            image: purifierVaccum.imageUrl,
            localimage: vaccumCleanerimg,
        },
        {
            categoryNameDisplay: '手機裝置', //'Mobile Devices',
            categoryName: '手機裝置',
            image: mobile.imageUrl,
            localimage: tabletimg,
        },
        {
            categoryNameDisplay: '平板電腦', //'Tablet',
            categoryName: '平板電腦',
            image: tablet.imageUrl,
            localimage: tabletimg,
        },
        {
            categoryNameDisplay: '筆記電腦', //'Laptop',
            categoryName: '筆記電腦',
            image: laptops.imageUrl,
            localimage: laptopimg,
        },
        {
            categoryNameDisplay: '健康運動', //'Health & Exercise',
            categoryName: '健康運動',
            image: healthexercise.imageUrl,
            localimage: smartwatchimg,
        },
        {
            categoryNameDisplay: '電視娛樂', //'TV',
            categoryName: '電視影音',
            image: tv.imageUrl,
            localimage: tvimg,
        },
    ];

    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };
    const agreementData: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const intializeResponse = globalState?.state?.sessionResponse?.InitializeResponse;
    let selectedDevice: any;

    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;

    const [serialNumber, setSrNo] = useState<string>('');
    const [isSrNoError, setSrNoError] = useState(false);
    const [duplicateSerialError] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [deviceCategory, setDeviceCategory] = useState<Array<DeviceCategory>>();
    const [date, setDate] = useState<string>('');
    // const [Placeholdertexts, setPlaceholderText] = useState<any>('');

    const [isChinese] = useState<boolean>(isChineseDefaultLanguage);

    const [brandLbl, setBrandLbl] = useState<string>('');
    const [modelLbl, setModelLbl] = useState<string>('');
    const [serialNumberLbl, setSerialNumberLbl] = useState<string>('');
    const [purchaseDateLbl, setPurchaseDateLbl] = useState<string>('');

    const AddNewDeviceScripts = Scripts.AddDevice.AddNewDevice;
    const AddNewDeviceKeys = translationsKeys.AddDevice.AddNewDevice;

    const pageName = AddNewDeviceKeys.PageNameAnchorDevice;

    if (agreementData) {
        if (
            hasMultiDeviceSupport(agreementData?.ClientOffer?.ClientOfferName) &&
            sessionStorage.getItem('dsMdn') &&
            globalState?.state?.fromUpdateDeviceButton
        ) {
            // get selected asset details stored in session
            const selectedAsset: any = JSON.parse(sessionStorage.getItem('dsMdn') || '');
            selectedDevice = agreementData?.Assets.Asset.filter(
                (a: any) => a.AssetId === selectedAsset?.assetId
            )[0];
        } else {
            selectedDevice = agreementData?.Assets.Asset.filter(
                (a: any) =>
                    a.AssetInstance === 'CUSVERIFY' &&
                    a.AssetRank?.toUpperCase() === 'ANCHOR DEVICE'
            )[0];
        }
    }

    const MobileDeviceNumber =
        selectedDevice && selectedDevice?.MobileDeviceNumber?.split('_')?.[0];
    const EmailAddress = agreementData?.ContactPoints?.ContactPoint?.filter(
        (a: any) => a.EmailAddress
    );
    const AssetPurchaseDate = getDateAndDay(
        selectedDevice && selectedDevice?.AssetPurchaseDate,
        'MMMM D, YYYY'
    );
    // const isChinese = isChineseDefaultLanguage();

    useEffect(() => {
        const filteredCategory = CategoryList?.filter((val: any) => {
            return val.categoryName === selectedDevice?.AssetCategory?.AssetCategoryName;
        });
        setDeviceCategory(filteredCategory);
    }, [agreementData && selectedDevice != undefined]);

    useEffect(() => {
        globalState?.dispatch({
            type: ActionTypes.SET_SELECTED_DEVICE_DETAILS,
            payload: selectedDevice,
        });
    }, [serialNumber && selectedDevice]);

    useEffect(() => {
        if (!isTranslationsLoading) {
            getBrandLbl();
            getModelLbl();
            getSerialNumberLbl();
            getPurchaseDateLbl();
        }
    }, [language, isTranslationsLoading]);

    const getBrandLbl = async () => {
        const brandLBL = await getTextScript(
            `${pageName}/${AddNewDeviceKeys.brand}`,
            isChinese ? CNScripts?.AddDevice?.AddNewDevice?.brand : AddNewDeviceScripts.brand,
            language,
            []
        );

        setBrandLbl(brandLBL);
    };

    const getModelLbl = async () => {
        const modelLbl = await getTextScript(
            `${pageName}/${AddNewDeviceKeys.model}`,
            isChinese ? CNScripts?.AddDevice?.AddNewDevice?.model : AddNewDeviceScripts.model,
            language,
            []
        );

        setModelLbl(modelLbl);
    };

    const getSerialNumberLbl = async () => {
        const serialNumberLbl = await getTextScript(
            `${pageName}/${AddNewDeviceKeys.serialNumber}`,
            isChinese
                ? CNScripts?.AddDevice?.AddNewDevice?.serialNumber
                : AddNewDeviceScripts.serialNumber,
            language,
            []
        );

        setSerialNumberLbl(serialNumberLbl);
    };

    const getPurchaseDateLbl = async () => {
        const purchaseDateLbl = await getTextScript(
            `${pageName}/${AddNewDeviceKeys.purchaseDate}`,
            isChinese
                ? CNScripts?.AddDevice?.AddNewDevice?.purchaseDate
                : AddNewDeviceScripts.purchaseDate,
            language,
            []
        );

        setPurchaseDateLbl(purchaseDateLbl);
    };

    const callInquiryRegisterDeviceApi = (document: object) => {
        setLoading(true);
        const CreateInquiryParams = {
            CreateInquiryParams: {
                MobileDeviceNumber: MobileDeviceNumber || '',
                FirstName: agreementData?.Customers?.Customer[0]?.FirstName || '',
                LastName: agreementData?.Customers?.Customer[0]?.LastName || '',
                ContactNumber: MobileDeviceNumber || '',
                ClientName: intializeResponse?.ClientName,
                InquiryChannel: 'ONLINE',
                InquiryType: 'Update Device Details',
                InquirySubType: 'Update Device Details',
                InquiryDescription: `ModelNo=${selectedDevice?.AssetModel}|SerialNumber=${serialNumber}|Category=${selectedDevice?.AssetCategory?.AssetCategoryName}|Make=${selectedDevice?.Make?.Name}|AssetCatalogId =${selectedDevice?.AssetCatalog?.AssetCatalogId}|PurchaseDate=${selectedDevice?.AssetPurchaseDate}|PurchasePrice=${selectedDevice?.AssetPurchasePrice}|StoreCode=${agreementData?.AgreementPurchase?.StoreCode}|CountryCallingCode=${agreementData?.ContactPoints?.ContactPoint[0]?.CountryCallingCode}| CurrencyCode = ${intializeResponse?.CurrencyCode}| ClientOfferName = ${agreementData?.ClientOffer?.ClientOfferName}`,
                CallDriverCategory: 'Master Data Management',
                CallDriverSubCategory: 'Master Data Management',
                ClientId: intializeResponse?.ClientId, // "39EBF36EBC7A11EC953306C1D4AC7E74",
                Email: EmailAddress[0]?.EmailAddress,
                DepartmentType: 'Supply Chain',
                Priority: 'High',
                ProgramName: agreementData?.ClientOffer?.ClientOfferName,
                AgreementId: agreementData?.AgreementId,
                isPremiumPlan: true,
                ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
                ClientChannelId: agreementData?.ClientAccount?.ClientChannelId,
                CustomerId: agreementData?.Customers?.Customer[0]?.CustomerId,
                AssetId: selectedDevice?.AssetId,
                Documents: document,
                DeviceDetails: {
                    Category: selectedDevice?.AssetCategory?.AssetCategoryName,
                    AssetMake: selectedDevice?.Make?.Name,
                    AssetModel: selectedDevice?.AssetCatalog?.AssetCatalogName?.concat(
                        '-',
                        selectedDevice?.Model?.Color
                    ),
                    AssetAttributes: `ModelNo=${selectedDevice?.AssetModel}|SerialNumber=${serialNumber}|Category=${selectedDevice?.AssetCategory?.AssetCategoryName}|Make=${selectedDevice?.Make?.Name}|AssetCatalogId =${selectedDevice?.AssetCatalog?.AssetCatalogId}|PurchaseDate=${selectedDevice?.AssetPurchaseDate}|PurchasePrice=${selectedDevice?.AssetPurchasePrice}|StoreCode=${agreementData?.AgreementPurchase?.StoreCode}|CountryCallingCode=${agreementData?.ContactPoints?.ContactPoint[0]?.CountryCallingCode}| CurrencyCode = ${intializeResponse?.CurrencyCode}| ClientOfferName = ${agreementData?.ClientOffer?.ClientOfferName}`,
                    PurchaseDate: selectedDevice?.AssetPurchaseDate,
                    SerialNumber: selectedDevice?.SerialNumber,
                },
                UpdateDeviceDetails: {
                    AssetCatalogId: selectedDevice?.AssetCatalog?.AssetCatalogId,
                    IMEI: serialNumber,
                    ClientAssetSkuNumber: selectedDevice?.AssetModel,
                },
            },
        };

        createInquiry(
            CreateInquiryParams,
            interactionData?.InteractionLine.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then((resp) => {
                setLoading(false);
                globalState?.dispatch({ type: ActionTypes.SET_INQUIRY_RESPONSE, payload: resp });
                navigate('/confirmDeviceRegistration', { replace: true });
            })
            .catch((error: any) => {
                setLoading(false);
                console.log('uploadPurchaseReceipt:', error);
                saveError(error, globalState);
                showErrorPage(navigate);
            });
    };

    const onCancelClick = () => {
        window.location.href = getRedirectUrl(getTypeByDomain());
    };

    const onClickPopup = () => {
        setOpenCancelPopup(!openCancelPopup);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDate(e.target.value);
        console.log(date);
    };

    const handleSrNoChange = (e: any) => {
        const regex = /^[a-zA-Z0-9-_#]+$/;

        if (regex.test(e.value)) {
            setSrNoError(false);
            setIsDisabled(false);
        } else {
            setSrNoError(true);
            setIsDisabled(true);
        }

        setSrNo(e.value);
    };

    // const getUpdateDeviceDetailsParams = () => {
    //   const UpdateDeviceParameters = {
    //     UpdateDeviceParameters: {
    //       MobileDeviceNumber: MobileDeviceNumber,
    //       AgreementId: agreementData?.AgreementId,
    //       SerialNumber: serialNumber,
    //       AssetId: selectedDevice?.AssetId,
    //       User: `${agreementData?.Customers?.Customer[0]?.FirstName || ''} ${agreementData?.Customers?.Customer[0]?.LastName || ''}`,
    //     },
    //   };
    //   return UpdateDeviceParameters;
    // };

    // const callUpdateDeviceDetailsAPI = () => {
    //   setLoading(true);
    //   updateDeviceDetailsApi(
    //     getUpdateDeviceDetailsParams(),
    //     interactionData?.InteractionLine?.InteractionLineId
    //   )
    //     .then((resp) => {
    //       setLoading(false);
    //       if (resp.message === 'Serial number is not unique or already in use') {
    //         setDuplicateSerialError(true)
    //       } else {
    //         navigate('/ConfirmDeviceRegistration', { replace: true });
    //       }
    //     })
    //     .catch((error) => {
    //       setLoading(false);
    //       saveError(error, globalState);
    //       showErrorPage(navigate);
    //     });
    // };

    const handleSubmit = () => {
        if (
            serialNbrScreenWithDeviceDetails(agreementData?.ClientOffer?.ClientOfferName) &&
            globalState?.state?.fromUpdateDeviceButton
        ) {
            callInquiryRegisterDeviceApi(globalState?.state?.updateDeviceFile);
        } else {
            // here convert date in to English only
            const purchaseDate = formatDate(
                selectedDevice?.AssetPurchaseDate,
                'YYYY-MM-DDT00:00:00.000Z',
                'en-US',
                'MMMM D, YYYY'
            );

            globalState?.dispatch({
                type: ActionTypes.SET_ADDED_DEVICE_DETAILS,
                payload: {
                    category: selectedDevice?.AssetCategory?.AssetCategoryName,
                    PurchaseDate: purchaseDate,
                    AssetMake: selectedDevice?.Make?.Name,
                    AssetModel: selectedDevice?.AssetCatalog?.AssetCatalogName?.concat(
                        '-',
                        selectedDevice?.Model?.Color
                    ),
                    SerialNumber: serialNumber,
                    AssetCatalogId: selectedDevice?.AssetCatalog?.AssetCatalogId,
                    ModelRRP: selectedDevice?.AssetPurchasePrice,
                },
            });
            navigate('/uploadPurchaseReceipt', { replace: true });
            //callUpdateDeviceDetailsAPI();
        }
    };

    const onBackClick = () => {
        window.history.back();
    };

    return (
        <Box>
            <Box w={{ base: 'full', lg: '80%' }} margin="auto" pt={'50px'}>
                <MainStackLayout gap={0} spacing={0}>
                    <SubHeader
                        backText={isChinese ? CNScripts?.Global.Back : Scripts.Global.Back}
                        allowBackButtonClick={false}
                        showCancelButton={false}
                        showBackButton={true}
                        cancelText={Scripts.CancelSR.cancelBtnTextCancel}
                        onCancelClick={onCancelClick}
                        onClick={onBackClick}
                    ></SubHeader>

                    {deviceCategory && (
                        <Box
                            id={'myasurion-profile-avatar'}
                            w={{ base: 'full', lg: '80%' }}
                            margin="0 auto"
                            h={'auto'}
                            p={'1rem'}
                        >
                            {deviceCategory?.map((a: any) => (
                                <Image
                                    my={'2'}
                                    marginX={'auto'}
                                    src={a.image || a.localimage || laptopimg}
                                    alt="your model"
                                />
                            ))}
                            {!deviceCategory?.length && (
                                <Image
                                    my={'2'}
                                    marginX={'auto'}
                                    src={laptop || laptopimg}
                                    alt="your model"
                                />
                            )}
                            <Text mt={'5'} fontSize={{ base: '3xl', lg: '4xl' }}>
                                <TextScript
                                    text={
                                        isChinese
                                            ? CNScripts.AddDevice.AddNewDevice.header
                                            : AddNewDeviceScripts.header
                                    }
                                    translationKey={`${pageName}/${AddNewDeviceKeys.header}`}
                                />
                            </Text>

                            {selectedDevice && (
                                <Box>
                                    <Box mt={'4'}>
                                        <Select
                                            size={'lg'}
                                            borderColor={'#A5AAAF'}
                                            bg={'#cfd1d4'}
                                            floatingPlaceholder={
                                                brandLbl || isChinese
                                                    ? CNScripts?.AddDevice?.AddNewDevice?.brand
                                                    : AddNewDeviceScripts.brand
                                            }
                                            floatingLabel={selectedDevice?.Make?.Name}
                                            variant={'filled'}
                                            className={`floatingPlaceholder`}
                                        >
                                            <option>{selectedDevice?.Make?.Name}</option>
                                        </Select>

                                        <Select
                                            size={'lg'}
                                            borderColor={'#A5AAAF'}
                                            bg={'#cfd1d4'}
                                            mt={'4'}
                                            floatingPlaceholder={
                                                modelLbl || isChinese
                                                    ? CNScripts?.AddDevice?.AddNewDevice?.model
                                                    : AddNewDeviceScripts.model
                                            }
                                            floatingLabel={selectedDevice?.AssetCatalog?.AssetCatalogName?.concat(
                                                '-',
                                                selectedDevice?.Model?.Color
                                            )}
                                            variant={'filled'}
                                            className={`floatingPlaceholder`}
                                        >
                                            <option>
                                                {selectedDevice?.AssetCatalog?.AssetCatalogName?.concat(
                                                    '-',
                                                    selectedDevice?.Model?.Color
                                                )}
                                            </option>
                                        </Select>

                                        <FormControl w={'full'} isRequired={true} mt={4}>
                                            <TextInput
                                                size={'lg'}
                                                showIcon={false}
                                                type="text"
                                                borderColor={'#A5AAAF'}
                                                placeholder={
                                                    serialNumberLbl || isChinese
                                                        ? CNScripts?.AddDevice?.AddNewDevice
                                                              ?.serialNumber
                                                        : AddNewDeviceScripts.serialNumber
                                                }
                                                onChange={(e: any) => handleSrNoChange(e)}
                                                value={serialNumber}
                                                maxLength={30}
                                                // isInvalid={!isInvalidInpuTBldgLBLValue}
                                            />
                                            {isSrNoError && (
                                                <Text color={'red'}>
                                                    <TextScript
                                                        text={
                                                            isChinese
                                                                ? CNScripts.AddDevice.AddNewDevice
                                                                      .errorSerialNumber
                                                                : AddNewDeviceScripts.errorSerialNumber
                                                        }
                                                        translationKey={`${pageName}/${AddNewDeviceKeys.errorSerialNumber}`}
                                                    />
                                                </Text>
                                            )}
                                        </FormControl>
                                    </Box>
                                    <Flex
                                        flexDir={'row'}
                                        my="5"
                                        pt={2}
                                        onClick={() => {
                                            onClickPopup();
                                        }}
                                    >
                                        <Image src={help} cursor={'pointer'}></Image>
                                        <Text
                                            cursor={'pointer'}
                                            ml={'2'}
                                            textDecoration={'underline'}
                                        >
                                            <TextScript
                                                text={
                                                    isChinese
                                                        ? CNScripts.AddDevice.AddNewDevice
                                                              .findSerialNumber
                                                        : AddNewDeviceScripts.findSerialNumber
                                                }
                                                translationKey={`${pageName}/${AddNewDeviceKeys.findSerialNumber}`}
                                            />
                                        </Text>
                                    </Flex>
                                    <InputGroup>
                                        <InputRightElement
                                            pointerEvents="none"
                                            marginTop={{ lg: '0px', base: '20px' }}
                                            marginRight={{ lg: '0px', base: '7px' }}
                                        >
                                            <CalendarIcon />
                                        </InputRightElement>
                                        <Input
                                            bg={'#cfd1d4'}
                                            placeholder={
                                                purchaseDateLbl || isChinese
                                                    ? CNScripts?.AddDevice?.AddNewDevice
                                                          ?.purchaseDate
                                                    : AddNewDeviceScripts.purchaseDate
                                            }
                                            borderColor={'#A5AAAF'}
                                            color={'brand.black'}
                                            value={AssetPurchaseDate}
                                            size="md"
                                            type="text"
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                </Box>
                            )}
                            {duplicateSerialError && (
                                <Text color={'red'}>
                                    <TextScript
                                        text={AddNewDeviceScripts.duplicateSerialNumber}
                                        translationKey={`${pageName}/${AddNewDeviceKeys.duplicateSerialNumber}`}
                                    />
                                </Text>
                            )}
                            <Box width={{ base: 'full', lg: '50%' }} marginX={'auto'}>
                                <Button
                                    mt={'5'}
                                    width={'full'}
                                    textAlign={'center'}
                                    colorScheme="primary"
                                    bg={isDisabled || isSrNoError ? 'gray.200' : colors.primary}
                                    cursor={isDisabled || isSrNoError ? 'not-allowed' : 'pointer'}
                                    disabled={isDisabled || isSrNoError}
                                    onClick={() =>
                                        isDisabled || isSrNoError ? undefined : handleSubmit()
                                    }
                                >
                                    <TextScript
                                        text={
                                            isChinese
                                                ? CNScripts.Global.Continue
                                                : Scripts.Global.Continue
                                        }
                                        translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Continue}`}
                                    />
                                </Button>
                            </Box>
                        </Box>
                    )}
                </MainStackLayout>
                <FindSerialNoModal
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
        </Box>
    );
}

export default AddNewDevice;
