import { useEffect, useState } from 'react';

import { getContentfulImage } from '../services/contentful';

function useContentfulImage(slug: string) {
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchImage() {
            try {
                if (!slug) {
                    setImageUrl(null);
                    return;
                } else {
                    const entry = await getContentfulImage(slug);
                    if (entry) {
                        setImageUrl(entry);
                    }
                }
            } catch (error) {
                console.error('Error fetching Contentful image:', error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchImage();
    }, [slug]);

    return { imageUrl, isLoading };
}

export default useContentfulImage;
