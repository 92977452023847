/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-console */
import { TextScript } from '@MGPD/myasurion-shared';
import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Input, Link } from '@chakra-ui/react';
import { useState } from 'react';

import Scripts from '../../Scripts/asurion-script.json';
import TranslationKeys from '../../Scripts/translationsKeys.json';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';

interface AlternateContactNumberProps {
    validLength: number;
    alternateContactNoPlaceholder: string;
    showAlternateContactField: boolean;
    setShowAlternateContactField: (args: boolean) => void;
}

const AlternateContactNumber: React.FC<AlternateContactNumberProps> = ({
    validLength,
    alternateContactNoPlaceholder,
    setShowAlternateContactField,
    showAlternateContactField,
}) => {
    const globalState = useCentralStore();

    const [alternateContactNo, setAlternateContactNo] = useState('');
    const [invalidMobile, setInvalidMobile] = useState(false);

    const confirmAlternateContactNumber = () => {
        if (alternateContactNo && !invalidMobile && globalState) {
            globalState?.dispatch({
                type: ActionTypes.SET_ALTERNATE_CONTACT_NUMBER,
                payload: alternateContactNo,
            });
            setShowAlternateContactField(false);
        }
    };

    const handleAlternateContactChange = (e: any) => {
        const { value } = e.target;
        setAlternateContactNo(value);

        let reg = new RegExp('^[\\d]{' + validLength + '}$', 'g');
        if (reg.test(value)) {
            setInvalidMobile(false);
        } else if (value.trim() === '') {
            setInvalidMobile(false);
        } else {
            setInvalidMobile(true);
        }
    };

    const handleCancel = () => {
        setShowAlternateContactField(false);
        setInvalidMobile(false);
    };

    return (
        <Box w="full" m="0 auto">
            <Box px={4} pb={2}>
                <Flex justifyContent={'space-between'} pb={1}>
                    <Box fontWeight={'bold'}>
                        <TextScript
                            text={'Alternate Contact Number'}
                            translationKey={`${TranslationKeys.RefreshLogisticsScripts.PageName}/alternateContactNoText1`}
                        />
                    </Box>
                    {!showAlternateContactField && (
                        <Box onClick={() => setShowAlternateContactField(true)}>
                            <Link textDecorationLine={'underline'}>
                                <TextScript
                                    text={Scripts.Global.Edit}
                                    translationKey={`${TranslationKeys.Global.PageName}/${TranslationKeys.Global.Edit}`}
                                />
                            </Link>
                        </Box>
                    )}
                    {showAlternateContactField && (
                        <Flex justifyContent={'end'}>
                            <Box
                                onClick={confirmAlternateContactNumber}
                                borderWidth="1px"
                                borderRadius="md"
                                borderColor="#6E767D"
                                px={1}
                                mr={3}
                                cursor={'pointer'}
                            >
                                <CheckIcon boxSize={4} focusable={true} />
                            </Box>
                            <Box
                                onClick={handleCancel}
                                borderWidth="1px"
                                borderRadius="md"
                                borderColor="#6E767D"
                                px={1}
                                cursor={'pointer'}
                            >
                                <SmallCloseIcon boxSize={4} focusable={true} />
                            </Box>
                        </Flex>
                    )}
                </Flex>
                {showAlternateContactField && (
                    <Box
                        borderWidth="1px"
                        borderRadius="lg"
                        borderColor="#6E767D"
                        maxW={{ base: '80%', lg: '50%' }}
                    >
                        <Input
                            border={'none'}
                            maxLength={validLength}
                            readOnly={false}
                            disabled={false}
                            placeholder={alternateContactNoPlaceholder}
                            _focus={{ border: 'none', boxShadow: 'none' }}
                            onChange={(e) => handleAlternateContactChange(e)}
                        />
                    </Box>
                )}
                {showAlternateContactField && invalidMobile && (
                    <Box color={'red'} fontSize={'14px'}>
                        <TextScript
                            text={'* Invalid mobile number'}
                            translationKey={`${TranslationKeys.RefreshLogisticsScripts.PageName}/alternateContactNoErrorMsg`}
                        />
                    </Box>
                )}
                {!showAlternateContactField && (
                    <Box>{globalState?.state?.alternateContactNumber || '-'}</Box>
                )}
            </Box>
        </Box>
    );
};

export default AlternateContactNumber;
