/* eslint-disable no-console */
import { AccountInfo, TextScript, useAccount } from '@MGPD/myasurion-shared';
import { Checkbox } from '@chakra-ui/checkbox';
import { Box } from '@chakra-ui/react';
import { useState } from 'react';

export interface NotificationItemCheckboxProps {
    id: string;
    notifType: string;
    title: string;
    value: boolean;
    onToggleCheck?: ({
        notifType,
        id,
        value,
    }: {
        notifType: string;
        id: string;
        value: boolean;
    }) => void;
}

export const NotificationItemCheckbox = ({
    id,
    notifType,
    title,
    value,
    onToggleCheck,
}: NotificationItemCheckboxProps) => {
    const [checked] = useState<boolean>();
    const [account, setAccount] = useAccount<AccountInfo>({ userId: '', profileId: '' });

    return (
        <Checkbox
            spacing="2"
            isChecked={checked || value}
            onChange={() => {
                const _id: string = id.toLowerCase();
                const v = !value;
                const np: any =
                    account &&
                    account.notificationPreferences &&
                    account.notificationPreferences[notifType] &&
                    account.notificationPreferences[notifType] &&
                    account.notificationPreferences[notifType]
                        ? account.notificationPreferences[notifType]
                        : {};

                np[_id] = v;

                setAccount({
                    ...account,
                    notificationPreferences: {
                        ...account.notificationPreferences,
                        [notifType]: np,
                    },
                });

                onToggleCheck && onToggleCheck({ notifType, id, value: v });
            }}
        >
            {/* <Text fontWeight="400">
        <TextTranslation
          text={title}
          translationKey={`notifications-checkbox-${id}`}
          module={'myAsurionProfile'}
          section={'notifications'}
        />
      </Text> */}
            <Box fontWeight="400">
                <TextScript
                    text={title}
                    translationKey={`CommunicationPreferences/notifications-checkbox-${id}`}
                />
            </Box>
        </Checkbox>
    );
};
export default NotificationItemCheckbox;
