import { TextScript, getTypeByDomain } from '@MGPD/myasurion-shared';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Center, Image, Text } from '@chakra-ui/react';

import translationsKeys from '../../Scripts/translationsKeys.json';
import message from '../../assets/message.png';
import MainStackLayout from '../../layout/MainStackLayout';
import { getRedirectUrl } from '../../utils/utills';

function ThanksMessage() {
    const ThanksNPSKeys = translationsKeys.ThanksFeedback;
    return (
        <Center>
            <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'} w="full">
                <MainStackLayout gap={0} spacing={0}>
                    <Box
                        px={'10px'}
                        w={{ base: 'full', lg: '80%' }}
                        paddingTop={'16px'}
                        marginX={{ base: '', lg: 'auto' }}
                        mb={{ base: '0', lg: '20px' }}
                    >
                        <Box
                            w={'full'}
                            h={'48px'}
                            p={'8px'}
                            display={'flex'}
                            alignItems={'flex-start'}
                            gap={'4px'}
                        >
                            <Box display={'flex'} justifyContent={'right'} width={'100%'}>
                                <CloseIcon
                                    onClick={() =>
                                        (window.location.href = getRedirectUrl(getTypeByDomain()))
                                    }
                                    display={'block'}
                                    cursor={'pointer'}
                                    alignSelf={'center'}
                                    marginX={'0.5rem'}
                                    padding={'1px'}
                                />
                            </Box>
                        </Box>
                        <Image
                            margin={'auto'}
                            align={'center'}
                            key={`img-messge`}
                            src={message}
                            alt="message img"
                            w={'80px'}
                            h={'80px'}
                        ></Image>
                        <Text fontSize={'32px'} py={'8px'}>
                            <TextScript
                                text={'Thanks for your feedback'}
                                translationKey={`${ThanksNPSKeys.PageName}/${ThanksNPSKeys.HeaderThanks}`}
                            />
                        </Text>
                        <Text fontSize={'18px'}>
                            <TextScript
                                text={'Your feedback is a huge part of improving the experience.'}
                                translationKey={`${ThanksNPSKeys.PageName}/${ThanksNPSKeys.MessageText}`}
                            />
                        </Text>
                    </Box>
                </MainStackLayout>
            </Box>
        </Center>
    );
}

export default ThanksMessage;
