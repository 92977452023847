import { useConfig } from '@MGPD/myasurion-shared';
import { Box, Flex, FormControl, FormErrorMessage, Icon, Input, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    ChangeEvent,
    ChangeEventHandler,
    EventHandler,
    FC,
    FocusEvent,
    FocusEventHandler,
    HTMLInputTypeAttribute,
    PropsWithChildren,
    useEffect,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface FloatLabelInputFieldProps {
    defaultValue: string;
    inputId: string;
    inputName: string;
    inputType: HTMLInputTypeAttribute | undefined;
    inputPlaceholder: string;
    maxLength?: number;
    onChange: EventHandler<ChangeEvent<HTMLInputElement>> | undefined;
    variant?: 'edit' | undefined;
    isRequired?: boolean;
    isInvalid?: boolean;
    onError?: (error?: string) => void;
    yupSchema?: yup.ObjectSchema<
        {
            [x: string]: string | undefined;
        },
        yup.AnyObject,
        {
            [x: string]: undefined;
        },
        ''
    >;
}

const PencilIcon = () => {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M19.0176 4.31533C18.3842 3.68116 17.5409 3.33283 16.6451 3.33283C15.7501 3.33283 14.9067 3.68116 14.2734 4.31533L4.76589 13.822C4.76589 13.822 4.76506 13.8245 4.76339 13.8253C4.69589 13.8937 4.58089 14.0737 4.54589 14.2095L3.35839 18.9628C3.28672 19.2462 3.37089 19.5478 3.57756 19.7537L3.57922 19.7553C3.78589 19.962 4.08589 20.0462 4.37006 19.9753L9.12339 18.7862C9.25922 18.7512 9.44006 18.6362 9.50756 18.5687L9.51089 18.5662L19.0184 9.06033C19.6517 8.42616 20.0009 7.58449 20.0001 6.68783C20.0001 5.79199 19.6517 4.94949 19.0184 4.31533L19.0176 4.31533ZM13.6759 7.27283L16.0617 9.65866L8.92172 16.7987L6.53589 14.4128L13.6759 7.27283ZM5.31256 18.0212L5.80839 16.0428L7.29172 17.527L5.31256 18.0212ZM17.8409 5.49449C18.4776 6.13199 18.4776 7.24366 17.8409 7.88116L17.2417 8.48116L14.8534 6.09366L15.4542 5.49449C16.0917 4.85783 17.2034 4.85699 17.8409 5.49449Z"
                fill="black"
            />
        </Icon>
    );
};

const FloatLabelInputField: FC<PropsWithChildren & FloatLabelInputFieldProps> = ({
    defaultValue,
    inputId,
    inputType = 'text',
    inputName,
    inputPlaceholder,
    maxLength,
    onChange,
    variant,
    isRequired = false,
    onError,
    yupSchema,
}) => {
    const schema = yup.object().shape({
        [inputName]: yup.string().required(),
    });
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const [inputFocused, setInputFocused] = useState(false);

    const {
        register,
        formState: { errors },
    } = useForm<{
        [x: string]: string | undefined;
    }>({
        mode: 'onChange',
        resolver: yupResolver(yupSchema || schema),
    });

    const handleFocus = () => {
        setInputFocused(true);
    };
    const handleBlur: FocusEventHandler<HTMLInputElement> = (
        event: FocusEvent<HTMLInputElement, Element>
    ) => {
        // if (!event?.target?.value) {
        setInputFocused(false);
        // }
        register(inputName).onBlur(event);
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        // if (event?.target?.value?.length === 0) {
        //   setInputFocused(false);
        // } else {
        //   setInputFocused(true);
        // }
        register(inputName).onChange(event);
        onChange && onChange(event);
    };

    useEffect(() => {
        onError && onError(errors[inputName]?.message);
    }, [errors, inputName, onError, inputFocused]);

    return (
        <FormControl isRequired={isRequired} isInvalid={!!errors[inputName]?.message}>
            <Box
                border={
                    inputFocused
                        ? `3px solid ${
                              errors[inputName]?.message ? 'red' : colors.primary || '#8223d2'
                          }`
                        : `1.5px solid ${errors[inputName]?.message ? 'red' : '#6e767d'}`
                }
                boxSizing="border-box"
                position="relative"
                width="100%"
                h="3.5rem"
                borderRadius="4px"
                pos={'relative'}
            >
                <Text
                    as="label"
                    htmlFor={inputId}
                    fontSize={inputFocused || defaultValue?.length > 0 ? '14px' : '16px'}
                    lineHeight={inputFocused || defaultValue?.length > 0 ? '16px' : '19px'}
                    color={
                        inputFocused || defaultValue?.length > 0
                            ? `${colors.primary || '#8223d2'}`
                            : '#6e767d'
                    }
                    position="absolute"
                    transformOrigin="top left"
                    transform={
                        inputFocused || defaultValue?.length > 0
                            ? 'translateY(4px) scale(.8)'
                            : 'translateY(24px) scale(1)'
                    }
                    transition="all .1s ease-in-out"
                    paddingX="12px"
                    marginTop="-5px"
                    paddingTop={inputFocused || defaultValue?.length > 0 ? '8px' : '0px'}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {inputPlaceholder}
                </Text>
                <Flex
                    direction="row"
                    h="full"
                    justifyContent="space-between"
                    alignItems="center"
                    zIndex="1"
                >
                    <Input
                        id={inputId}
                        boxShadow="unset !important"
                        type={inputType}
                        border="none"
                        fontSize="16px"
                        outline="0"
                        lineHeight="19px"
                        width="full"
                        h="auto !important"
                        p="20px 9px 10px 9px"
                        pos="static"
                        background="none"
                        defaultValue={defaultValue}
                        data-placeholder={inputPlaceholder}
                        placeholder={
                            inputFocused || defaultValue?.length > 0 ? inputPlaceholder : ''
                        }
                        zIndex={'unset'}
                        onFocus={handleFocus}
                        // onKeyUp={handleKeyUp}
                        // autoComplete="off"
                        // onInput={}
                        maxLength={maxLength}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        {...register(inputName)}
                        onBlur={(e) => {
                            handleBlur(e);
                        }}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                    />
                    {variant &&
                        variant === 'edit' &&
                        (inputFocused || defaultValue?.length > 0) && (
                            <Box h="full" p="12px 12px 12px 8px">
                                <PencilIcon />
                            </Box>
                        )}
                </Flex>
            </Box>
            <FormErrorMessage height="min-content">
                <Text fontSize={'16px'} px="12px">
                    {errors[inputName]?.message
                        ?.toString()
                        ?.replace(inputName, inputPlaceholder ?? inputId)}
                </Text>
            </FormErrorMessage>
        </FormControl>
    );
};

export default FloatLabelInputField;
