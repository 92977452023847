import { Icon, IconProps } from '@chakra-ui/icons';
import * as React from 'react';
import { JSX } from 'react/jsx-runtime';

export const CalendarIcon = (
    props: JSX.IntrinsicAttributes &
        Omit<React.SVGProps<SVGSVGElement>, 'as' | 'translate' | keyof IconProps> & {
            htmlTranslate?: 'yes' | 'no' | undefined;
        } & IconProps & { as?: 'svg' | undefined }
) => (
    <Icon xmlns="http://www.w3.org/2000/svg" width={'24px'} height={'24px'} fill="none" {...props}>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21 3H18V1H16V7H18V5H21V20H3V5H5V3H3C1.896 3 1 3.895 1 5V20C1 21.104 1.896 22 3 22H21C22.104 22 23 21.104 23 20V5C23 3.895 22.104 3 21 3Z"
                fill="black"
            />
            <path d="M8 5H15V3H8V1H6V7H8V5Z" fill="black" />
            <path d="M5 9H9V13H5V9Z" fill="black" />
            <path d="M14 9H10V13H14V9Z" fill="black" />
            <path d="M15 9H19V13H15V9Z" fill="black" />
            <path d="M9 14H5V18H9V14Z" fill="black" />
            <path d="M10 14H14V18H10V14Z" fill="black" />
        </svg>
    </Icon>
);
export default CalendarIcon;
