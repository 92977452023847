/* eslint-disable no-console */
// import { AddIcon } from '@chakra-ui/icons';
import {
    TextScript,
    getCarrierByDomain,
    getTextScript,
    getTypeByDomain,
    useConfig,
    useSessionStorage,
    useTextScript,
} from '@MGPD/myasurion-shared';
import {
    Box,
    BoxProps,
    Button,
    Flex,
    Link,
    Skeleton,
    // Square,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ReactNode } from 'react';
//timeConvert24to12Hr
import { useNavigate } from 'react-router-dom';

import ClaimCardKeys from '../../Scripts/translationsKeys.json';
import {
    CancelMySRKey,
    ResumeFlowDataKey,
    assetDataKey,
    claimDetailsResponseKey,
    currentAgreementKey,
    interactionLineKey,
    processIncidentKey,
    serviceFeeKey,
    serviceRequestKey,
    shippingMethodResponseKey,
} from '../../modules/config/constants';
import {
    TCATCDeleteJob,
    TCATCScheduleJob,
    getUpdateShippingDetails,
    repairRequestSchedule,
    updateDelivery,
    updateRepairRequest,
    updateStatus,
    createInquiryV1,
} from '../../services/api';
import {
    cancelSRApi,
    createServiceRequestApi,
    getFulfillmentMethodApi,
    logGTMEvent,
    processIncidentApi,
    setFulfillmentMethodApi,
} from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import {
    CANCEL_CLEANING_SERVICE_ELIGIBLE_STATUS,
    CANCEL_RETURN_ELIGIBLE_REPAIR_STATUS,
    FULLFILLMENT_METHOD_TYPE,
    FULLFILLMENT_TYPES,
    GTM_EVENT,
    INCIDENT_TYPES,
    LOGISTICS_TYPES,
    PICKUP_ELIGIBLE_REPAIR_STATUS,
    RETURN_ELIGIBLE_REPAIR_STATUS,
    TCAT_JOB_MODE,
    WARRANTY_TYPE,
} from '../../utils/constant';
import {
    callServiceFee,
    callShippingMethodv1,
    callUpdateDelivery,
    getDeliverySlot,
    getFulfillmentMethodType,
    hasEditAlternateNumberFeature,
    hasMultiDeviceSupport,
    hasTCAT,
    hideWarranty,
    isMalfuntionWarrantyEligible,
    pickUpDeliveryWindow,
    returnDeliveryWindow,
} from '../../utils/featuresUtills';
import { camelize, getCountryCode } from '../../utils/helper';
import {
    getCurrencySymbol,
    getGlobalDateFormat,
    getPerilText,
    getProgrammeName,
} from '../../utils/localization-helper';
import {
    convertDateToUTC,
    convertTimeRange24To12,
    getAddress,
    getDateAndDay,
    getGTMData,
    getMDNFromAgreement,
    getShippingNodeByJobMode,
    // getTimeSlot,
    isBatteryReplacement,
    isClaimEligible,
    isDeviceRefresh,
    isDeviceRepair,
    isMalfunction,
    isReplacement,
    isScreenRepair,
    isScreenRepairBatReplacement,
    isScreenRepairWarranty,
    isSwap,
    isWalkIn,
    saveError,
    showErrorPage,
} from '../../utils/utills';
import Scripts from './../../Scripts/asurion-script.json';
import CancelSrPopup from './CancelSrPopup';
import DeviceService from './DeviceService';
import ClaimDetails from './claimDetails';
import TrackMyDelivery from './TrackMyDelivery';
import CancelDeliveryPopup from './CancelDeliveryPopup';

export interface DeviceCardProps {
    plan: string;
    mdn: string;
    model: string;
    imei: string;
    imageEl?: ReactNode | undefined;
    showMoreDetails?: boolean;
    detailsEl?: ReactNode | undefined;
    isLoading?: boolean;
    assetId?: string;
    status: string;
    warranty: string;
    data?: any;
    handleResume?: any;
    handleInwarranty?: any;
    callCancelClaimApi?: any;
    callClaimDetailsAPI?: any;
    hideWarrantyButton?: boolean;
}
export interface AssetData {
    MobileDeviceNumber: string;
    CountryCallingCode: string;
    AssetId: string;
    AssetCatalog: {
        AssetCatalogId: string;
        AssetCatalogName: string;
        AssetFamily: string;
    };
    IMEI: string;
    Model: {
        ModelId: string;
        Name: string;
    };
}

export const DeviceCard: React.FC<DeviceCardProps & BoxProps> = (args: any) => {
    const [clickMore, setClickMore] = useState(true);
    const [claimCancncelled, setClaimCancncelled] = useState(false);
    const [updateRepairStatus, setupdateRepairStatus] = useState(false);
    const [clickSwapRequest, setClickSwapRequest] = useState(false);
    const [clickReturnDeviceRequest, setReturnDeviceRequest] = useState(false);
    const [assetData, setAssetData] = useState<any>();
    const [, setServiceRequestData] = useSessionStorage<any>(serviceRequestKey, null);
    const [, setProcessIncidentData] = useSessionStorage<any>(processIncidentKey, null);
    // const [serviceFee, setServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);
    const [, setSelectedAssetData] = useSessionStorage<any>(assetDataKey, null);
    const [, setInteractionLineData] = useSessionStorage<any>(interactionLineKey, null);

    const [showEditableFields, setShowEditableFields] = useState(false);
    const [openTrackMyDelivery, setOpenTrackMyDelivery] = useState(false);
    const [openTrackOrderPopup, setOpenTrackOrderPopup] = useState(false);
    const [cancelDeliveryPopup, setCancelDeliveryPopup] = useState(false);
    const [cancelDeliverySuccessMsg, setCancelDeliverySuccessMsg] = useState(false);
    
    const navigate = useNavigate();
    const domainType = getTypeByDomain();
    const carrier = getCarrierByDomain();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [notes, setNotes] = useState('');
    const [TCATJobError, setTCATJobError] = useState('');

    const {
        data,
        warranty,
        assetId,
        status,
        plan,
        mdn,
        model,
        imei,
        imageEl,
        showMoreDetails,
        detailsEl,
        isLoading,
        handleResume,
        handleInwarranty,
        callCancelClaimApi,
        callClaimDetailsAPI,
        hideWarrantyButton,
        ...props
    } = args;

    const globalState = useCentralStore();
    const { isTranslationsLoading, language } = useTextScript();
    const initializeData = globalState?.state?.sessionResponse?.InitializeResponse;
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [, setResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
    const [, setCancelFromMySR] = useSessionStorage<any>(CancelMySRKey, null);
    const [, setCurrentAgreementData] = useSessionStorage<any>(currentAgreementKey, null);
    const [ServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);
    const [shippingMethodResponse] = useSessionStorage<any>(shippingMethodResponseKey, null);
    // const [FulfillmentOption] = useSessionStorage<any>('FulfillmentOption', null);
    const [FulfilmentOptionsLocal, setFulfillmentOptionsLocal] = useState();
    const [PerilType, setPerilType] = useState('');
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [FulFillmentOptionType, setFulFillmentOptionType] = useState('');
    const [programmeNameLBL, setProgrammeNameLBL] = useState('');
    const [dateFormat, setDateFormat] = useState<string>('');
    const [, setFulfillmentOption] = useSessionStorage<any>('FulfillmentOption', null);
    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };
    const [AgreementData] = useSessionStorage<any>(currentAgreementKey, null);
    const AgreementDataMDN = JSON.parse(sessionStorage.getItem('dsMdn') || '')
    const [agreementDataResp] = useSessionStorage<any>('agreementDataResponse', '');
    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0] || agreementDataResp
    const onlineFindAgreement  = AgreementDataMDN;
    const currencyCode = globalState?.state?.sessionResponse?.InitializeResponse?.CurrencyCode;
    const carrierNumber = globalState?.state?.sessionResponse?.InitializeResponse?.DeliveryCarrier;

    const [allowChangeDetails, setAllowChangeDetails] = useState(false);

    const find_agreement = globalState?.state?.currentAgreementData || AgreementDataMDN ;
    const InitializeResponse = globalState?.state?.sessionResponse?.InitializeResponse;
    const isTOM = InitializeResponse?.ClientName === 'Asurion_TechCare';
    const agreementData: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0]
    const onlineFindAgreement1 = AgreementDataMDN || AgreementData;;
    let hideWarrantyContent;
    if (agreementData?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) {
        hideWarrantyContent = hideWarranty(agreementData?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan)
            ? true
            : false;
    }
    let fields: { field: string; label: string; weight?: number }[] = [
        { field: 'mdn', label: 'Mobile:', weight: 700 },
        { field: 'model', label: 'Model:' },
        { field: 'imei', label: 'IMEI:' },
        //{ field: 'warranty', label: 'Warranty:' },
    ];
    const updatedDate = data?.UpdatedDate || data?.CreatedDate;

    if (!hideWarrantyContent) {
        fields.push({ field: 'warranty', label: 'Warranty:' });
    }

    const holds = data.holds;
    // const isVideoHold = holds.filter((hold: any) => hold.HoldType === 'VIDEO').length > 0;

    //localization color theme changes
    const { data: configData } = useConfig();
    const { theme } = configData;
    const { colors } = theme;
    const { primary, accent } = colors;

    const isButtonDisabled = () => {
        const isVideoUploaded = data?.VideoUploaded;
        const isVideoHold =
            holds &&
            holds.filter((hold: any) => hold.HoldType === 'VIDEO' && hold?.HoldStatus === 'OPEN')
                .length > 0;
        const isDocumentHold =
            holds &&
            holds.filter((val: any) => {
                return val.HoldType === 'DOCUMENT' && val.HoldStatus === 'OPEN';
            })?.length > 0;
        if (isVideoHold) {
            return isVideoHold && isVideoUploaded ? true : false;
        } else if (isDocumentHold) {
            return true;
        } else if (holds?.length > 0) {
            return true;
        } else if (
            callServiceFee(find_agreement?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) &&
            !ServiceFeeData
        ) {
            return true;
        } else if (serviceRequest?.ServiceOrder?.ServiceOrderStatus?.toUpperCase() === 'BORD') {
            return true;
        } else {
            false;
        }
    };

    const isConfirmButtonDisabled = () => {
        if (
            hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) &&
            hasTCAT(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan)
        ) {
            if (getTCATJobMode() === TCAT_JOB_MODE.PICKUP) {
                const address1 =
                    globalState?.state?.pickUpAddress?.Address1 ||
                    serviceRequest?.ShippingOrderOutbound?.Address?.Address1;
                if (
                    !address1 ||
                    address1?.length < 0 ||
                    (hasEditAlternateNumberFeature(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) &&
                        (!globalState?.state?.alternateContactNumber ||
                            globalState?.state?.alternateContactNumber?.length < 0))
                )
                    return true;

                return false;
            } else {
                const address1 =
                    globalState?.state?.returnAddress?.Address1 ||
                    serviceRequest?.ShippingOrderInbound?.Address?.Address1;
                if (
                    !address1 ||
                    address1?.length < 0 ||
                    !globalState?.state?.returnDate ||
                    globalState?.state?.returnDate?.length < 0 ||
                    !globalState?.state?.returnTime ||
                    globalState?.state?.returnTime?.length < 0 ||
                    (hasEditAlternateNumberFeature(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) &&
                        (!globalState?.state?.alternateContactNumber ||
                            globalState?.state?.alternateContactNumber?.length < 0))
                )
                    return true;

                return false;
            }
        }

        return false;
    };

    const getChangeDetailsLabel = () => {
        let text = Scripts.ClaimCard.ChangeDetails;
        let key = ClaimCardKeys.ClaimCard.btnChangeDetails;

        if (data?.RepairStatus?.toUpperCase() === 'READYFORDELIVERY') {
            text = Scripts.ClaimCard?.ConfirmDeliveryDetails;
            key = ClaimCardKeys.ClaimCard?.ConfirmDeliveryDetails;
        }

        if (
            hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) &&
            hasTCAT(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan)
        ) {
            if (getTCATJobMode() === TCAT_JOB_MODE.PICKUP) {
                text = Scripts.ClaimCard.UpdatePickUpDetails;
                key = ClaimCardKeys.ClaimCard.UpdatePickUpDetails;
            } else {
                text = Scripts.ClaimCard.UpdateReturnDetails;
                key = ClaimCardKeys.ClaimCard.UpdateReturnDetails;
            }
        }

        return (
            <TextScript text={text} translationKey={`${ClaimCardKeys.Global.PageName}/${key}`} />
        );
    };

    const getTimeSlot = (timeSlot: string) => {
        const DeliverySlots = [
          '8am - 10am:8-10',
          '9am - 11am:9-11',
          '10am - 12pm:10-12',
          '11am - 1pm:11-13',
          '12pm - 2pm:12-14',
          '1pm - 3pm:13-15',
          '2pm - 4pm:14-16',
          '3pm - 5pm:15-17',
          '4pm - 6pm:16-18',
          '5pm - 7pm:17-19',
          '6pm - 8pm:17-20',
          '7pm - 10pm:19-22',
          '8am - 4pm:8-16',
          'In 2 Hours:2H'
        ];
        let selectedTimeSlot;
        if(timeSlot === '2H' || timeSlot === '8am - 4pm:8-16'){
          selectedTimeSlot = 'In 2 Hours:2H';
        }else{
          selectedTimeSlot = timeSlot;
        }
        const formatedTimeSlot = DeliverySlots.filter((str: string) =>
          str.includes(selectedTimeSlot)
        )?.[0]?.split(':')[0];
        const selectedtimeSlot = formatedTimeSlot !== undefined ? formatedTimeSlot : selectedTimeSlot;
        return selectedtimeSlot;
      };
    
      const getTimeSlotForAPI = (timeSlot: string) => {
        const DeliverySlots = [
          '8am - 10am:8-10',
          '9am - 11am:9-11',
          '10am - 12pm:10-12',
          '11am - 1pm:11-13',
          '12pm - 2pm:12-14',
          '1pm - 3pm:13-15',
          '2pm - 4pm:14-16',
          '3pm - 5pm:15-17',
          '4pm - 6pm:16-18',
          '5pm - 7pm:17-19',
          '6pm - 8pm:17-20',
          '7pm - 10pm:19-22',
          '8am - 4pm:8-16',
          'In 2 Hours:2H'
        ];
        let selectedTimeSlot;
        if(timeSlot === '2H' || timeSlot === '8am - 4pm:8-16'){
          selectedTimeSlot = 'In 2 Hours:2H';
        }else{
          selectedTimeSlot = timeSlot;
        }
        const formatedTimeSlot = DeliverySlots.filter((str: string) =>
          str.includes(selectedTimeSlot)
        )?.[0]?.split(':')[1];
        const selectedtimeSlot = formatedTimeSlot !== undefined ? formatedTimeSlot : selectedTimeSlot;
        return selectedtimeSlot;
      };

    const serviceRequest = data?.ServiceRequests?.[0];
    const fullFillmentOption = serviceRequest?.FulfillmentOption;
    const DeliveryInstructions =
        serviceRequest?.ShippingOrder?.ShipmentDetails?.DeliveryInstructions;
    const ServiceRequestId = serviceRequest?.ServiceRequestId;
    const ServiceOrderId = serviceRequest?.ServiceOrder?.ServiceOrderId;
    const incType = camelize(data?.IncidentType);
    const shippingOrderAddress = serviceRequest?.ShippingOrder?.Address;
    const isPUR = serviceRequest?.FulfillmentOption?.toUpperCase() === FULLFILLMENT_TYPES.PUR;
    const isVAS = serviceRequest?.FulfillmentOption?.toUpperCase() === FULLFILLMENT_TYPES.VAS;
    const isWalkin = isWalkIn(serviceRequest?.FulfillmentOption) || data?.ISSGWalkin;
    const isIW_OEM = data?.WarrantyType === WARRANTY_TYPE.IW_OEM;
    const isEWS = data?.WarrantyType === WARRANTY_TYPE.HI && isMalfunction(incType);
    const isRepair =
        isDeviceRefresh(incType) ||
        isDeviceRepair(incType) ||
        isBatteryReplacement(incType) ||
        isScreenRepair(incType) ||
        isScreenRepairBatReplacement(incType) ||
        isScreenRepairWarranty(incType) ||
        (isMalfunction(incType) && isIW_OEM) ||
        isEWS;

    const RepairStatus = data?.ServiceRequests[0]?.RepairRequest?.RepairStatus;
    const isBackOrder =
        (isTOM || globalState?.state?.isMaxis) &&
        serviceRequest?.ServiceOrder?.ServiceOrderStatus?.toUpperCase() === 'BORD';
    const isBackOrderPendingAction =
        data?.CustomerCaseStatus?.toUpperCase() === 'WORKING' && data?.IsWBKODeviceAvailable;

    const RequestType = () => {
        if (serviceRequest?.FulfillmentOption?.toUpperCase() === 'PUR') {
            return LOGISTICS_TYPES.PUR;
        } else if (isWalkIn(serviceRequest?.FulfillmentOption)) {
            return LOGISTICS_TYPES.WALKIN;
        } else if (data?.ISSGWalkin === true) {
            return LOGISTICS_TYPES.WALKIN;
        } else if (
            serviceRequest?.ShippingOrder?.Address &&
            Object.keys(serviceRequest.ShippingOrder.Address).length > 0
        ) {
            return LOGISTICS_TYPES.DELIVERY;
        } else {
            return '';
        }
    };

    const deliveryDate = getDateAndDay(
        data?.ServiceRequests[0]?.ShippingOrder?.ExpectedDeliveryDate
    );
    let deliveryTime =
        isTOM || globalState?.state?.isMaxis
            ? data?.ServiceRequests[0]?.ShippingOrder?.DeliveryWindow
            : getTimeSlot(
                  globalState?.state?.deliveryTime ||
                      data?.ServiceRequests[0]?.ShippingOrder?.DeliveryWindow
              );
    if (globalState?.state?.isMaxis && isWalkin && deliveryTime && deliveryTime.includes('WI')) {
        let Time = deliveryTime.split('-');
        let timeformat = Time?.[0];
        deliveryTime = timeformat?.concat(':00:00');
    } else if (
        globalState?.state?.isMaxis &&
        !isWalkin &&
        (isSwap(incType) || isMalfunction(incType))
    ) {
        deliveryTime = 'By 8pm';
    }
    const pickupDate = getDateAndDay(serviceRequest?.ShippingOrderOutbound?.ExpectedDeliveryDate);
    const pickupTime =
        isTOM || globalState?.state?.isMaxis
            ? serviceRequest?.ShippingOrderOutbound?.DeliveryWindow
            : getTimeSlot(serviceRequest?.ShippingOrderOutbound?.DeliveryWindow);
    const returnDate =
        serviceRequest?.ShippingOrderInbound?.ExpectedDeliveryDate &&
        getDateAndDay(serviceRequest?.ShippingOrderInbound?.ExpectedDeliveryDate);
    const returnTime =
        isTOM || globalState?.state?.isMaxis
            ? serviceRequest?.ShippingOrderInbound?.DeliveryWindow
            : getTimeSlot(serviceRequest?.ShippingOrderInbound?.DeliveryWindow);
            // getTimeSlot(serviceRequest?.ShippingOrderInbound?.DeliveryWindow)

    const isClaimResumable = !(isMalfuntionWarrantyEligible(agreementData?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) && status === 'Completed') &&
        !(
            data?.ServiceRequests[0]?.ServiceRequestStatus?.toLowerCase()?.includes('cmpltd') ||
            data?.ServiceRequests[0]?.ServiceRequestStatus?.toLowerCase()?.includes('cncl') ||
            data?.CustomerCaseStatus?.toLowerCase()?.includes('cmpltd') ||
            data?.CustomerCaseStatus?.toLowerCase()?.includes('cncl') ||
            (data?.RepairStatus && data?.RepairStatus?.toUpperCase() !== 'RESERVED')
        ) || data?.CustomerCaseStatus?.toLowerCase()?.includes('reopen');
    const isClaimCompleted =
        data?.ServiceRequests[0]?.ServiceRequestStatus?.toLowerCase()?.includes('cmpltd') ||
        (data?.CustomerCaseStatus?.toLowerCase()?.includes('cmpltd') &&
            data?.IsInWarranty === true);

    const isDeviceRefrshRTLC =
        RepairStatus?.toUpperCase() === 'RTLC' &&
        data?.ServiceRequests[0]?.IncidentType?.toLowerCase() === 'device refresh' &&
        data?.ServiceRequests[0]?.FulfillmentOption?.toUpperCase() === 'PUR'
            ? true
            : false;

    const nightDeliveryFees =
        data?.ChargeOrderline &&
        data?.ChargeOrderline.filter(
            (item: any) => item.ChargeOrderLineType === 'Night_Delivery_Fee'
        )?.[0]?.TotalAmount;

    useEffect(() => {
        if (globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0]) {
            // get programme name label
            getProgrammeNameLbl();

            setCurrentAgreementData(agreementData1);
            globalState?.dispatch({
                type: ActionTypes.SET_CURRENT_AGREEMENT_DATA,
                payload: agreementData1,
            });
            // queryClient.setQueryData(['findAgreement'], agreementData1);
            let assetData1: AssetData;
            // agreementData1?.Assets.Asset.filter(
            //   (a: any) => a.AssetInstance === 'ENROLLED'
            // )[0];

            // for multi device support get selected device from session
            if (
                hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) &&
                sessionStorage.getItem('dsMdn')
            ) {
                // get selected asset details stored in session
                const selectedAsset: any = JSON.parse(sessionStorage.getItem('dsMdn') || '');
                assetData1 = agreementData1?.Assets.Asset.filter(
                    (a: any) => a.AssetId === selectedAsset?.assetId
                )[0];
            } else {
                assetData1 = agreementData1?.Assets.Asset.filter(
                    (a: any) => a.AssetInstance === 'REPLACED'
                )[0];

                if (!assetData1 || assetData1 === undefined) {
                    assetData1 = agreementData1?.Assets.Asset.filter(
                        (a: any) => a.AssetInstance === 'ENROLLED'
                    )[0];
                }
            }

            setAssetData(assetData1);
            setSelectedAssetData(assetData1);
        }
    }, [globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0]]);

    const onYesClick = () => {
        callClaimDetailsAPI && callClaimDetailsAPI();
    };

    const getModeOfPayment = (paymentType: string) => {
        if (paymentType === 'CRE') {
            return 'Credit Card';
        } else if (paymentType === 'PYPL') {
            return 'PayPal';
        } else {
            return paymentType;
        }
    };

    const handleCancel = () => {
        setOpenCancelPopup(true);
        setResumeFlowData(data);
        setCancelFromMySR(true)
    };

    const changeDetailsEligibility = () => {
        if (
            (isTOM || globalState?.state?.isMaxis) &&
            serviceRequest?.ServiceOrder?.ServiceOrderStatus?.toUpperCase() !== 'READY'
        ) {
            setAllowChangeDetails(false);
        } else if (
            (isSwap(incType) || isReplacement(incType)) &&
            ['ready', 'bord', 'wbko'].includes(data?.ShippingOrderStatus?.toLowerCase()) &&
            !data?.CustomerCaseStatus?.toLowerCase().includes('cncl')
        ) {
            setAllowChangeDetails(true);
        } else if (
            !isVAS &&
            (data?.RepairStatus === 'CCX STARTED' ||
                data?.RepairStatus === 'READYFORDELIVERY' ||
                data?.RepairStatus === 'SCHEDULED' ||
                (hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) &&
                    isPUR &&
                    (showPickupDetails() || showReturnDetails()))) &&
            !['CMPLTD', 'CNCLCUS']?.includes(data?.CustomerCaseStatus?.toUpperCase()) &&
            !isMalfuntionWarrantyEligible(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) 
        ) {
            // we are not allowing customer to change on same day for CHT
            // allowing for first time return schedule as default return date in system is pickup date which can be past date
            // for first time return schedule repair status is 'AWAITING DELIVERY SCHEDULE'
            if (
                hasTCAT(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) &&
                !['AWAITING RETURN SCHEDULE', 'AWAITING DELIVERY SCHEDULE']?.includes(
                    data?.RepairStatus?.toUpperCase()
                )
            ) {
                // fetch date based on Job type
                const date =
                    getTCATJobMode() === TCAT_JOB_MODE.PICKUP
                        ? serviceRequest?.ShippingOrderOutbound?.ExpectedDeliveryDate
                        : serviceRequest?.ShippingOrderInbound?.ExpectedDeliveryDate;

                // check if return date is of today then do not allow to update
                setAllowChangeDetails(!IsPastDayChange(date));
            } else {
                setAllowChangeDetails(true);
            }
        } else {
            setAllowChangeDetails(false);
        }
    };

    useEffect(() => {
        if (!isTranslationsLoading) {
            getDateFormat();
            getCurrency();
            changeDetailsEligibility();
            getPerilLbl();
            getFullFillmentOption();
        }
    }, [language, isTranslationsLoading]);

    useEffect(() => {
        if (
            status &&
            status?.length > 0 &&
            dateFormat &&
            dateFormat?.length > 0 &&
            !isTranslationsLoading
        ) 
        if(isMalfuntionWarrantyEligible(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement1?.plan) && status === 'Submitted'){
            getWarrantyRequetCases();
        } else {
            getTitleScript();
            getDescriptionScript();
            getNotesScript();
        }
    }, [status, dateFormat, language, isTranslationsLoading]);

    useEffect(() => {
        isConfirmButtonDisabled();
    }, [globalState?.state?.alternateContactNumber]);

    const getPerilLbl = async () => {
        const incidentType = isIW_OEM
            ? INCIDENT_TYPES.MALFUNCTION_IW
            : isEWS
            ? INCIDENT_TYPES.MALFUNCTION_EWS
            : incType;
        const defaultIncidentTypeLBL = isIW_OEM
            ? Scripts.Global.MalfunctionIW
            : isEWS
            ? Scripts.Global.MalfunctionEWS
            : incType;

        const peril = await getPerilText(incidentType, defaultIncidentTypeLBL);
        setPerilType(peril);
    };

    const getFullFillmentOption = async () => {
        if (RequestType() && RequestType() !== undefined) {
            const fulFillmentType = await getPerilText(RequestType()?.toUpperCase(), RequestType());
            setFulFillmentOptionType(fulFillmentType);
        }
    };

    const ConfirmChangeDetailsClick = async () => {
        if (isRepair) {
            callUpdateShipingOrderAPI();
        } else {
            if (globalState?.state?.chargeNightDeliveryFee) {
                globalState?.dispatch({
                    type: ActionTypes.SET_UPDATESALESORDER_PARAMS,
                    payload: getUpdateSalesOrderParams(),
                });

                globalState?.dispatch({
                    type: ActionTypes.SET_REPAIRSCHEDULE_PARAMS,
                    payload: getRepairScheduleParams(),
                });
                navigate('/payment');
            } else {
                callRepairReqScheduleAPI();
            }
        }
    };

    const getCurrency = async () => {
        const currency = await getCurrencySymbol();
        setCurrencySymbol(currency);
    };

    const getProgrammeNameLbl = async () => {
        const programmeName = await getProgrammeName(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan);
        setProgrammeNameLBL(programmeName);
    };

    const getDateFormat = async () => {
        const format = await getGlobalDateFormat();
        setDateFormat(format);
    };

    const formatDate = (inputDate: string) => {
        const supportedFormats = [
            Scripts.Global.DateFormat,
            'DD MMMM YYYY, dddd',
            'MMMM DD, YYYY, dddd',
        ];

        for (const format of supportedFormats) {
            const parsedDate = moment(inputDate, format, language, true);
            if (parsedDate?.isValid()) {
                return parsedDate?.format('YYYY-MM-DD');
            }
        }
        return inputDate;
    };

    const getSelectedShippingMethod = () => {
        const selectedShippingMethodType = shippingMethodResponse?.filter((val: any) => {
            return val?.EstimatedDeliveryDate === globalState?.state?.deliveryDate;
        });

        return selectedShippingMethodType?.[0];
    };

    const getMaxisSwapDeliveryWindow = () => {
        // first fetch ShippingMethodType
        const selectedShippingMethodType = getSelectedShippingMethod();

        // check ShippingMethodType if same day then 3H else Next Day 18H
        if (selectedShippingMethodType?.ShippingMethodType === 'Same Day') return '3H';
        else return '18H';
    };

    const fetchFulfillmentMethodType = () => {
        const incidentType = isIW_OEM
            ? INCIDENT_TYPES.MALFUNCTION_IW
            : isEWS
            ? INCIDENT_TYPES.MALFUNCTION_EWS
            : incType;
        return (
            getFulfillmentMethodType(incidentType, agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) ||
            FULLFILLMENT_METHOD_TYPE.REPAIR
        );
    };

    const isAdvanceExch = () => {
        let fulfillmentMethodType = fetchFulfillmentMethodType();
        return fulfillmentMethodType === FULLFILLMENT_METHOD_TYPE.ADVEXCH;
    };

    const getDPDeliveryWindowSS3 = () => {
        if(getDeliverySlot(find_agreement?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan)){
          return getTimeSlotForAPI(deliveryTime);
        }else if(globalState?.state?.deliveryDate === getDateAndDay(new Date(), 'YYYY-MM-DD'))
          return '4H'
        else
          return deliveryTime === '8am - 12pm' ? '12H' : '18H'
      }

    const callUpdateShipingOrderAPI = () => {
        const UpdateShippingOrderParameters = {
            UpdateShippingOrderParameters: {
                EntityUpdateOptions: {
                    ReturnEntity: true,
                },
                IsDataTransfer: data?.IsDataTransfer,
                InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
                ServiceRequestId: ServiceRequestId,
                FulfilmentMethod:
                    (isSwap(incType) || isReplacement(incType)) && isWalkin ? 'INTLC' : undefined,
                ShippingOrder: {
                    ShippingMethodType: 'Same Day',
                    CarrierNumber: carrierNumber,
                    SignatureRequired: true,
                    RequestedShipmentDate:
                        formatDate(globalState?.state?.deliveryDate) || formatDate(deliveryDate),
                    ExpectedDeliveryDate:
                        formatDate(globalState?.state?.deliveryDate) || formatDate(deliveryDate),
                    DeliveryWindow: globalState?.state?.deliveryTime || deliveryTime,
                    NationalId: find_agreement?.IdentificationNumber?.substr(
                        find_agreement?.IdentificationNumber.length - 4
                    ),
                    Remark: extractProxy(DeliveryInstructions),

                    AlternateContactNumber: getAlternateContactNumber(),
                    ReceiverName: find_agreement?.Customers?.Customer?.[0]?.FullName,
                    ReceiverAddress: {
                        LocationType: 'Residential',
                        BusinessName: find_agreement?.Customers?.Customer?.[0]?.FullName,
                        Standardized: true,
                        IsOverridden: false,
                        StandardizedSourceName: 'QAS',
                        BaseAddressHeaderId: 'c7730733f6bea936403e881fed322e26',
                        IsScrubbed: true,
                        Address1:
                            globalState?.state?.deliveryAddress?.Address1 ||
                            shippingOrderAddress?.Address1,
                        RepairStoreCode: globalState?.state?.deliveryAddress?.RepairStoreCode,
                        RepairStoreName: globalState?.state?.deliveryAddress?.RepairStoreName,
                        AssetMake: globalState?.state?.deliveryAddress?.AssetMake,
                        AddressLine1:
                            globalState?.state?.deliveryAddress?.Address1 ||
                            shippingOrderAddress?.Address1,
                        City:
                            globalState?.state?.deliveryAddress?.City ||
                            shippingOrderAddress?.City ||
                            'Singapore',
                        StateProvinceCode:
                            globalState?.state?.deliveryAddress?.StateProvinceCode ||
                            serviceRequest?.ShippingOrderOutbound?.Address?.StateProvinceCode ||
                            (isTOM ? 'Bangkok' : 'Singapore'),
                        CountryCode: getCountryCode(currencyCode), //isTOM ? 'TH' : 'SGP',
                        PostalCode:
                            globalState?.state?.deliveryAddress?.PostalCode ||
                            shippingOrderAddress?.PostalCode,
                    },
                },
                isReschedule: true,
            },
        };

        const UpdateShippingOrderParameters2 = {
            UpdateShippingOrderParameters: {
                EntityUpdateOptions: {
                    ReturnEntity: true,
                },
                IsDataTransfer: data?.IsDataTransfe,
                InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
                ServiceRequestId: ServiceRequestId,
                InboundShippingOrder: isAdvanceExch()
                    ? undefined
                    : {
                          ShippingMethodType: 'Same Day',
                          CarrierNumber: carrierNumber,
                          SignatureRequired: true,
                          RequestedShipmentDate:
                              globalState?.state?.returnDate || returnDate
                                  ? formatDate(globalState?.state?.returnDate) ||
                                    formatDate(returnDate)
                                  : '',
                          ExpectedDeliveryDate:
                              globalState?.state?.returnDate || returnDate
                                  ? formatDate(globalState?.state?.returnDate) ||
                                    formatDate(returnDate)
                                  : '',
                          // DeliveryWindow: getReturnDeliveryWindow(globalState?.state?.returnTime || returnTime),
                          DeliveryWindow:
                              returnDeliveryWindow(
                                  globalState?.state?.returnTime || returnTime,
                                  find_agreement?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan
                              ) || returnTime,
                          NationalId: find_agreement?.IdentificationNumber,
                          Remark: '',
                          AlternateContactNumber: getAlternateContactNumber(
                              hasTCAT(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan)
                                  ? TCAT_JOB_MODE.RETURN
                                  : undefined
                          ),
                          ReceiverName: find_agreement?.Customers?.Customer?.[0]?.FullName,
                          ReceiverAddress: {
                              LocationType: 'Residential',
                              BusinessName: find_agreement?.Customers?.Customer?.[0]?.FullName,
                              Standardized: true,
                              IsOverridden: false,
                              StandardizedSourceName: 'QAS',
                              BaseAddressHeaderId: 'c7730733f6bea936403e881fed322e26',
                              IsScrubbed: true,
                              Address1:
                                  globalState?.state?.returnAddress?.Address1?.trim() ||
                                  serviceRequest?.ShippingOrderInbound?.Address?.Address1,
                              Address2:
                                  globalState?.state?.returnAddress?.Address2?.trim() ||
                                  serviceRequest?.ShippingOrderInbound?.Address?.Address2,
                              Address3:
                                  globalState?.state?.returnAddress?.Address3?.trim() ||
                                  serviceRequest?.ShippingOrderInbound?.Address?.Address3,
                              City:
                                  globalState?.state?.returnAddress?.City ||
                                  serviceRequest?.ShippingOrderInbound?.Address?.City ||
                                  'Singapore',
                              StateProvinceCode:
                                  globalState?.state?.returnAddress?.StateProvinceCode ||
                                  serviceRequest?.ShippingOrderOutbound?.Address
                                      ?.StateProvinceCode ||
                                  (isTOM ? 'Bangkok' : 'Singapore'),
                              CountryCode: getCountryCode(currencyCode),
                              PostalCode:
                                  globalState?.state?.returnAddress?.PostalCode ||
                                  serviceRequest?.ShippingOrderInbound?.Address?.PostalCode,
                          },
                      },

                ShippingOrder: {
                    ShippingMethodType: 'Same Day',
                    CarrierNumber: carrierNumber,
                    SignatureRequired: true,
                    RequestedShipmentDate:
                        formatDate(globalState?.state?.pickupDate) || formatDate(pickupDate),
                    ExpectedDeliveryDate:
                        formatDate(globalState?.state?.pickupDate) || formatDate(pickupDate),
                    // DeliveryWindow: globalState?.state?.pickupTime || pickupTime,
                    // DeliveryWindow: globalState?.state?.isMaxis ? getPickUpDeliveryWindow(globalState?.state?.pickupTime || pickupTime) : globalState?.state?.pickupTime || pickupTime,
                    DeliveryWindow: pickUpDeliveryWindow(
                        globalState?.state?.pickupTime || pickupTime,
                        find_agreement?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan
                    )
                        ? pickUpDeliveryWindow(
                              globalState?.state?.pickupTime || pickupTime,
                              find_agreement?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan
                          )
                        : globalState?.state?.pickupTime || pickupTime,
                    NationalId: find_agreement?.IdentificationNumber.substr(
                        find_agreement?.IdentificationNumber.length - 4
                    ),
                    Remark: extractProxy(DeliveryInstructions),
                    AlternateContactNumber: getAlternateContactNumber(
                        hasTCAT(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan)
                            ? TCAT_JOB_MODE.PICKUP
                            : undefined
                    ),
                    ReceiverName: find_agreement?.Customers?.Customer?.[0]?.FullName,
                    ReceiverAddress: {
                        LocationType: 'Residential',
                        BusinessName: find_agreement?.Customers?.Customer?.[0]?.FullName,
                        Standardized: true,
                        IsOverridden: false,
                        StandardizedSourceName: 'QAS',
                        BaseAddressHeaderId: 'c7730733f6bea936403e881fed322e26',
                        IsScrubbed: true,
                        Address1:
                            globalState?.state?.pickUpAddress?.Address1 ||
                            serviceRequest?.ShippingOrderOutbound?.Address?.Address1,
                        Address2:
                            globalState?.state?.pickUpAddress?.Address2 ||
                            serviceRequest?.ShippingOrderOutbound?.Address?.Address2,
                        Address3:
                            globalState?.state?.pickUpAddress?.Address3 ||
                            serviceRequest?.ShippingOrderOutbound?.Address?.Address3,
                        City:
                            globalState?.state?.pickUpAddress?.City ||
                            serviceRequest?.ShippingOrderOutbound?.Address?.City ||
                            'Singapore',
                        StateProvinceCode:
                            globalState?.state?.pickUpAddress?.StateProvinceCode ||
                            serviceRequest?.ShippingOrderOutbound?.Address?.StateProvinceCode ||
                            (isTOM ? 'Bangkok' : 'Singapore'),
                        CountryCode: getCountryCode(currencyCode),
                        PostalCode:
                            globalState?.state?.pickUpAddress?.PostalCode ||
                            serviceRequest?.ShippingOrderOutbound?.Address?.PostalCode,
                    },
                },
                isReschedule: true,
            },
        };
        setLoading(true);
        getUpdateShippingDetails(
            isWalkin ? UpdateShippingOrderParameters : UpdateShippingOrderParameters2,
            ServiceOrderId,
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then(() => {
                setLoading(false);
                setShowEditableFields(false);
                if (isPUR) {
                    if (hasTCAT(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan)) {
                        // handle TCAT job integration
                        handleTCATJobIntegration();
                    } else if (callUpdateDelivery(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan)) {
                        callUpdateDeliveryAPI();
                    } else {
                        callClaimDetailsAPI();
                    }
                } else {
                    callUpdateRepairRequestApi();
                }
            })
            .catch((error) => {
                setLoading(false);
                saveError(error, globalState);
                showErrorPage(navigate);
            });
    };

    const getDefaultAlternateContactNumber = () => {
        const mode = hasTCAT(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan)
            ? getTCATJobMode()
            : undefined;
        return data?.ServiceRequests[0]?.[getShippingNodeByJobMode(mode)]?.AlternateContactNumber;
    };

    const getAlternateContactNumber = (mode?: string) => {
        // first check if hasEditAlternateNumberFeature + TCAT job Mode check
        // if yes then get alternate number from state
        if (
            hasEditAlternateNumberFeature(agreementData1?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) &&
            (mode === getTCATJobMode() || isReturnDeliveryJobMode(mode)) &&
            globalState?.state?.alternateContactNumber &&
            globalState?.state?.alternateContactNumber?.length > 0
        ) {
            return globalState?.state?.alternateContactNumber;
        } else if (
            data?.ServiceRequests[0]?.[getShippingNodeByJobMode(mode)]?.AlternateContactNumber &&
            data?.ServiceRequests[0]?.[getShippingNodeByJobMode(mode)]?.AlternateContactNumber
                ?.length > 0
        ) {
            return data?.ServiceRequests[0]?.[getShippingNodeByJobMode(mode)]
                ?.AlternateContactNumber;
        } else {
            return getMDNFromAgreement(agreementData1);
        }
    };

    const convertTo24HourFormat = (timeWindowString: string) => {
        const [startPart, endPart] = timeWindowString?.split('-');
        const startTime = moment(startPart, 'ha', 'en');
        const endTime = moment(endPart, 'ha', 'en');
        const formattedStartTime = startTime?.format('H');
        const formattedEndTime = endTime?.format('H');
        return `${formattedStartTime}-${formattedEndTime}`;
    };

    const getDeliveryWindow = () => {
        if (isWalkin) {
            return (
                globalState?.state?.deliveryTime ||
                data?.ServiceRequests[0]?.ShippingOrder?.DeliveryWindow
            );
        } else {
            if (isTOM) {
                return globalState?.state?.deliveryTime || deliveryTime;
            } else if (globalState?.state?.isMaxis) {
                return getMaxisSwapDeliveryWindow();
            }
            if (callShippingMethodv1(incType, find_agreement?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan)) {
                return getDPDeliveryWindowSS3(); // SS3.0
            } else {
                return (
                    globalState?.state?.deliveryTimeCode ||
                    (deliveryTime &&
                        convertTo24HourFormat(globalState?.state?.deliveryTime || deliveryTime))
                );
            }
        }
    };

    const getUpdateSalesOrderParams = () => {
        const updateSalesOrderParams = {
            UpdateSalesOrderStatusParameters: {
                ClientName: InitializeResponse?.ClientName,
                ServiceOrderNbr: serviceRequest?.ServiceOrder?.ServiceOrderNumber?.toString(),
                ShippingMethodType: getSelectedShippingMethod()?.ShippingMethodType || 'Same Day',
                RequestedShipmentDate: getSelectedShippingMethod()?.EstimatedShipmentDate,
                RequestedDeliveryDate:
                    formatDate(globalState?.state?.deliveryDate) || formatDate(deliveryDate),
                FirstName: find_agreement?.Customers?.Customer?.[0]?.FirstName,
                LastName: find_agreement?.Customers?.Customer?.[0]?.LastName,
                AddressLine1:
                    globalState?.state?.deliveryAddress?.Address1 || shippingOrderAddress?.Address1,
                AddressLine2:
                    globalState?.state?.deliveryAddress?.Address2 ||
                    shippingOrderAddress?.Address2 ||
                    globalState?.state?.deliveryAddress?.Address1 ||
                    shippingOrderAddress?.Address1,
                AddressLine3: globalState?.state?.deliveryAddress?.Address3,
                RepairStoreCode: isWalkin
                    ? globalState?.state?.deliveryAddress?.RepairStoreCode || 'TLC'
                    : '',
                RepairStoreName: isWalkin
                    ? globalState?.state?.deliveryAddress?.RepairStoreName
                    : '',
                AssetMake: isWalkin ? globalState?.state?.deliveryAddress?.AssetMake : '',
                CityName:
                    globalState?.state?.deliveryAddress?.City ||
                    shippingOrderAddress?.City ||
                    'Singapore',
                StateProvinceCode:
                    globalState?.state?.deliveryAddress?.StateProvinceCode ||
                    serviceRequest?.ShippingOrderOutbound?.Address?.StateProvinceCode ||
                    (isTOM ? 'Bangkok' : 'Singapore'),
                PostalCode:
                    globalState?.state?.deliveryAddress?.PostalCode ||
                    shippingOrderAddress?.PostalCode,
                CuntryCode: getCountryCode(currencyCode),
                CustomerCaseId: data?.CustomerCaseId,
                ServiceOrderId: ServiceOrderId,
                ServiceRequestId: ServiceRequestId,
                DeliveryWindow: getDeliveryWindow(),
                IsWBKO: false,
                ISSGWalkin: data?.ISSGWalkin,
                IsDataTransfer: data?.IsDataTransfer,
            },
        };
        return updateSalesOrderParams;
    };

    const callUpdateStatusAPI = () => {
        setLoading(true);
        updateStatus(
            getUpdateSalesOrderParams(),
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then(() => {
                setLoading(false);
                setShowEditableFields(false);
                callClaimDetailsAPI();
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const extractProxy = (inputString: string) => {
        const regex = /Repesentative Name:(.*)(?=;|$)/;
        const match = inputString.match(regex);

        if (match) {
            const extractedString = 'Repesentative Name:' + match?.[1]?.trim();
            return extractedString;
        } else {
            return '';
        }
    };

    // const deliverywindow = () => {
    //   if(isTOM || globalState?.state?.isMaxis){
    //     return (globalState?.state?.deliveryTime || deliveryTime)
    //   }else{
    //    return globalState?.state?.deliveryTimeCode || (deliveryTime && convertTo24HourFormat(globalState?.state?.deliveryTime || deliveryTime))
    //   }
    // }

    const getRepairScheduleParams = () => {
        const repairScheduleParams = {
            CreateRepairScheduleParameters: {
                CustomerCaseId: data?.CustomerCaseId,
                ServiceRequestId: ServiceRequestId,
                ServiceOrderId: ServiceOrderId,
                RepairRequestType: isWalkin ? LOGISTICS_TYPES.WALKIN : LOGISTICS_TYPES.DELIVERY,
                RepairStatus: isWalkin ? 'SCHEDULED' : '',
                IsWBKO: false,
                OutboundShippingOrder: {
                    ShippingOrderId: serviceRequest?.ShippingOrder?.ShippingOrderId,
                    ShippingMethodType: serviceRequest?.ShippingOrder?.ShippingMethodType,
                    RequestedPickupDate:
                        formatDate(globalState?.state?.deliveryDate) || formatDate(deliveryDate),
                    DeliveryWindow:
                        globalState?.state?.isMaxis && !isWalkin
                            ? getMaxisSwapDeliveryWindow()
                            : getDeliveryWindow(), //deliverywindow(),
                    ReceiverAddress: {
                        AddressLine1:
                            globalState?.state?.deliveryAddress?.Address1 ||
                            shippingOrderAddress?.Address1,
                        AddressLine2:
                            globalState?.state?.deliveryAddress?.Address2 ||
                            shippingOrderAddress?.Address2,
                        AddressLine3:
                            globalState?.state?.deliveryAddress?.Address3 ||
                            shippingOrderAddress?.Address3,
                        CityName:
                            globalState?.state?.deliveryAddress?.City ||
                            shippingOrderAddress?.City ||
                            'Singapore',
                        StateProvinceCode:
                            globalState?.state?.deliveryAddress?.StateProvinceCode ||
                            serviceRequest?.ShippingOrderOutbound?.Address?.StateProvinceCode ||
                            (isTOM ? 'Bangkok' : 'Singapore'),
                        PostalCode:
                            globalState?.state?.deliveryAddress?.PostalCode ||
                            shippingOrderAddress?.PostalCode,
                        Country: getCountryCode(currencyCode),
                        Standardized: false,
                    },
                },
                isReschedule: true,
            },
        };

        return repairScheduleParams;
    };

    const callRepairReqScheduleAPI = () => {
        const walk_in_paylod = {
            CreateRepairScheduleParameters: {
                CustomerCaseId: data?.CustomerCaseId,
                ServiceRequestId: ServiceRequestId,
                ServiceOrderId: ServiceOrderId,
                RepairRequestType: isWalkin ? LOGISTICS_TYPES.WALKIN : LOGISTICS_TYPES.DELIVERY,
                RepairStatus: isWalkin ? 'SCHEDULED' : '',
                IsWBKO: false,
                OutboundShippingOrder: {
                    ShippingOrderId: serviceRequest?.ShippingOrder?.ShippingOrderId,
                    ShippingMethodType: serviceRequest?.ShippingOrder?.ShippingMethodType,
                    RequestedPickupDate:
                        formatDate(globalState?.state?.deliveryDate) || formatDate(deliveryDate),
                    DeliveryWindow:
                        globalState?.state?.deliveryTime ||
                        data?.ServiceRequests[0]?.ShippingOrder?.DeliveryWindow,
                    ReceiverAddress: {
                        Address1:
                            globalState?.state?.deliveryAddress?.Address1 ||
                            shippingOrderAddress?.Address1,
                        RepairStoreCode:
                            globalState?.state?.deliveryAddress?.RepairStoreCode || 'TLC',
                        RepairStoreName: globalState?.state?.deliveryAddress?.RepairStoreName,
                        AssetMake: globalState?.state?.deliveryAddress?.AssetMake,
                        AddressLine1:
                            globalState?.state?.deliveryAddress?.Address1 ||
                            shippingOrderAddress?.Address1,
                        CityName:
                            globalState?.state?.deliveryAddress?.City ||
                            shippingOrderAddress?.City ||
                            'Singapore',
                        StateProvinceCode:
                            globalState?.state?.deliveryAddress?.StateProvinceCode ||
                            serviceRequest?.ShippingOrderOutbound?.Address?.StateProvinceCode ||
                            (isTOM ? 'Bangkok' : 'Singapore'),
                        PostalCode:
                            globalState?.state?.deliveryAddress?.PostalCode ||
                            shippingOrderAddress?.PostalCode,
                        Country: getCountryCode(currencyCode),
                        Standardized: false,
                    },
                },
                isReschedule: true,
            },
        };

        setLoading(true);
        repairRequestSchedule(
            isWalkin ? walk_in_paylod : getRepairScheduleParams(),
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then(() => {
                setLoading(false);
                if (isTOM || globalState?.state?.isMaxis) {
                    setShowEditableFields(false);
                    callUpdateStatusAPI();
                    callClaimDetailsAPI();
                } else {
                    callUpdateStatusAPI();
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const callUpdateDeliveryAPI = () => {
        const payload = {
            UpdateDeliverySchedule: {
                DeliveryWindow:
                    pickUpDeliveryWindow(
                        globalState?.state?.pickupTime || pickupTime,
                        find_agreement?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan
                    ) ||
                    globalState?.state?.pickupTime ||
                    pickupTime, //globalState?.state?.pickupTime || pickupTime, //'09-10'
                NewDeliveryDate:
                    formatDate(globalState?.state?.pickupDate) || formatDate(pickupDate),
            },
            SelectedInquiryType: 'RESCHEDULE MY DELIVERY',
            CustomerCaseNumber: data?.CustomerCaseNumber?.toString(),
            InquiryDescription: 'test',
            UpdateDeliveryAddress: [
                globalState?.state?.pickUpAddress?.Address1?.trim() ||
                    serviceRequest?.ShippingOrderOutbound?.Address?.Address1,
                globalState?.state?.pickUpAddress?.Address2?.trim() ||
                    serviceRequest?.ShippingOrderOutbound?.Address?.Address2,
                globalState?.state?.pickUpAddress?.Address3?.trim() ||
                    serviceRequest?.ShippingOrderOutbound?.Address?.Address3,
                globalState?.state?.pickUpAddress?.City ||
                    serviceRequest?.ShippingOrderOutbound?.Address?.City ||
                    'Singapore',
                globalState?.state?.pickUpAddress?.PostalCode ||
                    serviceRequest?.ShippingOrderOutbound?.Address?.PostalCode,
            ]
                .filter(Boolean)
                .join(' | '),
            Remarks: 'test',
        };

        setLoading(true);
        updateDelivery(
            payload,
            interactionData?.InteractionLine?.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then(() => {
                setLoading(false);
                callClaimDetailsAPI();
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const callUpdateRepairRequestApi = () => {
        const payload = {
            UpdateRepairRequestParameter: {
                RepairRequestId: serviceRequest?.RepairRequest?.RepairRequestId,
                CustomerCaseId: data?.CustomerCaseId,
                ServiceRequestId: ServiceRequestId,
                ServiceOrderId: ServiceOrderId,
                InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
                ClientChannelId: InitializeResponse?.ClientChannelId,
                ClientId: InitializeResponse?.ClientId,
                ClientName: InitializeResponse?.ClientName,
                ServiceProviderId: globalState?.state?.deliveryAddress?.ServiceProviderId,
                // AppointmentDate:isDeviceRefrshRTLC ? '' : formatDate(globalState?.state?.deliveryDate) || formatDate(deliveryDate)
                AppointmentDate: isDeviceRefrshRTLC
                    ? ''
                    : convertDateToUTC(
                          `${globalState?.state?.deliveryDate || formatDate(deliveryDate)} ${
                              globalState?.state?.deliveryTime || deliveryTime
                          }`,
                          'YYYY-MM-DD HH:mm:ssa',
                          initializeData?.TimeZone
                      ),
                // || convertDateToUTC(
                //   `${deliveryDate} ${deliveryTime}`,
                //   'YYYY-MM-DD HH:mm:ssa',
                //   initializeData?.TimeZone
                // ),
                RepairStatus: isDeviceRefrshRTLC ? 'RWR ASP' : '',
                UpdateAction: isDeviceRefrshRTLC ? 'REPAIRREJECTED' : '',
                RepairRequestType: isDeviceRefrshRTLC ? 'PUR' : '',
            },
        };

        setLoading(true);
        updateRepairRequest(
            payload,
            interactionData?.InteractionLine?.InteractionLineId,
            agreementData1?.AgreementId,
            globalState?.state?.findAgreementSessionData
        )
            .then(() => {
                if (isDeviceRefrshRTLC) {
                    setClaimCancncelled(true);
                    setupdateRepairStatus(true);
                }
                setLoading(false);
                callClaimDetailsAPI();
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleTCATJobIntegration = () => {
        // get mode
        const mode = getTCATJobMode();

        // call Create TCAT job
        callTCATJobCreate(mode);

        // check if it update schedule then call Delete TCAT Job
        const OBTNumber = getTCAT_OBT_Number(mode);

        if (OBTNumber && OBTNumber?.length > 0) callTCATJobDelete(OBTNumber);
    };

    const callTCATJobCreate = (mode: string) => {
        const payload = {
            AddTcatShipmentRequest: {
                ServiceRequestId: ServiceRequestId,
                Mode: mode, //"pickup"
            },
        };

        setLoading(true);
        TCATCScheduleJob(
            payload,
            interactionData?.InteractionLine?.InteractionLineId,
            agreementData1?.AgreementId,
            globalState?.state?.findAgreementSessionData
        )
            .then((data) => {
                setLoading(false);
                callClaimDetailsAPI();
                // show error message if there is error
                if (
                    data &&
                    data?.AddTcatShipmentResponse &&
                    data?.AddTcatShipmentResponse?.IsOK?.toUpperCase() === 'Y'
                ) {
                    setTCATJobError('');
                } else {
                    setTCATJobError(
                        data?.AddTcatShipmentResponse?.Message ||
                            'Error while updating details. Please retry'
                    );
                }
            })
            .catch((error: any) => {
                setLoading(false);
                saveError(error, globalState);
                showErrorPage(navigate);
            });
    };

    const callTCATJobDelete = (OBTNumber: string) => {
        const payload = {
            DeleteTcatShipmentRequest: {
                OBTNumber: OBTNumber,
            },
        };

        // setLoading(true);
        TCATCDeleteJob(
            payload,
            interactionData?.InteractionLine?.InteractionLineId,
            agreementData1?.AgreementId,
            globalState?.state?.findAgreementSessionData
        )
            .then(() => {
                // setLoading(false);
                // callClaimDetailsAPI();
            })
            .catch(() => {
                // setLoading(false);
            });
    };

    const getTitleScripts = async (key: string, scriptlabel: string) => {
        const PlaceHolderText = await getTextScript(
            `${ClaimCardKeys.ClaimCard.PageName}/${key}`,
            scriptlabel,
            language,
            []
        );
        if (isDeviceRefrshRTLC && !clickSwapRequest) {
            const appendedText = `${PlaceHolderText}`;
            setTitle(appendedText);
        } else {
            const appendedText = `${PlaceHolderText} ${getDateAndDay(updatedDate, dateFormat)}`;
            setTitle(appendedText);
        }
    };

    const getDescriptionScripts = async (key: string, scriptlabel: string) => {
        const PlaceHolderText = await getTextScript(
            `${ClaimCardKeys.ClaimCard.PageName}/${key}`,
            scriptlabel,
            language,
            []
        );
        setDescription(PlaceHolderText);
    };

    const getNotesScripts = async (key: string, scriptlabel: string) => {
        const PlaceHolderText = await getTextScript(
            `${ClaimCardKeys.ClaimCard.PageName}/${key}`,
            scriptlabel,
            language,
            []
        );
        setNotes(PlaceHolderText);
    };

    const getTitleScript = async () => {
        switch (status) {
            case 'Cancelled':
                let scriptlabel1 = Scripts.ClaimCard.CancelledTitle;
                return await getTitleScripts('CancelledTitle', scriptlabel1);

            case 'Submitted':
                if (isDeviceRefrshRTLC && !clickSwapRequest) {
                    let scriptlabel6 = Scripts.ClaimCard.rtlcRefresh;
                    return await getTitleScripts('PendingAction', scriptlabel6);
                } else {
                    let scriptlabel2 = Scripts.ClaimCard.SubmittedTitle;
                    return await getTitleScripts('SubmittedTitle', scriptlabel2);
                }

            case 'Completed':
                let scriptlabel3 = Scripts.ClaimCard.DeliveredTitle;
                return await getTitleScripts('DeliveredTitle', scriptlabel3);

            case 'Back Order':
                let scriptlabel4 = Scripts.ClaimCard.BackorderTitle;
                return await getTitleScripts('BackorderTitle', scriptlabel4);

            default:
                if (isBackOrderPendingAction)
                    return getTitleScripts('instockTitle', Scripts.MyClaims.BackOrder.instockTitle);

                let scriptlabel5 = Scripts.ClaimCard.DraftSavedTitle;
                return await getTitleScripts('DraftSavedTitle', scriptlabel5);
        }
    };

    const getDescriptionScript = async () => {
        switch (status) {
            case 'Submitted':
                if (isDeviceRefrshRTLC && !clickSwapRequest && !updateRepairStatus) {
                    let scriptlabel3 = Scripts.MyClaims.rtlcdescription;
                    return await getDescriptionScripts('RefreshRTLCDesc', scriptlabel3);
                } else if (isBackOrder) {
                    let scriptlabel2 = Scripts.MyClaims.BackOrder.description;
                    return await getDescriptionScripts('BODesc', scriptlabel2);
                } else {
                    let scriptlabel1;
                    let paymentMode = getModeOfPayment(
                        data?.ServiceRequests[0]?.ChargeOrder[0]?.PaymentMethodType
                    );
                    if (paymentMode === 'COD') {
                        scriptlabel1 = Scripts.ClaimCard.SubmittedDescCOD;
                        return await getDescriptionScripts('SubmittedDescCOD', scriptlabel1);
                    } else {
                        scriptlabel1 = Scripts.ClaimCard.SubmittedDesc;
                        return isPUR
                            ? await getDescriptionScripts('SubmittedDesc', scriptlabel1)
                            : '';
                    }
                }

            case 'Back Order':
                let scriptlabel2 = Scripts.MyClaims.BackOrder.description;
                return await getDescriptionScripts('BODesc', scriptlabel2);

            case 'Completed':
                return '';

            case 'Draft':
                if (isBackOrderPendingAction) {
                    return await getDescriptionScripts(
                        'instockDesc',
                        Scripts.MyClaims.BackOrder.instockDesc
                    );
                } else if (holds?.length > 0) {
                    let scriptLabel7 = Scripts.ClaimCard.DefautDesc;
                    return await getDescriptionScripts('DefautDesc', scriptLabel7);
                } else {
                    let scriptLabel9 = Scripts.ClaimCard.DraftDesc;
                    return await getDescriptionScripts('DraftDesc', scriptLabel9);
                }

            case 'Cancelled':
                return ''; //await getDescriptionScripts("CancelledDesc",'')

            default:
                if (isBackOrderPendingAction) {
                    return await getDescriptionScripts(
                        'instockDesc',
                        Scripts.MyClaims.BackOrder.instockDesc
                    );
                } else {
                    return await getDescriptionScripts('DefautDesc', Scripts.ClaimCard.DefautDesc);
                }
        }
    };

    const getNotesScript = async () => {
        switch (status) {
            case 'Submitted':
                if (isDeviceRefrshRTLC && !clickSwapRequest && !updateRepairStatus) {
                    return await getNotesScripts('notesRtlc', Scripts.MyClaims.notesRtlc);
                } else if (isPUR) {
                    let paymentMode = getModeOfPayment(
                        data?.ServiceRequests[0]?.ChargeOrder[0]?.PaymentMethodType
                    );

                    if (paymentMode === 'COD') {
                        return await getNotesScripts(
                            'SubmittedDescCOD',
                            Scripts.ClaimCard.SubmittedDescCOD
                        );
                    }

                    return await getNotesScripts('SubmittedDesc', Scripts.ClaimCard.SubmittedDesc);
                } else {
                    return '';
                }

            case 'Back Order':
                return await getNotesScripts('BackOrderNotes', Scripts.MyClaims.BackOrder.notes);

            case 'Completed':
                return '';

            default:
                if (isBackOrderPendingAction)
                    return await getNotesScripts(
                        'instockDesc',
                        Scripts.MyClaims.BackOrder.instockDesc
                    );

                return '';
        }
    };

    const getTitle = () => {
        switch (status) {
            case 'Cancelled':
                return `${Scripts.ClaimCard.CancelledTitle} ${getDateAndDay(updatedDate)}`;

            case 'Submitted':
                if (isDeviceRefrshRTLC && !clickSwapRequest) {
                    return `Pending your action`;
                } else {
                    return `${Scripts.ClaimCard.SubmittedTitle} ${getDateAndDay(updatedDate)}`;
                }

            case 'Completed':
                return `${Scripts.ClaimCard.DeliveredTitle} ${getDateAndDay(updatedDate)}`;

            case 'Back Order':
                return `${Scripts.ClaimCard.BackorderTitle} ${getDateAndDay(updatedDate)}`;

            default:
                if (isBackOrderPendingAction) return Scripts.MyClaims.BackOrder.instockTitle;

                return `${Scripts.ClaimCard.DraftSavedTitle} ${getDateAndDay(updatedDate)}`;
        }
    };

    const getDescription = () => {
        switch (status) {
            case 'Cancelled':
                return '';

            case 'Submitted':
                if (isPUR && !isMalfuntionWarrantyEligible(agreementData?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan)) {
                    return Scripts.ClaimCard.SubmittedDesc;
                } else {
                    return '';
                }

            case 'Completed':
                if (isDeviceRefrshRTLC && !clickSwapRequest && !updateRepairStatus) {
                    return 'Unable to complete Device Refresh request';
                } else {
                    return '';
                }

            case 'Back Order':
                return Scripts.MyClaims.BackOrder.description;

            case 'Draft':
                if (isBackOrderPendingAction) {
                    return Scripts.MyClaims.BackOrder.instockDesc;
                } else if (holds?.length > 0) {
                    return Scripts.ClaimCard.DefautDesc;
                } else {
                    return Scripts.ClaimCard.DraftDesc;
                }

            default:
                if (isBackOrderPendingAction) return Scripts.MyClaims.BackOrder.instockDesc;
                return Scripts.ClaimCard.DefautDesc;
        }
    };

    const getNotes = () => {
        switch (status) {
            case 'Submitted':
                if (isDeviceRefrshRTLC && !clickSwapRequest && !updateRepairStatus) {
                    return Scripts.MyClaims.notesRtlc;
                } else if (isPUR && !isMalfuntionWarrantyEligible(agreementData?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan)) {
                    let paymentMode = getModeOfPayment(
                        data?.ServiceRequests[0]?.ChargeOrder[0]?.PaymentMethodType
                    );

                    if (paymentMode === 'COD') {
                        return Scripts.ClaimCard.SubmittedDescCOD;
                    }

                    return `You will be notified by SMS and Email once your request is approved. Service
          fee would be charged to your credit card only upon approval.`;
                } else {
                    return '';
                }

            case 'Back Order':
                return Scripts.MyClaims.BackOrder.notes;

            case 'Completed':
                return '';

            default:
                if (isBackOrderPendingAction) return Scripts.MyClaims.BackOrder.instockDesc;

                return '';
        }
    };

    const getStatusBgColor = () => {
        switch (status) {
            case 'Completed':
                return '#37E7A7';

            case 'Cancelled':
                return '#DB1B1B';

            default:
                if (isBackOrderPendingAction || isDeviceRefrshRTLC) {
                    return '#F9D51C';
                } else {
                    return '#A5AAAF';
                }
        }
    };

    const arr: any = [
        {
            Que: 'Is the device in your possession?',
            Ans: 'Yes',
            key: 'swap',
        },
    ];

    //getFulfillment APi
    const { refetch: getFulfillmentMethod } = useQuery(
        ['getfulfillmentmethod'],
        async () => {
            setLoading(true);
            return await getFulfillmentMethodApi(
                serviceRequestData?.ServiceRequest.ServiceRequestId,
                interactionData?.InteractionLine.InteractionLineId,
                globalState?.state?.findAgreementSessionData
            );
        },
        {
            enabled: false,
            onSuccess: (data: any) => {
                setLoading(true);
                const FulfillmentOptions = data[0].FulfillmentOption;
                setFulfillmentOption(FulfillmentOptions);
                setFulfillmentOptionsLocal(FulfillmentOptions);
            },
            onError: (error) => {
                setLoading(false);
                saveError(error, globalState);
                showErrorPage(navigate);
            },
        }
    );

    //SetFulfillment APi
    const {} = useQuery(
        ['setfulFillmentMethod'],
        async () => {
            setLoading(true);
            return await setFulfillmentMethodApi(
                interactionData?.InteractionLine.InteractionLineId,
                'RPLCMNT',
                serviceRequestData?.ServiceRequest.ServiceRequestId,
                globalState?.state?.findAgreementSessionData
            );
        },
        {
            enabled: !!FulfilmentOptionsLocal,
            onSuccess: () => {
                setLoading(false);
                navigate('../replacement');
            },
            onError: (error) => {
                setLoading(false);
                saveError(error, globalState);
                showErrorPage(navigate);
            },
        }
    );
    // CREATE SERVICE REQUEST API
    const {
        refetch: createServiceRequest,
        data: serviceRequestData,
        isFetching: serviceRequestLoading,
    } = useQuery(
        ['createServiceRequest'],
        async () =>
            await createServiceRequestApi(
                interactionData?.InteractionLine.InteractionLineId,
                agreementData1?.AgreementId,
                globalState?.state?.findAgreementSessionData
            ),
        {
            enabled: false,
            onSuccess: (data) => {
                sessionStorage.removeItem('ResumeFlowData');
                setServiceRequestData(data);
                // queryClient.setQueryData(['startServiceRequest'], data);
                globalState?.dispatch({ type: ActionTypes.SET_CREATE_SERVICE_DATA, payload: data });
                ProcessIncidentcall();
                let GTMData = getGTMData(
                    GTM_EVENT.CLAIM_NO_EVENT,
                    agreementData1,
                    carrier,
                    domainType,
                    data?.CustomerCaseNumber
                );
                logGTMEvent(GTMData);
            },
            onError: (error) => {
                saveError(error, globalState);
                showErrorPage(navigate);
            },
        }
    );

    // PROCESS INCIDENT API
    const { refetch: ProcessIncidentcall, isFetching: processIncidentLoading } = useQuery(
        ['processIncident'],
        async () => {
            return await processIncidentApi(
                interactionData?.InteractionLine.InteractionLineId,
                serviceRequestData?.ServiceRequest.ServiceRequestId,
                serviceRequestData?.CustomerCaseId,
                agreementData1?.ClientOffer.ClientOfferId,
                assetData!.MobileDeviceNumber,
                agreementData1?.AgreementId,
                agreementData1?.Customers?.Customer?.[0]?.CustomerId,
                'Swap',
                'Swap',
                moment(new Date()).format('YYYY-MM-DD'),
                assetData!.AssetCatalog.AssetCatalogId,
                assetData!.AssetId,
                assetData!.IMEI,
                undefined,
                arr,
                globalState?.state?.findAgreementSessionData
            );
        },
        {
            enabled: false,
            onSuccess: (data) => {
                globalState?.dispatch({ type: ActionTypes.SET_SWAP_CONVERSION, payload: true });
                if (isClaimEligible(data)) {
                    setProcessIncidentData(data);
                    setSelectedAssetData(assetData);
                    setInteractionLineData(interactionData?.InteractionLine.InteractionLineId);
                    globalState?.dispatch({
                        type: ActionTypes.SET_PROCESS_INC_DATA,
                        payload: data,
                    });
                    getFulfillmentMethod();
                    let GTMData = getGTMData(
                        GTM_EVENT.TRIAGE_SCREEN_EVENT,
                        agreementData1,
                        carrier,
                        domainType,
                        data?.CustomerCaseNumber
                    );
                    GTMData.ClaimEligibilty = true;
                    logGTMEvent(GTMData);
                } else {
                    // claim is not eligible show claim reveiw page
                    let GTMData = getGTMData(
                        GTM_EVENT.TRIAGE_SCREEN_EVENT,
                        agreementData1,
                        carrier,
                        domainType,
                        data?.CustomerCaseNumber
                    );
                    GTMData.ClaimEligibilty = false;
                    logGTMEvent(GTMData);
                    navigate('../reviewhold', { replace: true });
                }
            },
            onError: (error) => {
                saveError(error, globalState);
                showErrorPage(navigate);
            },
        }
    );

    const callCancelClaimApis = () => {
        const CancelServiceRequestParams = {
            CancelServiceRequestParams: {
                ServiceRequestId: data?.ServiceRequests[0]?.ServiceRequestId,
                InteractionLineId: interactionData?.InteractionLine.InteractionLineId,
                NoteText: 'Cancel as claim is not repair Feasible so converting to swap',
                Operation: 'Cancel',
                Reason: 'Cancel as claim is not repair Feasible so converting to swap',
                SubReason: 'Cancel as claim is not repair Feasible so converting to swap',
                CancelEntities: 'ALL',
                RequestedBy: 'Customer',
                ReturnEntity: true,
                // AgreementId: agreementData1?.AgreementId,
                // CustomerCaseNumber: '420095479 ' || serviceRequestData?.CustomerCaseNumber,
                // MobileDeviceNumber: '10000239'
            },
        };

        setLoading(true);
        cancelSRApi(
            data?.ServiceRequests[0]?.ServiceRequestId,
            CancelServiceRequestParams,
            interactionData?.InteractionLine.InteractionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then(() => {
                setLoading(false);
                sessionStorage.removeItem('ResumeFlowData');
                sessionStorage.removeItem('replacementData');
                sessionStorage.removeItem('createServiceOrderRequest');
                if (clickSwapRequest) {
                    createServiceRequest();
                }
            })
            .catch((error: any) => {
                setLoading(false);
                saveError(error, globalState);
                showErrorPage(navigate);
            });
    };

    useEffect(() => {
        if (serviceRequestLoading || processIncidentLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [serviceRequestLoading, processIncidentLoading]);

    const handleSwapClick = () => {
        setClickSwapRequest(true);
        setReturnDeviceRequest(false);
    };
    const handleSwapConfirm = () => {
        callCancelClaimApis();
    };
    const handlePhoneReturnClick = () => {
        setReturnDeviceRequest(true);
    };
    const handleConfirmPhoneReturn = () => {
        callUpdateRepairRequestApi();
    };

    const updateCustomerFacingStatus = () => {
        if (isTOM || globalState?.state?.isMaxis) {
            return status;
        }
        if (
            isBackOrderPendingAction ||
            (isDeviceRefrshRTLC && !updateRepairStatus && !clickSwapRequest)
        ) {
            return Scripts.MyClaims.BackOrder.instockTitle;
        }
        if (status?.toUpperCase() === 'BACK ORDER') {
            return 'Draft';
        }
        return status;
    };

    const IsPastDayChange = (date: string): boolean => {
        // System doesn't allow to update pickup-retrun details on same day or for past date

        let todaysDate = moment(new Date(), 'YYYY-MM-DD');
        let compareDate = moment(date, 'YYYY-MM-DD');

        return compareDate?.isBefore(todaysDate);
    };

    const showPickupDetails = () => {
        // return false;
        const multiDeviceSupport = hasMultiDeviceSupport(
            find_agreement?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan
        );

        if (multiDeviceSupport) {
            if (PICKUP_ELIGIBLE_REPAIR_STATUS.includes(data?.RepairStatus)) return true;
            else return false;
        }

        return isPUR;
    };

    const showReturnDetails = () => {
        // return true;
        const multiDeviceSupport = hasMultiDeviceSupport(
            find_agreement?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan
        );

        if (multiDeviceSupport) {
            if (
                RETURN_ELIGIBLE_REPAIR_STATUS.includes(data?.RepairStatus) ||
                CANCEL_RETURN_ELIGIBLE_REPAIR_STATUS.includes(data?.RepairStatus)
            )
                return true;
            else return false;
        }

        return isPUR && !isIW_OEM && !isEWS;
    };

    const getTCATJobMode = (): string => {
        if (PICKUP_ELIGIBLE_REPAIR_STATUS.includes(data?.RepairStatus)) {
            return TCAT_JOB_MODE.PICKUP;
        } else if (RETURN_ELIGIBLE_REPAIR_STATUS.includes(data?.RepairStatus)) {
            return TCAT_JOB_MODE.DELIVERY;
        } else if (CANCEL_RETURN_ELIGIBLE_REPAIR_STATUS.includes(data?.RepairStatus)) {
            return TCAT_JOB_MODE.RETURN;
        }
        return '';
    };

    const isReturnDeliveryJobMode = (mode?: string): boolean => {
        // for retrun and delivery mode shipping order node is same which is ShippingOrderInbound
        // so we are checking for both job node

        const currentTCATJobMode = getTCATJobMode();
        if (
            (mode === TCAT_JOB_MODE.DELIVERY || mode === TCAT_JOB_MODE.RETURN) &&
            (currentTCATJobMode === TCAT_JOB_MODE.DELIVERY ||
                currentTCATJobMode === TCAT_JOB_MODE.RETURN)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const getTCAT_OBT_Number = (mode: string): string => {
        if (mode === TCAT_JOB_MODE.PICKUP) {
            return serviceRequest?.ShippingOrderOutbound?.TrackingNbr;
        } else {
            return serviceRequest?.ShippingOrderInbound?.TrackingNbr;
        }
    };

    const isVASClaimCancelable = (): boolean => {
        return (
            CANCEL_CLEANING_SERVICE_ELIGIBLE_STATUS.includes(data?.RepairStatus?.toUpperCase()) &&
            isVAS &&
            !data?.CustomerCaseStatus?.toLowerCase().includes('cncl') &&
            !isClaimCompleted
        );
    };

    const isVASScheduled = (): boolean => {
        return (
            data?.RepairStatus != undefined &&
            data?.RepairStatus?.toUpperCase() != 'PENDING SCHEDULE CONFIRMATION' &&
            data?.RepairStatus?.toUpperCase() != 'SCHEDULE REJECTED'
        );
    };

    const getCollectionDate = (): string => {
        let result = '';

        if (isWalkin || (isVAS && isVASScheduled())) {
            if (!isRepair) {
                result = deliveryDate;
            }
        } else if (isVAS && !isVASScheduled()) {
            result = data?.ServiceRequests[0]?.ShippingOrderOutbound?.PreferredDay;
        }

        return result;
    };


    const CloseDetails = () => {
        return (
            <Text
                fontWeight={700}
                fontSize="16px"
                textDecorationLine={'underline'}
                color={accent}
                textAlign={'center'}
                mt={'24px'}
            >
                <TextScript
                    text="Close Details"
                    translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.btnCloseDetails}`}
                />
            </Text>
        )
    }

    const getWarrantyRequetCases = () => {
        const repairStatus = data?.RepairStatus?.toUpperCase();
        getTitleScripts('RequestSubmittedTitle', Scripts.ClaimCard.RequestSubmittedTitle)
        if (isMalfunction(incType)){            
            if( ['AWAITING CUSTOMER SELECTION'].includes(repairStatus)){
                globalState?.dispatch({ type: ActionTypes.SET_WARRANTY_REPAIR_ELIGIBILITY, payload: false });
                globalState?.dispatch({ type: ActionTypes.SET_WARRANTY_SWAP_ELIGIBILITY, payload: true });                 
                getDescriptionScripts('WarrantyRepairRejected', Scripts.ClaimCard.WarrantyRepairRejected)                            
            } else if(['PROCEED WITH REPAIR'].includes(repairStatus)){
                globalState?.dispatch({ type: ActionTypes.SET_WARRANTY_REPAIR_ELIGIBILITY, payload: true });
                getDescriptionScripts('WarrantyRepairCompleted', Scripts.ClaimCard.WarrantyRepairCompleted);                
            } else{
                getDescriptionScripts('DefautDesc', Scripts.ClaimCard.DefautDesc);
            }
        } else if(isDeviceRepair(incType)){
            if(repairStatus === 'AWAITING PAYMENT FROM CUSTOMER'){
                globalState?.dispatch({ type: ActionTypes.SET_PAID_REPAIR_REQUEST, payload: true });
                getDescriptionScripts('RepairForFee', Scripts.ClaimCard.RepairForFee)
            } else if(['REPAIR REJECTED'].includes(repairStatus)){
                globalState?.dispatch({ type: ActionTypes.SET_PAID_REPAIR_REQUEST, payload: false });
                getDescriptionScripts('RepairRejected', Scripts.ClaimCard.RepairRejected)
            } else{
                getDescriptionScripts('DefautDesc', Scripts.ClaimCard.DefautDesc);
            }
        }
    }

    const getWarrantyRequetsResumeText = () => {
        let buttonText: string = '';
        let buttonKey: string = '';
        let cancelBtnText;

        if (isMalfunction(incType)){
            if(!globalState?.state?.isWarrantyRepairEligible){
                buttonText = 'See device options'
                buttonKey = 'btnSeeDeviceOptions'
                cancelBtnText = 'Cancel request and return phone'
            }
        } else if(isDeviceRepair(incType)) {
            if(globalState?.state?.isRepairRequestEligible){
                buttonText = 'Proceed with repair'
                buttonKey = 'btnProceedWithRepair'
                cancelBtnText = 'Cancel request and return phone'
            } else {
                buttonText = 'Schedule delivery'
                buttonKey = 'btnScheduleDelivery'
            }
        }
        return (
            <>
                <Box mt={2} w={'full'}>
                {buttonKey && <Button
                        py={3}
                        px={5}
                        w={'full'}
                        colorScheme="primary"
                        isDisabled={isButtonDisabled()}
                        onClick={() => handleResume(data)}
                    >
                        <TextScript
                            text={buttonText}
                            translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${buttonKey}`}
                        />
                    </Button>}
                </Box>
                <Box w="full" mt={2}>
                    {cancelBtnText ? <Button
                        variant={'outline'}
                        w={'full'}
                        py={3}
                        px={5}
                        color={accent}
                        borderColor={primary}
                        onClick={() =>
                            handleCancel()
                        }
                    >
                        <TextScript
                            text={cancelBtnText}
                            translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.cancelRequestKey}`}
                        />
                    </Button>: null}
                </Box>
            </>
        )
        
        /* <TextScript
        text="In-Warranty Service"
        translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.btnInWarrantyService}`}
    /> */        
  }
 
  const textColor = isBackOrderPendingAction ? '#000' : '#ffffff';
  
  const displayTrackSRBtn = !isMalfuntionWarrantyEligible(agreementData?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) &&
  data && (data?.ServiceRequests?.[0]?.ServiceRequestStatus?.toUpperCase() === 'CMPLTD' || data?.ServiceRequests?.[0]?.RepairRequest?.RepairStatus)

  const callInquiryForTrackSR = (type: any) => {
    setLoading(true);
    let payload = {
      CreateInquiryParams: {
      FirstName: find_agreement?.Customers?.Customer?.[0]?.FirstName,
      LastName: find_agreement?.Customers?.Customer?.[0]?.LastName,
      ClientId: InitializeResponse?.ClientId,
      ClientChannelId: InitializeResponse?.ClientChannelId,
      ClientName: InitializeResponse?.ClientName,
      CustomerCaseNumber: data?.CustomerCaseNumber?.toString(),
      InquiryChannel: "ONLINE",
      InquiryType: type === 'trackOrder' ? "Track My Delivery" : 'Cancel my delivery',
      InquirySubType: type === 'trackOrder' ? "Track My Delivery" : 'Cancel my delivery',
      CallDriverCategory: type === 'trackOrder' ? "Track My Delivery" : 'Cancel my delivery',
      CallDriverSubCategory: type === 'trackOrder' ? "Track My Delivery" : 'Cancel my delivery',
      MobileDeviceNumber: assetData?.MobileDeviceNumber,
      ContactNumber: getAlternateContactNumber(),
      InquiryDescription: type === 'trackOrder' ? "Delivery confirmation" : 'Customer has requested for delivery cancellation.',
      Note: type === 'trackOrder' ? "Delivery confirmation" : 'Customer has requested for delivery cancellation.',
      SendEmail: type === 'trackOrder' ? false : true,
      ResolveStatus: type === 'trackOrder' ? true : false,
      UnnecessaryInquiry: false,
      isTLCUpdateRequest: false,
      Priority: "Low",
      Files: []
  }}

    createInquiryV1(
      payload,
      interactionData?.InteractionLine.InteractionLineId,
      globalState?.state?.findAgreementSessionData
    )
      .then((res) => {
        console.log('res', res);
        setLoading(false);
        globalState?.dispatch({ type: ActionTypes.TRACK_ORDER_DATA, payload: res });
        if (res?.updatedDelivery) {
          setOpenTrackOrderPopup(type === 'trackOrder' ? true : false)
        }
        if(type !== 'trackOrder') {
          // navigate('/', { replace: true });
          setCancelDeliverySuccessMsg(true)
        }
      })
      .catch((error: any) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  }
  
 return (
    <>
      {!isLoading ? (
        <Box
          id={'myasurion-devicecard'}
          borderRadius={'8px'}
          // minW={{ base: '335px', lg: '545px' }}
          minW={'auto'}
          minH={'124px'}
          borderWidth={'1px'}
          borderStyle={'solid'}
          borderColor={'gray.300'}
          mb={4}
          {...props}
        >
          <>
            <Box p={'16px 10px'}>
              <Flex justify={'center'}>
                <Box
                  w={'full'}
                  mb={'15px'}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  px={1}
                >
                  <Box
                    m={0}
                    px={'4px'}
                    py={1}
                    bg={'#E6E6EB'}
                    borderRadius={'2px'}
                    display={`${status == 'Çompleted' && warranty}`}
                  >
                    <Text fontSize={'14px'} textAlign={'center'} fontWeight={700}>
                      {plan}
                    </Text>
                  </Box>
                  <Box
                    m={0}
                    px={'4px'}
                    py={1}
                    bg={getStatusBgColor()}
                    borderRadius={'2px'}
                  >
                    <Text color={textColor} fontSize={'14px'} textAlign={'center'} fontWeight={400}> 
                    {/* {isBackOrderPendingAction || (isDeviceRefrshRTLC && !updateRepairStatus ) ? Scripts.MyClaims.BackOrder.instockTitle : status?.toUpperCase() === 'BACK ORDER' ? 'Draft' :status }  */}   
                      {/* <TextScript 
                        text={isBackOrderPendingAction || (isDeviceRefrshRTLC && !updateRepairStatus && !clickSwapRequest ) ? Scripts.MyClaims.BackOrder.instockTitle : status}
                        translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${isBackOrderPendingAction ? Scripts.MyClaims.BackOrder.instockTitle : status}`}
                      /> */}
                      <TextScript 
                        text={ updateCustomerFacingStatus()}
                        translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${updateCustomerFacingStatus()}`}
                      />
                    </Text>
                  </Box>
                </Box>
              </Flex>
              <Flex justifyContent={'space-between'} px={1}>
                <Box w={'75%'}>
                  {fields.map((f, i) => {
                    return (
                      <Stack
                        direction={'row'}
                        gap={0}
                        spacing={0}
                        w={'full'}
                        fontSize={'md'}
                        fontWeight={400}
                        key={`devicecard-field-${f.field}-${i}`}
                      >
                        {args[`${f.field}`] && (
                          <>                          
                            <Box w={'35%'} float={'left'}>                              
                              <TextScript 
                                text={f.label}
                                translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.labelFieldsModel}${i}`}
                              />
                            </Box>
                            <Box
                              color={
                                f.field === 'warranty'
                                  ? warranty == 'Valid'
                                    ? '#008662'
                                    : warranty == 'Expired'
                                    ? '#DB1B1B'
                                    : ''
                                  : ''
                              }
                              w={'65%'}
                              float={'right'}
                              fontWeight={f.weight || 400}
                            >
                              {args[`${f.field}`]}
                            </Box>
                          </>
                        )}
                      </Stack>
                    );
                  })}
                </Box>
                <Box w={'25%'}>
                  {imageEl ? (
                    imageEl
                  ) : (
                    <Box m={'30% auto'} fontSize={'xs'} color={'gray.300'}>
                      No image
                    </Box>
                  )}
                </Box>
                {showMoreDetails && detailsEl && <Box w={'full'}>{detailsEl}</Box>}
              </Flex>
            </Box>
           <DeviceService
              mt={2}
              mb={4}
              w={'full'}
              status={isDeviceRefrshRTLC ? 'Processing' :status}
              title={ title || getTitle()}
              desc={description || getDescription()}
              feeText={''}
              deviceServiceButtonProps={{
                label: '',
                onClick: () => {
                  ('');
                },
                disable: true,
              }}
            ></DeviceService>
            <Box px={1} my={4}>
              <Link onClick={() => setClickMore(true)} style={clickMore ? { display: 'none' } : {}}>
                <Text
                  textAlign={'center'}
                  fontSize="16px"
                  color={accent}
                  textDecorationLine="underline"
                  fontWeight={700}
                >
                  <TextScript 
                    text='More Details'
                    translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.btnMoreDetails}`}
                  />
                </Text>
              </Link>
              {clickMore && (!clickSwapRequest && !clickReturnDeviceRequest && !claimCancncelled)  && (
                <Box>
                  {!openTrackMyDelivery && <Box px={2}>
                    <ClaimDetails
                      deliveryAddress={(!fullFillmentOption || isPUR || isWalkin || isVAS) ? '' : getAddress(shippingOrderAddress)}
                      deliveryDate={(!fullFillmentOption || isPUR || isWalkin || isBackOrder || isVAS) ? '' : deliveryDate}
                      deliveryTime={(!fullFillmentOption || isPUR || isWalkin || isBackOrder || isVAS) ? '' : deliveryTime}
                      collectionAddress={(isWalkin || isVAS) && !isRepair ? getAddress(shippingOrderAddress) : ''}
                      collectionDate={getCollectionDate()}
                      collectionTime={
                        (isWalkin || isVAS) && !isRepair ? convertTimeRange24To12(deliveryTime) : ''
                      }
                      pickupAddress={isPUR ? serviceRequest?.ShippingOrderOutbound?.Address : ''}
                      pickupDate={isPUR ? pickupDate : ''}
                      pickupTime={isPUR ? pickupTime : ''}
                      returnAddress={isPUR ? serviceRequest?.ShippingOrderInbound?.Address : ''}
                      returnDate={isPUR ? returnDate : ''}
                      returnTime={isPUR ? returnTime : ''}
                      RepairCentreLocation={isWalkin && isRepair ? getAddress(shippingOrderAddress) : ''}
                      AppointmentDate={isWalkin && isRepair ? deliveryDate : ''}
                      AppointmentTime={isWalkin && isRepair ? deliveryTime : ''}
                      nightDeliveryFee={nightDeliveryFees}
                      showDeliveryDetails={isPUR || isWalkin ? false : true}
                      showPickupDeatils={isPUR}
                      showReturnDetails={showReturnDetails() || status === 'Completed'}
                      showAppointmentDetails={isWalkin && isRepair}
                      showCollectionDetails={(isWalkin || isVAS) && !isRepair}
                      isNightFeesApplicable={true}
                      ServiceRequestId={data?.CustomerCaseNumber}
                      ServiceRequestStatus={isDeviceRefrshRTLC ? 'Processing':status}
                      // RequestedService={
                      //   incType?.toLowerCase() === 'fault repair' ? 'Device Repair' : incType
                      // }
                      RequestedService={PerilType?.toLowerCase() === INCIDENT_TYPES.FAULT_REPAIR?.toLowerCase() ? INCIDENT_TYPES.DEVICE_REPAIR : PerilType || (isDeviceRepair(incType) ? 'Device Repair' : incType)}
                      RequestType={isVAS ? '' : FulFillmentOptionType || RequestType()}
                      Fees={data?.ServiceRequests[0]?.ChargeOrder[0]?.TotalAmount}
                      PaymentMethod={isVAS ? '' :
                        data?.ServiceRequests[0]?.ChargeOrder[0]?.PaymentMethodType &&
                        getModeOfPayment(
                          data?.ServiceRequests[0]?.ChargeOrder[0]?.PaymentMethodType
                        )
                      }
                      showEditableFields={showEditableFields}
                      claimData={data}
                      isWalkin={(isWalkin || isVAS)}
                      Notes={notes || getNotes()}
                      WarrantyExpiryDate={
                        data?.WarrantyExpiryDate
                          ? moment(data?.WarrantyExpiryDate).format('ll')
                          : ''
                      }
                      IsInWarranty={data?.IsInWarranty}
                      currencySymbol={currencySymbol || ''}
                      isTOM={isTOM}
                      isMaxis={globalState?.state?.isMaxis}
                      AlternativeContactNumber={getDefaultAlternateContactNumber()}
                      isPickupEditable={showPickupDetails()}
                      isReturnEditable={showReturnDetails()}
                      isAlternateNumberEditable= {hasEditAlternateNumberFeature(find_agreement?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) }
                    />
                  </Box>}
                  {openTrackMyDelivery &&
                      <TrackMyDelivery 
                        openTrackMyDelivery={openTrackMyDelivery} 
                        claimData={data}
                        agreementdata = {find_agreement}
                        callInquiryForTrackSR={(type) => callInquiryForTrackSR(type)}
                        openTrackOrderPopup={openTrackOrderPopup}
                        setOpenTrackOrderPopup={setOpenTrackOrderPopup}
                        />
                    }
                    <CancelDeliveryPopup
                      closeCancelPopup={setCancelDeliveryPopup}
                      cancelDeliveryPopup={cancelDeliveryPopup}
                      callInquiryForTrackSR={(type) => callInquiryForTrackSR(type)}
                      setCancelDeliverySuccessMsg={() => setCancelDeliverySuccessMsg(!cancelDeliverySuccessMsg)}
                      cancelDeliverySuccessMsg={cancelDeliverySuccessMsg}
                    />
                  <VStack px={4}>
                    {isDeviceRefrshRTLC && !updateRepairStatus
                      && (
                        <Box>
                          <Button
                            mt={2}
                            py={3}
                            px={5}
                            isDisabled={isButtonDisabled()}
                            colorScheme="primary"
                            w="full"
                            onClick={() => {
                              handleSwapClick();
                            }}
                          >
                          <TextScript
                            text = 'Request for Swap' 
                            translationKey=  {`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.RequestSwapbtn}`}/>
                          </Button>
                          <Button
                            variant={'outline'}
                            mt={2}
                            py={3}
                            px={5}
                            isDisabled={isButtonDisabled()}
                            color={primary}
                            w="full"
                            onClick={() => {
                              handlePhoneReturnClick();
                            }}
                          >
                            <TextScript
                            text ='Phone to be returned'
                            translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.PhoneReturned}`}/>
                          </Button>
                          <Button
                            variant={'outline'}
                            mt={2}
                            py={3}
                            px={5}
                            isDisabled={isButtonDisabled()}
                            color={primary}
                            w="full"
                            onClick={() => {
                              alert("Awaiting FRD")
                            }}
                          >
                            <TextScript
                            text = 'See other services' 
                            translationKey=  {`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.SeeOtherDevices}`}/>
                            
                          </Button>
                        </Box>
                      )}
                    {!openTrackMyDelivery && isClaimCompleted && !isClaimResumable && warranty == 'Valid' && !hideWarrantyButton && !isDeviceRefrshRTLC  &&  (
                      <Button
                        mt={2}
                        py={3}
                        px={5}
                        isDisabled={isButtonDisabled()}
                        colorScheme="primary"
                        w="full"
                        onClick={() => {
                          handleInwarranty();
                        }}
                      >
                        <TextScript 
                          text='In-Warranty Service'
                          translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.btnInWarrantyService}`}
                        />
                      </Button>
                    )}
                    {isClaimResumable && !isVAS && (
                      <Button
                        mt={2}
                        py={3}
                        px={5}
                        isDisabled={isButtonDisabled()}
                        colorScheme="primary"
                        w="full"
                        onClick={() => {
                          handleResume(data);
                        }}
                      >
                        <TextScript 
                          text='Resume'
                          translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.btnResume}`}
                        />
                      </Button>
                    )}
                    {(isClaimResumable || isVASClaimCancelable()) && (
                      <Box w="full" mt={2}>
                        <Button
                          variant={'outline'}
                          w={'full'}
                          py={3}
                          px={5}
                          color={accent}
                          borderColor={primary}
                          fontWeight={700}
                          onClick={handleCancel}
                        >
                          <TextScript 
                            text={Scripts?.Global?.Cancel}
                            translationKey={`${ClaimCardKeys.Global.PageName}/${ClaimCardKeys.Global.Cancel}`}
                          />
                        </Button>
                      </Box>
                    )}
                    {data?.CustomerCaseStatus !== 'CNCLCUS' && !showEditableFields && displayTrackSRBtn && 
                    // (status === 'Completed' || data?.ServiceRequests?.[0]?.RepairRequest?.RepairStatus)
                    <>
                      <Button
                        mt={2}
                        py={3}
                        px={5}
                        w="full"
                        onClick={() => {
                          setOpenTrackMyDelivery(!openTrackMyDelivery);
                        }}
                        fontWeight={700}
                        colorScheme="primary"
                      >
                        {openTrackMyDelivery ? <TextScript 
                                text='Go back'
                                translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.goBack}`}
                            /> : <TextScript 
                                text='Track My Delivery'
                                translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.trackMyDelivery}`}
                            /> }
                      </Button>
                      {!openTrackMyDelivery && <Button
                        mt={2}
                        py={3}
                        px={5}
                        w="full"
                        onClick={() => {
                          // callInquiryForTrackSR('cancelDelivery'),
                          setCancelDeliveryPopup(true)
                        }}
                        fontWeight={700}
                        colorScheme="primary"
                      >
                        <TextScript 
                          text='Send Cancellation Request'
                          translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.sendCancellationRequest}`}
                        /> 
                        </Button>}
                    </>
                    }
                    {!openTrackMyDelivery && !showEditableFields && allowChangeDetails && (
                      <Button
                        mt={2}
                        py={3}
                        px={5}
                        w="full"
                        onClick={() => {
                          setResumeFlowData(data);
                          sessionStorage.removeItem(claimDetailsResponseKey);
                          setShowEditableFields(true);
                        }}
                        fontWeight={700}
                        colorScheme="primary"
                      >
                        {getChangeDetailsLabel()}
                        {/* <TextScript 
                          text='Change Details'
                          translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.btnChangeDetails}`}
                        /> */}
                      </Button>
                    )}

                    {/* <Box mt={2} w={'full'}>
                      <Button
                        py={3}
                        px={5}
                        variant={'outline'}
                        w={'full'}
                        // color={primary}
                        fontWeight={700}
                      >
                        Appoint a Representative
                      </Button>
                    </Box> */}

                    {showEditableFields && (
                      <>
                        <Box mt={2} w={'full'}>
                          <Button
                            py={3}
                            px={5}
                            w={'full'}
                            colorScheme="primary"
                            isDisabled={isConfirmButtonDisabled()}
                            onClick={ConfirmChangeDetailsClick}
                          >
                            <TextScript 
                              text={Scripts.Global.Confirm}
                              translationKey={`${ClaimCardKeys.Global.PageName}/${ClaimCardKeys.Global.Confirm}`}
                            />
                          </Button>
                        </Box>
                        <Box w="full" mt={2}>
                          <Button
                            variant={'outline'}
                            w={'full'}
                            py={3}
                            px={5}
                            color={accent}
                            borderColor={primary}
                            onClick={() => setShowEditableFields(false)}
                          >
                            <TextScript 
                              text={Scripts?.Global?.Cancel}
                              translationKey={`${ClaimCardKeys.Global.PageName}/${ClaimCardKeys.Global.Cancel}`}  
                            />
                          </Button>
                        </Box>
                      </>
                    )}
                  </VStack>
                  {!showEditableFields && TCATJobError && TCATJobError?.length > 0 &&
                    <Text
                      fontWeight={'bold'}
                      fontSize="16px"
                      mt={2}
                      color={'red'}
                      textAlign={'center'}>
                      {TCATJobError}
                    </Text>
                  }
                    {isMalfuntionWarrantyEligible(agreementData?.ClientOffer?.ClientOfferName || onlineFindAgreement?.plan) && status === 'Completed' &&
                                                        getWarrantyRequetsResumeText()
                                                    }
                                 

                  <Box mb={2} mt={2}>
                    <Link mb={4} alignContent={'center'}
                      onClick={() => {
                        setClickMore(false)
                        setClickSwapRequest(false)
                      }}>
                      {CloseDetails()}
                    </Link>
                  </Box>
                </Box>
              )}
              {clickSwapRequest &&
                <Box margin={"9px"}>
                  { programmeNameLBL && 
                    <Text fontSize={'24px'} mt={'16px'}>
                      <TextScript
                        text ='Please confirm you wish to request for a Swap under {1}.'
                        translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.RequestSwapScript1}`}
                        dynamicValues={[programmeNameLBL]}/>
                    </Text>
                  }
                  <Text mt={'16px'} fontSize={'18px'}>
                    <TextScript
                    text='Your current service request will be cancelled.'
                    translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.RequestSwapScript2}`}/>
                  </Text>
                  { programmeNameLBL && 
                    <Text fontSize={'18px'}>
                      <TextScript
                        text='You will be redirected to {1} Swap request page.'
                        translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.RequestSwapScript3}`}
                        dynamicValues={[programmeNameLBL]}/>
                    </Text>
                  }
                  <Box>
                    <Button
                      mt={'16px'}
                      py={3}
                      px={5}
                      isDisabled={isButtonDisabled()}
                      bgColor={primary}
                      color="white"
                      w="full"
                      onClick={() => {
                        handleSwapConfirm();
                      }}>
                        <TextScript
                          text= {Scripts.Global.Confirm}
                          translationKey={`${ClaimCardKeys.Global.PageName}/${ClaimCardKeys.Global.Confirm}`}
                        />
                    </Button>
                    <Button
                      variant={''}
                      w={'full'}
                      py={3}
                      px={5}
                      mt={'4px'}
                      color={accent}
                      borderColor={primary}
                      fontWeight={700}
                      onClick={handleCancel}
                    >
                      <TextScript
                        text={Scripts?.Global?.Cancel}
                        translationKey={`${ClaimCardKeys.Global.PageName}/${ClaimCardKeys.Global.Cancel}`}/>
                    </Button>
                    <Link mb={4} alignContent={'center'}
                      onClick={() => {
                        setClickMore(false)
                        setClickSwapRequest(false)
                      }}>
                      {CloseDetails()}
                    </Link>
                  </Box>
                </Box>}
              {clickReturnDeviceRequest && !claimCancncelled &&
                <Box margin={"9px"}>
                  <Text fontSize={'24px'} mt={'16px'}>
                    <TextScript
                    text = 'Please confirm you wish to cancel the service request and have your phone returned'
                    translationKey = {`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.PhoneReturnedScript1}`}/>
                    </Text>
                  <Box>
                    <Button
                      mt={'16px'}
                      py={3}
                      px={5}
                      isDisabled={isButtonDisabled()}
                      bgColor={primary}
                      color="white"
                      w="full"
                      onClick={() => {
                        handleConfirmPhoneReturn();
                      }}>
                       <TextScript
                          text= {Scripts.Global.Confirm}
                          translationKey={`${ClaimCardKeys.Global.PageName}/${ClaimCardKeys.Global.Confirm}`}
                        />
                    </Button>
                    <Button
                      variant={''}
                      w={'full'}
                      py={3}
                      px={5}
                      mt={'4px'}
                      color={accent}
                      borderColor={primary}
                      fontWeight={700}
                      onClick={handleCancel}
                    >
                    <TextScript
                        text={Scripts?.Global?.Cancel}
                        translationKey={`${ClaimCardKeys.Global.PageName}/${ClaimCardKeys.Global.Cancel}`}/>
                    </Button>
                    <Link mb={4} alignContent={'center'}
                      onClick={() => {
                        setClickMore(false)
                        setReturnDeviceRequest(false)
                      }}>
                      {CloseDetails()}
                    </Link>
                  </Box>
                </Box>}
                {claimCancncelled && 
                <Box margin={"9px"}>
                <Text fontSize={'24px'} mt={'16px'}>
                  {/* <TextScript
                  text ='Please confirm you wish to request for a Swap under SmartSupport.'
                  translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.RequestSwapScript1}`}/> */}
                  Your Service Request (ID: {`${data.CustomerCaseNumber}`}) has been Pending for approval
                </Text>
                <Text mt={'16px'} fontSize={'18px'}>
                   <TextScript
                  text='No charges will be incurred. A SMS and/or email will be sent to you shortly.'
                  translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.PhoneReturnScript1}`}/> 
                </Text>
                <Box>
                  <Button
                    mt={'16px'}
                    py={3}
                    px={5}
                    isDisabled={isButtonDisabled()}
                    bgColor={primary}
                    color="white"
                    w="full"
                    onClick={() => {
                      setClickMore(false)
                      setReturnDeviceRequest(false)
                      setClaimCancncelled(false)
                    }}>
                    <TextScript
                      text= 'Go Back'
                      translationKey={`${ClaimCardKeys.ClaimCard.PageName}/${ClaimCardKeys.ClaimCard.Gobackbtnlbl}`}/> 
                  </Button>
                  <Link mb={4} alignContent={'center'}
                    onClick={() => {
                      setClickMore(false)
                      setReturnDeviceRequest(false)
                      setClaimCancncelled(false)
                    }}>
                    {CloseDetails()}
                  </Link>
                </Box>
              </Box>
                }
            </Box>
          </>
          <CancelSrPopup
            onYesClick={onYesClick}
            closeCancelPopup={setOpenCancelPopup}
            openCancelPopup={openCancelPopup}
            isVAS={isVAS}
          />
        </Box>
      ) : (
        <Skeleton minW={'335px'} minH={'124px'} w={'full'} borderRadius={'8px'} />
      )}
    </>
  );
};

export default DeviceCard;