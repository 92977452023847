import Scripts from '../Scripts/asurion-script.json';
import { useCentralStore } from '../store/appContext';
import { CHECK_BALANCE_FLOW_TYPE } from '../utils/constant';
import {
  Box,
  Center,
  Flex,
  ListItem,
  Text,
  UnorderedList,
  VStack,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import translationsKeys from '../Scripts/translationsKeys.json';
import JBDefaultPageLayout from './JBDefaultPageLayout';
import { UnableToProceedAlert } from './component/UnableToProceedAlert/UnableToProceedAlert';

const mockTermsStatement =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.';

const mockPoints = [
  { id: 'tier1', label: 'Tier 1', description: mockTermsStatement },
  { id: 'tier2', label: 'Tier 2', description: mockTermsStatement },
  { id: 'tier3', label: 'Tier 3', description: mockTermsStatement },
];

const VerifyProviderPage: React.FC = () => {
  // Media Query
  const [isDesktop] = useMediaQuery('(min-width: 850px)');

  // Global State
  const navigate = useNavigate();
  const globalState = useCentralStore();
  const agreementData1 =
    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  const assetData = agreementData1?.Assets.Asset.filter(
    (a: any) => a.AssetInstance === 'ENROLLED'
  )[0];
  const MobileDeviceNumber = assetData && assetData?.MobileDeviceNumber?.split('_')?.[0];
  const programmeNameLBL = '';

  // Translations
  const VerifyProviderPageKeys = translationsKeys.HowCanWeHelpPage;

  // States
  const [openCancelPopup, setOpenCancelPopup] = useState(false);

  // Unable To Proceed Alert State
  const {
    isOpen: isUnableToProcessModalOpen,
    onOpen: onOpenUnableToProcessModal,
    onClose: onCloseUnableToProcessModal,
  } = useDisclosure();

  useEffect(() => {
    if (globalState?.state?.checkBalanceData?.FlowType === CHECK_BALANCE_FLOW_TYPE.INELIGIBLE) {
      onOpenUnableToProcessModal();
    }
  }, [globalState?.state?.checkBalanceData]);

  return (
    <JBDefaultPageLayout
      headerText={Scripts.HowCanWeHelpYou.HeaderText}
      headerTranslationKey={`${VerifyProviderPageKeys.PageName}/${VerifyProviderPageKeys.HeaderText}`}
      onCancelClick={() => setOpenCancelPopup(!openCancelPopup)}
      showDeviceCard={true}
      assetId={assetData && assetData?.AssetId.toString()}
      programmeNameLBL={programmeNameLBL || agreementData1?.ClientOffer?.ClientOfferName}
      clientOfferName={agreementData1?.ClientOffer?.ClientOfferName}
      countryCallingCode={
        assetData?.CountryCallingCode?.split('+')?.[1] ||
        agreementData1?.ContactPoints?.ContactPoint?.[0]?.CountryCallingCode
      }
      mobileDeviceNumber={MobileDeviceNumber}
      assetCatalogName={assetData?.AssetCatalog.AssetCatalogName}
      imei={assetData?.IMEI}
      assetFamily={assetData?.AssetCatalog.AssetFamily}
      onNextClick={() => {
        navigate('../repair', { replace: true });
      }}
      isNextButtonDisabled={globalState?.state?.checkBalanceData?.FlowType === CHECK_BALANCE_FLOW_TYPE.INELIGIBLE}
      showCancelButton={false}
    >
      <Center>
        <Flex
          w={isDesktop ? '50%' : '90%'}
          m="0"
          gap={'16px'}
          direction="column"
          wrap="wrap"
          justifyContent="center"
          alignContent="center"
          id={'myasurion-verify-provider-3'}
          h={'auto'}
        >
          <Box
            className={'upgradeTierHeader'}
            w={{ base: 'full', lg: '100%' }}
            margin={{ base: '0', lg: '0' }}
            pb={{ base: '0', lg: '0' }}
          >
            <Text fontSize={'24px'}>Verifying with payment provider</Text>
          </Box>
          <VStack spacing="2">
            <UnorderedList>
              {mockPoints.map((currentPoint) => (
                <ListItem>{currentPoint?.description}</ListItem>
              ))}
            </UnorderedList>
          </VStack>
        </Flex>
      </Center>
      <UnableToProceedAlert
        isOpen={isUnableToProcessModalOpen}
        onClose={() => {
          onCloseUnableToProcessModal();
        }}
      />
    </JBDefaultPageLayout>
  );
};

export default VerifyProviderPage;
