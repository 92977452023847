import { getCarrierByDomain } from '@MGPD/myasurion-shared';
import { Spinner } from '@chakra-ui/spinner';
import { useMachine } from '@xstate/react';
import axios from 'axios';
import { noop } from 'lodash';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import * as React from 'react';

import Completed from './Completed';
// import { NPSReasons } from './NPSReasons';
import { NPSSlider } from './NPSSlider';
import npsMachine from './machines/nps';
import npsReasons from './nps-reasons';
import { getContentByCarrier } from './nps-reasons';
import { SurveyConfig } from './types';

const { useEffect } = React;

export type NPSType = {
    clientId: string;
    triggerPoint?: string;
    surveyType: 'NPS';
    serviceRequestId?: string;
    customerCaseNumber?: string;
    language?: string;
    sessionId?: string;
    mdn?: string;
    config: SurveyConfig;
    onSubmit: (response: any) => void;
    onError: (data: any) => void;
    defaultScore?: number;
};

export const DEFAULT_TRIGGER_POINT = 'SR_Creation';
export const DEFAULT_SURVEY_TYPE: NPSType['surveyType'] = 'NPS';
export const DEFAULT_LANGUAGE = '';

export const NPS = ({
    clientId,
    triggerPoint = DEFAULT_TRIGGER_POINT,
    surveyType = DEFAULT_SURVEY_TYPE,
    serviceRequestId = '',
    customerCaseNumber = '',
    sessionId = '',
    language = 'en-US',
    mdn = '',
    config,
    onSubmit = noop,
    onError = noop,
    defaultScore = 10,
}: NPSType) => {
    const [state, send] = useMachine(npsMachine, {
        devTools: import.meta.env.DEV,
        services: {
            async getSurvey() {
                const { data } = await axios({
                    baseURL: config.baseUrl,
                    url: config.actions['getSurvey'].path,
                    headers: config.headers,
                    method: config.actions['getSurvey'].method,
                    data: {
                        GetSurveyQuestions: {
                            ClientId: clientId,
                            TriggerPoint: triggerPoint,
                            SurveyType: surveyType,
                            ServiceRequestId: serviceRequestId,
                            Language: language,
                            SessionId: sessionId,
                        },
                    },
                });
                const surveyQuestions = sortBy(
                    data?.SurveyQuestions || data.GetSurveyQuestionsResponse?.SurveyQuestions,
                    [(o) => parseInt(o.DisplayOrder)]
                );

                send('SET_CLIENT_ID', { id: clientId });
                send('SET_SURVEY_ID', { id: surveyQuestions[0].SurveyId });
                send('SET_SCORE_QUESTION_ID', {
                    id: find(surveyQuestions, { QuestionType: 'NPS1', QuestionOptions: 'slider' })
                        ?.QuestionId,
                });
                send('SET_MDN', { mdn });
                return {
                    PROMOTER: {
                        questionId: find(surveyQuestions, { QuestionType: 'promoter' })?.QuestionId,
                        description: find(surveyQuestions, { QuestionType: 'promoter' })
                            ?.QuestionDescription,
                        reasons: getContentByCarrier(carrier)['PROMOTER'] ?? npsReasons['PROMOTER'],
                    },
                    PASSIVE: {
                        questionId: find(surveyQuestions, { QuestionType: 'passive' })?.QuestionId,
                        description: find(surveyQuestions, { QuestionType: 'passive' })
                            ?.QuestionDescription,
                        reasons: getContentByCarrier(carrier)['PASSIVE'] ?? npsReasons['PASSIVE'],
                    },
                    DETRACTOR: {
                        questionId: find(surveyQuestions, { QuestionType: 'detractor' })
                            ?.QuestionId,
                        description: find(surveyQuestions, { QuestionType: 'detractor' })
                            ?.QuestionDescription,
                        reasons:
                            getContentByCarrier(carrier)['DETRACTOR'] ?? npsReasons['DETRACTOR'],
                    },
                };
            },
            async submitSurvey(context) {
                let results: Array<{ QuestionId: string; Answer: string | number }> = [];
                if (context.customerCategory !== null && context.score !== null) {
                    results = [
                        {
                            QuestionId: context.scoreQuestionId,
                            Answer: context.score.toString(),
                        },
                        // {
                        //     QuestionId: context.reasons[context.customerCategory].questionId,
                        //     Answer: context.selectedReasons.join(','),
                        // },
                    ];
                }

                const result: any = await axios({
                    baseURL: config.baseUrl,
                    url: config.actions['submitSurvey'].path,
                    headers: config.headers,
                    method: config.actions['submitSurvey'].method,
                    data: {
                        SubmitSurveyAnswers: {
                            SurveyId: context.surveyId,
                            ClientId: context.clientId,
                            // engagementId: '',
                            // mobileNumber: context.mdn,
                            // InteractionLineId: '',
                            Results: results,
                            // CacheId: '',
                            CustomerCaseNumber: customerCaseNumber,
                            ServiceRequestId: serviceRequestId,
                        },
                    },
                });
                return result.data;
            },
        },
    });

    const carrier = getCarrierByDomain();
    useEffect(() => {
        if (clientId) {
            send('FETCH');
        }
    }, [clientId]);

    useEffect(() => {
        if (state.context.surveyResult) {
            onSubmit?.(state.context.surveyResult);
        }
    }, [state.context.surveyResult]);

    useEffect(() => {
        if (state.context.errors) {
            onError?.(state.context.errors);
        }
    }, [state.context.errors]);

    return (
        <>
            {(state.matches('fetchingSurvey') || state.matches('submittingSurvey')) &&
                state.hasTag('loading') && <Spinner alignSelf={'center'} justifySelf={'center'} />}
            {state.matches('surveyUnavailable') && <>Survey not available</>}
            {state.matches('selectScore') && (
                <NPSSlider
                    defaultScore={defaultScore}
                    onSubmit={(score) => {
                        send('SET_SCORE', { score });
                        send('SUBMIT_SCORE');
                    }}
                />
            )}

            {/* {(state.matches('selectReason') || state.matches('submittingSurvey')) && (
                <NPSReasons
                    category={state.context.customerCategory}
                    loading={state.hasTag('loading')}
                    description={
                        state.context.customerCategory === null
                            ? ''
                            : state.context.reasons[state.context.customerCategory].description
                    }
                    reasons={
                        state.context.customerCategory === null
                            ? []
                            : state.context.reasons[state.context.customerCategory].reasons
                    }
                    onSubmit={(selectedReasons) => {
                        send('UPDATE_REASONS', { selectedReasons });
                        send('SUBMIT_NPS');
                    }}
                />
            )}  */}
            {state.matches('surveySubmitted') && <Completed />}
        </>
    );
};

export default NPS;
