/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-console */
import { SubHeader, TextScript, useTextScript } from '@MGPD/myasurion-shared';
import { Box, Button, Card, CardBody, Center, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import TranslationsKeys from '../../Scripts/translationsKeys.json';
import MainStackLayout from '../../layout/MainStackLayout';
import { submitCleaningServices, updateCleaningServices } from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { CLEANING_SERVICE_ACTION, CLEANING_SERVICE_EDIT_FIELDS } from '../../utils/constant';
import { getFullAddress, getMDNFromAgreement, saveError, showErrorPage } from '../../utils/utills';
import CancelSrPopup from '../myclaims/CancelSrPopup';
import ServiceField from './ServiceField';

const CleaningReviewSubmission = () => {
    const navigate = useNavigate();
    const globalState = useCentralStore();

    const [address, setAddress] = useState('');
    // const [dateFormat, setDateFormat] = useState<string>('');
    const [openCancelPopup, setOpenCancelPopup] = useState(false);

    const { isTranslationsLoading, language } = useTextScript();

    const agreementData: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

    const interactionData = globalState?.state?.sessionResponse?.Interaction;

    const reviewcripts = Scripts?.ReviewSubmission;

    useEffect(() => {
        setAddress(getFullAddress(globalState?.state?.cleaningLogistics?.address));
    }, [globalState?.state?.cleaningLogistics]);

    useEffect(() => {
        if (!isTranslationsLoading) {
            // getDateFormat();
        }
    }, [language, isTranslationsLoading]);

    // const getDateFormat = async () => {
    //   const format = await getGlobalDateFormat();
    //   setDateFormat(format);
    // };

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    const getDeviceName = () => {
        const selectedModel = globalState?.state?.selectedDeviceModel;

        return selectedModel?.DeviceCategoryCN + ' ' + selectedModel?.DeviceTypeModel;
    };

    const onSubmit = () => {
        callUpdateCleaningServiceAPI();
    };

    const onCancelClick = () => {
        setOpenCancelPopup(!openCancelPopup);
    };

    const onBackClick = () => {
        navigate('/cleaningLogistics', { replace: true });
    };

    const isButtonDisabled = (): boolean => {
        return false;
    };

    const handleEditClick = (name: string) => {
        globalState?.dispatch({
            type: ActionTypes.SET_EDIT_CLEANING_LOGISTICS_FIELD,
            payload: name,
        });

        onBackClick();
    };

    const callUpdateCleaningServiceAPI = () => {
        const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;

        const selectedModel = globalState?.state?.selectedDeviceModel;
        const selectedDeviceDetails = globalState?.state?.selectedCleaningDeviceDetails;

        const cleaningIncidentData =
            globalState?.state?.cleaningIncidentData?.UpdateCleaningServiceRequest;
        const address = globalState?.state?.cleaningLogistics?.address;

        const UpdateCleaningServiceRequest = {
            UpdateCleaningServiceRequest: {
                Action: CLEANING_SERVICE_ACTION.ACTION_CREATE_SHIPPING,
                InteractionLineId: interactionLineId,
                AgreementId: agreementData?.AgreementId,
                ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
                CustomerId: agreementData?.Customers?.Customer[0]?.CustomerId,
                ClientChannelId: agreementData?.ClientAccount?.ClientChannelId,
                MobileDeviceNumber: getMDNFromAgreement(agreementData),
                SubscriptionNumber: getMDNFromAgreement(agreementData),
                Brand: selectedDeviceDetails?.brand,
                ModelNo: selectedDeviceDetails?.model,
                DeviceCategory: selectedModel?.DeviceCategory,
                AssetCatalogId: selectedModel?.AssetCatalogId,
                AssetId: cleaningIncidentData?.AssetId,
                ServiceRequestId: cleaningIncidentData?.ServiceRequest?.ServiceRequestId,
                CustomerCaseId: cleaningIncidentData?.ServiceRequest?.CustomerCaseId,
                AddressLine1: address?.Address1,
                AddressLine2: address?.Address2,
                AddressLine3: address?.Address3,
                CityName: address?.City,
                StateProvince: address?.StateProvinceCode,
                Country: address?.CountryCode,
                Postal: address?.PostalCode,
                AlternateContactNumber: globalState?.state?.alternateContactNumber,
                ServiceProviderId:
                    globalState?.state?.deviceCategories?.GetCleaningServiceDeviceDetails
                        ?.ServiceProviderId,
                ScheduleDate: globalState?.state?.cleaningLogistics?.date,
                TimeSlot: globalState?.state?.cleaningLogistics?.timeSlot,
                PreferredDay: globalState?.state?.cleaningLogistics?.day,
            },
        };

        setLoading(true);

        updateCleaningServices(
            UpdateCleaningServiceRequest,
            interactionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then(() => {
                setLoading(false);

                // for COD call submit request API and redirect to Summary page
                callSubmitCleaningServiceAPI();
                // redirect to invoice page
                // navigate('/cleaningInvoice', { replace: true });
            })
            .catch((error) => {
                setLoading(false);
                saveError(error, globalState);
                showErrorPage(navigate);
            });
    };

    const callSubmitCleaningServiceAPI = () => {
        const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;

        const selectedModel = globalState?.state?.selectedDeviceModel;
        const selectedDeviceDetails = globalState?.state?.selectedCleaningDeviceDetails;

        const cleaningIncidentData =
            globalState?.state?.cleaningIncidentData?.UpdateCleaningServiceRequest;
        const address = globalState?.state?.cleaningLogistics?.address;

        const SubmitCleaningServiceRequest = {
            SubmitCleaningServiceRequest: {
                AgreementId: agreementData?.AgreementId,
                ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
                CustomerId: agreementData?.Customers?.Customer[0]?.CustomerId,
                MobileDeviceNumber: getMDNFromAgreement(agreementData),
                Brand: selectedDeviceDetails?.brand,
                ModelNo: selectedDeviceDetails?.model,
                DeviceCategory: selectedModel?.DeviceCategory,
                ServiceRequestStatus: cleaningIncidentData?.ServiceRequest?.ServiceRequestStatus,
                DeviceTypeModelName: selectedModel?.DeviceTypeModel,
                DeviceTypeModelPrice: selectedModel?.DeviceTypeModelPrice?.toString(),
                ServiceRequestId: cleaningIncidentData?.ServiceRequest?.ServiceRequestId,
                CustomerCaseId: cleaningIncidentData?.ServiceRequest?.CustomerCaseId,
                AddressLine1: address?.Address1,
                AddressLine2: address?.Address2,
                AddressLine3: address?.Address3,
                CityName: address?.City,
                StateProvince: address?.StateProvinceCode,
                Country: address?.CountryCode,
                Postal: address?.PostalCode,
                AlternateContactNumber: globalState?.state?.alternateContactNumber,
                ScheduleDate: globalState?.state?.cleaningLogistics?.date,
                TimeSlot: globalState?.state?.cleaningLogistics?.timeSlot,
                PreferredDay: globalState?.state?.cleaningLogistics?.day,
                ServiceProviderId:
                    globalState?.state?.deviceCategories?.GetCleaningServiceDeviceDetails
                        ?.ServiceProviderId,
                isCOD: true,
                DiscountAmount: selectedModel?.DiscountPrice?.toString(),
                InteractionLineId: interactionLineId,
            },
        };

        setLoading(true);

        submitCleaningServices(
            SubmitCleaningServiceRequest,
            interactionLineId,
            globalState?.state?.findAgreementSessionData
        )
            .then(() => {
                setLoading(false);

                // for COD call submit request API and redirect to Summary page
                navigate('/cleaningSummary', { replace: true });
                // redirect to invoice page
                // navigate('/cleaningInvoice', { replace: true });
            })
            .catch((error) => {
                setLoading(false);
                saveError(error, globalState);
                showErrorPage(navigate);
            });
    };

    return (
        <Box paddingTop={'54px'} w="full">
            <MainStackLayout gap={0} spacing={0}>
                <Flex
                    w={{ base: 'full', lg: '80%' }}
                    margin="0 auto"
                    h={'100%'}
                    direction={'column'}
                >
                    <SubHeader
                        onClick={onBackClick}
                        backText={'Back'}
                        allowBackButtonClick={false}
                        showCancelButton={true}
                        showBackButton={true}
                        cancelText={Scripts.CancelSR.cancelBtnText}
                        onCancelClick={onCancelClick}
                    ></SubHeader>

                    <Box w={{ base: 'full', lg: '60%' }} mx={'auto'} px={4} mt={6}>
                        <Box p={2}>
                            <Text fontSize={{ base: '2xl', lg: '3xl' }}>
                                <TextScript
                                    text={reviewcripts?.Title}
                                    translationKey={`${TranslationsKeys.ReviewSubmissionPage.PageName}/title`}
                                />
                            </Text>
                        </Box>

                        <Card borderWidth="1px" borderColor={'#A5AAAF'} variant={'outline'}>
                            <CardBody bgColor={'#F0F0F5'} borderTopRadius={'base'} p={0}>
                                <Flex borderRadius={'full'} p={4}>
                                    <Center>
                                        <Text fontSize="xl">{getDeviceName()}</Text>
                                    </Center>
                                </Flex>
                            </CardBody>

                            <Box my={2} px={4}>
                                <Box mb={2}>
                                    <ServiceField
                                        name={CLEANING_SERVICE_EDIT_FIELDS.BRAND}
                                        value={
                                            globalState?.state?.selectedCleaningDeviceDetails?.brand
                                        }
                                        onEditClick={handleEditClick}
                                        text={Scripts.AddDevice.AddNewDevice.brand}
                                        pageName={
                                            TranslationsKeys.AddDevice.AddNewDevice
                                                .PageNameAnchorDevice
                                        }
                                        translationKey={
                                            TranslationsKeys.AddDevice.AddNewDevice.brand
                                        }
                                        showEditButton={false}
                                    ></ServiceField>
                                </Box>

                                <Box mb={2}>
                                    <ServiceField
                                        name={CLEANING_SERVICE_EDIT_FIELDS.MODEL}
                                        value={
                                            globalState?.state?.selectedCleaningDeviceDetails?.model
                                        }
                                        onEditClick={handleEditClick}
                                        text={Scripts.AddDevice.AddNewDevice.model}
                                        pageName={
                                            TranslationsKeys.AddDevice.AddNewDevice
                                                .PageNameAnchorDevice
                                        }
                                        translationKey={
                                            TranslationsKeys.AddDevice.AddNewDevice.model
                                        }
                                        showEditButton={false}
                                    ></ServiceField>
                                </Box>

                                <Box mb={2}>
                                    <ServiceField
                                        name={CLEANING_SERVICE_EDIT_FIELDS.ADDRESS}
                                        value={address}
                                        onEditClick={handleEditClick}
                                        text="Address"
                                        pageName="DeliveryDetails"
                                        translationKey="lblAddress"
                                        showEditButton={true}
                                    ></ServiceField>
                                </Box>

                                <Box mb={2}>
                                    <ServiceField
                                        name={CLEANING_SERVICE_EDIT_FIELDS.REGISTERED_NUMBER}
                                        value={globalState?.state?.cleaningLogistics?.mobile}
                                        onEditClick={handleEditClick}
                                        text={
                                            Scripts.CleaningService.CleaningLogistics
                                                .RegisteredNumber
                                        }
                                        pageName="DeliveryDetails"
                                        translationKey="lblRegisteredNumber"
                                        showEditButton={false}
                                    ></ServiceField>
                                </Box>

                                {globalState?.state?.cleaningLogistics?.alternateMobile && (
                                    <Box mb={2}>
                                        <ServiceField
                                            name={
                                                CLEANING_SERVICE_EDIT_FIELDS.ALTERNATE_CONTACT_NUMBER
                                            }
                                            value={
                                                globalState?.state?.cleaningLogistics
                                                    ?.alternateMobile || ''
                                            }
                                            onEditClick={handleEditClick}
                                            text={Scripts.Global.AlternateContactNumber}
                                            pageName={TranslationsKeys.Global.PageName}
                                            translationKey={
                                                TranslationsKeys.Global.AlternateContactNumber
                                            }
                                            showEditButton={true}
                                        ></ServiceField>
                                    </Box>
                                )}

                                {/* {dateFormat && (
                  <Box mb={2}>
                    <ServiceField
                      name={CLEANING_SERVICE_EDIT_FIELDS.DATE_TIME}
                      value={
                        getDateAndDay(globalState?.state?.cleaningLogistics?.date, dateFormat) || ''
                      }
                      onEditClick={handleEditClick}
                      text={Scripts.CleaningService.CleaningLogistics.PreferredDateLBL}
                      pageName={TranslationsKeys.CleaningService.CleaningLogistics.PageName}
                      translationKey={
                        TranslationsKeys.CleaningService.CleaningLogistics.PreferredDateLBL
                      }
                      showEditButton={true}
                    ></ServiceField>
                  </Box>
                )} */}

                                <Box mb={2}>
                                    <ServiceField
                                        name={CLEANING_SERVICE_EDIT_FIELDS.DATE_TIME}
                                        value={globalState?.state?.cleaningLogistics?.day}
                                        onEditClick={handleEditClick}
                                        text={
                                            Scripts.CleaningService.CleaningLogistics
                                                .PreferredDayLBL
                                        }
                                        pageName={
                                            TranslationsKeys.CleaningService.CleaningLogistics
                                                .PageName
                                        }
                                        translationKey={
                                            TranslationsKeys.CleaningService.CleaningLogistics
                                                .PreferredDayLBL
                                        }
                                        showEditButton={true}
                                    ></ServiceField>
                                </Box>

                                <Box mb={2}>
                                    <ServiceField
                                        name={CLEANING_SERVICE_EDIT_FIELDS.DATE_TIME}
                                        value={
                                            globalState?.state?.cleaningLogistics?.timeSlot || ''
                                        }
                                        onEditClick={handleEditClick}
                                        text={
                                            Scripts.CleaningService.CleaningLogistics
                                                .PreferredTimeLBL
                                        }
                                        pageName={
                                            TranslationsKeys.CleaningService.CleaningLogistics
                                                .PageName
                                        }
                                        translationKey={
                                            TranslationsKeys.CleaningService.CleaningLogistics
                                                .PreferredTimeLBL
                                        }
                                        showEditButton={true}
                                    ></ServiceField>
                                </Box>
                            </Box>
                        </Card>
                    </Box>

                    <Box
                        width={{ base: 'full', lg: '30%' }}
                        marginX={'auto'}
                        marginTop={'auto'}
                        height={'100%'}
                        alignContent={'flex-end'}
                        pb={{ base: '25%', lg: '5%' }}
                        pt={'5%'}
                        px={4}
                    >
                        <Button
                            width={'full'}
                            textAlign={'center'}
                            color="white"
                            colorScheme="primary"
                            isDisabled={isButtonDisabled()}
                            onClick={() => {
                                onSubmit();
                                return;
                            }}
                        >
                            <TextScript
                                text={Scripts.Global.Confirm}
                                translationKey={`${TranslationsKeys.Global.PageName}/${TranslationsKeys.Global.Confirm}`}
                            />
                        </Button>
                    </Box>
                </Flex>
            </MainStackLayout>
            <CancelSrPopup
                closeCancelPopup={setOpenCancelPopup}
                openCancelPopup={openCancelPopup}
                isVAS={true}
            />
        </Box>
    );
};

export default CleaningReviewSubmission;
