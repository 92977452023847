/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-console */
import {
    Box,
    BoxProps,
    Flex,
    Radio,
    RadioGroup,
    Stack,
    Text,
} from '@chakra-ui/react';
import { PropsWithChildren, useState } from 'react';

export interface StoreSelectionProps {
    ASPStoreList?: {
        RepairStoreCode: string;
        RepairStoreName: string;
        AddressLine1: string;
        City: string;
        PostalCode: string;
        State: string;
        Country: string;
        AssetMake: string;
        ServiceProviderId: string;
    }[];
    onSelect: (e: any) => void;
}

export const StoreSelection: React.FC<PropsWithChildren & BoxProps & StoreSelectionProps> = ({
    ASPStoreList,
    onSelect
}) => {
    const [value, setValue] = useState();
    const [selected, setSelected] = useState(false);

    const handleStoreSelection = (e: any) => {
        // setValue(e)
        setSelected(e.target.value);
        onSelect(e.target.value);
    };
    return (
        <Stack>
            <RadioGroup value={value} onChange={(value: any) => setValue(value)}>
                {ASPStoreList &&
                    ASPStoreList?.map((address, index) => {
                        return (
                            <Flex
                                key={index}
                                borderWidth="1px"
                                borderRadius="lg"
                                justifyContent={'Start'}
                                p={4}
                                alignItems={'baseline'}
                                mb={4}
                                style={{
                                    border: selected ? '1px solid black' : '1px solid #A5AAAF',
                                    borderRadius: '8px',
                                }}
                            >
                                {/* <ChakraProvider theme={theme}> */}
                                <Radio
                                    name={address?.RepairStoreName}
                                    p={4}
                                    // colorScheme="blackAlpha"
                                    value={address.ServiceProviderId}
                                    onChange={(e) => handleStoreSelection(e)}
                                ></Radio>
                                {/* </ChakraProvider> */}
                                <Box>
                                    <Text fontWeight={300} fontSize="20px">
                                        {address?.RepairStoreName}
                                    </Text>
                                    <Text
                                        fontWeight={400}
                                        fontSize="14px"
                                    >{`${address.AddressLine1} ${address.City} ${address.State} ${address.Country} , ${address.PostalCode}`}</Text>
                                </Box>
                            </Flex>
                        );
                    })}
            </RadioGroup>
        </Stack>
    );
};

export default StoreSelection;