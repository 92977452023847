import { TextScript, useConfig, useSessionStorage } from '@MGPD/myasurion-shared';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { generateLabelKey } from '../../modules/config/constants';
import translationsKeys from '../../Scripts/translationsKeys.json'
import Scripts from '../../Scripts/asurion-script.json';
import { ChakraProvider } from '@chakra-ui/react';
import PDFViewer from './labelViewer';

function GenerateLabel() {
  const [generateLabelData] = useSessionStorage<any>(generateLabelKey, null);
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { primary } = colors;

  const navigate = useNavigate();
  const pdfLabelBase64 = generateLabelData?.GenerateEParcellabelResponse?.PdfLabelBase64

  return (
    <>
      <Box>
        <Box py='3' mt='5' px={{ base: 3, lg: 0 }}>
          <Text fontSize={'20px'}>
            Thank you for raising a Service Request with Upgrade, please send in your device to us.
          </Text>
          <Text fontSize={'16px'} paddingTop={'16px'}>Please see your eLabel below, print this label, or take an electronic copy, to your nearest AusPost outlet. You will be given packaging to send your device to us at no charge.
          </Text>

          <Box>
            <Flex>
              <ChakraProvider>
                <PDFViewer
                  pdfLabelBase64={pdfLabelBase64} />
              </ChakraProvider>
            </Flex>
          </Box>
          <Box w={{ base: 'full', lg: '50%' }} m="auto">
            <Button
              textAlign={'center'}
              onClick={() => navigate('/claimconfirmation', { replace: true })}
              colorScheme={'primary'}
              p={'12px 20px'}
              width={'full'}
              px={{ base: '20px', lg: '60px' }}
              mt={{ base: '16px', lg: '16px' }}
              style={{
                background: primary,
                color: 'white',
              }}
            >
              <TextScript
                text={Scripts.Global.Next}
                translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Next}`}
              />
            </Button>
          </Box>
        </Box>
        <Box>
        </Box>
      </Box>



    </>
  )


}

export default GenerateLabel;