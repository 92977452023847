import { useConfig } from '@MGPD/myasurion-shared';
import { TextScript } from '@MGPD/myasurion-shared';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
    Box,
    BoxProps,
    Button,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Radio,
    RadioGroup,
    Text,
} from '@chakra-ui/react';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import SmartStockScript from '../Scripts/asurion-script.json';
import SmartStockScriptKeys from '../Scripts/translationsKeys.json';

export interface SortOptions {
    name: string;
    value: string;
}

export interface SmartStockProps {
    SortOptions: Array<SortOptions>;
    sidebarBody: React.ReactNode;
    getSelectedOption: (args: string) => void;
}

export const SmartStockContainer: React.FC<PropsWithChildren<BoxProps & SmartStockProps>> = ({
    SortOptions,
    getSelectedOption,
}) => {
    const [sortedOptions, setSortedOptions] = useState('Best selling');

    const renderIcon = (isOpen = false) => {
        return isOpen ? (
            <ChevronUpIcon h={'6'} w={'6'} color={primary} />
        ) : (
            <ChevronDownIcon h={'6'} w={'6'} color={primary} />
        );
    };

    useEffect(() => {
        getSelectedOption(sortedOptions);
    }, [sortedOptions]);

    useEffect(() => {
        getSelectedOption(sortedOptions);
    }, [sortedOptions]);

    //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { primary, accent } = colors;

    return (
        <Flex direction={'row'}>
            <Box bg={'#F0F0F5'}>
                <center>
                    <Text fontSize={'24px'} fontWeight={'700'} pt={'36px'}>
                        <TextScript
                            text={SmartStockScript?.SmartStock.ChooseDiffPhone}
                            translationKey={`${SmartStockScriptKeys.SmartStock.PageName}/${SmartStockScriptKeys.SmartStock.ChooseDiffPhone}`}
                        />
                    </Text>
                    <Text fontWeight={'300'} paddingX={'6px'}>
                        <TextScript
                            text={SmartStockScript?.SmartStock.Description}
                            translationKey={`${SmartStockScriptKeys.SmartStock.PageName}/${SmartStockScriptKeys.SmartStock.Description}`}
                        />
                    </Text>
                    <Box
                        display={'flex'}
                        justifyContent={'space-evenly'}
                        pt={'16px'}
                        pb={'24px'}
                        w="full"
                    >
                        <Menu>
                            {({ isOpen }) => (
                                <>
                                    <MenuButton
                                        as={Button}
                                        borderRadius={'8px'}
                                        rightIcon={renderIcon(isOpen)}
                                        variant={'outline'}
                                        // colorScheme="purple"
                                        color={primary}
                                        w="80%"
                                        // paddingRight={'24px'}
                                        // iconSpacing={'-0.5'}
                                    >
                                        <Text
                                            noOfLines={3}
                                            paddingTop={'2px'}
                                            fontSize={'14px'}
                                            width={'full'}
                                            color={accent}
                                        >
                                            {/* {sortedOptions} */}
                                            <TextScript
                                                text={sortedOptions}
                                                translationKey={`${SmartStockScriptKeys.SmartStock.PageName}/${sortedOptions}`}
                                            />
                                        </Text>
                                    </MenuButton>
                                    {/* <ChakraProvider theme={theme}> */}
                                    <MenuList
                                        zIndex={3}
                                        border="1px solid black"
                                        borderRadius="8px"
                                        py="0.5rem"
                                        // maxW={'full'}
                                        background={'white'}
                                        w="full"
                                    >
                                        <Flex
                                            justifyContent={'space-between'}
                                            py={'0.25rem'}
                                            px={'1rem'}
                                            borderBottom={'1px solid black'}
                                        >
                                            <Text
                                                display={'inline-block'}
                                                fontWeight={'bold'}
                                                color={accent}
                                                w="full"
                                            >
                                                {/* {sortedOptions} */}
                                                <TextScript
                                                    text={sortedOptions}
                                                    translationKey={`${SmartStockScriptKeys.SmartStock.PageName}/${sortedOptions}`}
                                                />
                                            </Text>
                                            <span>{<ChevronUpIcon w={6} h={6} />}</span>
                                        </Flex>
                                        <RadioGroup
                                            onChange={setSortedOptions}
                                            value={sortedOptions}
                                            w="full"
                                        >
                                            {SortOptions.map((option, index) => (
                                                <MenuItem key={index} w="full">
                                                    <Radio value={option.name}>
                                                        <TextScript
                                                            text={option.name}
                                                            translationKey={`${SmartStockScriptKeys.SmartStock.PageName}/${option.name}`}
                                                        />
                                                    </Radio>
                                                </MenuItem>
                                            ))}
                                        </RadioGroup>
                                    </MenuList>
                                    {/* </ChakraProvider> */}
                                </>
                            )}
                        </Menu>
                    </Box>
                </center>
            </Box>
        </Flex>
    );
};

export default SmartStockContainer;
