import Scripts from '../Scripts/asurion-script.json';
import { useCentralStore } from '../store/appContext';
import { Tier } from '../types/types';
import { hasMultiDeviceSupport } from '../utils/featuresUtills';
import { Box, Center, Flex, Radio, Text, VStack, useMediaQuery } from '@chakra-ui/react';
import _ from '../utils/lodashUtils.ts';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import translationsKeys from '../Scripts/translationsKeys.json';
import JBDefaultPageLayout from './JBDefaultPageLayout';
import { useSessionStorage } from '@MGPD/myasurion-shared';
import { FulfillmentOptionKey } from '../modules/config/constants.ts';

const mockTermsStatement =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.';

enum DeviceCategory {
  LAPTOP = 'LAPTOP',
  MOBILE = 'MOBILE',
}

type TransformedTier = Tier & { id: string; label: string; description: string };

const transformTiers = (tiers: Tier[] = [], deviceCategory: string = ''): TransformedTier[] => {
  // Filter Tiers based on Device Category: This is JB Hard-Coded Logic for now, will be removed once we get the actual data
  if (tiers.length > 3) {
    tiers = deviceCategory === DeviceCategory.LAPTOP ? tiers.slice(0, 4) : tiers.slice(4, 8);
  }

  return tiers.map((tier, index) => {
    return {
      id: tier.TierId,
      label: `Tier ${index + 1}`,
      description: mockTermsStatement,
      ...tier,
    };
  });
};

interface TierOptionsProps {
  id: string;
  label: string;
  description: string;
  selectedTier: string | null;
  handleTierChange: (value: string) => void;
}

const TierOptions: React.FC<TierOptionsProps> = ({
  id,
  label,
  description,
  selectedTier,
  handleTierChange,
}) => {
  return (
    <Box
      className={`termsRadioButton${id}`}
      p="4"
      borderRadius="md"
      display="flex"
      alignItems="flex-start"
      borderColor={selectedTier === id ? 'black' : 'gray.300'}
      borderWidth={selectedTier === id ? '2px' : '1px'}
      onClick={() => {
        handleTierChange(id);
      }}
      sx={{ marginTop: "2px" }}
    >
      <Radio
        value={id}
        isChecked={selectedTier === id}
        onChange={(e:any) => {
          // handleTierChange(id);
          e.preventDefault();
          e.stopPropagation();
        }}
        size={'lg'}
        colorScheme="gray"
      />
      <Box>
        <Text ml="4" fontWeight="bold">
          {label}
        </Text>
        <Text ml="4">{description}</Text>
      </Box>
    </Box>
  );
};

const LowBalancePage: React.FC = () => {
  // Media Query
  const [isDesktop] = useMediaQuery('(min-width: 850px)');

  // Global State
  const navigate = useNavigate();
  const globalState = useCentralStore();
  const agreementData1 =
    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  const assetData = agreementData1?.Assets.Asset.filter(
    (a: any) => a.AssetInstance === 'ENROLLED'
  )[0];
  const MobileDeviceNumber = assetData && assetData?.MobileDeviceNumber?.split('_')?.[0];
  const programmeNameLBL = '';
  const LowBalancePageKeys = translationsKeys.LowBalancePage;
  // Avalable Tiers
  const availableTiers = globalState?.state?.checkBalanceData?.AvailableTiers;

  // States
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [selectedTier, setSelectedTier] = useState<string | null>(null);

  // Handlers
  const handleTierChange = (value: string) => {
    setSelectedTier(value);
  };

  // Temp Logic for JB Reset, will be removed with above mockTermsStatement
  const [, setFulfillmentOption] = useSessionStorage<string>(FulfillmentOptionKey, '')
  useEffect(() => {setFulfillmentOption('')}, []);

  // Device Category : This is JB Hard-Coded Logic for now, will be removed once we get the actual data
  const deviceCategory =
    !hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName) &&
    assetData?.Make?.Name?.toUpperCase() === 'MICROSOFT'
      ? DeviceCategory.LAPTOP
      : DeviceCategory.MOBILE;

  return (
    <JBDefaultPageLayout
      headerText={Scripts.HowCanWeHelpYou.HeaderText}
      headerTranslationKey={`${LowBalancePageKeys.PageName}/${LowBalancePageKeys.HeaderText}`}
      onCancelClick={() => setOpenCancelPopup(!openCancelPopup)}
      showDeviceCard={true}
      assetId={assetData && assetData?.AssetId.toString()}
      programmeNameLBL={programmeNameLBL || agreementData1?.ClientOffer?.ClientOfferName}
      clientOfferName={agreementData1?.ClientOffer?.ClientOfferName}
      countryCallingCode={
        assetData?.CountryCallingCode?.split('+')?.[1] ||
        agreementData1?.ContactPoints?.ContactPoint?.[0]?.CountryCallingCode
      }
      disableNextButton={_.isEmpty(selectedTier)}
      mobileDeviceNumber={MobileDeviceNumber}
      assetCatalogName={assetData?.AssetCatalog.AssetCatalogName}
      imei={assetData?.IMEI}
      assetFamily={assetData?.AssetCatalog.AssetFamily}
      onNextClick={() => {
        if (_.isNotEmpty(selectedTier)) {
          return navigate('../verifyprovider', { replace: true });
        }
      }}
    >
      <Center>
        <Flex
          w={isDesktop ? '50%' : '90%'}
          m="0"
          gap={'16px'}
          direction="column"
          wrap="wrap"
          justifyContent="center"
          alignContent="center"
          id={'myasurion-low-balance-3'}
          h={'auto'}
        >
          <Box
            className={'upgradeTierHeader'}
            w={{ base: 'full', lg: '100%' }}
            margin={{ base: '0', lg: '0' }}
            pb={{ base: '0', lg: '0' }}
          >
            <Text fontSize={'24px'}>What upgrade tier would you like?</Text>
          </Box>
          <VStack spacing="2">
            {transformTiers(availableTiers, deviceCategory).map((currentTier) => (
              <TierOptions
                key={currentTier.id}
                id={currentTier.id}
                label={currentTier.label}
                description={currentTier.description}
                selectedTier={selectedTier}
                handleTierChange={handleTierChange}
              />
            ))}
          </VStack>
          <Text size={'16px'}>
            If you want to learn more about these tiers,{' '}
            <button
              style={{ textDecoration: 'underline' }}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              click here
            </button>
            .
          </Text>
        </Flex>
      </Center>
    </JBDefaultPageLayout>
  );
};

export default LowBalancePage;
