const AlertIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.31812 13.6819 3.33335 10 3.33335C6.3181 3.33335 3.33333 6.31812 3.33333 10C3.33333 13.6819 6.3181 16.6667 10 16.6667ZM10 18.3334C14.6024 18.3334 18.3333 14.6024 18.3333 10C18.3333 5.39765 14.6024 1.66669 10 1.66669C5.39763 1.66669 1.66667 5.39765 1.66667 10C1.66667 14.6024 5.39763 18.3334 10 18.3334ZM9.16667 12.5V5.00002H10.8333V12.5H9.16667ZM10 15C10.4602 15 10.8333 14.6269 10.8333 14.1667C10.8333 13.7064 10.4602 13.3334 10 13.3334C9.53976 13.3334 9.16667 13.7064 9.16667 14.1667C9.16667 14.6269 9.53976 15 10 15Z"
            fill="#DB1B1B"
        />
    </svg>
);
export default AlertIcon;
