/* eslint-disable no-console */
import { SubHeader, TextScript, getTextScript, useTextScript } from '@MGPD/myasurion-shared';
import { Box, Button, Checkbox, Flex, Skeleton, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import Translationkeys from '../../Scripts/translationsKeys.json';
import MainStackLayout from '../../layout/MainStackLayout';
import { useCentralStore } from '../../store/appContext';

function CleaningTermsAndConditions() {
    const globalState = useCentralStore();

    const navigate = useNavigate();
    const { isTranslationsLoading, language } = useTextScript();

    const [isChecked, setIsChecked] = useState(false);
    const [CleaningTerms, setCleaningTerms] = useState('');
    // const [currencySymbol, setCurrencySymbol] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

    useEffect(() => {
        // getCurrency();
        getCleaningTermsText();
    }, [language, isTranslationsLoading]);

    useEffect(() => {
        if (agreementData1) {
            setIsLoading(false);
        }
    }, [agreementData1]);

    const getCleaningTermsText = async () => {
        // show terms based on selectd category
        // const isAC = globalState?.state?.selectedDeviceCategory?.categoryName === 'Air conditioner'

        // const key = isAC ? Translationkeys.CleaningService.Terms.TermsAC : Translationkeys.CleaningService.Terms.TermsWM;
        // const value = isAC ? Scripts.CleaningService.Terms.TermsAC : Scripts.CleaningService.Terms.TermsWM;

        const CleaningTermsText = await getTextScript(
            `${Translationkeys.CleaningService.Terms.PageName}/${Translationkeys.CleaningService.Terms.Terms}`,
            Scripts.CleaningService.Terms.Terms,
            language,
            []
        );
        setCleaningTerms(CleaningTermsText);
    };

    // const getCurrency = async () => {
    //   const currency = await getCurrencySymbol();
    //   setCurrencySymbol(currency);
    // };

    const onSubmit = () => {
        navigate('/selectDeviceCateogry', { replace: true });
    };

    const onCancelClick = () => {
        window.history.back();
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
        // onchange(e.target.checked);
    };

    return (
        <>
            <Skeleton isLoaded={!isLoading}>
                <Box paddingTop={'54px'} w="full">
                    <MainStackLayout gap={0} spacing={0}>
                        <Flex
                            w={{ base: 'full', lg: '80%' }}
                            margin="0 auto"
                            h={'100%'}
                            direction={'column'}
                        >
                            <SubHeader
                                backText={'Back'}
                                allowBackButtonClick={true}
                                showCancelButton={false}
                                showBackButton={true}
                                cancelText={Scripts.CancelSR.cancelBtnText}
                                onCancelClick={onCancelClick}
                            ></SubHeader>

                            <Box w={{ base: 'full', lg: '60%' }} mx={'auto'} px={4}>
                                <Box margin={{ base: '0', lg: 'auto' }} pb={{ base: '0', lg: '0' }}>
                                    <Box marginTop={{ base: '24px', lg: '0px' }}>
                                        <Text fontSize={{ base: '2xl', lg: '3xl' }}>
                                            <TextScript
                                                text={Scripts.CleaningService.Terms.HeaderText}
                                                translationKey={`${Translationkeys.CleaningService.Terms.PageName}/${Translationkeys.CleaningService.Terms.HeaderText}`}
                                            ></TextScript>
                                        </Text>
                                    </Box>
                                    <Box marginTop={'24px'} mx={'10px'}>
                                        {/* <Text>
                    <TextScript
                      text={Scripts.CleaningService.Terms.TermsTitle}
                      translationKey={`${Translationkeys.CleaningService.Terms.PageName}/${Translationkeys.CleaningService.Terms.TermsTitle}`}
                    ></TextScript>
                  </Text> */}
                                        <Box marginTop={'10px'}>
                                            {CleaningTerms && (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: CleaningTerms,
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                width={{ base: 'full', lg: '60%' }}
                                marginX={'auto'}
                                marginTop={'auto'}
                                height={'100%'}
                                alignContent={'flex-end'}
                                mb={{ base: '25%', lg: '2rem' }}
                                px={4}
                            >
                                <Flex my={'1rem'} mx={'auto'} justifyContent={'start'}>
                                    <Checkbox
                                        alignItems={'flex-start'}
                                        size="lg"
                                        onChange={handleCheckboxChange}
                                        colorScheme="primary"
                                        border={'#A5AAAF'}
                                    ></Checkbox>
                                    <Text marginLeft={'8px'} size={'16px'}>
                                        <TextScript
                                            text={Scripts.CleaningService.Terms.AcceptText}
                                            translationKey={`${Translationkeys.TermsAndConditionsPage.PageName}/${Translationkeys.TermsAndConditionsPage.AcceptText}`}
                                            dynamicValues={['']}
                                        ></TextScript>
                                    </Text>
                                </Flex>

                                {/* <Box>
                <TextScript
                  text={Scripts.CleaningService.Terms.SRF}
                  translationKey={`${Translationkeys.CleaningService.Terms.PageName}/${Translationkeys.CleaningService.Terms.SRF}`}
                ></TextScript>
                {currencySymbol && (
                  <Text as={'b'}>
                    {' '}
                    {currencySymbol}
                    {globalState?.state?.selectedDeviceModel?.PricewithDiscount}
                  </Text>
                )}
              </Box> */}

                                <Box width="full" marginX={'auto'} mb={'24px'} textAlign={'center'}>
                                    <Button
                                        isDisabled={!isChecked}
                                        color="white"
                                        colorScheme="primary"
                                        width={{ base: 'full', lg: '50%' }}
                                        mt={'1rem'}
                                        onClick={onSubmit}
                                        justifyContent={'center'}
                                    >
                                        <TextScript
                                            text={'Next'}
                                            translationKey={`${Translationkeys.Global.PageName}/${Translationkeys.Global.Next}`}
                                        />
                                    </Button>
                                </Box>
                            </Box>
                        </Flex>
                    </MainStackLayout>
                </Box>
            </Skeleton>
        </>
    );
}

export default CleaningTermsAndConditions;
