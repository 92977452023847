/* eslint-disable no-console */
import { Renewal, getTypeByDomain } from '@MGPD/myasurion-shared';
import { getTextScript } from '@MGPD/myasurion-shared/components/localization';
import { Box, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import MainStackLayout from '../../layout/MainStackLayout';
import '../../services/api';
import { getQuote } from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { getRedirectUrl, saveError } from '../../utils/utills';

function ImeiDetail() {
    const RenewalScrips = Scripts.Renewal.Renewal;
    const globalState = useCentralStore();
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState<any>('');
    const [renewOptions, setRenewOptions] = useState<any>(undefined);
    const [getQuoteDetails, setGetQuoteDetails] = useState<any>(undefined);
    const [dateDiff, setDateDiff] = useState<any>(undefined);

    const agreementData1: any =
        globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
    const interactionData = globalState?.state?.sessionResponse?.Interaction;
    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;

    const handleOptionValue = (e: any) => {
        setSelectedOption(e);
        globalState?.dispatch({ type: ActionTypes.SET_RENEWAL_OPTION, payload: e });
    };

    const setLoading = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
    };

    const isDisabled = selectedOption !== '' ? false : true;

    const getTitleText = async (value: string) => {
        const val = await getTextScript(`Renewal/title1`, RenewalScrips.title, 'en-US', [value]);
        setDateDiff(val);
    };

    useEffect(() => {
        if (agreementData1 !== undefined) {
            // let assetData1: any = agreementData1?.Assets.Asset.filter(
            //     (a: any) => a.AssetInstance === 'ENROLLED'
            //   )[0];

            let assetData1 = agreementData1?.Assets?.Asset?.filter(
                (asset: any) => asset?.AssetInstance === 'ENROLLED'
            )?.[0];
            if (assetData1 === undefined) {
                assetData1 = agreementData1?.Assets?.Asset?.filter(
                    (asset: any) => asset?.AssetInstance === 'REPLACED'
                )?.[0];
            }

            callGetQuoteApi(assetData1?.ItemId);

            // if(agreementData1?.ClientAccountBillingCycle?.toUpperCase() === 'YEARLY') {
            getTitleText(
                moment(agreementData1?.EndDate).diff(moment(Date.now()), 'days')?.toString()
            );
            // }
        }
    }, [agreementData1]);

    const callGetQuoteApi = (baseItemId: any) => {
        setLoading(true);
        const payload = {
            GetQuoteRequest: {
                BaseItemId: baseItemId, //S830-0411-IPH125G256RED
            },
        };
        getQuote(payload, interactionLineId, globalState?.state?.findAgreementSessionData)
            .then((resp) => {
                setLoading(false);
                setGetQuoteDetails(resp?.plans);
                globalState?.dispatch({
                    type: ActionTypes.SET_RENEWAL_GETQUOTE_DETAILS,
                    payload: resp?.plans,
                });
            })
            .catch((err) => {
                setLoading(false);
                saveError(err, globalState);
            });
    };
    const [saveLBL, setSaveLBL] = useState<string>('');
    const [offLBL, setoffLBL] = useState<string>('');

    const getSaveLBL = async () => {
        const returnedText = await getTextScript(
            `${RenewalScrips.PageName}/${RenewalScrips.saveLBL}`,
            RenewalScrips.saveLBL
        );
        setSaveLBL(returnedText);
    };

    const getOffLBL = async () => {
        const returnedText = await getTextScript(
            `${RenewalScrips.PageName}/${RenewalScrips.offLBL}`,
            RenewalScrips.offLBL
        );
        setoffLBL(returnedText);
    };

    useEffect(() => {
        const _monthlyDiscount = getQuoteDetails?.[
            'TechCare_SwapOnly_Monthly'
        ]?.PromotionalOffer?.map((v: any) => {
            return v?.discountValue;
        });
        globalState?.dispatch({
            type: ActionTypes.SET_RENEWAL_MONTHLY_DISCOUNT,
            payload: _monthlyDiscount,
        });
        const monthlyDiscount = _monthlyDiscount
            ?.map((v: any) => {
                return v + '%';
            })
            ?.toString()
            ?.replace(',', ' + ');

        const _annualDiscount = getQuoteDetails?.[
            'TechCare_SwapOnly_12Month'
        ]?.PromotionalOffer?.map((v: any) => {
            return v?.discountValue;
        });
        globalState?.dispatch({
            type: ActionTypes.SET_RENEWAL_ANNUAL_DISCOUNT,
            payload: _annualDiscount,
        });
        const annualDiscount = _annualDiscount
            ?.map((v: any) => {
                return v + '%';
            })
            ?.toString()
            ?.replace(',', ' + ');

        setRenewOptions([
            {
                name: 'Renew monthly payment',
                discount:
                    monthlyDiscount !== '' ? saveLBL + ' ' + monthlyDiscount + ' ' + offLBL : '',
            },
            {
                name: 'Renew term payment',
                discount:
                    annualDiscount !== '' ? saveLBL + ' ' + annualDiscount + ' ' + offLBL : '',
            },
        ]);
    }, [getQuoteDetails]);

    useEffect(() => {
        // setLoading(true)
        getSaveLBL();
        getOffLBL();
    }, []);
    // useEffect(() => {
    //     dateDiff ? setLoading(false) : setLoading(true)
    // }, [dateDiff])

    return (
        <Box>
            <Box
                w={{ base: 'full', lg: '80%' }}
                margin="auto"
                // pb={{ base: 0, lg: '150px' }}
                id={'myasurion-profile-dashboard'}
                paddingTop={'54px'}
            >
                <MainStackLayout gap={0} spacing={0}>
                    <Box
                        id={'myasurion-profile-avatar'}
                        w={{ base: 'full', lg: '80%' }}
                        margin="0 auto"
                        h={'auto'}
                        p={'10px'}
                    >
                        <Text px="4" mt="2" mb="-15px" fontWeight={'700'}>
                            {dateDiff}
                        </Text>
                        <Renewal
                            pageTitle={''}
                            title={''}
                            heading={RenewalScrips.heading}
                            doNotRenewText={RenewalScrips.doNotRenewText}
                            renewOptions={renewOptions && renewOptions}
                            handleOptionChange={(optn: any) => handleOptionValue(optn)}
                            selectedOption={selectedOption}
                            isDisabled={isDisabled}
                            btnSubmitLBL={RenewalScrips.btnSubmitLBL}
                            onClickSubmit={() => navigate('/renewalConfirmation')}
                            handleDonotRenew={() =>
                                (window.location.href = getRedirectUrl(getTypeByDomain()))
                            }
                        />
                    </Box>
                </MainStackLayout>
            </Box>
        </Box>
    );
}

export default ImeiDetail;
