import { NPSReasons } from '@MGPD/myasurion-shared';
import { Box, Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import MainStackLayout from '../../layout/MainStackLayout';

function NpsReasons() {
    const navigate = useNavigate();
    return (
        <Center>
            <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'} w="full">
                <MainStackLayout gap={0} spacing={0}>
                    <Box
                        w={{ base: 'full', lg: '80%' }}
                        marginX={{ base: '', lg: 'auto' }}
                        mb={{ base: '0', lg: '20px' }}
                    >
                        <NPSReasons
                            category="PASSIVE"
                            onSubmit={() => {
                                navigate('/thanksmessage', { replace: true });
                            }}
                            description=""
                            reasons={[]}
                        ></NPSReasons>
                    </Box>
                </MainStackLayout>
            </Box>
        </Center>
    );
}

export default NpsReasons;
