import {
    SubHeader,
    TextInput,
    TextScript,
    useConfig,
    useSessionStorage,
} from '@MGPD/myasurion-shared';
import { CalendarIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Select,
    Text,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import laptopimg from '../../ClaimComponents/icons/laptop_big.svg';
import { deviceRegDataKey } from '../../modules/config/constants';
import FindSerialNoPopup from '../../ClaimComponents/pages/AddDevice/FindSerialNoModal';
import { useCentralStore } from '../../ClaimComponents/store/appContext';
import ActionTypes from '../../ClaimComponents/store/actionTypes';

const DeviceRegistrationInfo = () => {
    const globalState = useCentralStore();
    
    const navigate = useNavigate();

    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isEditable, setIsEditable] = useState(true);
    const [makelist, setMakelist] = useState<string[]>([]);
    const [formData, setFormData] = useState({
        purchaseDate: '',
        serialNumber: '',
        purchasePrice: '',
        brand: '',
        model: '',
        warrantyPeriod: '',
    });
    const [, setFormDataSession] = useSessionStorage<any>(deviceRegDataKey, '');

    const [errors, setErrors] = useState({
        isSrNoError: false,
        purchasePriceError: false,
    });

    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;

    type FormData = {
        [key: string]: string;
    };

    const checkIfAnyFieldIsEmpty = (formData: FormData): boolean => {
        return Object.values(formData).some((value) => value.trim() === '');
    };

    const handleSubmit = () => {
        console.log('formData =', formData);
        setFormDataSession(formData);
        navigate('/upload-receipt');

        // reset error data
        if(globalState?.state?.errorData){
            globalState?.dispatch({
                type: ActionTypes.SET_ERROR_DATA,
                payload: {},
            });
        }
    };

    const onClickPopup = () => {
        setOpenCancelPopup(!openCancelPopup);
    };
    const onCancelClick = () => {};

    const onBackClick = () => {
        window.history.back();
    };

    useEffect(() => {
        const device = globalState?.state?.selectedEnrolledDevice;
        if (device) {
            
            // scenario
            // if there is make available and serial number is empty 
            // then preload all values and disable the fields except serial number

            if (device?.make?.toUpperCase() !== "UNKNOWN" && !device?.imei) {

                // set purchase date
                formData.brand = device?.make;
                formData.model = device?.model;
                formData.purchaseDate = device?.startDate?.split('T')?.[0];
                formData.purchasePrice = device?.PurchasePrice?.toString();
                formData.warrantyPeriod = `${device?.IwPeriod} 年`;

                setIsEditable(false);
                
            } else {
                // set purchase date
                formData.purchaseDate = device?.startDate?.split('T')?.[0];
            }

             // set brand list
             if (!makelist?.length && device) {
                if (device?.clientProductSkuNbr?.includes('WIN')) {
                    setMakelist(['Asus', 'Acer', 'Lenovo', 'HP', 'Dell', 'MSI']);
                } else setMakelist(['Apple']);
            }

            setFormData(formData);
        }
    }, [globalState?.state?.selectedEnrolledDevice]);

    useEffect(() => {
        const isEmptyField = checkIfAnyFieldIsEmpty(formData);
        const hasErrors = Object.values(errors).some((error) => error === true);
        setIsDisabled(isEmptyField || hasErrors);
    }, [formData]);

    useEffect(() => {
        if (globalState?.state?.errorData && globalState?.state?.errorData === 'Active agreement exists with Imei') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                isSrNoError: true,
            }));
        }
      }, [globalState?.state?.errorData]);

    const handleFormChange = (e: any) => {
        const field = e.id ? e : e.target;
        const { name, value } = field;
        setFormData((prevFormData) => ({ ...prevFormData, [name ? name : e.id]: value }));
        if (e.id === 'serialNumber') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                isSrNoError: !/^[a-zA-Z0-9-_#]*$/.test(value),
            }));
        } else if (e.id === 'purchasePrice') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                purchasePriceError: !/^[0-9]*\.?[0-9]{0,2}$/.test(value),
            }));
        }
        setIsDisabled(checkIfAnyFieldIsEmpty({ ...formData, [name]: value }));
    };

    const inputRef = useRef<HTMLInputElement>(null);
    const showPicker = () => {
        inputRef?.current?.showPicker();
    };

    return (
        <>
            <Box>
                <Box w={{ base: 'full', lg: '80%' }} margin="auto" pt={'50px'}>
                    <SubHeader
                        backText={'back'}
                        allowBackButtonClick={false}
                        showCancelButton={false}
                        showBackButton={false}
                        cancelText={'Cancel'}
                        onCancelClick={onCancelClick}
                        onClick={onBackClick}
                    ></SubHeader>
                    <Box
                        id={'myasurion-profile-avatar'}
                        w={{ base: 'full', lg: '80%' }}
                        margin="0 auto"
                        h={'auto'}
                        p={'1rem'}
                    >
                        <Image my={'2'} marginX={'auto'} src={laptopimg} alt="your model" />
                        <Text mt={'5'} fontSize={{ base: '3xl', lg: '4xl' }}>
                            <TextScript
                                text={'Tell us about your device'}
                                translationKey={`DeviceRegistration/header`}
                            />
                        </Text>

                        <Box mt={'4'}>
                            <Flex direction={{ base: 'column', lg: 'row' }} gap={4}>
                                <Box flex="1">
                                    <FormControl isRequired>
                                        <FormLabel htmlFor="brand" mb={1}>
                                            <TextScript
                                                text={'Brand'}
                                                translationKey={`DeviceRegistration/lblBrand`}
                                            />
                                        </FormLabel>
                                        <Select
                                            id="brand"
                                            name="brand"
                                            size={'lg'}
                                            borderColor={'#A5AAAF'}
                                            bg={!isEditable ? '#cfd1d4' : ''}
                                            placeholder={'品牌'}
                                            height={{ base: '60px', lg: '55px' }}
                                            onChange={handleFormChange}
                                            value={formData.brand}
                                            isDisabled={!isEditable}
                                        >
                                            {makelist?.map((item) => (
                                                <option>{item}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box flex="1" mt={{ base: 2, lg: 0 }}>
                                    <FormControl isRequired>
                                        <FormLabel htmlFor="model" mb={1}>
                                            <TextScript
                                                text={'Model'}
                                                translationKey={`DeviceRegistration/lblModel`}
                                            />
                                        </FormLabel>
                                        <TextInput
                                            id="model"
                                            name="model"
                                            size={'lg'}
                                            showIcon={false}
                                            type="text"
                                            borderColor={'#A5AAAF'}
                                            placeholder={'序號'}
                                            onChange={handleFormChange}
                                            value={formData.model}
                                            maxLength={30}
                                            isDisabled={!isEditable}
                                            bg={!isEditable ? '#cfd1d4' : ''}
                                        ></TextInput>
                                    </FormControl>
                                </Box>
                            </Flex>

                            <Flex
                                direction={{ base: 'column', lg: 'row' }}
                                gap={4}
                                mt={{ base: 2, lg: 4 }}
                            >
                                <Box flex="1">
                                    <FormControl
                                        w={'full'}
                                        isRequired={true}
                                        mt={{ base: 4, lg: 2 }}
                                    >
                                        <FormLabel htmlFor="serialNumber" mb={1}>
                                            <TextScript
                                                text={'Serial number'}
                                                translationKey={`DeviceRegistration/lblSerialNo`}
                                            />
                                        </FormLabel>
                                        <TextInput
                                            id="serialNumber"
                                            name="serialNumber"
                                            size={'lg'}
                                            showIcon={false}
                                            type="text"
                                            borderColor={'#A5AAAF'}
                                            placeholder={'產品序號'}
                                            onChange={handleFormChange}
                                            value={formData.serialNumber}
                                            maxLength={30}
                                        />
                                        {errors.isSrNoError && (
                                            <Text color={'red'} mt={1} fontSize={'sm'}>
                                                <TextScript
                                                    text={'invalid serial number'}
                                                    translationKey={`DeviceRegistration/invalidSerialNo`}
                                                />
                                            </Text>
                                        )}
                                    </FormControl>
                                    <Box mt={1} width={'fit-content'}>
                                        <Link
                                            cursor={'pointer'}
                                            textDecoration={'underline'}
                                            onClick={() => {
                                                onClickPopup();
                                            }}
                                        >
                                            <TextScript
                                                text={'How to find your model and serial number'}
                                                translationKey={`DeviceRegistration/lblfindSerialNo`}
                                            />
                                        </Link>
                                    </Box>
                                </Box>
                                <Box flex="1" mt={2}>
                                    <FormControl isRequired>
                                        <FormLabel htmlFor="warrantyPeriod" mb={1}>
                                            <TextScript
                                                text={'OEM Warranty period'}
                                                translationKey={`DeviceRegistration/lblWPeriod`}
                                            />
                                        </FormLabel>
                                        <Select
                                            id="warrantyPeriod"
                                            name="warrantyPeriod"
                                            size={'lg'}
                                            borderColor={'#A5AAAF'}
                                            // bg={'#cfd1d4'}
                                            bg={!isEditable ? '#cfd1d4' : ''}
                                            placeholder="例: 原廠保固為1年, 選1"
                                            height={{ base: '60px', lg: '55px' }}
                                            onChange={handleFormChange}
                                            value={formData.warrantyPeriod}
                                            isDisabled={!isEditable}
                                        >
                                            <option>1 年</option>
                                            <option>2 年</option>
                                            <option>3 年</option>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Flex>
                            <Flex
                                direction={{ base: 'column', lg: 'row' }}
                                gap={4}
                                mt={{ base: 4, lg: 2 }}
                            >
                                <Box flex="1" mt={2}>
                                    <FormControl
                                        isRequired
                                        onClick={() => showPicker()}
                                        position={'relative'}
                                    >
                                        <FormLabel htmlFor="purchaseDate" mb={1}>
                                            <TextScript
                                                text={'Purchase date'}
                                                translationKey={`DeviceRegistration/lblPurchase_date`}
                                            />
                                        </FormLabel>
                                        <Input
                                            disabled={ true }
                                            id="purchaseDate"
                                            name="purchaseDate"
                                            size="md"
                                            borderColor={'#A5AAAF'}
                                            max={new Date().toISOString().split('T')[0]}
                                            min={
                                                new Date(
                                                    new Date().setDate(new Date().getDate() - 30)
                                                )
                                                    .toISOString()
                                                    .split('T')[0]
                                            }
                                            ref={inputRef}
                                            onChange={handleFormChange}
                                            display={'hidden'}
                                            position={'absolute'}
                                            cursor={'pointer'}
                                            style={{
                                                opacity: 0,
                                                overflow: 'hidden',
                                                zIndex: 999,
                                            }}
                                        />
                                        <InputGroup>
                                            <InputRightElement
                                                pointerEvents="none"
                                                marginTop={{ lg: '0px', base: '20px' }}
                                                marginRight={{ lg: '0px', base: '15px' }}
                                            >
                                                <CalendarIcon />
                                            </InputRightElement>
                                            <Input
                                                id="purchaseDate"
                                                name="purchaseDate"
                                                // onFocus={(e) => e.target.showPicker()}
                                                // bg={'#cfd1d4'}
                                                placeholder={'訂單的購買日期, 提交後不可修改'}
                                                borderColor={'#A5AAAF'}
                                                color={'brand.black'}
                                                size="md"
                                                type="text"
                                                value={formData.purchaseDate}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </Box>

                                <Box flex="1">
                                    <FormControl w={'full'} isRequired={true} mt={2}>
                                        <FormLabel htmlFor="purchasePrice" mb={1}>
                                            <TextScript
                                                text={'Laptop purchase price'}
                                                translationKey={`DeviceRegistration/lblPurchasePrice`}
                                            />
                                        </FormLabel>
                                        <TextInput
                                            id="purchasePrice"
                                            name="purchasePrice"
                                            size={'lg'}
                                            showIcon={false}
                                            type="text"
                                            borderColor={'#A5AAAF'}
                                            placeholder={'筆電購買價格'}
                                            onChange={handleFormChange}
                                            value={formData.purchasePrice}
                                            maxLength={30}
                                            isDisabled={!isEditable}
                                            bg={!isEditable ? '#cfd1d4' : ''}
                                        />
                                        {errors.purchasePriceError && (
                                            <Text color={'red'} mt={1} fontSize={'sm'}>
                                                <TextScript
                                                    text={'invalid Price'}
                                                    translationKey={`DeviceRegistration/invalidPrice`}
                                                />
                                            </Text>
                                        )}
                                    </FormControl>
                                </Box>
                            </Flex>
                        </Box>
                        <Box width={{ base: 'full', lg: '50%' }} marginX={'auto'}>
                            <Button
                                mt={8}
                                width={'full'}
                                textAlign={'center'}
                                colorScheme="primary"
                                bg={isDisabled ? 'gray.200' : colors.primary}
                                cursor={isDisabled ? 'not-allowed' : 'pointer'}
                                disabled={isDisabled}
                                isDisabled={isDisabled}
                                onClick={handleSubmit}
                            >
                                <TextScript text={'Continue'} translationKey={'Global/Continue'} />
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <FindSerialNoPopup
                closeCancelPopup={setOpenCancelPopup}
                openCancelPopup={openCancelPopup}
            />
        </>
    );
};

export default DeviceRegistrationInfo;
